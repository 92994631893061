import {Breadcrumb, Layout, Menu, Dropdown, Button} from 'antd';
import {DownloadOutlined, TranslationOutlined, UserOutlined, ZhihuOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useUser} from "../../../context/UserContext";
import {useEffect} from "react";
import ApiService from "../../../service/api-service";
import {LocalStorageService} from "../../../service/local-storage-service";
import {useHistory} from "react-router-dom";
import {Pages} from "../../../../AppRouter";
import {useApp} from "../../../context/AppContext";

const {SubMenu} = Menu;

export default function ProfileDropdown()
{
    const {t} = useTranslation();
    const {signOut, setUser, user} = useUser();
    const history = useHistory()

    const onClick = (item) => {
        if(item.key === 'logout')
        {
            signOut()
        }
        else if(item.key === 'dictionary')
        {
            history.push(Pages.User.Dictionary)
        }
        else if(['en', 'tr'].includes(item.key))
        {
            LocalStorageService.setLanguage(item.key)
            window.location.reload()
        }
    }

    const menu = (
        <Menu onClick={onClick}>
            <SubMenu icon={<ZhihuOutlined />} key={'languages'} title={'Language'}>
                <Menu.Item key="en">
                    English
                </Menu.Item>
                <Menu.Item key="tr">
                    Türkçe
                </Menu.Item>
            </SubMenu>
            <Menu.Item key="dictionary" icon={<TranslationOutlined />}>
                {t('Dictionary')}
            </Menu.Item>
            <Menu.Item key="download-viewer" icon={<DownloadOutlined />}>
                <a href={`${ApiService.RisApiEndpoint}/Public/launcher/ECloudpacsViewerInstaller.exe`} download>
                    {t('Viewer Download (Windows)')}
                </a>
            </Menu.Item>
            <Menu.Item key="logout" icon={<UserOutlined />}>
                {t('Logout')}
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} placement="bottomCenter" icon={<UserOutlined />}>
            <Button>
                <UserOutlined />
                {user && `${user.data.name}`}
            </Button>
        </Dropdown>
    )
}
