import {Input, Button, Tree, Tooltip} from 'antd';
import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import {CheckSquareOutlined, CloseCircleOutlined, DeleteOutlined, MinusSquareOutlined} from "@ant-design/icons";
import {MdOutlineCancel} from 'react-icons/md';
import ReactIcon from "./ReactIcon";

const filterData = (searchValue, source) => {
    if(!searchValue)
        return source;

    let data = [];
    source.map(item => {
        let text = (item.text || item.title || '').toLowerCase()
        if(text.includes(searchValue.toLowerCase()))
        {
            data.push(item)
        }
        else
        {
            if(item.children)
            {
                let filteredItems = filterData(searchValue, item.children)
                if(filteredItems.length > 0)
                {
                    data.push({
                        ...item,
                        children: filteredItems
                    })
                }
            }
        }
    })
    return data
}

const findItem = (key, source) => {
    if(!key)
        return source;

    let result = null;

    for (let i=0; i<source.length; i++)
    {
        if(source[i].key === key)
            return source[i];
        else
        {
            if(source[i].children)
            {
                const result = findItem(key, source[i].children);
                if(result)
                    return result;
            }
        }
    }
    return result
}

export default function SearchableTreeView({onCheckedChange, hideAllSelectable, treeItemData, checkedKeys, onSearch, searchText, treeViewProps = {}})
{
    const [_checkedKeys, set_checkedKeys] = useState(checkedKeys || [])
    const [treeData, setTreeData] = useState(treeItemData || [])
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [searchValue, setSearchValue] = useState('' || searchText);

    const {t} = useTranslation();

    const onCheck = (checkedKeys, e) => {
        if(e.checked)
        {
            const keys = Array.from(new Set([..._checkedKeys, ...checkedKeys]));
            const nodes = keys.map(x => findItem(x, treeItemData));
            set_checkedKeys(keys)
            onCheckedChange && onCheckedChange(keys, nodes)
        }
        else
        {
            const keys = _checkedKeys.filter(k => checkedKeys.includes(k));
            const nodes = keys.map(x => findItem(x, treeItemData));
            set_checkedKeys(keys)
            onCheckedChange && onCheckedChange(keys, nodes)
        }
    }

    const onExpand = (expandedKeys) => {
        setExpandedKeys(expandedKeys)
    }

    const onSearchChange = (e) => {
        e.preventDefault();

        const { value } = e.target;

        onSearch && onSearch(value)

        setTreeData(filterData(value, treeItemData))
        setSearchValue(value)
    }

    const reset = () => {
        setTreeData(treeItemData)
        setSearchValue('')
    }

    useEffect(() => {
        set_checkedKeys(checkedKeys || [])
    }, [checkedKeys])

    useEffect(() => {
        setTreeData(treeItemData || [])
    }, [treeItemData])

    useEffect(() => {
        setSearchValue(searchText)
    }, [searchText])

    const selectItems = (_treeItems) => {
        let keys = []
        _treeItems.forEach(x => {
            if(x.children) {
                keys = [...keys, ...(selectItems(x.children) || [])]
            }
            else {
                keys.push(x.key)
            }
        })

        return keys
    }

    return (
        <div>
            <Input
                style={{marginBottom: 8}}
                onChange={onSearchChange}
                value={searchValue}
                placeholder={t('Filter')} addonAfter={(
                    <div style={{display: 'flex', alignItems: 'center', gap:2}}>
                        <Tooltip title={t('Clear Filter')}>
                            <Button
                                size={'small'}
                                icon={<CloseCircleOutlined />}
                                onClick={reset}
                            />
                        </Tooltip>
                        {
                            !hideAllSelectable && (
                                <Tooltip title={t('Select All')}>
                                    <Button
                                        size={'small'}
                                        icon={<CheckSquareOutlined />}
                                        onClick={() => {
                                            let keys = selectItems(treeData)
                                            onCheck(keys, {checked: true})
                                            reset()
                                        }}
                                    />
                                </Tooltip>
                            )
                        }
                        <Tooltip title={t('Clear Selection')}>
                            <Button
                                size={'small'}
                                icon={<MinusSquareOutlined />}
                                onClick={() => {onCheck([], {checked: false})}}
                            />
                        </Tooltip>
                    </div>
                )}
            />
            <div style={{height: 500, overflowY: 'auto'}}>
                <Tree
                    onExpand={onExpand}
                    expandedKeys={expandedKeys}
                    checkedKeys={_checkedKeys}
                    checkable
                    onCheck={onCheck}
                    treeData={treeData}
                    {...treeViewProps}
                    style={{border: '1px solid #efefef', borderRadius: 4, padding: 4, ...(treeViewProps.style || {})}}
                />
            </div>
            <label>{`${t('Selected')}: ${_checkedKeys.length}`}</label>
        </div>
    )
}
