import {Button, Dropdown, Menu} from "antd";
import {PERMISSIONS, useUser} from "../../../core/context/UserContext";
import {v4} from "uuid";
import {t} from "i18next";
import ApiService from "../../../core/service/api-service";
import {DownOutlined} from "@ant-design/icons";
import ReportPreview from "../../report/ReportPreview";
import {DataGridProvider, useDataGrid} from "../../../core/components/data-grid/DataGridContext";
import {useModal} from "../../../core/context/ModalContext";
import RowMenu from "../../../core/components/data-grid/RowMenu";
import {Pages} from "../../../AppRouter";
import ReportModal from "../../../pages/report/ReportModal";
import {useEffect} from "react";
import ReportVersionsGrid from "../../report/ReportVersionsGrid";

export default function RequestRowMenu({studyId, row, hideReqRepPage})
{
    const {hasPermission} = useUser();
    const {refreshDataGrid} = useDataGrid()
    const {openModal, closeModal} = useModal()

    const openReportPreviewModal = (studyId, requestId, reportId) => {
        openModal(t('Report Preview'), (
            <ReportPreview
                studyId={studyId}
                requestId={requestId}
                reportId={reportId}
                onConfirmed={async () => {
                    await refreshDataGrid();
                }}
            />
        ))
    }

    return (
        <RowMenu text={row.id}>
            <Menu>
                {
                    !hideReqRepPage && hasPermission(PERMISSIONS.REPORT_ADD_OR_EDIT) && !row.reportIsConfirm && row.isRead && (
                        <Menu.Item
                            key={v4()}
                            onClick={() => {
                                openModal('Report', (
                                    <ReportModal
                                        studyId={studyId}
                                        requestId={row.id}
                                    />
                                ), null, window.innerWidth*0.98, 20)
                            }}
                        >
                            {t('Request & Reports')}
                        </Menu.Item>
                    )
                }
                {
                    !hideReqRepPage && hasPermission(PERMISSIONS.REPORT_LIST) && row.reportId && (
                        <Menu.Item
                            key={v4()}
                            onClick={() => {openReportPreviewModal(studyId, row.id, row.reportId)}}
                        >
                            {t('Report Preview')}
                        </Menu.Item>
                    )
                }
                {
                    hasPermission(PERMISSIONS.REPORT_CONFIRM) && row.reportId && !row.reportIsConfirm && (
                        <Menu.Item
                            key={v4()}>
                            <div
                                onClick={async () => {
                                    let response = await ApiService.Report.ConfirmReport(studyId, row.id, row.reportId);
                                    if(response.success)
                                    {
                                        await refreshDataGrid();
                                    }
                                }}
                            >
                                {t('Confirm Report')}
                            </div>
                        </Menu.Item>
                    )
                }
                {
                    hasPermission(PERMISSIONS.REPORT_REMOVE_CONFIRM) && row.reportId && row.reportIsConfirm && (
                        <Menu.Item
                            key={v4()}>
                            <div
                                onClick={async () => {
                                    let response = await ApiService.Report.RemoveReportConfirmation(studyId, row.id, row.reportId);
                                    if(response.success)
                                    {
                                        await refreshDataGrid();
                                    }
                                }}
                            >
                                {t('Remove Confirmation')}
                            </div>
                        </Menu.Item>
                    )
                }
                {
                    hasPermission(PERMISSIONS.REPORT_DOWNLOAD_PDF) && row.reportId && row.reportIsConfirm && (
                        <Menu.Item
                            key={v4()}
                        >
                            <div
                                onClick={async () => {
                                    const response = await ApiService.ReportGenerator.GetReportPdf(studyId, row.id, row.reportId);
                                    if(response.success)
                                    {
                                        ApiService.downloadFile(response.data, response.fileName);
                                    }
                                }}
                            >
                                {t('Download PDF')}
                            </div>
                        </Menu.Item>
                    )
                }
                {
                    hasPermission(PERMISSIONS.REPORT_DOWNLOAD_WORD) && row.reportId && row.reportIsConfirm && (
                        <Menu.Item
                            key={v4()}
                        >
                            <div
                                onClick={async () => {
                                    const response = await ApiService.ReportGenerator.GetReportWord(studyId, row.id, row.reportId)
                                    if(response.success)
                                    {
                                        ApiService.downloadFile(response.data, response.fileName);
                                    }
                                }}
                            >
                                {t('Download Word')}
                            </div>
                        </Menu.Item>
                    )
                }
                {
                    hasPermission(PERMISSIONS.STUDY_REQUEST_DELETE) && !row.reportIsConfirm && !row.isRequestDeleted && (
                        <Menu.Item
                            key={v4()}
                        >
                            <div
                                onClick={async () => {
                                    const response = await ApiService.Request.DeleteRequest(studyId, row.id)
                                    if(response.success)
                                    {
                                        refreshDataGrid()
                                    }
                                }}
                            >
                                {t('Delete')}
                            </div>
                        </Menu.Item>
                    )
                }
                {
                    hasPermission(PERMISSIONS.STUDY_REQUEST_RESTORE_DELETED) && row.isRequestDeleted && (
                        <Menu.Item
                            key={v4()}
                        >
                            <div
                                onClick={async () => {
                                    const response = await ApiService.Request.RestoreRequest(studyId, row.id)
                                    if(response.success)
                                    {
                                        refreshDataGrid()
                                    }
                                }}
                            >
                                {t('Restore')}
                            </div>
                        </Menu.Item>
                    )
                }
                {
                    !hideReqRepPage && hasPermission(PERMISSIONS.REPORT_VERSIONS) && row.reportId && (
                        <Menu.Item
                            key={v4()}
                            onClick={() => {
                                openModal('Report Versions', (
                                    <DataGridProvider>
                                        <ReportVersionsGrid
                                            studyId={studyId}
                                            reportId={row.reportId}
                                            requestId={row.id}
                                        />
                                    </DataGridProvider>
                                ), null, window.innerWidth*0.80, 20)
                            }}
                        >
                            {t('Report Versions')}
                        </Menu.Item>
                    )
                }
            </Menu>
        </RowMenu>
    )
}