import {useEffect, useState} from "react";
import ApiService from "../../core/service/api-service";
import Flex from "../../core/components/Flex";
import {PERMISSIONS, useUser} from "../../core/context/UserContext";
import {Button, Divider, notification, Space, Tooltip, Typography} from "antd";
import {t} from "i18next";
import printJS from 'print-js'
import './report-preview.css'
import {toast} from "react-toastify";

const Keys = {
    firmName: '{{firmName}}',
    hospitalName: '{{hospitalName}}',
    hospitalOfficialName: '{{hospitalOfficialName}}',
    patientId: '{{patientId}}',
    patientName: '{{patientName}}',
    gender: '{{gender}}',
    patientBirthdate: '{{patientBirthdate}}',
    age: '{{age}}',
    createdDate: '{{createdDate}}',
    accessionNumber: '{{accessionNumber}}',
    studyDate: '{{studyDate}}',
    modality: '{{modality}}',
    requestName: '{{requestName}}',
    complaintPeriod: '{{complaintPeriod}}',
    diagnosis: '{{diagnosis}}',
    medicalHistory: '{{medicalHistory}}',
    patientComplaint: '{{patientComplaint}}',
    physicalExamination: '{{physicalExamination}}',
    clinicalInformation: '{{clinicalInformation}}',
    previousExaminations: '{{previousExaminations}}',
    doctorName: '{{doctorName}}',
    doctorReportName: '{{doctorReportName}}',
    doctorSign: '{{doctorSign}}',
    teknik: 'ECAHUAQD',
    bulgular: 'AZCMSRCT',
    karsilastirma: 'QWDGGXPD',
    sonucVeOneri: 'SUFHFZNM'
}

export default function ReportPreview({studyId, requestId, reportId, onConfirmed, onDataChanged, disableActions})
{
    const [report, setReport] = useState({});
    const [reportModel, setReportModel] = useState({
        reportFields: []
    });
    const {hasPermission} = useUser()

    const getReport = async () => {
        const response = await ApiService.Report.Get(studyId, requestId, reportId)
        if(response.success)
        {
            setReport(response.data)
        }
    }

    const getReportModel = async () => {
        const response = await ApiService.ReportGenerator.GetReportModel(studyId, requestId, reportId)
        if(response.success)
        {
            setReportModel(response.data)
        }
    }

    const get = async () => {
        await getReport();
        await getReportModel()
    }

    useEffect(() => {
        get()
    }, [])

    const confirm = async () => {
        const response = await ApiService.Report.ConfirmReport(studyId, requestId, reportId);
        if(response.success)
        {
            onConfirmed && onConfirmed()
            await get();
            onDataChanged && onDataChanged()
        }
    }

    const print = async () => {
        const response = await ApiService.ReportGenerator.GetReportPdf(studyId, requestId, reportId);
        if(response.success)
        {
            let url = response.data;
            printJS(url)
        }
    }

    const getData = (key, defaultData = '') => {
        const field = reportModel.reportFields.find(x => x.key === key);
        if(!field || !field.data)
            return defaultData || '';

        return field.data;
    }

    const labelStyle = {
        color: '#ff0000',
        marginRight: 4,
        fontWeight: 'bold'
    }

    const onMouseHoverCopy = () => {
        document.getElementById('report-content').style.backgroundColor = '#3498db77';
        document.getElementById('report-content').style.borderRadius = '16px';
    }

    const onMouseLeaveCopy = () => {
        document.getElementById('report-content').style.backgroundColor = '#ffffff';
    }

    const copy = () => {
        onMouseLeaveCopy()
        const temp = document.createElement("div");
        temp.style.backgroundColor = '#fff'
        temp.setAttribute("contentEditable", true);
        temp.innerHTML = document.getElementById('report-content').innerHTML;
        temp.setAttribute("onfocus", "document.execCommand('selectAll',false,null)");
        document.body.appendChild(temp);
        temp.focus();
        document.execCommand("copy");
        document.body.removeChild(temp);
        toast.success(t('Report Content was copied.'))
    }

    const setHbysTransfer = async () => {
        if(!report.isHbysTransfer)
        {
            const response = await ApiService.Report.SetHbysTransfer(studyId, requestId, reportId, true);
            onDataChanged && onDataChanged()
        }
        copy()
    }

    return (
        <>
            {
                !disableActions && (
                    <Space flexDirection={'row'}>
                        {
                            hasPermission(PERMISSIONS.REPORT_CONFIRM) && !report.isConfirm && (
                                <Button onClick={confirm} type={'primary'}>{t('Confirm Report')}</Button>
                            )
                        }
                        {
                            hasPermission(PERMISSIONS.REPORT_DOWNLOAD_PDF) && report.isConfirm && (
                                <Button type={'primary'} onClick={print}>{t('Print')}</Button>
                            )
                        }
                        <Button onMouseOver={onMouseHoverCopy} onMouseLeave={onMouseLeaveCopy} onClick={copy}>{t('Copy')}</Button>
                        {
                            hasPermission(PERMISSIONS.REPORT_HBYS_TRANSFER) && report.isConfirm && (
                                <Tooltip title={t('Copy report content and set status to transferred to hbys')}>
                                    <Button onMouseOver={onMouseHoverCopy} onMouseLeave={onMouseLeaveCopy} onClick={setHbysTransfer}>{t('Copy & Hbys')}</Button>
                                </Tooltip>
                            )
                        }
                    </Space>
                )
            }
            <Flex flexDirection={'column'} style={{fontSize: '10pt'}}>
                {
                    reportModel.reportFields.length > 0 && (
                        <>
                            <Typography.Title level={3} style={{flex: 1, textAlign: 'center'}}>
                                {getData(Keys.hospitalOfficialName, '')}
                            </Typography.Title>
                            <Flex justifyContent={'space-between'}>
                                <table>
                                    <tr>
                                        <td>
                                            <Typography.Text style={labelStyle}>
                                                {t('Patient Name')+':'}
                                            </Typography.Text>
                                        </td>
                                        <td>
                                            <Typography.Text>
                                                {getData(Keys.patientName)}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography.Text style={labelStyle}>
                                                {t('Accession Number')+':'}
                                            </Typography.Text>
                                        </td>
                                        <td>
                                            <Typography.Text>
                                                {getData(Keys.accessionNumber, '--')}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography.Text style={labelStyle}>
                                                {t('Patient Id')+':'}
                                            </Typography.Text>
                                        </td>
                                        <td>
                                            <Typography.Text>
                                                {getData(Keys.patientId)}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography.Text style={labelStyle}>
                                                {t('Birthdate')+':'}
                                            </Typography.Text>
                                        </td>
                                        <td>
                                            <Typography.Text>
                                                {getData(Keys.patientBirthdate)}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr>
                                        <td>
                                            <Typography.Text style={labelStyle}>
                                                {t('Study Date')+':'}
                                            </Typography.Text>
                                        </td>
                                        <td>
                                            <Typography.Text>
                                                {getData(Keys.studyDate)}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography.Text style={labelStyle}>
                                                {t('Report Date')+':'}
                                            </Typography.Text>
                                        </td>
                                        <td>
                                            <Typography.Text>
                                                {getData(Keys.createdDate, '--')}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography.Text style={labelStyle}>
                                                {t('Modality')+':'}
                                            </Typography.Text>
                                        </td>
                                        <td>
                                            <Typography.Text>
                                                {getData(Keys.modality)}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography.Text style={labelStyle}>
                                                {t('Gender')+':'}
                                            </Typography.Text>
                                        </td>
                                        <td>
                                            <Typography.Text>
                                                {getData(Keys.gender)}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                </table>
                            </Flex>
                            <Divider />
                            <div id={'report-content'} style={{padding: 8}}>
                                <Typography.Title level={5}>
                                    Sayın meslektaşım hastanızın {report.request.requestType.displayName} incelemesinde :
                                </Typography.Title>
                                <Typography.Text style={labelStyle}>
                                    {t('Teknik')+':'}
                                </Typography.Text>
                                <div dangerouslySetInnerHTML={{__html: getData(Keys.teknik, '--')}} />
                                <Typography.Text style={labelStyle}>
                                    {t('Clinical Information')+':'}
                                </Typography.Text>
                                <br/>
                                <Typography.Text>
                                    {getData(Keys.clinicalInformation, '--')}
                                </Typography.Text>
                                <br/><br/>
                                <Typography.Text style={labelStyle}>
                                    {t('Bulgular')+':'}
                                </Typography.Text>
                                <div dangerouslySetInnerHTML={{__html: getData(Keys.bulgular, '--')}} />
                                <Typography.Text style={labelStyle}>
                                    {t('Karşılaştırma')+':'}
                                </Typography.Text>
                                <div dangerouslySetInnerHTML={{__html: getData(Keys.karsilastirma, '--')}} />
                                <Typography.Text style={labelStyle}>
                                    {t('Sonuç ve Öneri')+':'}
                                </Typography.Text>
                                <div dangerouslySetInnerHTML={{__html: getData(Keys.sonucVeOneri, '--')}} />
                                <Typography.Text style={{fontWeight: 'bold'}}>
                                    {getData(Keys.doctorReportName, '--')}
                                </Typography.Text>
                            </div>
                            <Flex flexDirection={'column'}>
                                <img width={120} height={120} src={'data:image/*;base64,'+getData(Keys.doctorSign, '--')} />
                            </Flex>
                        </>
                    )
                }
            </Flex>
        </>
    )
}
