import ApiService from "../../../../../core/service/api-service";
import {useEffect, useState} from "react";
import {Button, Table} from "antd";
import pacsSystemColumns from "./PacsSystemColumns";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {useModal} from "../../../../../core/context/ModalContext";
import PacsSystemCreateEdit from "../../../PacsSystemCreateEdit";

export default function PacsSystemGrid({hospitalId})
{
    const {openModal, closeModal} = useModal();
    const {t} = useTranslation();
    const history = useHistory();
    const [data, setData] = useState([]);

    const getPacsSystems = async () => {
        const response = await ApiService.PacsSystem.GetList(hospitalId)
        if(response.success)
        {
            setData(response.data)
        }
    }

    useEffect(() => {
        getPacsSystems()
    }, [])

    const openPacsModal = (pacsSystemId) => {
        openModal(t('Create Pacs System'), (
            <PacsSystemCreateEdit
                hospitalId={hospitalId}
                pacsSystemId={pacsSystemId}
                onSuccess={async () => {
                    closeModal()
                    await getPacsSystems()
                }}
            />
        ))
    }

    return (
        <>
            <Button
                size={'small'}
                style={{marginBottom: 8}}
                onClick={() => openPacsModal(null)}
            >
                {t('Create Pacs System')}
            </Button>
            <Table
                size={'small'}
                dataSource={data}
                columns={pacsSystemColumns(t, history, hospitalId, (pacsSystemId) => openPacsModal(pacsSystemId))}
                pagination={false}
            />
        </>
    )
}
