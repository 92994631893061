import RowMenu from "../../../core/components/data-grid/RowMenu";
import {Menu} from "antd";
import {t} from "i18next";
import {useHistory} from "react-router-dom";
import {Pages} from "../../../AppRouter";
import {useModal} from "../../../core/context/ModalContext";
import UserHospital from "../UserHospitalEdit";

export default function UserRowMenu({row})
{
    const history = useHistory();
    const {openModal, closeModal} = useModal()

    return (
        <>
            <RowMenu text={row.id}>
                <Menu>
                    <Menu.Item
                        onClick={() => {
                            history.push(Pages.User.Edit(row.id))
                        }}
                    >
                        {t('Edit')}
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => {
                            openModal(t('User Hospitals'), (
                                <UserHospital userId={row.id}/>
                            ))
                        }}
                    >
                        {t('Edit Hospitals')}
                    </Menu.Item>
                </Menu>
            </RowMenu>
        </>
    )
}
