import ApiService from "../../core/service/api-service";
import {useEffect, useState} from "react";
import _ from 'lodash'
import {v4} from "uuid";
import SearchableTreeView from "../../core/components/SearchableTreeView";
import {Button} from "antd";
import {t} from "i18next";

export default function UserHospital({userId})
{
    const [user, setUser] = useState({});
    const [hospitals, setHospitals] = useState([]);
    const [modalities, setModalities] = useState([])
    const [treeData, setTreeData] = useState([])
    const [selectedData, setSelectedData] = useState([]);

    const getHospitals = async () => {
        const response = await ApiService.Hospital.GetList();
        if(response.success)
        {
            setHospitals(response.data)
        }
    }

    const getModalities = async () => {
        const response = await ApiService.Modality.GetList()
        if(response.success)
        {
            setModalities(response.data);
        }
    }

    const getUser = async () => {
        if(userId)
        {
            const response = await ApiService.UserManager.GetUser(userId);
            setUser(response.data)
        }
    }

    const get = async () => {
        await getHospitals()
        await getModalities()
        await getUser()
    }

    const save = async () => {
        const grouped = _.groupBy(selectedData.filter(x => !['h', 'f'].includes(x[0])), s => {
            const arr = s.split('-');
            return arr.slice(0, arr.length-1).join('-')
        });

        const response = await ApiService.UserManager.SetHospitals(userId,Object.keys(grouped).map(key => {
            const splitted = key.split('-');
            const modalities = grouped[key];
            const hospitalId = splitted[splitted.length-1];
            return {
                hospitalId,
                modalityIdList: modalities.map(m => {
                    const s = m.split('-');
                    return parseInt(s[s.length-1])
                })
            }
        }))

    }

    useEffect(() => {
        let keys = [];
        user?.hospitalUsers?.forEach(iu => {
            iu.modalityIdList.forEach(mId => {
                keys.push(`m-${iu.hospitalId}-${mId}`)
            })
        })

        setSelectedData(keys)
    }, [user])

    useEffect(() => {
        const grouped = _.groupBy(hospitals, h => h.firm.name);
        setTreeData(Object.keys(grouped).map(firm => {
            return {
                title: firm,
                key: `f-${v4()}`,
                children: grouped[firm].map(h => {
                    return {
                        title: h.name,
                        key: `h-${h.id}`,
                        children: modalities.filter(x => h.modalityIdList.findIndex(y => y === x.id) > -1).map(m => {
                            return {
                                title: m.name,
                                key: `m-${h.id}-${m.id}`
                            }
                        })
                    }
                })
            }
        }))

    }, [hospitals, modalities])

    useEffect(() => {
        get()
    }, [])

    return (
        <>
            <SearchableTreeView
                checkedKeys={selectedData}
                treeItemData={treeData}
                onCheckedChange={(keys) => {
                    setSelectedData(keys)
                }}
                treeViewProps={{
                    minHeight: 230,
                    style: {minHeight: 250}
                }}
            />

            <div
                style={{display: 'flex', alignItems: 'center', gap: 16}}
            >
                <Button type={"text"} onClick={() => {setSelectedData([])}}>
                    Clear
                </Button>
                <Button
                    onClick={save}
                    type={'primary'}
                >
                    {t('Save')}
                </Button>
            </div>
        </>
    )
}
