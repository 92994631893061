import {PERMISSIONS, useUser} from "../../../core/context/UserContext";
import {useDataGrid} from "../../../core/components/data-grid/DataGridContext";
import {useModal} from "../../../core/context/ModalContext";
import {Button, Dropdown, Menu} from "antd";
import {v4} from "uuid";
import {t} from "i18next";
import ApiService from "../../../core/service/api-service";
import {DownOutlined} from "@ant-design/icons";
import RowMenu from "../../../core/components/data-grid/RowMenu";

export default function VoiceRecordRowMenu({row, studyId})
{
    const {hasPermission} = useUser();
    const {refreshDataGrid} = useDataGrid()
    const {openModal, closeModal} = useModal()

    return (
        <RowMenu text={row.id}>
            <Menu>
                {
                    hasPermission(PERMISSIONS.VOICE_RECORD_DELETE) && !row.isDeleted && (
                        <Menu.Item
                            key={v4()}
                        >
                            <div
                                onClick={async () => {
                                    const response = await ApiService.VoiceRecord.DeleteVoiceRecord(studyId, row.id)
                                    if(response.success)
                                    {
                                        refreshDataGrid()
                                    }
                                }}
                            >
                                {t('Delete')}
                            </div>
                        </Menu.Item>
                    )
                }
                {
                    hasPermission(PERMISSIONS.VOICE_RECORD_RESTORE_DELETED) && row.isDeleted && (
                        <Menu.Item
                            key={v4()}
                        >
                            <div
                                onClick={async () => {
                                    const response = await ApiService.VoiceRecord.RestoreVoiceRecord(studyId, row.id)
                                    if(response.success)
                                    {
                                        refreshDataGrid()
                                    }
                                }}
                            >
                                {t('Restore')}
                            </div>
                        </Menu.Item>
                    )
                }
            </Menu>
        </RowMenu>
    )
}