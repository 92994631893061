import {DATE_OPTIONS, getDateFromNow} from "../../utils/date-utils";
import {Button, Dropdown, Menu, Select, Space} from "antd";
import {t} from "i18next";
import Flex from "../Flex";
import {useState} from "react";
import {DownOutlined, TranslationOutlined, UserOutlined, ZhihuOutlined} from "@ant-design/icons";

export default function QuickDatePicker({onChange})
{
    const [val, setVal] = useState(null)

    const onClick = (key) => {
        const date = getDateFromNow(DATE_OPTIONS[key.key]);
        onChange && onChange(date.start, date.end)
    }

    const menu = (
        <Menu onClick={onClick}>
            {
                Object.keys(DATE_OPTIONS).filter(x => !['TODAY', 'YESTERDAY', 'LAST_2_DAYS'].includes(x)).map(key => (
                    <Menu.Item key={key}>
                        {t(DATE_OPTIONS[key])}
                    </Menu.Item>
                ))
            }
        </Menu>
    )

    return (
        <div>
            <Flex flexDirection={'row'} flexWrap={'wrap'}>
                <Button
                    size={'small'}
                    style={{width: 50}}
                    onClick={() =>
                    {
                        const date = getDateFromNow(DATE_OPTIONS.TODAY);
                        onChange && onChange(date.start, date.end)
                    }}
                    key={DATE_OPTIONS.TODAY}
                    value={DATE_OPTIONS.TODAY}
                >
                    {t(DATE_OPTIONS.TODAY)}
                </Button>
                <Button
                    size={'small'}
                    style={{width: 62}}
                    onClick={() =>
                    {
                        const date = getDateFromNow(DATE_OPTIONS.YESTERDAY);
                        onChange && onChange(date.start, date.end)
                    }}
                    key={DATE_OPTIONS.YESTERDAY}
                    value={DATE_OPTIONS.YESTERDAY}
                >
                    {t(DATE_OPTIONS.YESTERDAY)}
                </Button>
                <Button
                    size={'small'}
                    style={{width: 50}}
                    onClick={() =>
                    {
                        const date = getDateFromNow(DATE_OPTIONS.LAST_2_DAYS);
                        onChange && onChange(date.start, date.end)
                    }}
                    key={DATE_OPTIONS.LAST_2_DAYS}
                    value={DATE_OPTIONS.LAST_2_DAYS}
                >
                    {t(DATE_OPTIONS.LAST_2_DAYS)}
                </Button>
                <Dropdown overlay={menu}>
                    <Button size={'small'}>
                        <DownOutlined />
                    </Button>
                </Dropdown>
                {/*<Select*/}
                {/*    placeholder={t('Quick Date Picker')}*/}
                {/*    value={null}*/}
                {/*    onChange={(val) => {*/}
                {/*        const date = getDateFromNow(val);*/}
                {/*        onChange && onChange(date.start, date.end)*/}
                {/*        setVal(null)*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {*/}

                {/*    }*/}
                {/*</Select>*/}
            </Flex>
        </div>
    )
}
