import {v4} from "uuid";
import {useEffect, useRef} from "react";

export default function AudioPlayerNavigator({children, onContinue, onStop, onSeek, onBack}) {
    const socket = useRef(null)

    useEffect(() => {
        socket.current = new WebSocket("ws://127.0.0.1:4010");
        socket.current.onopen = (evt) => {
            console.log('Connected to Pedal Service')
        }

        socket.current.onmessage = (evt) => {
            if(evt.data === 'PLAY') {
                onContinue && onContinue()
            }
            else if(evt.data === 'FORWARD') {
                onContinue && onContinue()
            }
            else if(evt.data === 'BACKWARD') {
                onContinue && onContinue()
            }
            else if(evt.data === 'PAUSE') {
                onStop && onStop()
            }
        }

        return () => {
            if(socket.current)
                socket.current.close()
        }
    }, []);

    return (
        <div id={'audio-player-navigator'} />
    )
}