import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import {Button, Card, Col, Divider, Row, Select, Space, Tabs, Typography} from "antd";
import ApiService from "../../core/service/api-service";
import ReportCreateEditForm from "../../app/report/ReportCreateEditForm";
import StudyInfo from "../../app/study/StudyInfo";
import StudyRequestGrid from "../../app/request/request-data-grid/StudyRequestGrid";
import {DataGridProvider} from "../../core/components/data-grid/DataGridContext";
import { Scrollbars } from 'react-custom-scrollbars';
import {v4} from "uuid";
import {PERMISSIONS, useUser} from "../../core/context/UserContext";
import VoiceRecordGrid from "../../app/voice-record/voice-record-grid/VoiceRecordGrid";
import {useApp} from "../../core/context/AppContext";
import UrlHelper from "../../core/utils/url-helper";

export default function ReportModal({studyId, requestId, onClose})
{
    const {t} = useTranslation()
    const selectedRequestId = requestId

    const [request, setRequest] = useState({})
    const {hasPermission} = useUser()
    const [anamnez, setAnamnez] = useState(null)
    const [listOtherVoice, setListOtherVoice]= useState(false)

    const [updateKey, setUpdateKey] = useState('')

    useEffect(() => {
        return () => {
            onClose && onClose()
        }
    }, []);

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} xl={8}>
                    <StudyInfo
                        studyId={studyId}
                        onChangeAnamnez={(data) => setAnamnez(data)}
                    />
                    <Divider style={{margin: 0, marginTop:6, marginBottom: 0}} />
                    <Tabs
                        onChange={(e) => {
                            if(e === 'voice-record')
                                setListOtherVoice(false)
                            else
                                setListOtherVoice(true)
                        }}
                    >
                        <Tabs.TabPane key={'voice-record'} tab={t('Voice Records')} />
                        <Tabs.TabPane key={'other-voice-record'} tab={t('Other Voice Records')} />
                    </Tabs>
                    {
                        hasPermission(PERMISSIONS.VOICE_RECORD_LIST) && (
                            <DataGridProvider>
                                <VoiceRecordGrid studyId={studyId} listOther={listOtherVoice}/>
                            </DataGridProvider>
                        )
                    }
                    <Divider style={{margin: 0, marginTop:6, marginBottom: 0}} />
                    <Tabs>
                        <Tabs.TabPane tab={t('Requests')} key={'Request'}>
                            <DataGridProvider>
                                <StudyRequestGrid
                                    hideReqRepPage={true}
                                    listOther={false}
                                    selectedRequestId={selectedRequestId}
                                    updateKey={updateKey}
                                    studyId={studyId}
                                    name={'request-report-table'}
                                    onSelected={(req, type) => {
                                        setRequest({
                                            ...req
                                        })
                                    }}
                                />
                            </DataGridProvider>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={t('Other Requests')} key={'Other Requests'}>
                            <DataGridProvider>
                                <StudyRequestGrid
                                    hideReqRepPage={true}
                                    listOther={true}
                                    selectedRequestId={selectedRequestId}
                                    updateKey={updateKey}
                                    studyId={studyId}
                                    name={'request-report-table'}
                                    onSelected={(req, type) => {
                                        setRequest({
                                            ...req
                                        })
                                    }}
                                />
                            </DataGridProvider>
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
                <Col xs={24} xl={16}>
                    <Typography.Title level={5}>{t('Report')} - {request?.requestTypeName}</Typography.Title>
                    <ReportCreateEditForm
                        studyId={request.studyId}
                        request={request}
                        onSaved={async () => {
                            setUpdateKey(v4())
                            if(anamnez) {
                                const res = await ApiService.StudyAnamnez.EditClinicalInformation(request.studyId, anamnez)
                            }
                        }}
                    />
                </Col>
            </Row>
        </>
    )
}
