import {useDataGrid} from "../../../core/components/data-grid/DataGridContext";
import DataGrid, {RenderableExpandRow} from "../../../core/components/data-grid/DataGrid";
import {useEffect, useRef} from "react";
import {t} from "i18next";
import ApiService from "../../../core/service/api-service";
import HL7RequestRowMenu from "./HL7RequestRowMenu";
import HL7GridTools from "./HL7GridTools";
import {useModal} from "../../../core/context/ModalContext";
import Colors from "../../../core/style/Colors";
import {Input, notification, Popover, Typography} from "antd";
import HL7ReportRowMenu from "./HL7ReportRowMenu";

export default function HL7MessageGrid({})
{
    const {setData, setPaginationResult, queryModel, setDataGridProps, setRowColor, refreshDataGrid, setSelectedRows, tableId} = useDataGrid();
    const {openModal, closeModal} = useModal()

    const manualMatch = async (messageId, studyId) => {
        if(!studyId)
        {
            notification.error({
                message: t('Study Id is empty')
            })
            return
        }

        const res = await ApiService.HL7Service.ManualMatchHL7(messageId, studyId)
        if(res.success) {
            refreshDataGrid()
        }
    }

    useEffect(() => {
        let columns = [
            {
                title: t('Created Date'),
                dataIndex: 'createdDate',
                key: 'createdDate',
                columnName: 'createdDate',
                render: (text, record) => new Date(record.createdDate).toLocaleString()
            },
            {
                title: t('Hospital'),
                dataIndex: 'hospital',
                key: 'hospital',
                columnName: 'hospital',
                render: (text, record) => record?.hospital?.name
            },
            {
                title: t('Study'),
                dataIndex: 'studyId',
                key: 'studyId',
                columnName: 'studyId',
                render: (text, record) => (
                    <>
                        <Input.Search
                            size={'small'}
                            placeholder={record?.studyId || '-'}
                            onSearch={(value) => manualMatch(record.id,  value)}
                            style={{ width: 100 }} />
                    </>
                )
            },
            {
                title: t('Patient Id'),
                dataIndex: 'patientId',
                key: 'patientId',
                columnName: 'patientId',
                render: (text, record) => `${record?.hl7Message?.patientHbysId} | ${record?.hl7Message?.patientId}`
            },
            {
                title: t('Accesion Number'),
                dataIndex: 'accessionNumber',
                key: 'accessionNumber',
                columnName: 'accessionNumber',
                render: (text, record) => record?.hl7Message?.accessionNumber
            },
            {
                title: t('Modality'),
                dataIndex: 'modality',
                key: 'modality',
                columnName: 'modality',
                render: (text, record) => record?.hl7Message?.modality
            },
            {
                title: t('Request Date'),
                dataIndex: 'createdDate',
                key: 'createdDate',
                columnName: 'createdDate',
                render: (text ,record) => {
                    return (
                        <HL7RequestRowMenu row={record} text={new Date(text).toLocaleString()} />
                    )
                }
            },
            {
                title: t('Req. Description'),
                dataIndex: 'description',
                key: 'description',
                columnName: 'description',
            },
            {
                title: t('Req. Completed'),
                dataIndex: 'completedDate',
                key: 'completedDate',
                columnName: 'completedDate',
                render: (text ,record) => text ? new Date(text).toLocaleString() : '-'
            },
            {
                title: t('Req. Status'),
                dataIndex: 'processStatus',
                key: 'processStatus',
                columnName: 'processStatus',
                render: (text, record) => {
                    let status = 'Waiting'
                    switch (text) {
                        case 0: status = t('Waiting'); break;
                        case 1: status = t('Complete'); break;
                        case 2: status = t('Error'); break;
                        case 3: status = t('Continue'); break;
                    }

                    return (
                        <RequestBody>
                            {status}
                        </RequestBody>
                    )
                }
            },
            {
                title: t('Report Date'),
                dataIndex: '#1',
                key: '#1',
                columnName: '#1',
                render: (text ,record) => {
                    if(record.report) {
                        return (
                            <HL7ReportRowMenu
                                row={record?.report}
                                text={record?.report?.createdDate ? new Date(record?.report?.createdDate).toLocaleString() : '-'}
                            />
                        )
                    }
                    else {
                        return '-'
                    }
                }
            },
            {
                title: t('ACK'),
                dataIndex: '#4',
                key: '#4',
                columnName: '#4',
                width: 200,
                render: (text, record) => {
                    let ack = record?.report?.ack
                    let message = '-'
                    if(ack) {
                        const split = ack.split('|').filter(x => x?.toString()?.trim())
                        message = split[split.length-1]
                    } else {
                        ack = '-'
                    }

                    return (
                        <Popover
                            placement={"leftBottom"}
                            content={(
                                <Typography.Text style={{width: 200}}>
                                    <div style={{whiteSpace: 'pre-wrap'}}>
                                        {ack}
                                    </div>
                                </Typography.Text>
                            )}
                        >
                            <Typography.Text ellipsis={true} style={{width: 200, fontSize: '9pt'}}>
                                {message}
                            </Typography.Text>
                        </Popover>
                    )
                }
            },
            {
                title: t('Report Completed'),
                dataIndex: '#2',
                key: '#2',
                columnName: '#2',
                render: (text ,record) => record?.report?.completedDate ? new Date(record?.report?.completedDate).toLocaleString() : '-'
            },
            {
                title: t('Report Status'),
                dataIndex: '#3',
                key: '#3',
                columnName: '#3',
                render: (text, record) => {
                    let status = t('Waiting');
                    switch (record?.report?.processStatus) {
                        case 0: status = t('Waiting'); break;
                        case 1: status = t('Complete'); break;
                        case 2: status = t('Error'); break;
                        case 3: status = t('Continue'); break;
                        default: status = '-'; break;
                    }

                    return (
                        <ReportBody>
                            {status}
                        </ReportBody>
                    )
                }
            },
        ];

        setDataGridProps({
            rowKey: 'id',
            columns: columns,
            antdTableProps: {
                scroll: {x: 800},
            },
            rowClassName: (record, index) => {

            },
            enableRowSelection: true,
            onRow: (record) => {
                return {
                    onClick: () => {
                        setRowColor(record.id, Colors.selectedRow);
                    },
                    onDoubleClick: () => {
                        setRowColor(record.id, Colors.selectedRow);
                    }
                }
            }
        })
    }, [])

    const getHL7Messages = async () => {
        const response = await ApiService.HL7Service.GetDataGrid(queryModel);
        if(response.success)
        {
            setData(response.data);
            setPaginationResult(response.pagination)
        }
    }

    useEffect(() => {
        getHL7Messages()
    }, [queryModel.updateKey])

    return (
        <>
            <DataGrid
                name={'hl7-message-table'}
                title={() => <HL7GridTools />}
            />
        </>
    )
}

const RequestBody = ({children}) => {
    return (
        <div style={{backgroundColor: '#f6e58d', paddingLeft: 4}}>
            {children}
        </div>
    )
}

const ReportBody = ({children}) => {
    return (
        <div style={{backgroundColor: '#7efff5', paddingLeft: 4}}>
            {children}
        </div>
    )
}