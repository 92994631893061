import DataGrid from "../../core/components/data-grid/DataGrid";
import {Button, Card} from "antd";
import {useTranslation} from "react-i18next";
import ResponsiveLayout from "../../core/components/ResponsiveLayout";
import {useHistory} from "react-router-dom";
import Flex from "../../core/components/Flex";
import CardTitle from "../../core/components/CardTitle";
import {Pages} from "../../AppRouter";
import {DataGridProvider} from "../../core/components/data-grid/DataGridContext";
import LayoutWithSider from "../../core/components/layout/layout-with-sider/LayoutWithSider";
import ReportTemplateFilter from "../../app/report-template/report-template-grid/ReportTemplateFilter";
import ReportTemplateGrid from "../../app/report-template/report-template-grid/ReportTemplateGrid";
import UserGrid from "../../app/user/user-data-grid/UserGrid";
import UserFilter from "../../app/user/user-data-grid/UserFilter";
import {useEffect} from "react";

export default function UserDataGridPage()
{
    const {t} = useTranslation();
    const history = useHistory();

    useEffect(() => {
        document.title = `ECloud Pacs - ${t('Users')}`
    }, []);
    const Title = () => {

        return (
            <Flex justifyContent={'space-between'}>
                <CardTitle title={t('Users')} />
                <Button
                    onClick={() => {
                        history.push(Pages.User.Create)
                    }}
                >
                    {t('New User')}
                </Button>
            </Flex>
        )
    }

    return (
        <>
            <DataGridProvider>
                <LayoutWithSider
                    fullWidth={true}
                    sidebarTitle={t('User Filter')}
                    sidebarComponent={(
                        <UserFilter/>
                    )}
                >
                    <Card title={<Title />}>
                        <UserGrid />
                    </Card>
                </LayoutWithSider>
            </DataGridProvider>
        </>
    )
}
