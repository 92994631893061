const KeycloakApiEndpoint = window.AppSettings.server && `${window.AppSettings.server.keycloak}`

async function auth(username, password)
{
    const form = {
        username,
        password,
        'grant_type': 'password',
        'client_id': 'ris-app'
    }

    let formBody = [];
    for (let property in form) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(form[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    try
    {
        const resp = await fetch(`${KeycloakApiEndpoint}/auth/realms/master/protocol/openid-connect/token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: formBody
        })

        if(resp.status !== 200)
        {
            return false;
        }

        const data = await resp.json();

        return {
            access_token: data.access_token,
            refresh_token: data.refresh_token
        }
    }
    catch (e)
    {
        return false;
    }
}

const Keycloak = {
    auth,
    KeycloakApiEndpoint
}

export default Keycloak;
