import {useEffect, useState} from "react";
import ApiService from "../../core/service/api-service";
import {useParams} from "react-router-dom";
import SearchableTreeView from "../../core/components/SearchableTreeView";
import {Button, Card} from "antd";
import {t} from "i18next";
import UserCreateEdit from "../../app/user/UserCreateEdit";
import LayoutWithSider from "../../core/components/layout/layout-with-sider/LayoutWithSider";
import HospitalUserPermission from "../../app/hospital/HospitalUserPermission";

export default function HospitalUserPermissionPage()
{
    const {hospitalId} = useParams();

    return (
        <>
            <LayoutWithSider>
                <Card title={t('Hospital Users Permission')}>
                    <HospitalUserPermission hospitalId={hospitalId} />
                </Card>
            </LayoutWithSider>
        </>
    )
}
