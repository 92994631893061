import {useEffect, useState} from "react";
import ApiService from "../../core/service/api-service";
import {Button, Select, Typography} from "antd";
import {t} from "i18next";

export default function StudyUserAssignModal({onSelected, selectDoctor, selectReporter}) {
    const [doctors, setDoctors] = useState([])
    const [reporters, setReporters] = useState([])

    const [selectedDoctor, setSelectedDoctor] = useState(null)
    const [selectedReporter, setSelectedReporter] = useState(null)

    const getDoctors = async () => {
        const res = await ApiService.UserManager.GetReadableUsers();
        if(res.success) {
            setDoctors(res.data)
        }
    }

    const getReporters = async () => {
        const res = await ApiService.UserManager.GetWritableUsers();
        if(res.success) {
            setReporters(res.data)
        }
    }

    const init = async () => {
        selectDoctor && await getDoctors()
        selectReporter && await getReporters()
    }

    useEffect(() => {
        init()
    }, [])

    return (
        <>
            {
                selectDoctor && (
                    <div
                        style={{display: 'flex', alignItems: 'center', gap: 8, marginBottom: 8}}
                    >
                        <Typography.Text style={{width: 80}}>
                            <b>{t('Doctor')}:</b>
                        </Typography.Text>

                        <Select
                            style={{minWidth: 200}}
                            showSearch={true}
                            filterOption={(input, option) => {
                                return (option?.visibleName ?? '').toUpperCase().includes(input.toUpperCase())
                            }}
                            value={selectedDoctor}
                            mode="single"
                            onChange={(val) => {
                                setSelectedDoctor(val)
                            }}
                            options={doctors}
                            fieldNames={{
                                label: 'visibleName',
                                value: 'id'
                            }}
                        />
                    </div>
                )
            }

            {
                selectReporter && (
                    <div
                        style={{display: 'flex', alignItems: 'center', gap: 8, marginBottom: 8}}
                    >
                        <Typography.Text style={{width: 80}}>
                            <b>{t('Reporter')}:</b>
                        </Typography.Text>

                        <Select
                            style={{minWidth: 200}}
                            showSearch={true}
                            filterOption={(input, option) => {
                                return (option?.visibleName ?? '').toUpperCase().includes(input.toUpperCase())
                            }}
                            value={selectedReporter}
                            mode="single"
                            onChange={(val) => {
                                setSelectedReporter(val)
                            }}
                            options={reporters}
                            fieldNames={{
                                label: 'visibleName',
                                value: 'id'
                            }}
                        />
                    </div>
                )
            }


            <Button
                disabled={!selectedDoctor && !selectedReporter}
                type={'primary'}
                onClick={() => {
                    onSelected && onSelected({
                        doctor: selectedDoctor,
                        reporter: selectedReporter
                    })
                }}
            >
                {t('Save')}
            </Button>
        </>
    )
}