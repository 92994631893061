import {useTranslation} from "react-i18next";
import {useDataGrid} from "../../../core/components/data-grid/DataGridContext";
import {useRef, useState} from "react";
import {Button, Form, Input, Space} from "antd";
import RequestTypeSelectBox from "../../request-type/RequestTypeSelectBox";
import ModalitySelectBox from "../../modality/ModalitySelectBox";

export default function ReportTemplateFilter()
{
    const {t} = useTranslation();
    const formRef = useRef();
    const [modalityId, setModalityId] = useState(-1);
    const {onFilterPageSort} = useDataGrid();

    const filter = (form) => {
        onFilterPageSort({
            filter: {
                ...form,
                modalityIdList: form.modalityId && [form.modalityId],
                requestTypeIdList: form.requestTypeIdList && [form.requestTypeIdList]
            }
        })
    }

    const reset = () => {
        formRef.current.resetFields();
        formRef.current.setFieldsValue({
            requestTypeIdList: null,
            modalityIdList: null
        })
        setModalityId(null)
        onFilterPageSort({
            filter: {}
        })
    }

    const onChangeModality = (modalityId) => {
        setModalityId(modalityId);
        formRef.current.setFieldsValue({
            requestTypeIdList: null
        })
    }

    return (
        <>
            <Form
                layout={'vertical'}
                ref={formRef}
                onFinish={filter}
            >
                <Space style={{marginBottom: 16}}>
                    <Button type={'primary'} size={'small'} htmlType="submit" >{t('Search')}</Button>
                    <Button size={'small'} onClick={reset}>{t('Reset')}</Button>
                </Space>

                <Form.Item
                    label={t('Name')}
                    name={'name'}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t('Modality')}
                    name={'modalityId'}
                >
                    <ModalitySelectBox
                        onChange={onChangeModality}
                        mode={'single'}
                    />
                </Form.Item>

                <Form.Item
                    label={t('Request Type')}
                    name={'requestTypeIdList'}
                >
                    <RequestTypeSelectBox
                        modalityId={modalityId}
                        style={{width: '100%'}}
                    />
                </Form.Item>

                <Form.Item
                    label={t('Sut Code')}
                    name={'sutCode'}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t('User')}
                    name={'user'}
                >
                    <Input
                        placeholder={t('Name or Username')}
                    />
                </Form.Item>

            </Form>
        </>
    )
}
