import {Button, Card, DatePicker, Divider, Dropdown, Form, Input, Radio, Select, Space, Switch, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import {useDataGrid} from "../../../core/components/data-grid/DataGridContext";
import ModalitySelectBox from "../../modality/ModalitySelectBox";
import HospitalTreeView from "../../hospital/HospitalTreeView";
import {DatePickerFooter} from "../../../core/components/DatePickerFooter";
import {
    DATE_OPTIONS,
    getBeginLocalStr,
    getBeginStr,
    getDateFromNow,
    getEndLocalStr,
    getEndStr
} from "../../../core/utils/date-utils";
import QuickDatePicker from "../../../core/components/data-grid/QuickDatePicker";
import {PERMISSIONS, useUser} from "../../../core/context/UserContext";
import HospitalGroupSelectBox from "../../hospital-group/HospitalGroupSelectBox";
import UserSelectBox from "../../user/UserSelectBox";
import HospitalSelectBox from "../../hospital/HospitalSelectBox";
import {LocalStorageService} from "../../../core/service/local-storage-service";
import InstitutionSelect from "../../user/InstitutionSelect";

const {RangePicker} = DatePicker;

const getDefaultReportDate = () => {
    const date = getDateFromNow(DATE_OPTIONS.LAST_15_DAYS);
    return [date.start, date.end]
}

const getDefaultFilter = () => {
    const date = getDefaultReportDate();

    return window.reportFilter || {
        reportDateBegin: date[0],
        reportDateEnd: date[1],
        isDeleted: false
    }
}

export default function ReportFilter()
{
    const defaultFilter = useRef(getDefaultFilter());
    const [dateType, setDateType] = useState('report')
    const [showDetail, setShowDetail] = useState(false)
    const [dates, setDates] = useState({
        dateTimeBegin: defaultFilter.current.reportDateBegin,
        dateTimeEnd: defaultFilter.current.reportDateEnd,
    })
    const {hasPermission, user} = useUser();

    const {t} = useTranslation();
    const formRef = useRef();

    const {onFilterPageSort} = useDataGrid();

    const filter = async (form, dates) =>
    {
        let filter = {
            ...form,
        };

        if(dateType === 'report') {
            filter = {
                ...filter,
                reportDateBegin: dates.dateTimeBegin,
                reportDateEnd: dates.dateTimeEnd,
            }
        }
        else if(dateType === 'study') {
            filter = {
                ...filter,
                studyDateBegin: dates.dateTimeBegin,
                studyDateEnd: dates.dateTimeEnd,
            }
        }

        window.reportFilter = filter;

        onFilterPageSort({
            filter
        })
    }

    const reset = async () => {
        window.location.reload()
    }

    useEffect(() => {
        onFilterPageSort({
            filter: defaultFilter.current
        })
    }, [])

    return (
        <>
            <Form
                ref={formRef}
                layout={'vertical'}
                onFinish={(form) => filter(form, dates)}
                initialValues={{
                    reportDateBegin: getDefaultReportDate()[0],
                    reportDateEnd: getDefaultReportDate()[1],
                    isRead: null,
                    isReportWritten: null,
                    hasVoiceRecord: null,
                    isDeleted: false,
                    studyTypeList: [],
                    studyStatus: [],
                    hospitalIdList: []
                }}
            >
                <Space style={{marginBottom: 16}}>
                    <Button type={'primary'} size={'small'} htmlType="submit" >{t('Search')}</Button>
                    <Button size={'small'} onClick={reset}>{t('Reset')}</Button>
                </Space>

                <Form.Item
                    label={
                        (
                            <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
                                <Radio.Group
                                    options={[{label: t('Report Date'), value: 'report'},{label: t('Study Date'), value: 'study'},]}
                                    onChange={(e) => setDateType(e.target.value)}
                                    value={dateType}
                                />
                            </div>
                        )
                    }
                    style={{margin: 0, padding: 0}}
                >
                    <div
                        style={{display: 'flex', alignItems: 'center'}}
                    >
                        <DatePicker
                            locale={LocalStorageService.getAntLocale()}
                            value={dates.dateTimeBegin}
                            showToday={false}
                            onChange={(date) => {
                                setDates({
                                    ...dates,
                                    dateTimeBegin: date
                                })
                            }}
                        />
                        <DatePicker
                            locale={LocalStorageService.getAntLocale()}
                            value={dates.dateTimeEnd}
                            showToday={false}
                            onChange={(date) => {
                                setDates({
                                    ...dates,
                                    dateTimeEnd: date
                                })
                            }}
                        />
                    </div>
                </Form.Item>

                <Form.Item>
                    <QuickDatePicker onChange={(start, end) => {
                        setDates({
                            dateTimeBegin: start,
                            dateTimeEnd: end
                        })
                    }} />
                </Form.Item>

                {/*<Form.Item*/}
                {/*    label={t('Study Date')}*/}
                {/*    style={{margin: 0, padding: 0}}*/}
                {/*>*/}
                {/*    <div*/}
                {/*        style={{display: 'flex', alignItems: 'center'}}*/}
                {/*    >*/}
                {/*        <DatePicker*/}
                {/*            locale={LocalStorageService.getAntLocale()}*/}
                {/*            value={dates.studyDateBegin}*/}
                {/*            showToday={false}*/}
                {/*            onChange={(date) => {*/}
                {/*                setDates({*/}
                {/*                    ...dates,*/}
                {/*                    reportDateBegin: null,*/}
                {/*                    reportDateEnd: null,*/}
                {/*                    studyDateBegin: date,*/}
                {/*                })*/}
                {/*            }}*/}
                {/*        />*/}
                {/*        <DatePicker*/}
                {/*            locale={LocalStorageService.getAntLocale()}*/}
                {/*            value={dates.studyDateEnd}*/}
                {/*            showToday={false}*/}
                {/*            onChange={(date) => {*/}
                {/*                setDates({*/}
                {/*                    studyDateBegin: dates.studyDateBegin ?? date,*/}
                {/*                    studyDateEnd: date,*/}
                {/*                    reportDateBegin: null,*/}
                {/*                    reportDateEnd: null,*/}
                {/*                })*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</Form.Item>*/}

                {/*<Form.Item>*/}
                {/*    <QuickDatePicker onChange={(start, end) => {*/}
                {/*        setDates({*/}
                {/*            studyDateBegin: start,*/}
                {/*            studyDateEnd: end,*/}
                {/*            reportDateBegin: null,*/}
                {/*            reportDateEnd: null*/}
                {/*        })*/}
                {/*    }} />*/}
                {/*</Form.Item>*/}

                {
                    hasPermission(PERMISSIONS.HOSPITAL_GROUP_FILTER) && (
                        <Form.Item
                            label={t('Hospital Groups')}
                            name="hospitalGroupIdList"
                        >
                            <HospitalGroupSelectBox />
                        </Form.Item>
                    )
                }

                <Form.Item
                    label={t('Patient Id')}
                    name="patientId"
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label={t('Patient Name')}
                    name="patientName"
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label={t('Accession No')}
                    name="accessionNumber"
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label={t('Hospital Name')}
                    name="hospitalIdList"
                >
                    <HospitalSelectBox
                    />
                </Form.Item>

                <Form.Item
                    label={t('Modality')}
                    name="modalities"
                >
                    <ModalitySelectBox />
                </Form.Item>

                <Divider>
                    <div>
                        <Typography.Text style={{fontWeight: 'bold', marginRight: 8}}>{t('Detail Filters')} </Typography.Text>
                        <Switch
                            onChange={(e) => setShowDetail(e)}
                        />
                    </div>
                </Divider>

                <div
                    style={{
                        marginTop: 16,
                        display: showDetail ? '' : 'none'
                    }}
                >
                    {
                        hasPermission(PERMISSIONS.VIEW_STUDY_INSTITUTION) && (
                            <InstitutionSelect
                                hide={true}
                                formItem={{
                                    label: t('Institution'),
                                    name: 'institutions'
                                }}
                            />
                        )
                    }

                    <Form.Item
                        label={t('Confirming Status')}
                        name={'isConfirm'}
                    >
                        <Select>
                            <Select.Option value={null}>{t('All')}</Select.Option>
                            <Select.Option value={true}>{t('Confirmed')}</Select.Option>
                            <Select.Option value={false}>{t('Not Confirmed')}</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={t('Hbys Transfer Status')}
                        name={'isHbysTransfer'}
                    >
                        <Select>
                            <Select.Option value={null}>{t('All')}</Select.Option>
                            <Select.Option value={true}>{t('Transferred')}</Select.Option>
                            <Select.Option value={false}>{t('Not Transferred')}</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={t('Read User')}
                        name="readUserId"
                    >
                        <UserSelectBox type={'read'}/>
                    </Form.Item>

                    <Form.Item
                        label={t('Wrote User')}
                        name="writeUserId"
                    >
                        <UserSelectBox type={'write'} />
                    </Form.Item>

                    <Form.Item
                        label={t('Study Type')}
                        name={'studyTypeList'}
                    >
                        <Select
                            mode={'multiple'}
                        >
                            <Select.Option value={null}>{t('All')}</Select.Option>
                            <Select.Option value={1}>{t('Routine')}</Select.Option>
                            <Select.Option value={2}>{t('Inpatient')}</Select.Option>
                            <Select.Option value={3}>{t('Emergency')}</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={t('Study Status')}
                        name={'studyStatus'}
                    >
                        <Select>
                            <Select.Option value={-1}>{t('All')}</Select.Option>
                            <Select.Option value={0}>{t('Not Completed')}</Select.Option>
                            <Select.Option value={3}>{t('Reports not confirmed')}</Select.Option>
                            <Select.Option value={1}>{t('Timeout, Reports not confirmed')}</Select.Option>
                            <Select.Option value={2}>{t('Completed')}</Select.Option>
                        </Select>
                    </Form.Item>

                    {
                        hasPermission(PERMISSIONS.STUDY_SET_READ) && (
                            <Form.Item
                                label={t('Assigned (Doctor)')}
                                name={'assignDoctor'}
                            >
                                <Select>
                                    <Select.Option value={null}>{t('All')}</Select.Option>
                                    <Select.Option value={user?.data?.id}>{t('Assigned Me')}</Select.Option>
                                </Select>
                            </Form.Item>
                        )
                    }

                    {
                        hasPermission(PERMISSIONS.REPORT_ADD_OR_EDIT) && (
                            <Form.Item
                                label={t('Assigned (Reporter)')}
                                name={'assignReporter'}
                            >
                                <Select>
                                    <Select.Option value={null}>{t('All')}</Select.Option>
                                    <Select.Option value={user?.data?.id}>{t('Assigned Me')}</Select.Option>
                                </Select>
                            </Form.Item>
                        )
                    }

                    <Form.Item
                        label={t('Is Emergency')}
                        name={'isEmergency'}
                    >
                        <Select>
                            <Select.Option value={null}>{t('All')}</Select.Option>
                            <Select.Option value={true}>{t('Emergency')}</Select.Option>
                            <Select.Option value={false}>{t('Not Emergency')}</Select.Option>
                        </Select>
                    </Form.Item>

                    {
                        hasPermission(PERMISSIONS.STUDY_REQUEST_LIST_DELETED) && (
                            <Form.Item
                                label={t('Is Deleted')}
                                name={'isDeleted'}
                            >
                                <Select>
                                    <Select.Option value={null}>{t('All')}</Select.Option>
                                    <Select.Option value={true}>{t('Deleted')}</Select.Option>
                                    <Select.Option value={false}>{t('Not Deleted')}</Select.Option>
                                </Select>
                            </Form.Item>
                        )
                    }
                </div>
            </Form>
        </>
    )
}
