import {useTranslation} from "react-i18next";
import {useEffect, useRef} from "react";
import {ForkOutlined} from "@ant-design/icons";
import {Button, Form, Input, InputNumber} from "antd";
import ApiService from "../../core/service/api-service";
import ModalitySelectBox from "../modality/ModalitySelectBox";
import FirmSelectBox from "../firm/FirmSelectBox";
import {useHistory} from "react-router-dom";
import {Pages} from "../../AppRouter";
import StudyTypeEnum from "../../enums/StudyTypeEnum";

export default function HospitalCreateEdit({hospitalId})
{
    const {t} = useTranslation();
    const history = useHistory();
    const formRef = useRef();

    const getHospital = async () => {
        if(hospitalId)
        {
            const response = await ApiService.Hospital.Get(hospitalId);
            if(response.success)
            {
                let mins = {}
                Object.keys(StudyTypeEnum).forEach((key, idx) => {
                    const data = response.data.studyTypesMin[StudyTypeEnum[key]-1]
                    const min = data.split(':')[0]
                    const timeout = data.split(':')[1]
                    mins[`number_${key}_min`] = min
                    mins[`number_${key}_timeout`] = timeout
                })

                formRef.current.setFieldsValue({
                    firmId: response.data.firm.id,
                    name: response.data.name,
                    modalityIdList: response.data.modalityIdList,
                    officialName: response.data.officialName,
                    ...mins
                })
            }
        }
    }

    const save = async (form) => {
        let studyTypesMin = [];
        Object.keys(StudyTypeEnum).forEach(key => {
            const min = form[`number_${key}_min`]
            const timeout = form[`number_${key}_timeout`]
            studyTypesMin.push(`${min}:${timeout}`)
        })
        form.studyTypesMin = studyTypesMin

        if(hospitalId)
        {
            const response = await ApiService.Hospital.Edit(hospitalId, form)
        }
        else
        {
            const response = await ApiService.Hospital.Create(form);
            if(response.success)
                history.push(Pages.Hospital.Edit(response.data))
        }
    }

    useEffect(() => {
        getHospital()
    }, [])

    return (
        <>
            <Form
                layout={'vertical'}
                ref={formRef}
                onFinish={save}
            >

                <Form.Item
                    label={t('Firm')}
                    name={'firmId'}
                    rules={[
                        {required: true}
                    ]}
                >
                    <FirmSelectBox />
                </Form.Item>

                <Form.Item
                    name={'name'}
                    label={t('Name')}
                    rules={[
                        {required: true}
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name={'officialName'}
                    label={t('Official Name')}
                    rules={[
                        {required: true}
                    ]}
                >
                    <Input />
                </Form.Item>

                {
                    Object.keys(StudyTypeEnum).map(key => {

                        return (
                            <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
                                <Form.Item
                                    name={`number_${key}_min`}
                                    label={`${t(key)} (Min/Timeout)`}
                                    rules={[
                                        {required: true}
                                    ]}
                                >
                                    <InputNumber
                                        style={{width: 175}} />
                                </Form.Item>
                                <Form.Item
                                    name={`number_${key}_timeout`}
                                    label={` `}
                                    required={false}
                                    rules={[
                                        {required: true}
                                    ]}
                                >
                                    <InputNumber
                                        style={{width: 175}} />
                                </Form.Item>
                            </div>
                        )
                    })
                }

                <Form.Item
                    label={t('Modalities')}
                    name={'modalityIdList'}
                    rules={[{required: true}]}
                >
                    <ModalitySelectBox />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {t('Save')}
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
