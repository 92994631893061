import {Button, DatePicker, Form, Input, Select, Space} from "antd";
import {useEffect, useRef, useState} from "react";
import {t} from "i18next";
import ApiService from "../../core/service/api-service";
import {useModal} from "../../core/context/ModalContext";

export default function StudyCreateModal({onSuccess})
{
    const [hospitals, setHospitals] = useState([]);
    const [modalities, setModalities] = useState([])
    const formRef = useRef();

    const onFinish = async (formData) => {
        const res = await ApiService.Dicom.UploadDicom(formData)
        if(res.success)
        {
            onSuccess && onSuccess()
        }
    }

    useEffect(async () => {
        const res = await ApiService.UserManager.GetHospitalsOfUser();
        if(res.success)
        {
            setHospitals(res.data)
        }
    }, [])

    useEffect(async () => {
        const res = await ApiService.Modality.GetList();
        if(res.success)
        {
            setModalities(res.data)
        }
    }, [])

    return (
        <>
            <Form
                layout={'vertical'}
                ref={formRef}
                onFinish={onFinish}
            >
                <Form.Item
                    label={t('Hospital')}
                    name={'hospitalId'}
                    rules={[{required: true}]}
                >
                    <Select
                        showSearch={true}
                        filterOption={(input, option) => {
                            return option.children.toLocaleLowerCase().includes(input.toLocaleLowerCase())
                        }}
                    >
                        {hospitals.map(h => {
                            return (
                                <Select.Option
                                    key={h.id}
                                    value={h.id}
                                >
                                    {h.name}
                                </Select.Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                <Form.Item
                    label={t('Patient Id')}
                    name={'dicomPatientId'}
                    rules={[{required: true}]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t('Patient Name')}
                    name={'patientName'}
                    rules={[{required: true}]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t('Birthdate')}
                    name={'birthdate'}
                    rules={[{required: true}]}
                >
                    <DatePicker format={'DD/MM/yyyy'} />
                </Form.Item>

                <Form.Item
                    label={t('Gender')}
                    name={'gender'}
                    rules={[{required: true}]}
                >
                    <Select>
                        <Select.Option value={'F'}>{t('F')}</Select.Option>
                        <Select.Option value={'M'}>{t('M')}</Select.Option>
                        <Select.Option value={'O'}>{t('O')}</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label={t('Modality')}
                    name={'modalityId'}
                    rules={[{required: true}]}
                >
                    <Select>
                        {modalities.map(m => {
                            return (
                                <Select.Option key={m.id} value={m.id}>
                                    {m.name}
                                </Select.Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                <Form.Item
                    label={t('Accession Number')}
                    name={'accessionNumber'}
                    rules={[{required: true}]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t('Study Description')}
                    name={'studyDescription'}
                    rules={[{required: true}]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                >
                    <Button type={'primary'} htmlType={'submit'}>{t('Save')}</Button>
                </Form.Item>

            </Form>
        </>
    )
}