import {dateFormat, getAge} from "../../core/utils/date-utils";
import {Button, Checkbox, Col, Divider, Form, Input, Popover, Row, Select, Space, Tooltip, Typography} from "antd";
import ReportEditor from "../../core/components/ReportEditor";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import ApiService from "../../core/service/api-service";
import Flex from "../../core/components/Flex";
import {PERMISSIONS, useUser} from "../../core/context/UserContext";
import {Scrollbars} from "react-custom-scrollbars";
import {useApp} from "../../core/context/AppContext";

export default function ReportCreateEditForm({studyId, request, onSaved, title}) {
    const {t} = useTranslation();
    const {hasPermission} = useUser()
    const {addKeyEvent, removeKeyEvent, pressedKey} = useApp()
    const formRef = useRef();
    const [autoClose, setAutoClose] = useState(localStorage.getItem('autoClose') || false)

    const [_reportId, setReportId] = useState(request?.request?.reportId)
    const [readUsers, setReadUsers] = useState([])
    const [templates, setTemplates] = useState([])
    const [template, setTemplate] = useState({content: {}});
    const [reportContent, setReportContent] = useState({});
    const [report, setReport] = useState({
        request: {
            requestType: {}
        }
    })
    const reportSaveContent = useRef({})

    const getReadUsers = async () => {
        const res = await ApiService.StudyDetails.GetReadUsersOfStudy([studyId]);
        if (res.success) {
            setReadUsers(res.data)
        }
    }

    const loadReportFileData = async (studyId, requestId, reportId) => {
        try {
            const response = await ApiService.Report.GetReportFileData(studyId, requestId, reportId);
            if (response.success) {
                const content = JSON.parse(response.data);
                setReportContent(content)
                reportSaveContent.current = content;
            }
        }
        catch (exp) {
            console.log(exp)
            setReportContent({})
        }
    }

    const onTemplateChange = async (val, option) => {
        setTemplate(option)
        await loadReportTemplateFile(option.id);
    }

    const loadReportTemplateFile = async (templateId) => {
        try {
            if(templateId) {
                const response = await ApiService.ReportTemplate.GetReportTemplateFileData(templateId);
                if (response.success ) {
                    setReportContent(JSON.parse(response.data))
                    reportSaveContent.current = JSON.parse(response.data)
                }
            }
            else {
                setReportContent({})
            }
        }
        catch (e) {
            setReportContent({})
        }
    }

    const loadReport = async (requestId, reportId) => {
        if (request?.id && request?.reportId) {
            const response = await ApiService.Report.Get(studyId, requestId, reportId)
            if (response.success) {
                setTemplate({})
                setReport(response.data)
                await loadReportFileData(studyId, requestId, reportId)
            }
        }
    }

    useEffect(() => {
        if(report?.studyReadUser?.id) {
            formRef.current.setFieldsValue({
                studyReadUserId: report?.studyReadUser?.id
            })
        }
        else if (readUsers?.length === 1) {
            formRef.current.setFieldsValue({
                studyReadUserId: readUsers[0].id
            })
        }
        else if(readUsers?.length > 1) {
            formRef.current.setFieldsValue({
                studyReadUserId: readUsers[readUsers.length-1]?.id
            })
        }
    }, [readUsers])

    const save = async (isConfirm) => {
        if(!request?.id || request?.reportIsConfirm)
            return

        if(!hasPermission(PERMISSIONS.REPORT_CONFIRM) && isConfirm)
            return

        const text = formRef.current.getFieldsValue().text;
        const studyReadUserId = formRef.current.getFieldsValue().studyReadUserId;

        if (text)
            await ApiService.StudyAnamnez.EditClinicalInformation(studyId, text)

        if (request?.reportId) {
            const response = await ApiService.Report.Edit(studyId, request?.id, request?.reportId, {
                content: reportSaveContent.current,
                studyReadUserId,
                isConfirm
            })

            response.success && onSaved && onSaved(studyId, request?.id, response.data);
            await loadReport(request?.id, response.data)
        } else {
            const response = await ApiService.Report.Create(studyId, request?.id, {
                content: reportSaveContent.current,
                studyReadUserId,
                isConfirm
            })

            response.success && onSaved && onSaved(studyId, request?.id, response.data);
            await loadReport(request?.id, response.data)
        }
    }

    const getReportTemplates = async (studyId, requestId) => {
        const response = await ApiService.ReportTemplate.GetList(studyId, requestId);
        if (response.success) {
            return response.data.map(t => {
                return {
                    ...t,
                    value: t.id,
                    label: t.name
                }
            })
        }

        return []
    }

    const init = async () => {
        if(!request?.id)
            return
        await getReadUsers()
        const templates = await getReportTemplates(studyId, request?.id)
        setTemplates(templates)

        if(request?.reportId) {
            await loadReport(request?.id, request?.reportId)
        }
        else {
            let templateId = templates[0]?.id;
            await loadReportTemplateFile(templateId)
        }
    }

    useEffect(() => {
        init()
    }, [request])

    useEffect(() => {
        addKeyEvent([
            {keyCode: 'KeyQ', modifier: 'ControlLeft', prevent: false,},
            {keyCode: 'KeyQ', modifier: 'AltLeft', prevent: false,},
            // {keyCode: 'F4', modifier: 'ControlLeft', prevent: true,},
        ])

        return () => {
            removeKeyEvent([
                {keyCode: 'KeyQ', modifier: 'ControlLeft', prevent: false,},
                {keyCode: 'KeyQ', modifier: 'AltLeft', prevent: false,},
            ])
        }
    }, []);

    useEffect(() => {
        if(pressedKey?.key?.code === 'KeyQ' && pressedKey?.modifier?.code === 'ControlLeft') {
            // console.log('Pressed CRTL+KeyQ')
            save(true)
        }
        else if(pressedKey?.key?.code === 'KeyQ' && pressedKey?.modifier?.code === 'AltLeft') {
            save(false)
        }
    }, [pressedKey]);

    return (
        <>
            <Form
                ref={formRef}
                layout={'vertical'}
            >
                <Space>
                    <Form.Item>
                        <Select
                            disabled={!request?.id || request?.reportIsConfirm}
                            placeholder={t('Report Template')}
                            value={template.value}
                            style={{minWidth: 200}}
                            options={templates}
                            onChange={onTemplateChange}
                        />
                    </Form.Item>
                    <Form.Item
                        name={'studyReadUserId'}
                        label={''}
                        required={true}
                    >
                        <Select
                            disabled={!request?.id || request?.reportIsConfirm}
                            style={{width: 150}}
                            placeholder={t('Read User')}
                        >
                            {
                                readUsers.map(x => (
                                    <Select.Option key={x.id} value={x.id}>
                                        {x?.user?.visibleName}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            disabled={!request?.id || request?.reportIsConfirm}
                            onClick={async () => {
                                await save(false)
                            }}
                            type={'primary'}>
                            {t('Save')+' (Alt + Q)'}
                        </Button>
                    </Form.Item>
                    {
                        hasPermission(PERMISSIONS.REPORT_CONFIRM) && (
                            <Form.Item>
                                <Button
                                    disabled={!request?.id || request?.reportIsConfirm}
                                    onClick={async () => {
                                        await save(true)
                                    }}
                                >
                                    {t('Save And Confirm')+' (Ctrl + Q)'}
                                </Button>
                            </Form.Item>
                        )
                    }
                    {/*<Form.Item>*/}
                    {/*    <Popover content={t('AUTO_CLOSE')}>*/}
                    {/*        <Checkbox*/}
                    {/*            checked={autoClose === 'true'}*/}
                    {/*            onChange={(e) => {*/}
                    {/*                setAutoClose(e.target.checked ? 'true' : 'false')*/}
                    {/*                localStorage.setItem('autoClose', e.target.checked ? 'true' : 'false')*/}
                    {/*            }}>*/}
                    {/*            {t('Auto Close')}*/}
                    {/*        </Checkbox>*/}
                    {/*    </Popover>*/}
                    {/*</Form.Item>*/}
                </Space>

                <Scrollbars
                    style={{
                        height: 900,
                    }}
                >
                    <div
                        style={{
                            border: '1px solid #cfcfcf',
                            padding: 4,
                            marginRight: 16
                        }}
                    >
                        <ReportEditor
                            editable={request?.id}
                            defaultReportContent={request?.id ? reportContent : {}}
                            onBulgular={(data) => {
                                reportSaveContent.current = {
                                    ...reportSaveContent.current,
                                    Bulgular: data
                                }
                            }}
                            onSonuc={(data) => {
                                reportSaveContent.current = {
                                    ...reportSaveContent.current,
                                    SonucVeOneriler: data
                                }
                            }}
                            onKarsilastirma={(data) => {
                                reportSaveContent.current = {
                                    ...reportSaveContent.current,
                                    Karsilastirma: data
                                }
                            }}
                            onTeknik={(data) => {
                                reportSaveContent.current = {
                                    ...reportSaveContent.current,
                                    Teknik: data
                                }
                            }}
                        />
                    </div>
                </Scrollbars>
            </Form>
        </>
    )
}
