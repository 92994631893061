import {Button, Pagination, Tooltip} from 'antd';
import {Table, Switch, Space} from 'antd';
import {useEffect, useRef, useState} from "react";
import {v4} from "uuid";
import {useDataGrid} from "./DataGridContext";
import {useTranslation} from "react-i18next";
import {EyeOutlined, ReloadOutlined, UnorderedListOutlined} from "@ant-design/icons";
import {useModal} from "../../context/ModalContext";
import ColumnFilter from "../ColumnFilter";


const SORT = {
    NONE: -1,
    ASC: 0,
    DESC: 1
}

export const RenderableExpandRow = ({children, expanded, recordId}) => {

    const {t} = useTranslation();
    const {renderItem, renderExpandedRow, refreshDataGrid} = useDataGrid()
    const [item, setItem] = useState('');

    useEffect(() => {
        if (renderItem && renderItem.recordId === recordId) {
            setItem(renderItem)
        }
    }, [renderItem])

    return (
        <>
            {
                expanded && (
                    <div key={item.key}>
                        {/*<Tooltip placement={'bottomLeft'} title={t('Refresh')}>*/}
                        {/*    <Button onClick={() => {*/}
                        {/*        renderExpandedRow(recordId)*/}
                        {/*    }}>*/}
                        {/*        <ReloadOutlined/>*/}
                        {/*    </Button>*/}
                        {/*</Tooltip>*/}
                        {children}
                    </div>
                )
            }
        </>
    )
}

export default function DataGrid({title, name}) {
    const {t} = useTranslation();
    const {
        resultModel,
        dataGridProps,
        setSelectedRows,
        onFilterPageSort,
        tableId,
        selectedRows,
        queryModel,
        focusOut,
        refreshDataGrid
    } = useDataGrid()
    const [columns, setColumns] = useState([])
    const {openModal, closeModal} = useModal()
    const [rowSelection, setRowSelection] = useState({
        selectedRowKeys: selectedRows.map(x => x[dataGridProps.rowKey || 'id']),
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows)
        },
        onSelect: (record, selected, selectedRows) => {

        },
        onSelectAll: (selected, selectedRows, changeRows) => {
        }
    })

    useEffect(() => {
        if (selectedRows) {
            setRowSelection({
                ...rowSelection,
                selectedRowKeys: selectedRows.map(x => x[dataGridProps.rowKey || 'id']),
            })
        }
    }, [selectedRows])

    useEffect(() => {
        if (dataGridProps.columns) {
            const selectedCols = JSON.parse(localStorage.getItem(`data-grid-${name}`) || '[]')
            setColumns(
                dataGridProps.columns
                    .filter(x => {
                        return selectedCols.find(y => y.key === x.key) || selectedCols.length === 0
                    })
                    .map(x => {
                        x.sortOrder = null
                        return x
                    })
            )
        }
    }, [dataGridProps.columns])
    //
    useEffect(() => {
        if (queryModel.orderByItems?.length === 0) {
            setColumns([...columns.map(x => {
                x.sortOrder = null
                return x
            })])
        }
    }, [queryModel.id])

    const getSortType = (sort) => {
        if (!sort)
            return SORT.NONE;
        else if (sort === 'ascend')
            return SORT.ASC
        else
            return SORT.DESC
    }

    const onChange = (pagination, filters, sorter) => {
        const _columns = [...columns].map(x => {
            x.sortOrder = ''
            return x;
        })

        const orderByItems = []
        const col = _columns.find(x => x.key === sorter.field);
        if (sorter.order && col) {
            col.sortOrder = sorter.order
            orderByItems.push({
                name: col.columnName || sorter.field,
                operation: getSortType(sorter.order)
            })
        } else {
            if(col) {
                col.sortOrder = null
            }
        }

        const paginationQuery = {
            pageNumber: pagination.current,
            pageSize: pagination.pageSize
        };

        onFilterPageSort({
            filter: queryModel.filter,
            pagination: paginationQuery,
            orderByItems
        })

        setColumns(_columns)
    }

    return (
        <>
            <Table
                size={'small'}
                scroll={{x: 1280, y: window.innerHeight-300}}
                rowKey={dataGridProps.rowKey || 'id'}
                columns={columns}
                rowSelection={dataGridProps.enableRowSelection ? rowSelection : null}
                dataSource={resultModel.data}
                onChange={onChange}
                showSorterTooltip={false}
                expandable={{
                    expandedRowRender: dataGridProps.expandedRowRender,
                    rowExpandable: dataGridProps.rowExpandable,
                    //expandedRowKeys: expandedRowKeys,
                }}
                rowClassName={dataGridProps.rowClassName}
                onRow={dataGridProps.onRow}
                id={tableId}
                title={() => {
                    return (
                        <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
                            <Button
                                type={'text'}
                                size={'small'}
                                onClick={() => {
                                    openModal(t('Hide/Show Columns'), (
                                        <ColumnFilter
                                            columns={dataGridProps.columns}
                                            selectedColumnKeys={columns.map(x => x.key)}
                                            onSelectedColumns={(cols) => {
                                                setColumns(cols)
                                                localStorage.setItem(`data-grid-${name}`, JSON.stringify(cols))
                                                closeModal()
                                            }}
                                        />
                                    ))
                                }}
                            >
                                <UnorderedListOutlined />
                            </Button>
                            <Button
                                size={'small'}
                                onClick={() => refreshDataGrid()}
                            >
                                {/*{t('Add')}*/}
                                <ReloadOutlined />
                            </Button>
                            {title && title()}
                        </div>
                    )
                }}
                pagination={{
                    total: resultModel?.pagination?.totalRecords || 0,
                    pageSize: resultModel.pagination?.pageSize || 10,
                    size: 'small',
                    current: resultModel.pagination?.pageNumber || 1,
                    showTotal: total => `Toplam ${total} kayıt`,
                    showSizeChanger: true,
                    // simple: true,
                    hideOnSinglePage: false,
                    pageSizeOptions: [10, 20, 50, 100, 250, 500]
                }}
                {...(dataGridProps.antdTableProps || {})}
            />
        </>
    )
}
