import SearchableTreeView from "./SearchableTreeView";
import {useEffect, useState} from "react";
import {v4} from "uuid";
import {Button} from "antd";
import {t} from "i18next";

export default function ColumnFilter({columns, selectedColumnKeys, onSelectedColumns}) {
    const [cols, setCols] = useState([])
    const [selectedKeys, setSelectedKeys] = useState(selectedColumnKeys || [])

    useEffect(() => {
        if(columns) {
            setCols(columns.map(x => {
                if(x.key.toString().toLowerCase() === 'id' || x.key.toString().toLowerCase() === 'key')
                    x.disabled = true
                return x
            }))
        }
    }, [columns])

    return (
        <>
            <SearchableTreeView
                checkedKeys={selectedKeys}
                treeItemData={cols}
                onCheckedChange={(keys) => {
                    setSelectedKeys(keys)
                }}
            />

            <br/>
            <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
                <Button
                    type={'ghost'}
                    onClick={() => {
                        setSelectedKeys(cols.map(x => x.key))
                    }}
                >
                    {t('Select All')}
                </Button>
                <Button
                    type={'primary'}
                    onClick={() => {
                        const _cols = cols.filter(x => selectedKeys.find(y => y === x.key))
                        onSelectedColumns && onSelectedColumns(_cols)
                    }}
                >
                    {t('Save')}
                </Button>
            </div>
        </>
    )
}