import {useTranslation} from "react-i18next";
import {useModal} from "../../../core/context/ModalContext";
import {useDataGrid} from "../../../core/components/data-grid/DataGridContext";
import ApiService from "../../../core/service/api-service";
import {useEffect, useRef, useState} from "react";
import {dateFormat, getBeginLocalStr, getBeginStr, getEndLocalStr, getEndStr} from "../../../core/utils/date-utils";
import {Button, Checkbox, Dropdown, Menu, message, Typography} from "antd";
import {v4} from "uuid";
import {Pages} from "../../../AppRouter";
import {DownOutlined} from "@ant-design/icons";
import Flex from "../../../core/components/Flex";
import DataGrid from "../../../core/components/data-grid/DataGrid";
import ReportRowMenu from "./ReportRowMenu";
import ReportGridTools from "./ReportGridTools";

import './row-styles.css'
import ReportPreview from "../ReportPreview";
import {PERMISSIONS, useUser} from "../../../core/context/UserContext";
import {t} from "i18next";
import Colors from "../../../core/style/Colors";
import {useSignalR} from "../../../core/context/SignalRContext";
import useFocusListener from "../../../core/components/FocusListener";
import {useApp} from "../../../core/context/AppContext";
import ReportModal from "../../../pages/report/ReportModal";

export default function ReportGrid() {
    const {t} = useTranslation();

    const {hasPermission} = useUser();
    const {onUpdateStudyGrid} = useSignalR()
    const {setDataGridProps, queryModel, setData, setPaginationResult, tableId, setRowColor, refreshDataGrid} = useDataGrid()

    const isFocus = useFocusListener({elementId: tableId})
    const {addKeyEvent, removeKeyEvent, pressedKey} = useApp()
    const {openModal, closeModal} = useModal()

    const selectedReport = useRef(null)

    const openReportPreviewModal = (studyId, requestId, reportId) => {
        openModal(
            t('Report Preview'),
            <ReportPreview
                studyId={studyId}
                requestId={requestId}
                reportId={reportId}
                onDataChanged={() => {
                    refreshDataGrid();
                }}
            />
        );
    }

    useEffect(() => {
        addKeyEvent([
            {keyCode: 'KeyR', modifier: null}
        ])
    }, []);

    useEffect(() => {
        console.log(pressedKey)
        if(!selectedReport.current)
            return

        if(!isFocus)
            return;

        const element = document.activeElement;
        if(element.tagName === 'INPUT')
            return

        if(pressedKey?.key?.code === 'KeyR' && !pressedKey?.modifier?.code) {
            openReqReportPage()
        }
    }, [pressedKey]);

    const openReqReportPage = () => {
        openModal(t('Report'), (
            <ReportModal
                studyId={selectedReport.current.studyId}
                requestId={selectedReport.current.requestId}
            />
        ), null, window.innerWidth*0.98, 20)
    }

    const initDataGrid = async () => {
        const _qm = {
            ...queryModel
        }

        _qm.filter = {
            ..._qm.filter,
            reportDateBegin: getBeginStr(_qm.filter.reportDateBegin),
            reportDateEnd: getEndStr(_qm.filter.reportDateEnd),
            studyDateBegin: getBeginLocalStr(_qm.filter.studyDateBegin),
            studyDateEnd: getEndLocalStr(_qm.filter.studyDateEnd),
        }

        const response = await ApiService.Report.GetDataGrid(_qm);
        setData(response.data)
        setPaginationResult(response.pagination)
    }

    const setHbysTransfer = async (row) => {
        const response = await ApiService.Report.SetHbysTransfer(row.studyId, row.requestId, row.id, !row.isHbysTransfer)
        if (response.success) {
            await refreshDataGrid()
        }
    }

    useEffect(() => {
        if(hasPermission(PERMISSIONS.HOSPITAL_GROUP_FILTER) && onUpdateStudyGrid?.key) {
            initDataGrid()
        }
    }, [onUpdateStudyGrid])

    useEffect(() => {
        if(window.reportGridKey !== queryModel.updateKey) {
            window.reportGridKey = queryModel.updateKey
            initDataGrid()
        }
    }, [queryModel.updateKey])

    useEffect(() => {
        let columns = [
            {
                title: 'Id',
                key: 'id',
                columnName: 'id',
                width: 60,
                sorter: true,
                render: (text, record) => (
                    <ReportRowMenu row={record}/>
                )
            },
            {
                title: t('Report Date'),
                dataIndex: 'reportDate',
                key: 'reportDate',
                columnName: 'createdDate',
                sorter: true,
                width: 115,
                render: (text, record) => {
                    return dateFormat(text)
                }
            },
            {
                title: t('Written By User'),
                dataIndex: 'writtenByUser',
                key: 'writtenByUser',
                columnName: 'wroteUser',
                width: 60,
                render: (text, record) => {
                    return (
                        <Typography.Text ellipsis={{tooltip: text}} style={{width: 60}}>
                            {text}
                        </Typography.Text>
                    )
                }
            },
            {
                title: t('Patient Id'),
                dataIndex: 'dicomPatientId',
                key: 'dicomPatientId',
                columnName: 'study.patient.dicomPatientId',
                sorter: true,
                width: 100,
                render: (text, record) => {
                    return (
                        <Typography.Text ellipsis={{tooltip: text}} style={{width: 100}}>
                            {text}
                        </Typography.Text>
                    )
                }
            },
            {
                title: t('Patient'),
                dataIndex: 'patientName',
                key: 'patientName',
                columnName: 'request.study.patient.name',
                sorter: true,
                width: 130,
                render: (text, record) => {
                    return (
                        <Typography.Text ellipsis={{tooltip: text}} style={{width: 130}}>
                            {text}
                        </Typography.Text>
                    )
                }
            },
            {
                title: t('Institution'),
                dataIndex: 'institution',
                key: 'institution',
                columnName: 'institution',
                sorter: true,
                width: 80,
                render: (text, record) => {
                    return (
                        <Typography.Text ellipsis={{tooltip: record.institution || '-'}} style={{width: 80}}>
                            {record.institution || '-'}
                        </Typography.Text>
                    )
                }
            },
            {
                title: t('Hospital'),
                dataIndex: 'hospitalName',
                key: 'hospitalName',
                columnName: 'study.pacsSystem.hospital.name',
                sorter: true,
                width: 75,
                render: (text, record) => {
                    return (
                        <Typography.Text ellipsis={{tooltip: text}} style={{width: 75}}>
                            {text}
                        </Typography.Text>
                    )
                }
            },
            {
                title: t('Request Name'),
                dataIndex: 'requestName',
                key: 'requestName',
                width: 175,
                render: (text, record) => {
                    return (
                        <Typography.Text ellipsis={{tooltip: text}} style={{width: 175}}>
                            {text}
                        </Typography.Text>
                    )
                }
            },
            {
                title: t('Modality'),
                dataIndex: 'modality',
                key: 'modality',
                width: 65,
                columnName: 'study.modality.name',
                sorter: true,
            },
            {
                title: t('Study Date'),
                dataIndex: 'studyDate',
                key: 'studyDate',
                columnName: 'study.studyDateTime',
                sorter: true,
                width: 115,
                render: (text, record) => text ? new Date(text).toLocaleString() : '-'
            },
            {
                title: t('Study Description'),
                dataIndex: 'studyDescription',
                key: 'studyDescription',
                width: 200,
                render: (text, record) => {
                    return (
                        <Typography.Text ellipsis={{tooltip: text}} style={{width: 200}}>
                            {text}
                        </Typography.Text>
                    )
                }
            },
            {
                title: t('Read Date'),
                dataIndex: 'readDate',
                key: 'readDate',
                columnName: 'readDate',
                width: 115,
                render: (text, record) => {
                    return text ? dateFormat(text) : '-'
                }
            },
            {
                title: t('Read By User'),
                dataIndex: 'readByUser',
                key: 'readByUser',
                columnName: 'readByUser',
                width: 60,
                render: (text, record) => {
                    return (
                        <Typography.Text ellipsis={{tooltip: text}} style={{width: 60}}>
                            {text ? text : '-'}
                        </Typography.Text>
                    )
                }
            },
            {
                title: t('Assigned Doctor'),
                dataIndex: 'assignedDoctorName',
                key: 'assignedDoctorName',
                columnName: 'assignedDoctorName',
                width: 60,
                render: (text, record) => {
                    return (
                        <Typography.Text ellipsis={{tooltip: text}} style={{width: 60}}>
                            {text ? text : '-'}
                        </Typography.Text>
                    )
                }
            },
            {
                title: t('Assigned Reporter'),
                dataIndex: 'assignedReporterName',
                key: 'assignedReporterName',
                columnName: 'assignedReporterName',
                width: 60,
                render: (text, record) => {
                    return (
                        <Typography.Text ellipsis={{tooltip: text}} style={{width: 60}}>
                            {text ? text : '-'}
                        </Typography.Text>
                    )
                }
            },
            {
                title: t('Confirm Date'),
                dataIndex: 'confirmDate',
                key: 'confirmDate',
                columnName: 'confirmDate',
                width: 120,
                render: (text, record) => {
                    return text ? dateFormat(text) : '-'
                }
            },
            {
                title: t('Confirmed By User'),
                dataIndex: 'confirmedByUser',
                key: 'confirmedByUser',
                columnName: 'confirmedByUser',
                width: 60,
                render: (text, record) => {
                    return (
                        <Typography.Text ellipsis={{tooltip: text}} style={{width: 60}}>
                            {text ? text : '-'}
                        </Typography.Text>
                    )
                }
            },
            {
                title: t('Sign'),
                dataIndex: 'isSigned',
                key: 'isSigned',
                columnName: 'isSigned',
                width: 40,
                render: (text, record) => {
                    return text ? '✔' : '-'
                }
            },
            {
                title: 'Hbys',
                key: 'action',
                width: 40,
                render: (text, record) => {
                    if(!record.isConfirm)
                        return '-'

                    if (!hasPermission(PERMISSIONS.REPORT_HBYS_TRANSFER)) {
                        return <Typography.Text>{record.isHbysTransfer ? '✓' : '𐄂'}</Typography.Text>
                    }

                    return (
                        <Checkbox
                            checked={record.isHbysTransfer}
                            onChange={async (e) => {
                                e.preventDefault();
                                await setHbysTransfer(record)
                            }}/>
                    )
                }
            },
        ];

        if(!hasPermission(PERMISSIONS.STUDY_GRID_USER_INFO)) {
            columns = columns.filter(x => x.dataIndex !== 'readByUser')
            columns = columns.filter(x => x.dataIndex !== 'assignedDoctorName')
            columns = columns.filter(x => x.dataIndex !== 'assignedReporterName')
            columns = columns.filter(x => x.dataIndex !== 'assignedReporterName')
            columns = columns.filter(x => x.dataIndex !== 'confirmedByUser')
            columns = columns.filter(x => x.dataIndex !== 'writtenByUser')
        }

        if(!hasPermission(PERMISSIONS.VIEW_STUDY_INSTITUTION)) {
            columns = columns.filter(x => x.dataIndex !== 'institution')
        }

        setDataGridProps({
            rowKey: 'id',
            columns: columns,
            enableRowSelection: true,
            rowClassName: (record, index) => {
                let className = 'normal-row';

                if (record.isRequestDeleted)
                    className = 'deleted-row';
                else if (record.isConfirm)
                    className = 'confirmed-report-row';
                else if (!record.isConfirm)
                    className = 'not-confirmed-report-row'
                else
                    className = 'normal-row'

                return className;
            },
            onRow: (record) => {
                return {
                    onClick: () => {
                        if(record?.id === selectedReport.current?.id) {
                            setRowColor(record.id, '');
                            selectedReport.current = null
                        }
                        else {
                            setRowColor(record.id, Colors.selectedRow);
                            selectedReport.current = record
                        }

                    },
                    onDoubleClick: () => {
                        setRowColor(record.id, Colors.selectedRow);
                        hasPermission(PERMISSIONS.REPORT_LIST) && openReportPreviewModal(record.studyId, record.requestId, record.id)
                    }
                }
            }
        })
    }, [])

    return (
        <>

            <DataGrid name={'report'} title={() => <ReportGridTools/>}/>
        </>
    )
}
