import ReportTemplateRowMenu from "./ReportTemplateRowMenu";

const reportTemplateColumns = (t) => {
    return [
        {
            title: t('Name'),
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            render: (text ,record) => (
                <ReportTemplateRowMenu row={record} />
            )
        },
        {
            title: t('Request Type'),
            dataIndex: 'requestName',
            key: 'requestName',
            render: (text, record) => {
                return record.requestType.name
            },
        },
        {
            title: t('Sut Code'),
            dataIndex: 'sutCode',
            key: 'sutCode',
            render: (text, record) => {
                return record.requestType.sutCode
            },
        },
        {
            title: t('Modality'),
            dataIndex: 'modalityName',
            key: 'modalityName',
            render: (text, record) => {
                return record.requestType.modality.name
            },
        },
    ]
};

export default reportTemplateColumns;
