import {useTranslation} from "react-i18next";
import ApiService from "../../core/service/api-service";
import {Button, Form, Input, Tabs} from "antd";
import {useEffect, useRef, useState} from "react";
import StudyFileGrid from "./study-files/grid/StudyFileGrid";
import {DataGridProvider} from "../../core/components/data-grid/DataGridContext";

const { TabPane } = Tabs;
export default function StudyAnamnezEdit({studyId, formRef})
{
    const {t} = useTranslation();

    const getAnamnez = async () => {
        const response = await ApiService.StudyAnamnez.GetStudyAnamnez(studyId);
        if(response.success)
        {
            formRef.current.setFieldsValue(response.data)
        }
    }

    useEffect(() => {
        getAnamnez()
    }, [])

    return (
        <div>
            <Form
                ref={formRef}
                layout={'vertical'}
            >
                <Tabs defaultActiveKey="1">
                    <TabPane tab={t('Clinic')} key="1">
                        <Form.Item
                            style={{fontWeight: 'bold'}}
                            name={'clinicalInformation'}
                            label={t('Clinical Information')}
                        >
                            <Input.TextArea rows={4}/>
                        </Form.Item>
                        <Form.Item
                            style={{fontWeight: 'bold'}}
                            name={'patientComplaint'}
                            label={t('Patient Complaint')}
                        >
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item
                            style={{fontWeight: 'bold'}}
                            name={'diagnosis'}
                            label={t('Diagnosis')}
                        >
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item
                            style={{fontWeight: 'bold'}}
                            name={'medicalHistory'}
                            label={t('Medical History')}
                        >
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item
                            style={{fontWeight: 'bold'}}
                            name={'customText'}
                            label={t('Note')}
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </TabPane>
                    <TabPane tab={t('Other')} key="2">
                        <Form.Item
                            style={{fontWeight: 'bold'}}
                            name={'complaintPeriod'}
                            label={t('Complaint Period')}
                        >
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item
                            style={{fontWeight: 'bold'}}
                            name={'previousExaminations'}
                            label={t('Previous Examinations')}
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </TabPane>
                    <TabPane tab={t('Files')} key={'files'}>
                        <DataGridProvider>
                            <StudyFileGrid studyId={studyId} />
                        </DataGridProvider>
                    </TabPane>
                </Tabs>
            </Form>
        </div>
    )
}
