export default {
    VOICE_RECORD_ADD: 1,
    VOICE_RECORD_DELETE: 2,
    VOICE_RECORD_RESTORE: 3,
    ADD_REQUEST: 4,
    DELETE_REQUEST: 5,
    RESTORE_REQUEST: 6,
    CREATE_REPORT: 7,
    EDIT_REPORT: 8,
    CONFIRM_REPORT: 9,
    REMOVE_CONFIRM_REPORT: 10,
    SET_STUDY_TYPE: 11,
    STUDY_DELETE: 12,
    STUDY_RESTORE: 13,
    SET_READ: 14,
    REMOVE_READ: 15,
    DELETE_READ_USER: 16,
    HL7_REQUEST_ADD: 17,
    MOVE_REQUEST: 18,
    SIGN_REPORT: 19,
    MANUAL_HBYS_SIGN_REPORT: 20,
    SET_STUDY_STATUS: 21,
    SET_READ_MANUAL: 22,
    REPROCESS_REQUEST_HL7: 23
}