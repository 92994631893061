import RowMenu from "../../../core/components/data-grid/RowMenu";
import {Menu} from "antd";
import {useTranslation} from "react-i18next";
import {Pages} from "../../../AppRouter";

export default function ReportTemplateRowMenu({row})
{
    const {t} = useTranslation();

    return (
        <>
            <RowMenu text={row.name}>
                <Menu>
                    <Menu.Item key={'edit'}>
                        <a href={Pages.ReportTemplate.Edit(row.id)} target={'_blank'}>{t('Edit')}</a>
                    </Menu.Item>
                </Menu>
            </RowMenu>
        </>
    )
}
