import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {useUser} from "../../core/context/UserContext";
import {Table} from "antd";

export default function WrittenUserDashboard({modalities, data, tableRef}) {
    const {t} = useTranslation();
    const {hasPermission} = useUser();

    const [columns, setColumns] = useState([])

    useEffect(() => {
        if(!modalities)
            return

        setColumns([
            {
                title: t('Reporter'),
                dataIndex: 'reportCreatedUser',
                key: 'reportCreatedUser',
                columnName: 'reportCreatedUser',
                width: 200
            },
            {
                title: () => (
                    <div style={{backgroundColor: '#c7ecee'}}>
                        {t('Routine')}
                    </div>
                ),
                children: [
                    ...modalities.map(m => {
                        return {
                            title: m.name,
                            dataIndex: m.name,
                            key: m.name,
                            render: (text, record) => {
                                let count = record?.confirmedReports?.find(x => x.modalityId === m.id)?.count
                                if(count) {
                                    return (
                                        <div style={{backgroundColor: '#c7ecee', paddingLeft: 4, fontWeight: 'bold'}}>
                                            {count}
                                        </div>
                                    )
                                }
                                return 0
                            }
                        }
                    }),
                    {
                        title: t('Sum'),
                        dataIndex: 'sum',
                        key: 'sum',
                        render: (text, record) => {
                            let sum = 0;
                            record?.confirmedReports?.forEach(x => sum += x.count)
                            return (
                                <div style={{backgroundColor: sum > 0 ? '#7ed6df' : '', paddingLeft: 4, fontWeight: 'bold'}}>
                                    {sum}
                                </div>
                            )
                        }
                    }
                ]
            },
            {
                title: () => (
                    <div style={{backgroundColor: '#fab1a0'}}>
                        {t('Emergency')}
                    </div>
                ),
                children: [
                    ...modalities.map(m => {
                        return {
                            title: m.name,
                            dataIndex: m.name,
                            key: m.name,
                            render: (text, record) => {
                                let count = record?.emergencyConfirmedReports?.find(x => x.modalityId === m.id)?.count
                                if(count) {
                                    return (
                                        <div style={{backgroundColor: '#fab1a0', paddingLeft: 4, fontWeight: 'bold'}}>
                                            {count}
                                        </div>
                                    )
                                }
                                return 0
                            }
                        }
                    }),
                    {
                        title: t('Sum'),
                        dataIndex: 'sum',
                        key: 'sum',
                        render: (text, record) => {
                            let sum = 0;
                            record?.emergencyConfirmedReports?.forEach(x => sum += x.count)
                            return (
                                <div style={{backgroundColor: sum > 0 ? '#ff7979' : '', paddingLeft: 4, fontWeight: 'bold'}}>
                                    {sum}
                                </div>
                            )
                        }
                    }
                ]
            }
        ])
    }, [modalities])

    return (
        <>
            <Table
                ref={tableRef}
                size={'small'}
                pagination={false}
                dataSource={data}
                columns={columns}
            />
        </>
    )
}
