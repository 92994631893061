import {useEffect, useState} from "react";
import ApiService from "../../core/service/api-service";
import {Button, Table, Typography} from "antd";
import {t} from "i18next";
import {ReloadOutlined} from "@ant-design/icons";

export default function SeriesMetadataTable({studyId}) {
    const [data, setData] = useState([])


    const getMetadata = async () => {
        const res = await ApiService.Dicom.GetSeriesMetadata(studyId)
        if(res.success) {
            setData(res.data)
        }
    }

    useEffect(() => {
        (async () => {
           await getMetadata()
        })()
    }, []);

    return (
        <>
            <Table
                title={() => {
                    return (
                        <div style={{display: 'flex', alignItems: 'center', gap: 16, justifyContent: 'space-between'}}>
                            <Button
                                size={'small'}
                                onClick={getMetadata}
                                icon={<ReloadOutlined />}
                            />
                            <Typography.Text>
                                <b>{t('Total Instance Count')}: </b> {data.length > 0 && data?.map(x => parseInt(x.instanceCount))?.reduce((a,b) => a + b)}
                            </Typography.Text>
                        </div>
                    )
                }}
                size={'small'}
                dataSource={data}
                pagination={false}
                style={{width: 800}}
                id={'seriesNumber'}
                columns={[
                    {
                        dataIndex: 'seriesNumber',
                        key: 'seriesNumber',
                        title: t('Series Number'),
                        width: 125
                    },
                    {
                        dataIndex: 'desc',
                        key: 'desc',
                        title: t('Series Description')
                    },
                    {
                        dataIndex: 'modality',
                        key: 'modality',
                        title: t('Modality'),
                        width: 125
                    },
                    {
                        dataIndex: 'instanceCount',
                        key: 'instanceCount',
                        title: t('Instance'),
                        width: 125
                    }
                ]}
            />
        </>
    )
}