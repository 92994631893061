import ApiService from "../../../core/service/api-service";
import {useTranslation} from "react-i18next";
import DataGrid, {RenderableExpandRow} from "../../../core/components/data-grid/DataGrid";
import {useEffect, useRef, useState} from "react";
import {DataGridProvider, useDataGrid} from "../../../core/components/data-grid/DataGridContext";
import studyGridColumns from "./StudyColumns";
import StudyGridTools from "./StudyGridTools";

import './row-styles.css'
import StudyAddRequest from "../study-add-request/StudyAddRequest";
import {useModal} from "../../../core/context/ModalContext";
import {PERMISSIONS, useUser} from "../../../core/context/UserContext";
import Colors from "../../../core/style/Colors";
import {useSignalR} from "../../../core/context/SignalRContext";
import useFocusListener from "../../../core/components/FocusListener";
import {Modal, Tabs} from 'antd'
import {useApp} from "../../../core/context/AppContext";
import StudyRequestGrid from "../../request/request-data-grid/StudyRequestGrid";
import VoiceRecordGrid from "../../voice-record/voice-record-grid/VoiceRecordGrid";
import StudyFileGrid from "../study-files/grid/StudyFileGrid";
import StudyTypeEnum from "../../../enums/StudyTypeEnum";
import StudyStatusEnum from "../../../enums/StudyStatusEnum";
import StudyHelper from "../StudyHelper";
import ReportModal from "../../../pages/report/ReportModal";
import {getBeginLocalStr, getBeginStr, getEndLocalStr, getEndStr} from "../../../core/utils/date-utils";
import StudyLogsGrid from "../StudyLogsGrid";
import SeriesMetadataTable from "../SeriesMetadataTable";

export default function StudyGrid({})
{
    const {t} = useTranslation();
    const {setDataGridProps, queryModel, setData, setPaginationResult, setRowColor, selectedRows, setSelectedRows, refreshDataGrid, renderExpandedRow, onFocusOut, tableId} = useDataGrid()
    const {openModal, closeModal, modal} = useModal()
    const {user,hasPermission} = useUser()
    const {onUpdateStudyGrid, hubContext} = useSignalR()
    const {addKeyEvent, removeKeyEvent, pressedKey} = useApp()
    const isFocus = useFocusListener({elementId: tableId})

    const initDataGrid = async () => {
        const _qm = {
            ...queryModel
        }

        let sortItems = queryModel.orderByItems
        if(_qm.filter.studyTypeList && _qm.filter.studyTypeList.find(x => x === StudyTypeEnum.EMERGENCY)) {
            if(queryModel.orderByItems?.length === 0)
            {
                sortItems = [
                    {
                        name: 'studyDetail.timeoutDate', operation: 2
                    }
                ]
            }
        }
        _qm.orderByItems = sortItems;
        _qm.filter = {
            ..._qm.filter,
            studyReceivedDateTimeBegin: getBeginStr(_qm.filter.studyReceivedDateTimeBegin),
            studyReceivedDateTimeEnd: getEndStr(_qm.filter.studyReceivedDateTimeEnd),
            studyDateTimeBegin: getBeginLocalStr(_qm.filter.studyDateTimeBegin),
            studyDateTimeEnd: getEndLocalStr(_qm.filter.studyDateTimeEnd),
            patientIdList: (_qm.filter.patientId && _qm.filter.patientId.split(',')) || [],
        };
        const response = await ApiService.Study.DataGrid(_qm);
        setData(response.data)
        setPaginationResult(response.pagination)
    }

    const selectedStudy = useRef(null)
    const selectedReq = useRef(null)

    useEffect(() => {
        if(hasPermission(PERMISSIONS.HOSPITAL_GROUP_FILTER) && onUpdateStudyGrid?.key) {
            initDataGrid()
        }
    }, [onUpdateStudyGrid])

    useEffect(() => {
        addKeyEvent([
            {keyCode: 'KeyR', modifier: null, prevent: false,},
            {keyCode: 'KeyA', modifier: null, prevent: false,},
        ])
    }, []);

    useEffect(() => {
        if(!isFocus)
            return

        if(!selectedStudy.current?.id)
            return

        const element = document.activeElement;
        if(element.tagName === 'INPUT')
            return

        if(!pressedKey?.modifier?.code) {
            switch (pressedKey?.key?.code) {
                case 'KeyA': {
                    addRequest()
                    break
                }
                case 'KeyR': {
                    if(StudyHelper.openReqRepPage(selectedReq.current, selectedStudy.current)) {
                        openModal(t('Report'), (
                            <ReportModal
                                studyId={selectedStudy.current?.id}
                                requestId={selectedReq.current?.id}
                            />
                        ), null, window.innerWidth*0.98, 20)
                    }
                    else {

                    }
                }
            }
        }
    }, [pressedKey]);

    const addRequest = async () =>
    {


        openModal(t('Add Request & Anamnez'), (
            <StudyAddRequest
                studyRow={selectedStudy.current}
                onSaved={async () =>
                {
                    refreshDataGrid()
                    renderExpandedRow(selectedStudy.current.id)
                    closeModal()
                }}
                studyId={selectedStudy.current.id}
            />
        ))
    }


    useEffect(() => {
        if(window.studyGridKey !== queryModel.updateKey) {
            window.studyGridKey = queryModel.updateKey
            initDataGrid()
        }
    }, [queryModel.updateKey])

    useEffect(() => {
        const columns = studyGridColumns(refreshDataGrid, hasPermission)
        setDataGridProps({
            rowKey: 'id',
            columns: columns,
            enableRowSelection: true,
            antdTableProps: {
                scroll: {x: columns.map(x => x.width || 0).reduce((a,b) => a + b),}
            },
            rowExpandable: record => true,
            expandedRowRender: (record, index, indent, expanded) => {
                return (
                    <RenderableExpandRow expanded={expanded} recordId={record.id}>
                        <Tabs defaultActiveKey="1" style={{backgroundColor: '#fff', padding: 8}}>
                            {
                                hasPermission(PERMISSIONS.STUDY_REQUEST_LIST) && (
                                    <Tabs.TabPane tab={t('Requests')} key="1">
                                        <DataGridProvider name={'study-request-provider'} defaultFilter={{}}>
                                            <StudyRequestGrid
                                                studyId={record.id}
                                                onSelected={(req) => {
                                                    if(record.id !== selectedStudy?.current?.id)
                                                        selectStudyRow(record)

                                                    selectedReq.current = req
                                                }}
                                            />
                                        </DataGridProvider>
                                    </Tabs.TabPane>
                                )
                            }
                            {
                                hasPermission(PERMISSIONS.VOICE_RECORD_LIST) && (
                                    <Tabs.TabPane tab={t('Voice Records')} key="2">
                                        <DataGridProvider name={'study-voice-rec-provider'} defaultFilter={{}}>
                                            <VoiceRecordGrid studyId={record.id} />
                                        </DataGridProvider>
                                    </Tabs.TabPane>
                                )
                            }
                            {
                                hasPermission(PERMISSIONS.STUDY_FILE_LIST) && (
                                    <Tabs.TabPane tab={t('Files')} key="3">
                                        <DataGridProvider name={'study-file-provider'}>
                                            <StudyFileGrid studyId={record.id} />
                                        </DataGridProvider>
                                    </Tabs.TabPane>
                                )
                            }
                            <Tabs.TabPane tab={t('Dicom Data')}>
                                <SeriesMetadataTable studyId={record.id} />
                            </Tabs.TabPane>
                            {
                                hasPermission(PERMISSIONS.STUDY_LOG) && (
                                    <Tabs.TabPane tab={t('Study Logs')} key="4">
                                        <DataGridProvider>
                                            <StudyLogsGrid studyId={record.id} />
                                        </DataGridProvider>
                                    </Tabs.TabPane>
                                )
                            }
                        </Tabs>
                    </RenderableExpandRow>
                )
            },
            rowClassName: (record, index) => {
                let className = 'normal-row';

                if(record.isDeleted)
                    className = 'deleted-row ';
                else if(record.studyType === StudyTypeEnum.EMERGENCY) {
                    if(record.studyStatus === StudyStatusEnum.COMPLETED)
                        className = 'has-report-row';
                    else if (record.studyStatus === StudyStatusEnum.TIMEOUT_HAS_NOT_CONFIRMED_REPORT)
                        className = 'has-report-row';
                    else
                        className = 'emergency-row';
                }
                else {
                    if(record.studyStatus === StudyStatusEnum.WAITING) {
                        className = 'no-report-row'
                    }
                    else if(record.studyStatus === StudyStatusEnum.HAS_NOT_CONFIRMED_REPORT) {
                        className = 'not-confirmed-row';
                    }
                    else if(record.studyStatus === StudyStatusEnum.TIMEOUT_HAS_NOT_CONFIRMED_REPORT) {
                        className = 'has-report-row';
                    }
                    else if(record.studyStatus === StudyStatusEnum.COMPLETED) {
                        className = 'has-report-row';
                    }
                }

                return className;
            },
            onRow: (record) => {
                return {
                    onClick: (e) => {
                        selectStudyRow(record)
                    },
                    onDoubleClick: () => {

                    }
                }
            }
        })
    }, [])

    const selectStudyRow = (record) => {
        if(selectedStudy?.current?.id === record?.id)
        {
            selectedStudy.current = null;
            selectedReq.current = null;
            setRowColor(record.id, '');
            return
        }
        setRowColor(record.id, Colors.selectedRow);
        selectedStudy.current = record
        selectedReq.current = null
    }

    useEffect(() => {
        if(selectedStudy.current) {
            setRowColor(selectedStudy.current.id, '');
            selectedStudy.current = null
        }
    }, [onFocusOut])

    return (
        <>
            <DataGrid
                title={() => (
                    <StudyGridTools />
                )}
                name={'study'}
            />
        </>
    )
}
