import LoginForm from "../app/auth/LoginForm";
import Flex from "../core/components/Flex";
import { Card } from 'antd';
import {useTranslation} from "react-i18next";

export default function LoginPage()
{
    const {t} = useTranslation();

    return (
        <Flex flexDirection={'row'} justifyContent={'center'} alignItems={'center'} style={{height: '100%', backgroundColor: '#edf2f8'}}>
            <Card title={t('Login')}>
                <LoginForm />
            </Card>
        </Flex>
    )
}
