import {useEffect, useState} from "react";
import UrlHelper from "../../core/utils/url-helper";
import {Progress, Tabs} from 'antd';
import LayoutWithSider from "../../core/components/layout/layout-with-sider/LayoutWithSider";
import ViewerPanel from "./ViewerPanel";
import ApiService from "../../core/service/api-service";
import {v4} from "uuid";
import {use} from "i18next";
import JSZip from "jszip";

export default function StudyOHIFPanel() {
    const [metadataList, setMetadataList] = useState([])
    const [progress, setProgress] = useState([])
    const [activeStudyId, setActiveStudyId] = useState('')
    const [studies, setStudies] = useState([])
    const [uiReady, setUiReady] = useState(false)

    const getMetadataList = async (studyList) => {
        const metadataList = []
        for (const studyId of studyList) {
            const mx = await ApiService.Dicom.GetStudyDicomMetadata(studyId)
            if(mx.success) {
                metadataList.push({
                    studyId,
                    ...mx.data
                })
            }
        }

        return metadataList;
    }

    const mapProgress = (metadataList) => {
        setProgress(metadataList.map(x => {
            return {
                studyId: x.studyId,
                value: 0,
                current: 0,
                total: 1,
            }
        }))
    }

    const updateProgress = (studyId, current) => {
        const _progress = [...progress];
        const p = progress.findIndex(x => x.studyId === studyId)
        if(p > -1) {
            _progress[p].current = current;
            _progress[p].value = ((current*100)/_progress[p].total).toFixed(1);
            setProgress(_progress)
        }
    }

    const getDicomFile = async (studyId, dicomMetadata) => {
        let count = 0;
        while (count < 3) {
            const resp = await ApiService.Dicom.GetStudyZip(studyId, true, false)
            if(resp.success) {
                return resp.data;
            }
            else {
                count++
            }
        }
    }

    const downloadDicomZip = async (metadataList) => {
        const data = []
        for (const metadata of metadataList) {
            const studyId = metadata.studyId;
            const zipFileBlobUrl = await ApiService.Dicom.GetStudyZip(studyId, false, false);

            const fileBlobResp = await fetch(zipFileBlobUrl.data)
            const fileBlob = await fileBlobResp.blob()

            const zip = new JSZip()
            const files = await zip.loadAsync(fileBlob);

            const dicomFiles = []
            for (const fileKey in files.files) {
                if(files.files[fileKey].dir)
                    continue

                const blob = await files.files[fileKey].async('blob')
                dicomFiles.push(URL.createObjectURL(blob))
            }

            updateProgress(studyId, 1)
            data.push({studyId, zipFileBlobUrl, dicomFiles, patientName: metadata.patientName, receivedDate: metadata.receivedDate})
            setStudies([...data])
        }

    }

    useEffect(async () => {
        const child = document.getElementsByClassName('ant-tabs-nav-add')[0];
        child.style.display = 'none'

        const list = UrlHelper.getUrlParam(window.location.search, 'studies');
        if(list) {
            const studyList = list.split('_')
            if(studyList && studyList.length > 0) {
                const metadataList = await getMetadataList(studyList);
                setMetadataList(metadataList)
            }
        }
    }, [])

    useEffect(() => {
        if(metadataList && metadataList.length > 0) {
            mapProgress(metadataList)
        }
    }, [metadataList])

    useEffect(() => {
        if(progress && progress.length > 0) {
            setUiReady(true)
        }
    }, [progress])

    useEffect(async () => {
        if(uiReady) {
            await downloadDicomZip(metadataList)
        }
    }, [uiReady])

    useEffect(() => {
        if(!activeStudyId && studies && studies.length > 0) {
            setActiveStudyId(studies[0].studyId)
        }
    }, [studies])

    return (
        <>
            <LayoutWithSider
                fullWidth={true}
            >
                {
                    progress.filter(x => !x.value|| x.value < 100).length > 0 && (
                        <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 16}}>
                            {
                                progress.map(p => {
                                    return (
                                        <div key={v4()} style={{display: 'flex', alignItems: 'center'}}>
                                            <label style={{width: 100}}><b>{p.studyId}</b>: ({p.current}/{p.total})</label>
                                            <Progress percent={p.value} width={100} style={{width: 250}} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                }
                <Tabs
                    type="editable-card"
                    onChange={(key) => {
                        setActiveStudyId(key)
                    }}
                    activeKey={activeStudyId}
                    onEdit={(e) => {
                        if(e) {
                            setStudies(studies.filter(x => x.studyId !== e))
                        }
                    }}
                >
                    {
                        studies.map(x => {
                            return (
                                <Tabs.TabPane tab={`${x.patientName} - ${new Date(x.receivedDate).toLocaleDateString()}`} key={x.studyId}>
                                    <ViewerPanel studyId={x.studyId} dicomFiles={x.dicomFiles} />
                                </Tabs.TabPane>
                            )
                        })
                    }
                </Tabs>
            </LayoutWithSider>
        </>
    )
}