import {Button} from "antd";
import UserRowMenu from "./UserRowMenu";

export default function userColumns(t, onSelect)
{
    let columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => {
                return (
                    <UserRowMenu
                        row={record}
                    />
                );
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
        },
        {
            title: 'Visible Name',
            dataIndex: 'visibleName',
            key: 'visibleName',
            sorter: true,
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            sorter: true,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: true,
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: (text, record) => {
                return record.role.name;
            }
        },
    ];

    if(onSelect)
    {
        columns = [
            {
                title: 'Select',
                key: 'select',
                render: (text, record) => {
                    return (
                        <>
                            <Button onClick={() => {onSelect(record)}}>
                                {t('Select')}
                            </Button>
                        </>
                    )
                }
            },
            ...columns
        ]
    }

    return columns;
}
