const getKeyByValue = (enumData, value) => {
    const keys = Object.keys(enumData)
    for (const key of keys) {
        if(enumData[key] === value)
            return key
    }
}

const EnumHelper = {
    getKeyByValue
}

export default EnumHelper