import {useTranslation} from "react-i18next";
import LayoutWithSider from "../../core/components/layout/layout-with-sider/LayoutWithSider";
import {Card, Form} from "antd";
import RoleManager from "../../app/role/RoleManager";
import {useEffect} from "react";

export default function RoleManagerPage()
{
    const {t} = useTranslation();

    useEffect(() => {
        document.title = `ECloud Pacs - ${t('Roles')}`
    }, []);

    return (
        <LayoutWithSider>
            <Card title={t('Roles')}>
                <RoleManager />
            </Card>
        </LayoutWithSider>
    )
}
