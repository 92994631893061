import {Tabs, Typography} from "antd";
import TinymceEditor from "./TinymceEditor";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import './report-editor.css'
import {useUser} from "../context/UserContext";
import _ from "lodash";

const { TabPane } = Tabs;

export default function ReportEditor({defaultReportContent, onBulgular, onSonuc, onKarsilastirma, onTeknik, editable=true})
{
    const {t} = useTranslation();
    const {dictionary} = useUser()

    return (
        <>
            <Tabs defaultActiveKey={'1'}>
                <TabPane tab={'Rapor'} key={'1'}>
                    <div style={{width: '100%', backgroundColor: '#eaeaea', paddingLeft: 8}}>
                        <Typography.Title level={5}>Bulgular</Typography.Title>
                    </div>
                    {
                        editable && (
                            <TinymceEditor
                                dictionary={dictionary}
                                height={'40vh'}
                                value={defaultReportContent?.Bulgular}
                                handleChange={(data) => {
                                    onBulgular && onBulgular(data)
                                }}
                            />
                        )
                    }

                    <div style={{width: '100%', backgroundColor: '#eaeaea', paddingLeft: 8, marginTop: 16}}>
                        <Typography.Title level={5}>Sonuç ve Öneriler</Typography.Title>
                    </div>
                    {
                        editable && (
                            <TinymceEditor
                                dictionary={dictionary}
                                height={'15vh'}
                                value={defaultReportContent?.SonucVeOneriler}
                                handleChange={(data) => {
                                    onSonuc && onSonuc(data)
                                }}
                            />
                        )
                    }
                </TabPane>

                <TabPane tab={'Diğer'} key={'2'}>
                    <div style={{width: '100%', backgroundColor: '#eaeaea', paddingLeft: 8}}>
                        <Typography.Title level={5}>Teknik</Typography.Title>
                    </div>
                    {
                        editable && (
                            <TinymceEditor
                                dictionary={dictionary}
                                height={'20vh'}
                                value={defaultReportContent?.Teknik}
                                handleChange={(data) => {
                                    onTeknik && onTeknik(data)
                                }}
                            />
                        )
                    }

                    <div style={{width: '100%', backgroundColor: '#eaeaea', paddingLeft: 8,  marginTop: 16}}>
                        <Typography.Title level={5}>Karşılaştırma</Typography.Title>
                    </div>
                    {
                        editable && (
                            <TinymceEditor
                                dictionary={dictionary}
                                height={'20vh'}
                                value={defaultReportContent?.Karsilastirma}
                                handleChange={(data) => {
                                    onKarsilastirma && onKarsilastirma(data)
                                }}
                            />
                        )
                    }
                </TabPane>
            </Tabs>
        </>
    )
}
