import Flex from "../../../core/components/Flex";
import {Button, Select, Space, Tooltip} from "antd";
import {t} from "i18next";
import {useModal} from "../../../core/context/ModalContext";
import StudyAddRequest from "../../study/study-add-request/StudyAddRequest";
import {useDataGrid} from "../../../core/components/data-grid/DataGridContext";
import Colors from "../../../core/style/Colors";
import {useEffect, useState} from "react";
import ApiService from "../../../core/service/api-service";
import {PERMISSIONS, useUser} from "../../../core/context/UserContext";
import {RedoOutlined, ReloadOutlined} from "@ant-design/icons";
import HL7Input from "./HL7Input";

export default function HL7GridTools({studyId})
{
    const {refreshDataGrid, selectedRows} = useDataGrid();
    const {openModal, closeModal} = useModal()

    return (
        <>
            <Space size={'small'}>
                <Button
                    size={'small'}
                    disabled={selectedRows.length === 0}
                    onClick={async () => {
                        const idList = []

                        selectedRows.forEach(x => {
                            if(x?.report?.reportId)
                                idList.push({reportId: x?.report?.reportId})
                            else {
                                idList.push({hl7MessageId: x?.id})
                            }
                        })

                        const res = await ApiService.HL7Service.CreateReportMessage(idList)
                        if(res.success) {
                            refreshDataGrid()
                        }
                    }}
                >
                    Create Reports
                </Button>
                <Button
                    size={'small'}
                    disabled={selectedRows.length === 0}
                    onClick={async () => {
                        const idList = []
                        selectedRows.forEach(x => {
                            if(x?.report?.reportId)
                                idList.push(x?.report?.id)
                        })

                        const res = await ApiService.HL7Service.SendReport(idList)
                        if(res.success) {
                            refreshDataGrid()
                        }
                    }}
                >
                    Send Reports
                </Button>
                <Button
                    size={'small'}
                    onClick={async () => {
                        openModal('Add HL7 Message', (
                            <HL7Input
                                onFinish={async (data) => {
                                    const res = await ApiService.HL7Service.AddManualHL7(data.hospitalId, {
                                        messageBase64: data.message
                                    })

                                    if(res.success) {
                                        closeModal()
                                        refreshDataGrid()
                                    }
                                }}
                            />
                        ))
                    }}
                >
                    Add HL7 Request
                </Button>
            </Space>
        </>
    )
}