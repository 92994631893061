import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import {PERMISSIONS, useUser} from "./core/context/UserContext";
import LoginPage from "./pages/LoginPage";
import UserDataGridPage from "./pages/user/UserDataGridPage";
import ReportTemplateDataGridPage from "./pages/report-template/ReportTemplateDataGridPage";
import ReportTemplateFormPage from "./pages/report-template/ReportTemplateFormPage";
import StudyGridPage from "./pages/study/StudyGridPage";
import ReportModal from "./pages/report/ReportModal";
import HospitalGridPage from "./pages/hospital/HospitalGridPage";
import MainLayout from "./core/components/layout/main-layout/MainLayout";
import ReportDataGridPage from "./pages/report/ReportDataGridPage";
import HospitalCreateEditPage from "./pages/hospital/HospitalCreateEditPage";
import SystemRequestTypePage from "./pages/request-type/SystemRequestTypePage";
import RoleManagerPage from "./pages/role/RoleManagerPage";
import UserCreateEditPage from "./pages/user/UserCreateEditPage";
import ReportPreviewPage from "./pages/report/ReportPreviewPage";
import HospitalUserPermissionPage from "./pages/hospital/HospitalUserPermissionPage";
import StudyOHIFPanel from "./app/ohif/StudyOHIFPanel";
import UserDictionaryPage from "./pages/user/UserDictionaryPage";
import HospitalGroupEditPage from "./pages/hospital-group/HospitalGroupEditPage";
import {SignalRProvider} from "./core/context/SignalRContext";
import DashboardPage from "./pages/dashboard/DashboardPage";
import HL7GridPage from "./pages/hl7/HL7GridPage";
import TeletipGridPage from "./pages/teletip/TeletipGridPage";

export const Pages = {
    Dashboard: {
        Home: `/app/dashboard`,
    },
    Auth: {
        Logout: '/app/authentication/logout',
        Login: '/app/authentication/login'
    },
    Study: {
        Study: '/app/study',
        Viewer: `/app/study/viewer`,
        Report: (studyId) => `/app/study/${studyId}/requests`,
        ReportEdit: (studyId, requestId, reportId) => `/app/study/${studyId}/requests/${requestId}/report/${reportId}`,
        ReportPreview: (studyId, requestId, reportId) => `/app/study/${studyId}/requests/${requestId}/report/${reportId}/preview`,
    },
    Hospital: {
        Hospital: '/app/hospitals',
        Edit: (id) => `/app/hospitals/${id}/edit`,
        Create: `/app/hospitals/create`,
        UserPermissions: (id) => `/app/hospitals/${id}/users`,
    },
    HL7: {
        DataGrid: `/app/hl7/messages`
    },
    PacsSystem: {
        Create: (hospitalId) => `/app/hospital/${hospitalId}/pacs/create`,
        Edit: (hospitalId, pacsSystemId) => `/app/hospital/${hospitalId}/pacs/${pacsSystemId}/`,
    },
    HospitalGroup: {
        HospitalGroupEdit: `/app/hospitals/groups`
    },
    User: {
        DataGrid: '/app/users',
        Create: `/app/users/create`,
        Edit: (userId) => `/app/users/${userId}/edit`,
        UserHospitals: (id) => `/app/users/${id}/hospitals`,
        UserPermissions: (id) => `/app/users/${id}/permissions`,
        Dictionary: `/app/users/dictionary`
    },
    ReportTemplate: {
        Create: `/app/report/templates/create`,
        Edit: (id) => `/app/report/templates/${id}/edit`,
        DataGrid: `/app/report/templates`
    },
    Report: {
        DataGrid: `/app/reports/grid`
    },
    RequestType: {
        System: `/app/request-types`
    },
    Role: {
        Manager: `/app/roles`
    },
    Download: {
        download: `/app/download`
    },
    Teletip: {
        DataGrid: `/app/teletip/grid`
    },
}

function PrivateRoute({children, ...rest})
{
    const {
        isAuth
    } = useUser();

    return (
        <Route {...rest} render={() =>
        {
            return isAuth()
                ? children
                : <Redirect to={Pages.Auth.Login} />
        }}/>
    )
}

export default function AppRouter()
{
    const {hasPermission, showDashboard} = useUser();

    return (
        <>
            <Router>
                <Switch>
                    <Route path={Pages.Auth.Login} component={LoginPage}/>
                    <PrivateRoute>
                        <MainLayout>
                            <SignalRProvider>
                                <Switch>
                                    {(<Route exact path={'/'}  component={showDashboard() ? DashboardPage : StudyGridPage}/>)}
                                    {showDashboard() && (<Route exact path={Pages.Dashboard.Home}  component={DashboardPage}/>)}
                                    {/*{hasPermission(PERMISSIONS.DASHBOARD_HOSPITAL) && (<Route exact path={Pages.Dashboard.ByHospital}  component={ByHospitalGridPage}/>)}*/}
                                    {/*{hasPermission(PERMISSIONS.DASHBOARD_HOSPITAL_MODALITY) && (<Route exact path={Pages.Dashboard.ByHospitalModality}  component={ByHospitalModalityGridPage}/>)}*/}
                                    {/*{hasPermission(PERMISSIONS.DASHBOARD_HOSPITAL_MODALITY_USER) && (<Route exact path={Pages.Dashboard.ByHospitalUser}  component={ByHospitalModalityUserGridPage}/>)}*/}

                                    <Route exact path={Pages.User.Dictionary} component={UserDictionaryPage}/>
                                    {hasPermission(PERMISSIONS.USER_MANAGER) && (<Route exact path={Pages.User.Create} component={UserCreateEditPage}/>)}
                                    {hasPermission(PERMISSIONS.USER_MANAGER) && (<Route exact path={Pages.User.Edit(':userId')} component={UserCreateEditPage}/>)}
                                    {hasPermission(PERMISSIONS.USER_MANAGER) && (<Route exact path={Pages.User.DataGrid} component={UserDataGridPage}/>)}

                                    {hasPermission(PERMISSIONS.REPORT_TEMPLATE_MANAGER) && (<Route exact path={Pages.ReportTemplate.Create} component={ReportTemplateFormPage}/>)}
                                    {hasPermission(PERMISSIONS.REPORT_TEMPLATE_MANAGER) && (<Route exact path={Pages.ReportTemplate.Edit(':id')} component={ReportTemplateFormPage}/>)}
                                    {hasPermission(PERMISSIONS.REPORT_TEMPLATE_MANAGER) && (<Route exact path={Pages.ReportTemplate.DataGrid} component={ReportTemplateDataGridPage}/>)}

                                    {hasPermission(PERMISSIONS.STUDY_LIST) && (<Route exact path={Pages.Study.Study} component={StudyGridPage}/>)}
                                    {hasPermission(PERMISSIONS.STUDY_OPEN_VIEWER) && (<Route exact path={Pages.Study.Viewer} component={StudyOHIFPanel}/>)}
                                    {hasPermission(PERMISSIONS.REPORT_ADD_OR_EDIT) && (<Route exact path={Pages.Study.Report(':studyId')} component={ReportModal}/>)}
                                    {hasPermission(PERMISSIONS.REPORT_ADD_OR_EDIT) && (<Route exact path={Pages.Study.ReportEdit(':studyId', ':requestId', ':reportId')} component={ReportModal}/>)}
                                    {hasPermission(PERMISSIONS.REPORT_LIST) && (<Route exact path={Pages.Study.ReportPreview(':studyId', ':requestId', ':reportId')} component={ReportPreviewPage}/>)}

                                    {hasPermission(PERMISSIONS.HOSPITAL_MANAGER) && (<Route exact path={Pages.Hospital.Hospital} component={HospitalGridPage}/>)}
                                    {hasPermission(PERMISSIONS.HOSPITAL_MANAGER) && (<Route exact path={Pages.Hospital.Create} component={HospitalCreateEditPage}/>)}
                                    {hasPermission(PERMISSIONS.HOSPITAL_MANAGER) && (<Route exact path={Pages.Hospital.UserPermissions(':hospitalId')} component={HospitalUserPermissionPage}/>)}
                                    {hasPermission(PERMISSIONS.HOSPITAL_MANAGER) && (<Route exact path={Pages.Hospital.Edit(':hospitalId')} component={HospitalCreateEditPage}/>)}
                                    {hasPermission(PERMISSIONS.HL7_MANAGER) && (<Route exact path={Pages.HL7.DataGrid} component={HL7GridPage}/>)}
                                    {hasPermission(PERMISSIONS.TELETIP_MANAGER) && (<Route exact path={Pages.Teletip.DataGrid} component={TeletipGridPage}/>)}

                                    {hasPermission(PERMISSIONS.HOSPITAL_GROUP_MANAGER) && (<Route exact path={Pages.HospitalGroup.HospitalGroupEdit} component={HospitalGroupEditPage}/>)}

                                    {hasPermission(PERMISSIONS.REQUEST_TYPE_MANAGER) && (<Route exact path={Pages.RequestType.System} component={SystemRequestTypePage}/>)}
                                    {hasPermission(PERMISSIONS.USER_MANAGER) && (<Route exact path={Pages.Role.Manager} component={RoleManagerPage}/>)}

                                    {hasPermission(PERMISSIONS.REPORT_LIST) && (<Route exact path={Pages.Report.DataGrid} component={ReportDataGridPage}/>)}

                                </Switch>
                            </SignalRProvider>
                        </MainLayout>
                    </PrivateRoute>
                </Switch>
            </Router>
        </>
    )
}
