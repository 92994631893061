import {useEffect, useRef} from "react";
import {Button, Form, Input} from "antd";
import {t} from "i18next";

export default function HospitalGroupCreateEdit({name, onSave}) {
    const formRef = useRef()

    const onFinish = () => {
        const data = formRef.current.getFieldsValue()
        onSave(data)
    }

    useEffect(() => {
        formRef.current.setFieldsValue({
            name
        })
    }, [name])

    return (
        <>
            <Form
                ref={formRef}
                layout={'vertical'}
                onFinish={onFinish}
            >
                <Form.Item
                    name={'name'}
                    label={t('Name')}
                    rules={[{required: true, min: 3}]}
                >
                    <Input />
                </Form.Item>

                <Form.Item>
                    <Button
                        type={'primary'}
                        htmlType={'submit'}
                    >
                        {t('Save')}
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}