import {Button, Dropdown, Menu, Typography} from "antd";
import {PERMISSIONS, useUser} from "../../../core/context/UserContext";
import {v4} from "uuid";
import {t} from "i18next";
import ApiService from "../../../core/service/api-service";
import {useDataGrid} from "../../../core/components/data-grid/DataGridContext";
import {useModal} from "../../../core/context/ModalContext";
import RowMenu from "../../../core/components/data-grid/RowMenu";
import {Pages} from "../../../AppRouter";

export default function HL7ReportRowMenu({text, row})
{
    const {hasPermission} = useUser();
    const {refreshDataGrid} = useDataGrid()
    const {openModal, closeModal} = useModal()

    return (
        <RowMenu text={text}>
            <Menu>
                {
                    hasPermission(PERMISSIONS.HOSPITAL_MANAGER) && (
                        <Menu.Item
                            key={v4()}
                            onClick={async () => {
                                const res = await ApiService.HL7Service.GetHL7ClientDataGrid(row.id)
                                if(res.success) {
                                    openModal('Message', (
                                        <>
                                            <Typography.Text copyable={true} style={{whiteSpace: 'pre-wrap'}}>
                                                {res.data}
                                                <br/>
                                            </Typography.Text>
                                        </>
                                    ))
                                }
                            }}
                        >
                            {t('Open Message')}
                        </Menu.Item>
                    )
                }
                {
                    hasPermission(PERMISSIONS.HOSPITAL_MANAGER) && row.reportId && (
                        <Menu.Item
                            key={v4()}
                            onClick={async () => {
                                const res = await ApiService.HL7Service.CreateReportMessage([{reportId: row.reportId}])
                            }}
                        >
                            {t('Recreate Report')}
                        </Menu.Item>
                    )
                }
                {
                    hasPermission(PERMISSIONS.HOSPITAL_MANAGER) && (
                        <Menu.Item
                            key={v4()}
                            onClick={async () => {
                                const res = await ApiService.HL7Service.SendReport([row.id])
                            }}
                        >
                            {t('Send Report')}
                        </Menu.Item>
                    )
                }
            </Menu>
        </RowMenu>
    )
}