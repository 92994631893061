import { Button, Menu } from 'antd';
import ApiService from '../../../core/service/api-service';
import { useModal } from '../../../core/context/ModalContext';
import { useDataGrid } from '../../../core/components/data-grid/DataGridContext';
import { useTranslation } from 'react-i18next';
import RowMenu from '../../../core/components/data-grid/RowMenu';
import { PERMISSIONS, useUser } from '../../../core/context/UserContext';
import StudyAddRequest from '../study-add-request/StudyAddRequest';
import StudyReadUsersModal from '../StudyReadUsersModal';
import StudyUserAssignModal from '../StudyUserAssignModal';
import { Pages } from '../../../AppRouter';
import StudyTypeEnum from '../../../enums/StudyTypeEnum';
import StudyHelper from '../StudyHelper';
import ReportModal from '../../../pages/report/ReportModal';
import ShareEmailModal from '../ShareEmailModal';
import { string } from 'prop-types';
import moment from 'moment/moment';

export default function StudyRowMenu({ row }) {
    const { t } = useTranslation();
    const { openModal, closeModal } = useModal();
    const { refreshDataGrid, renderExpandedRow } = useDataGrid();
    const { hasPermission, user } = useUser();

    const addRequest = async () => {
        openModal(
            t('Add Request & Anamnez'),
            <StudyAddRequest
                studyRow={row}
                onSaved={async () => {
                    refreshDataGrid();
                    renderExpandedRow(row.id);
                    closeModal();
                }}
                studyId={row.id}
            />
        );
    };

    const setEmergency = async (emergency) => {
        const response = await ApiService.StudyDetails.SetStudyType({
            studyIdList: [row.id],
            studyType: emergency
                ? StudyTypeEnum.EMERGENCY
                : StudyTypeEnum.ROUTINE,
        });

        if (response.success) {
            refreshDataGrid();
        }
    };

    const setInpatient = async (inpatient) => {
        const response = await ApiService.StudyDetails.SetStudyType({
            studyIdList: [row.id],
            studyType: inpatient
                ? StudyTypeEnum.INPATIENT
                : StudyTypeEnum.ROUTINE,
        });

        if (response.success) {
            refreshDataGrid();
        }
    };

    const deleteReadUser = async () => {
        openModal(
            t('Study Read User'),
            <StudyReadUsersModal
                studyIdList={[row.id]}
                onSelected={async (userId) => {
                    const response =
                        await ApiService.StudyDetails.DeleteReadUser(
                            [row.id],
                            userId
                        );
                    if (response.success) {
                        refreshDataGrid();
                        closeModal();
                    }
                }}
            />
        );
    };

    const removeRead = async () => {
        const response = await ApiService.StudyDetails.RemoveRead([row.id]);
        if (response.success) {
            refreshDataGrid();
            closeModal();
        }
    };

    const setReadTrue = async () => {
        const read = async (userId) => {
            const response = await ApiService.StudyDetails.AddRead({
                studyIdList: [row.id],
                isRead: true,
                readUserId: userId,
            });

            if (response.success) {
                refreshDataGrid();
                return true;
            }

            return false;
        };

        if (hasPermission(PERMISSIONS.STUDY_SET_READ_USER)) {
            openModal(
                t('Doctors'),
                <StudyUserAssignModal
                    selectDoctor={true}
                    onSelected={async ({ doctor, reporter }) => {
                        if (await read(doctor)) closeModal();
                    }}
                />
            );
        } else {
            if (await read(user?.data?.id)) closeModal();
        }
    };

    const downloadVoiceRecords = async () => {
        const response = await ApiService.VoiceRecord.CreateZip([row.id]);
        if (response.success) {
            ApiService.downloadFile(response.data, response.fileName);
        }
    };

    const deleteStudy = async () => {
        const response = await ApiService.Study.DeleteStudies([row.id]);
        if (response.success) {
            await refreshDataGrid();
        }
    };

    const restoreStudy = async () => {
        const response = await ApiService.Study.RestoreStudies([row.id]);
        if (response.success) {
            await refreshDataGrid();
        }
    };

    let downloadDicomZip = async () => {
        const resp = await ApiService.Sharing.ShareStudyDicom({
            studyIdList: [row.id],
        });
    };

    let shareStudyByEmail = async () => {
        openModal(
            t('Send Email'),
            <ShareEmailModal
                onFinish={async (form) => {
                    const resp = await ApiService.Sharing.ShareStudyDicom({
                        studyIdList: [row.id],
                        ...form,
                    });
                    if (resp.success) {
                        closeModal();
                    }
                }}
            />
        );
    };

    const openReqReportPage = () => {
        if (StudyHelper.openReqRepPage(null, row)) {
            openModal(
                'Report',
                <ReportModal studyId={row.id} />,
                null,
                window.innerWidth * 0.98,
                20
            );
        }
    };

    const makeKos = async () => {
        const res = await ApiService.Teletip.MakeKos([{studyId: row.id}]);
        if (res.success) {
        }
    };

    return (
        <>
            <RowMenu text={row.id}>
                <Menu>
                    {hasPermission(PERMISSIONS.STUDY_REQUEST_SET) && (
                        <Menu.Item key={'add-request'} onClick={addRequest}>
                            <div>{t('Add Request') + ' (A)'}</div>
                        </Menu.Item>
                    )}
                    {hasPermission(PERMISSIONS.REPORT_ADD_OR_EDIT) && (
                        <Menu.Item
                            key={'add-request-report'}
                            onClick={openReqReportPage}
                        >
                            <div>{t('Request & Reports') + ' (R)'}</div>
                        </Menu.Item>
                    )}
                    {(hasPermission(PERMISSIONS.STUDY_SET_EMERGENCY) ||
                        hasPermission(PERMISSIONS.STUDY_REMOVE_EMERGENCY)) && (
                        <>
                            <Menu.Item key={'set-emergency-menu'}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 4,
                                    }}
                                >
                                    {hasPermission(
                                        PERMISSIONS.STUDY_SET_EMERGENCY
                                    ) && (
                                        <Button
                                            disabled={
                                                row.studyType ===
                                                StudyTypeEnum.EMERGENCY
                                            }
                                            size={'small'}
                                            style={{ width: 80 }}
                                            onClick={(e) => {
                                                setEmergency(true);
                                            }}
                                        >
                                            {t('Emergency')}
                                        </Button>
                                    )}
                                    {hasPermission(
                                        PERMISSIONS.STUDY_SET_EMERGENCY
                                    ) && (
                                        <Button
                                            disabled={
                                                row.studyType ===
                                                StudyTypeEnum.INPATIENT
                                            }
                                            size={'small'}
                                            style={{ width: 80 }}
                                            onClick={(e) => {
                                                setInpatient(true);
                                            }}
                                        >
                                            {t('Inpatient')}
                                        </Button>
                                    )}
                                    {hasPermission(
                                        PERMISSIONS.STUDY_REMOVE_EMERGENCY
                                    ) && (
                                        <Button
                                            disabled={
                                                row.studyType ===
                                                StudyTypeEnum.ROUTINE
                                            }
                                            size={'small'}
                                            style={{ width: 80 }}
                                            onClick={(e) => {
                                                setInpatient(false);
                                            }}
                                        >
                                            {t('Routine')}
                                        </Button>
                                    )}
                                </div>
                            </Menu.Item>
                        </>
                    )}
                    {(hasPermission(PERMISSIONS.STUDY_SET_READ) ||
                        hasPermission(PERMISSIONS.STUDY_REMOVE_READ)) && (
                        <Menu.Item key={'set-read-menu'}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 4,
                                }}
                            >
                                {hasPermission(PERMISSIONS.STUDY_SET_READ) && (
                                    <Button
                                        size={'small'}
                                        style={{ width: 80 }}
                                        onClick={async (e) => {
                                            await setReadTrue();
                                        }}
                                    >
                                        {t('Read')}
                                    </Button>
                                )}
                                {hasPermission(
                                    PERMISSIONS.STUDY_REMOVE_READ
                                ) && (
                                    <Button
                                        size={'small'}
                                        style={{ width: 80 }}
                                        onClick={async (e) => {
                                            await deleteReadUser();
                                        }}
                                    >
                                        {t('Not Read')}
                                    </Button>
                                )}
                            </div>
                        </Menu.Item>
                    )}
                    {hasPermission(PERMISSIONS.STUDY_REMOVE_READ) && (
                        <Menu.Item key={'delete-read-user'}></Menu.Item>
                    )}
                    {(hasPermission(PERMISSIONS.STUDY_ASSIGN_DOCTOR_USER) || hasPermission(PERMISSIONS.STUDY_ASSIGN_REPORTER_USER))
                         && (
                            <Menu.Item
                                key={'assign-user'}
                                onClick={() => {
                                    openModal(
                                        t('Assign User'),
                                        <StudyUserAssignModal
                                            selectDoctor={hasPermission(
                                                PERMISSIONS.STUDY_ASSIGN_DOCTOR_USER
                                            )}
                                            selectReporter={hasPermission(
                                                PERMISSIONS.STUDY_ASSIGN_REPORTER_USER
                                            )}
                                            onSelected={async ({
                                                doctor,
                                                reporter,
                                            }) => {
                                                let resDoctor = null;
                                                let resReporter = null;
                                                if (
                                                    hasPermission(
                                                        PERMISSIONS.STUDY_ASSIGN_DOCTOR_USER
                                                    )
                                                ) {
                                                    resDoctor =
                                                        await ApiService.StudyUser.AssignDoctorUser(
                                                            {
                                                                studyIdList: [
                                                                    row.id,
                                                                ],
                                                                doctorUserId:
                                                                    doctor,
                                                            }
                                                        );
                                                }

                                                if (
                                                    hasPermission(
                                                        PERMISSIONS.STUDY_ASSIGN_REPORTER_USER
                                                    )
                                                ) {
                                                    resReporter =
                                                        await ApiService.StudyUser.AssignReporterUser(
                                                            {
                                                                studyIdList: [
                                                                    row.id,
                                                                ],
                                                                reporterUserId:
                                                                    reporter,
                                                            }
                                                        );
                                                }

                                                if (
                                                    resDoctor.success ||
                                                    resReporter.success
                                                ) {
                                                    refreshDataGrid();
                                                    closeModal();
                                                }
                                            }}
                                        />
                                    );
                                }}
                            >
                                <div>{t('Assign User')}</div>
                            </Menu.Item>
                        )}
                    {hasPermission(PERMISSIONS.STUDY_ASSIGN_DOCTOR_USER) && (
                        <Menu.Item
                            key={'remove-doctor'}
                            onClick={async () => {
                                const res =
                                    await ApiService.StudyUser.RemoveDoctorUser(
                                        {
                                            studyIdList: [row.id],
                                            doctorUserId: 'remove',
                                            reporterUserId: null,
                                        }
                                    );

                                if (res.success) {
                                    refreshDataGrid();
                                    closeModal();
                                }
                            }}
                        >
                            <div>{t('Remove Doctor')}</div>
                        </Menu.Item>
                    )}
                    {hasPermission(PERMISSIONS.STUDY_ASSIGN_REPORTER_USER) && (
                        <Menu.Item
                            key={'remove-reporter'}
                            onClick={async () => {
                                const res =
                                    await ApiService.StudyUser.RemoveReporterUser(
                                        {
                                            studyIdList: [row.id],
                                            doctorUserId: null,
                                            reporterUserId: 'remove',
                                        }
                                    );

                                if (res.success) {
                                    refreshDataGrid();
                                    closeModal();
                                }
                            }}
                        >
                            <div>{t('Remove Reporter')}</div>
                        </Menu.Item>
                    )}

                    {hasPermission(PERMISSIONS.VOICE_RECORD_DOWNLOAD) && (
                        <Menu.Item
                            key={'download-voice-records'}
                            onClick={downloadVoiceRecords}
                        >
                            <div>{t('Download Voice Records')}</div>
                        </Menu.Item>
                    )}

                    {hasPermission(PERMISSIONS.STUDY_DELETE_OR_RESTORE) &&
                        !row.isDeleted && (
                            <Menu.Item
                                key={'delete-study'}
                                onClick={deleteStudy}
                            >
                                <div>{t('Delete Study')}</div>
                            </Menu.Item>
                        )}

                    {hasPermission(PERMISSIONS.STUDY_DELETE_OR_RESTORE) &&
                        row.isDeleted && (
                            <Menu.Item
                                key={'restore-study'}
                                onClick={restoreStudy}
                            >
                                <div>{t('Restore Study')}</div>
                            </Menu.Item>
                        )}
                    {hasPermission(PERMISSIONS.STUDY_OPEN_VIEWER) && (
                        <Menu.Item
                            key={'download-study-zip'}
                            onClick={downloadDicomZip}
                        >
                            <div>{t('Download Dicom Zip')}</div>
                        </Menu.Item>
                    )}
                    {hasPermission(PERMISSIONS.SHARE_STUDY_BY_EMAIL) && (
                        <Menu.Item
                            key={'share-study'}
                            onClick={shareStudyByEmail}
                        >
                            <div>{t('Email')}</div>
                        </Menu.Item>
                    )}
                    {hasPermission(PERMISSIONS.TELETIP_MANAGER) && (
                        <Menu.Item key={'make-kos'} onClick={makeKos}>
                            <div>{t('Make Kos')}</div>
                        </Menu.Item>
                    )}
                </Menu>
            </RowMenu>
        </>
    );
}
