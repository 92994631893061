import {Card} from "antd";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import ReportTemplateCreateEditForm from "../../app/report-template/ReportTemplateCreateEditForm";
import LayoutWithSider from "../../core/components/layout/layout-with-sider/LayoutWithSider";

export default function ReportTemplateFormPage()
{
    const {t} = useTranslation();
    const {id} = useParams();

    return (
        <LayoutWithSider>
            <Card title={id ? t('Edit Report Template') : t('Create Report Template')}>
                <ReportTemplateCreateEditForm reportTemplateId={id} />
            </Card>
        </LayoutWithSider>
    )
}
