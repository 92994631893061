import {useHistory} from "react-router-dom";
import {Button, Space} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";

export default function CardTitle({title})
{
    const history = useHistory();

    return (
        <>
            <Space>
                <Button
                    type={'text'}
                    icon={<ArrowLeftOutlined />}
                    onClick={() => {
                        history.goBack()
                    }}
                />
                {title}
            </Space>
        </>
    )
}
