import RowMenu from "../../../../../core/components/data-grid/RowMenu";
import {Button, Menu, Space, Typography} from "antd";
import {Pages} from "../../../../../AppRouter";
import ReportRowMenu from "../../../../report/report-grid/ReportRowMenu";
import {v4} from "uuid";

export default function pacsSystemColumns(t, history, hospitalId, onEdit)
{
    return [
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
            render: (text, record) => {
                return (
                    <RowMenu text={record.key}>
                        <Menu>
                            <Menu.Item
                                key={v4()}
                                onClick={() => {onEdit && onEdit(record.id)}}
                            >
                                {t('Edit')}
                            </Menu.Item>
                        </Menu>
                    </RowMenu>
                )
            }
        },
        {
            title: t('Name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('AE Title'),
            dataIndex: 'aeTitle',
            key: 'aeTitle',
        },
        {
            title: t('Server Address'),
            dataIndex: 'serverAddress',
            key: 'serverAddress',
        },
        {
            title: t('Port'),
            dataIndex: 'dimseCPort',
            key: 'dimseCPort',
        },
    ]
}
