import {Button, Col, Divider, Layout, Row} from "antd";
import {ArrowLeftOutlined, ArrowRightOutlined} from "@ant-design/icons";
import Flex from "../../Flex";
import {useEffect, useState} from "react";
import './layout.css'
import {isMobile} from "react-device-detect";
import {v4} from "uuid";

const { Sider } = Layout;



export default function LayoutWithSider({children, sidebarTitle, sidebarComponent, fullWidth=false})
{
    const [open, setOpen] = useState(localStorage.getItem(sidebarTitle));

    useEffect(() => {
        if(!open) {
            localStorage.removeItem(sidebarTitle)
        }
        else {
            localStorage.setItem(sidebarTitle, '1')
        }
    }, [open]);

    return (
        <>
            <Sider
                width={300}
                collapsed={!open || !sidebarComponent}
                collapsedWidth={sidebarComponent ? 35 : 0}
                style={{
                    marginTop: 64,
                    overflow: 'auto',
                    height: 'calc(100% - 64px)',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    backgroundColor: '#fff'
                }}
            >
                <Button
                    icon={ !(open && sidebarComponent) ? <ArrowRightOutlined /> : <ArrowLeftOutlined />} type={'text'}
                    onClick={() => {
                        !(open && sidebarComponent) ? setOpen(true) : setOpen(false)
                    }}
                />
                <Divider style={{marginTop: 4}}/>
                <div style={{paddingLeft: 8, paddingRight: 8}}>
                    <Flex flexDirection={'row'} alignItems={'center'}>
                        <div style={{display: (open && sidebarComponent) ? 'none' : 'block'}}>
                            {
                                sidebarTitle && sidebarTitle.split('').reverse().map((l, i) => {
                                    return (
                                        <div key={v4()} className={'rotate'}>
                                            {l}
                                            <br/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div style={{display: (open && sidebarComponent) ? 'block' : 'none'}}>
                            <h3>{sidebarTitle}</h3>
                            <div style={{height: '100%', paddingBottom: 100}}>
                                {sidebarComponent}
                            </div>
                        </div>
                    </Flex>
                </div>

            </Sider>
            <Layout
                style={{ marginTop: 80, marginLeft: (open && sidebarComponent) ? 332 : 48, marginRight: 32, width: '100%', marginBottom: 48 }}
            >
                <Layout style={{width: (open && sidebarComponent) ? window.innerWidth-360 : window.innerWidth-80}}>
                    <Row justify={'center'}>
                        <Col xs={24} sm={24} md={24} lg={fullWidth ? 24 : 18} xl={fullWidth ? 24 : 14} xxl={fullWidth ? 24 : 12}>
                            {children}
                        </Col>
                    </Row>
                </Layout>
            </Layout>
        </>
    )
}
