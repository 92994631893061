import {useDataGrid} from "../../../core/components/data-grid/DataGridContext";
import {useEffect} from "react";
import ApiService from "../../../core/service/api-service";
import DataGrid, {RenderableExpandRow} from "../../../core/components/data-grid/DataGrid";
import hospitalColumns from "./HospitalColumns";
import {useTranslation} from "react-i18next";
import PacsSystemGrid from "./expanded-row/pacs-systems/PacsSystemGrid";
import {Card, Tabs} from "antd";
import HL7ClientGrid from "./expanded-row/hl7-client/HL7ClientGrid";

export default function HospitalGrid()
{
    const {t} = useTranslation();
    const {setData, setPaginationResult, queryModel, setDataGridProps} = useDataGrid();

    useEffect(() => {
        setDataGridProps({
            rowKey: 'id',
            columns: hospitalColumns(t),
            rowExpandable: record => true,
            expandedRowRender: (record, index, indent, expanded) => {
                return (
                    <RenderableExpandRow expanded={expanded} recordId={record.id}>
                        <Card style={{marginTop: 8}}>
                            <Tabs>
                                <Tabs.TabPane key={`pacs-system-${record.id}`} tab={'Pacs Systems'}>
                                    <PacsSystemGrid hospitalId={record.id} />
                                </Tabs.TabPane>
                                <Tabs.TabPane key={`hl7-client-${record.id}`} tab={'HL7 Clients'}>
                                    <HL7ClientGrid hospitalId={record.id} />
                                </Tabs.TabPane>
                            </Tabs>
                        </Card>
                    </RenderableExpandRow>
                )
            },
        })
    }, [])

    const getHospitalGrid = async () => {
        const response = await ApiService.Hospital.GetDataGrid(queryModel);
        if(response.success)
        {
            setData(response.data);
            setPaginationResult(response.pagination)
        }
    }

    useEffect(() => {
        getHospitalGrid()
    }, [queryModel.updateKey])

    return (
        <>
            <DataGrid name={'hospital'}/>
        </>
    )
}
