import ApiService from "../../../../../core/service/api-service";
import {useEffect, useState} from "react";
import {Button, Menu, Table, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {useModal} from "../../../../../core/context/ModalContext";
import HL7ClientCreateEditModal from "./HL7ClientCreateEditModal";
import RowMenu from "../../../../../core/components/data-grid/RowMenu";
import {v4} from "uuid";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";

export default function HL7ClientGrid({hospitalId})
{

    const {t} = useTranslation();
    const history = useHistory();
    const [data, setData] = useState([]);
    const {openModal, closeModal} = useModal()

    const getHL7Clients = async () => {
        const response = await ApiService.HL7Client.GetHL7ClientDataGrid(hospitalId)
        if(response.success)
        {
            setData(response.data)
        }
    }

    useEffect(() => {
        getHL7Clients()
    }, [])

    return (
        <>
            <Button
                size={'small'}
                onClick={() => {
                    openModal('', (
                        <HL7ClientCreateEditModal
                            hospitalId={hospitalId}
                            onSave={async (data) => {
                                const res = await ApiService.HL7Client.CreateClient(data);
                                if(res.success) {
                                    closeModal()
                                    await getHL7Clients()
                                }
                            }}
                        />
                    ))
                }}
            >
                {t('Create HL7 Client')}
            </Button>

            <Table
                style={{marginTop: 8}}
                size={'small'}
                dataSource={data}
                columns={[
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                        render: (text, record) => {
                            return (
                                <RowMenu text={<Typography.Text copyable={true}>{record?.appClient?.name}</Typography.Text>}>
                                    <Menu>
                                        <Menu.Item
                                            key={`edit-${record?.id}`}
                                            onClick={() => {
                                                openModal(record?.appClient?.name, (
                                                    <HL7ClientCreateEditModal
                                                        data={record}
                                                        onSave={async (data) => {
                                                            const res = await ApiService.HL7Client.EditClient(record.id, data)
                                                            if(res.success){
                                                                closeModal()
                                                                await getHL7Clients()
                                                            }
                                                        }}
                                                    />
                                                ))
                                            }}
                                        >
                                            {t('Edit')}
                                        </Menu.Item>
                                        <Menu.Item
                                            key={`refresht-${record?.id}`}
                                            onClick={async () => {
                                                const res = await ApiService.HL7Client.RefreshToken(record?.id)
                                                if(res.success) {
                                                    await getHL7Clients()
                                                }
                                            }}
                                        >
                                            {t('Refresh Token')}
                                        </Menu.Item>
                                    </Menu>
                                </RowMenu>
                            )
                        }
                    },
                    {
                        title: t('Name'),
                        dataIndex: 'name',
                        key: 'name',
                        render: (text, record) => <Typography.Text copyable={true}>{record?.appClient?.name}</Typography.Text>
                    },
                    {
                        title: t('Key'),
                        dataIndex: 'key',
                        key: 'name',
                        render: (text, record) => <Typography.Text copyable={true}>{record?.appClient?.secret}</Typography.Text>
                    },
                    {
                        title: t('Description'),
                        dataIndex: 'description',
                        key: 'description',
                    },
                    {
                        title: t('Enabled'),
                        dataIndex: 'enabled',
                        key: 'enabled',
                        render: (text, record) => record.enabled ? <CheckOutlined /> : <CloseOutlined />
                    },
                    {
                        title: t('Created Date'),
                        dataIndex: 'createdDate',
                        key: 'createdDate',
                        render: (text, record) => new Date(text).toLocaleString()
                    }
                ]}
                pagination={false}
            />
        </>
    )
}
