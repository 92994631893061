import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import ApiService from "../../core/service/api-service";
import {Select} from "antd";

export default function FirmSelectBox(props)
{
    const {t} = useTranslation();

    const [firms, setFirms] = useState([])

    const getFirms = async () =>
    {
        const response = await ApiService.Firm.GetList();
        if (response.success)
        {
            setFirms(response.data)
        }
    }

    useEffect(() => {
        getFirms()
    }, [])

    return (
        <>
            <Select
                mode="single"
                allowClear
                options={firms}
                fieldNames={{
                    label: 'name',
                    value: 'id'
                }}
                {...props}
            />
        </>
    )
}
