import {useEffect, useState} from "react";
import {Progress} from "antd";
import ApiService from "../../core/service/api-service";
import {v4} from "uuid";

export default function ViewerPanel({studyId, dicomFiles}) {
    const [ready, setReady] = useState(false)

    const initFrame = async () => {
        const frame = document.getElementById('viewer_'+studyId)
        if(!frame)
            return

        const filesAsync = dicomFiles.map(x => {
            return fetch(x).then(y => y.blob())
        })

        const files = await Promise.all(filesAsync)
        frame.contentWindow.postMessage(files, '*')
    }

    useEffect(async () => {
        if(dicomFiles && dicomFiles.length > 0 && ready) {
            await initFrame()
        }
    }, [ready, dicomFiles])

    return (
        <>
            <iframe
                id={'viewer_'+studyId}
                onLoad={(event) => {
                    setReady(true)
                }}
                width={'99%'}
                height={window.innerHeight-150}
                src={`https://app.ecloudpacs.com:444/?date=${new Date().getFullYear()}${new Date().getMonth()}${new Date().getDate()}`}
            />
        </>
    )
}