import {useDataGrid} from "../../../core/components/data-grid/DataGridContext";
import DataGrid, {RenderableExpandRow} from "../../../core/components/data-grid/DataGrid";
import {useEffect, useRef} from "react";
import {t} from "i18next";
import ApiService from "../../../core/service/api-service";
import {useModal} from "../../../core/context/ModalContext";
import Colors from "../../../core/style/Colors";
import {Button, Input, notification, Popover, Tooltip, Typography} from "antd";
import TeletipFilter from "./TeletipFilter";
import TeletipRowMenu from "./TeletipRowMenu";
import KosStatusEnum from "../../../enums/KosStatusEnum";
import {CloseCircleOutlined, InfoCircleOutlined} from "@ant-design/icons";



export default function TeletipGrid({})
{
    const {setData, setPaginationResult, queryModel, setDataGridProps, setRowColor, refreshDataGrid, setSelectedRows, tableId, selectedRows} = useDataGrid();
    const {openModal, closeModal} = useModal()

    useEffect(() => {
        let columns = [
            {
                title: t('Study Received'),
                dataIndex: 'studyReceivedDate',
                key: 'studyReceivedDate',
                columnName: 'studyReceivedDate',
                width: 125,
                render: (text, record) => new Date(text).toLocaleString()
            },
            {
                title: t('Study') + ' Id',
                dataIndex: 'studyId',
                key: 'studyId',
                columnName: 'studyId',
                width: 125,
                render: (text, record) => (
                    <TeletipRowMenu
                        text={text}
                        row={record}
                    />
                )
            },
            {
                title: t('Hospital'),
                dataIndex: 'hospitalName',
                key: 'hospitalName',
                columnName: 'hospitalName',
                width: 125,
                render: (text, record) => <Typography.Text ellipsis={true} style={{width: 125}}>{text}</Typography.Text>
            },
            {
                title: t('Patient Id'),
                dataIndex: 'dicomPatientId',
                key: 'dicomPatientId',
                columnName: 'dicomPatientId',
                width: 125,
                render: (text, record) => <Typography.Text ellipsis={true} style={{width: 125}}>{text}</Typography.Text>
            },
            {
                title: t('Patient Name'),
                dataIndex: 'patientName',
                key: 'patientName',
                columnName: 'patientName',
                width: 175,
                render: (text, record) => <Typography.Text ellipsis={true} style={{width: 125}}>{text}</Typography.Text>
            },
            {
                title: t('Accesion Number'),
                dataIndex: 'accessionNumber',
                key: 'accessionNumber',
                columnName: 'accessionNumber',
                width: 175,
                render: (text, record) => <Typography.Text ellipsis={true} style={{width: 125}}>{text}</Typography.Text>
            },
            {
                title: t('Modality'),
                dataIndex: 'modality',
                key: 'modality',
                columnName: 'modality',
                width: 50,
            },
            {
                title: t('KOS Date'),
                dataIndex: 'kosCreatedDate',
                key: 'kosCreatedDate',
                columnName: 'kosCreatedDate',
                width: 125,
                render: (text, record) => (
                    <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
                        <span>{text ? new Date(text).toLocaleString() : '-'}</span>
                        {
                            record.kosError && (
                                <Popover
                                    content={(
                                        <Input.TextArea
                                            value={record.kosError}
                                            rows={5}
                                            style={{width: 400, height: 300}}
                                            readonly={true}
                                        />
                                    )}
                                >
                                    <Button
                                        type={'text'}
                                        icon={<CloseCircleOutlined />}
                                        size={'small'}
                                    />
                                </Popover>
                            )
                        }
                    </div>
                ),
            },
            {
                title: t('Sent Date'),
                dataIndex: 'sentDate',
                key: 'sentDate',
                columnName: 'sentDate',
                width: 125,
                render: (text, record) => (
                    text && (
                        <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
                            <span>{text ? new Date(text).toLocaleString() : '-'}</span>
                            {
                                record.teletipMessage && (
                                    <Popover
                                        content={(
                                            <Input.TextArea
                                                value={record.teletipMessage}
                                                rows={5}
                                                style={{width: 400, height: 300}}
                                                readonly={true}
                                            />
                                        )}
                                    >
                                        <Button
                                            type={'text'}
                                            icon={<InfoCircleOutlined/>}
                                            size={'small'}
                                        />
                                    </Popover>
                                )
                            }
                        </div>
                    )
                ),
            },
            {
                title: t('Teletip Status'),
                dataIndex: 'teletipStatus',
                key: 'teletipStatus',
                columnName: 'teletipStatus',
                width: 125,
                render: (text, record) => Object.keys(KosStatusEnum).find(key => KosStatusEnum[key] === text)
            },
        ];

        setDataGridProps({
            rowKey: 'id',
            columns: columns,
            antdTableProps: {
                scroll: {x: 800},
            },
            rowClassName: (record, index) => {

            },
            enableRowSelection: true,
            onRow: (record) => {
                return {
                    onClick: () => {
                        setRowColor(record.id, Colors.selectedRow);
                    },
                    onDoubleClick: () => {
                        setRowColor(record.id, Colors.selectedRow);
                    }
                }
            }
        })
    }, [])

    const getTeletipRequests = async () => {
        const response = await ApiService.Teletip.GetTeletipGrid(queryModel);
        if(response.success)
        {
            setData(response.data);
            setPaginationResult(response.pagination)
        }
    }

    useEffect(() => {
        getTeletipRequests()
    }, [queryModel.updateKey])

    return (
        <>
            <DataGrid
                title={() => {
                    return (
                        <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
                            <Button
                                size={'small'}
                                onClick={async () => {
                                    const res = await ApiService.Teletip.MakeKos(selectedRows.map(x => ({studyId: x.studyId})))
                                    if(res.success) {

                                    }
                                }}
                            >
                                Make KOS
                            </Button>
                            <Button
                                size={'small'}
                                onClick={async () => {
                                    const res = await ApiService.Teletip.SendKos(selectedRows.map(x => x.id))
                                    if(res.success) {
                                        await refreshDataGrid()
                                    }
                                }}
                            >
                                Send KOS
                            </Button>
                        </div>
                    )
                }}
                name={'teletip-request-table'}
            />
        </>
    )
}