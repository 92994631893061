import {Card} from "antd";
import RoleManager from "../../app/role/RoleManager";
import LayoutWithSider from "../../core/components/layout/layout-with-sider/LayoutWithSider";
import {t} from "i18next";
import UserCreateEdit from "../../app/user/UserCreateEdit";
import {useParams} from "react-router-dom";

export default function UserCreateEditPage()
{
    const {userId} = useParams();

    return (
        <LayoutWithSider>
            <Card title={t('New User')}>
                <UserCreateEdit userId={userId} />
            </Card>
        </LayoutWithSider>
    )
}
