import React, {useContext, useEffect} from "react";
import {v4} from "uuid";

const defaultValue = {
    tableId: v4(),
    queryModel: {
        filter: {},
        paginationQuery: {
            pageNumber: 1,
            pageSize: 10
        },
        orderByItems: [],
        updateKey: ''
    },
    defaultFilter: {},
    selectedRows: [],
    expandedRowKeys: [],
    data: [],
    pagination: {

    },
    focusOut: null,
    dataGridProps: {
        rowKey: 'id',
        columns: [],
        enableRowSelection: false,
        expandedRowRender: () => {},
        rowExpandable: false,
        antdTableProps: {},
        rowClassName: () => {},
        onRow: (record) => {}
    },
    renderItem: {
        recordId: '',
        key: ''
    }
}

export const DataGridActions = {
    ON_DEFAULT_FILTER: 'ON_DEFAULT_FILTER',
    ON_FILTER_PAGE_SORT: 'ON_FILTER_PAGE_SORT',
    ON_SELECTED_ROWS: 'ON_SELECTED_ROWS',
    SET_DATA: 'SET_DATA',
    SET_PAGINATION_RESULT: 'SET_PAGINATION_RESULT',
    SET_DATA_GRID_PROPS: 'SET_DATA_GRID_PROPS',
    RENDER_EXPANDED_ROW: 'RENDER_EXPANDED_ROW',
    SET_EXPANDED_ROW_KEYS: 'SET_EXPANDED_ROW_KEYS',
    REFRESH_DATA_GRID: 'REFRESH_DATA_GRID',
    FOCUS_OUT: 'FOCUS_OUT'

}

const DataGridContext = React.createContext(defaultValue)

function dataGridReducer(state, action)
{
    switch (action.type)
    {
        case DataGridActions.FOCUS_OUT: {
            return {
                ...state,
                focusOut: v4()
            }
        }
        case DataGridActions.ON_FILTER_PAGE_SORT: {
            let _state = {
                ...state
            };

            _state.selectedRows = []

            _state.queryModel.updateKey = v4();
            if(action.filter) {
                _state.queryModel.filter = {
                    ...action.filter
                }
            }

            if(action.orderByItems) {
                _state.queryModel.orderByItems = action.orderByItems
            }

            if(action.pagination) {
                _state.queryModel.paginationQuery = {
                    ...action.pagination
                }
            }

            if(!action.pagination && action.filter) {
                _state.queryModel.paginationQuery = {
                    pageNumber: 1,
                    pageSize: state.queryModel.paginationQuery.pageSize
                }
            }

            return _state
        }
        case DataGridActions.ON_DEFAULT_FILTER: {
            return {
                ...state,
                defaultFilter: action.defaultFilter
            }
        }
        case DataGridActions.ON_SELECTED_ROWS: {
            return {
                ...state,
                selectedRows: action.selectedRows
            }
        }
        case DataGridActions.SET_DATA: {
            return {
                ...state,
                data: action.data
            }
        }
        case DataGridActions.SET_PAGINATION_RESULT: {
            return {
                ...state,
                pagination: action.pagination
            }
        }
        case DataGridActions.SET_DATA_GRID_PROPS: {
            return {
                ...state,
                dataGridProps: action.dataGridProps
            }
        }
        case DataGridActions.RENDER_EXPANDED_ROW: {
            return {
                ...state,
                renderItem: {
                    recordId: action.recordId,
                    key: v4()
                }
            }
        }
        case DataGridActions.REFRESH_DATA_GRID: {
            return {
                ...state,
                queryModel: {
                    ...state.queryModel,
                    updateKey: v4()
                }
            }
        }
        default: {
            return defaultValue
        }
    }
}

function DataGridProvider({children, name, defaultFilter})
{
    const [dataGrid, dispatch] = React.useReducer(dataGridReducer, {
        ...defaultValue,
        tableId: v4(),
        queryModel: {
            ...defaultValue.queryModel,
            filter: defaultFilter || {},
        },
        defaultFilter: defaultFilter || {}
    });

    const value = {dataGrid, dispatch};

    return (
        <DataGridContext.Provider value={value}>
            {children}
        </DataGridContext.Provider>
    )
}

function useDataGrid()
{
    const context = React.useContext(DataGridContext);

    if(!context)
        throw new Error('Data HospitalDashboardGrid Provider Error')

    const {dataGrid, dispatch} = context;

    const onFilterPageSort = ({filter, pagination, orderByItems}) => {
        dispatch({
            type: DataGridActions.ON_FILTER_PAGE_SORT,
            filter,
            orderByItems,
            pagination
        })
    }

    const setSelectedRows = (selectedRows) => {
        dispatch({
            type: DataGridActions.ON_SELECTED_ROWS,
            selectedRows
        })
    }

    const setData = (data) => {
        dispatch({
            type: DataGridActions.SET_DATA,
            data
        })
    }

    const setDataGridProps = ({rowKey, columns, rowExpandable, expandedRowRender, enableRowSelection, antdTableProps, rowClassName, onRow}) => {
        dispatch({
            type: DataGridActions.SET_DATA_GRID_PROPS,
            dataGridProps: {
                ...dataGrid.dataGridProps,
                rowKey,
                columns,
                enableRowSelection,
                antdTableProps,
                rowExpandable,
                expandedRowRender,
                rowClassName,
                onRow
            }
        })
    }

    const setPaginationResult = (pagination) => {
        dispatch({
            type: DataGridActions.SET_PAGINATION_RESULT,
            pagination
        })
    }

    const renderExpandedRow = (recordId) => {
        dispatch({
            type: DataGridActions.RENDER_EXPANDED_ROW,
            recordId
        })
    }

    const refreshDataGrid = () => {
        dispatch({
            type: DataGridActions.REFRESH_DATA_GRID,
        })
    }

    const focusOut = () => {
        dispatch({
            type: DataGridActions.FOCUS_OUT,
        })
    }

    const setRowColor = (id, color) => {
        const table = document.getElementById(dataGrid.tableId);
        if(table)
        {
            let row = table.querySelectorAll(`[data-row-key="${id}"]`)[0];
            let tmp = document.createElement('div');

            // tmp.style.backgroundColor = color;
            // if(row.style.backgroundColor === tmp.style.backgroundColor)
            // {
            //     color = ''
            // }

            let rows = table.querySelectorAll(`[data-row-key]`);
            rows.forEach(r => {
                r.style.backgroundColor = ''
                if(r?.cells?.length > 0) {
                    r.cells[0].style.backgroundColor = ''
                }
            })

            // if(row?.cells?.length > 0) {
            //     const css = window.getComputedStyle(row);
            //     const color = css.getPropertyValue( "background-color" );
            //     row.cells[0].style.backgroundColor = color
            // }
            if(row && row.style)
                row.style.backgroundColor = color
        }

    }

    return {
        tableId: dataGrid.tableId,
        queryModel: dataGrid.queryModel,
        defaultFilter: dataGrid.defaultFilter,
        resultModel: {
            pagination: dataGrid.pagination,
            data: dataGrid.data,
        },
        dataGridProps: dataGrid.dataGridProps,
        selectedRows: dataGrid.selectedRows,
        renderItem: dataGrid.renderItem,
        onFilterPageSort,
        setSelectedRows,
        setData,
        setPaginationResult,
        setDataGridProps,
        renderExpandedRow,
        refreshDataGrid,
        setRowColor,
        focusOut,
        onFocusOut: dataGrid.focusOut
    }
}

export {DataGridProvider, useDataGrid}
