import ApiService from "../../core/service/api-service";
import {Button, Select, Typography} from "antd";
import {useUser} from "../../core/context/UserContext";
import {useEffect, useState} from "react";
import {t} from "i18next";

export default function StudyReadUsersModal({onSelected, studyId, studyIdList}) {
    const [studyReads, setStudyReads] = useState([])
    const [filtered, setFiltered] = useState([])
    const [selected, setSelected] = useState(null)

    const getStudyReads = async () => {
        const res = await ApiService.StudyDetails.GetReadUsersOfStudy(studyIdList);
        if(res.success) {
            const users = []
            res.data.forEach(x => {
                if(users.find(y => y.visibleName === x.user.visibleName))
                    return

                users.push({
                    ...x,
                    visibleName: x.user.visibleName
                })
            })

            setStudyReads(users)
            setFiltered(users)
        }
    }

    useEffect(() => {
        getStudyReads()
    }, [])

    useEffect(() => {
        if(studyReads?.length > 0) {
            setSelected(studyReads[0].userId)
        }
    }, [studyReads])

    return (
        <>
            <div
                style={{display: 'flex', alignItems: 'center', gap: 8, marginBottom: 8}}
            >
                <Typography.Text>
                    <b>{t('User')}:</b>
                </Typography.Text>

                <Select
                    style={{minWidth: 200}}
                    showSearch={true}
                    filterOption={(input, option) => {
                        return (option?.visibleName ?? '').toUpperCase().includes(input.toUpperCase())
                    }}
                    value={selected}
                    mode="single"
                    onChange={(val) => {
                        setSelected(val)
                    }}
                    options={[{visibleName: t('Only Set to not readed'), userId: null},...filtered]}
                    fieldNames={{
                        label: 'visibleName',
                        value: 'userId'
                    }}
                />
            </div>

            <Button
                type={'primary'}
                onClick={() => {
                    onSelected && onSelected(selected)
                }}
            >
                {t('Select')}
            </Button>

        </>
    )
}