import React, {useEffect, useState} from 'react';
import {TreeSelect} from 'antd';
import ApiService from "../../core/service/api-service";
import {useTranslation} from "react-i18next";

const {TreeNode} = TreeSelect;

/**
 *
 * @param {{modalityId: number}} props
 */
export default function RequestTypeSelectBox(props)
{
    const {t} = useTranslation();
    const [data, setData] = useState([]);
    const [value, setValue] = useState(undefined);

    const getRequestGroupList = async () => {
        if(!props.modalityId)
        {
            setData([])
            return
        }

        const response = await ApiService.RequestTypeGroup.GetGroupList(props.modalityId)
        if(response.success)
        {
            setData(response.data)
        }
    }

    const onChange = () =>
    {
        setValue(value);
    };

    useEffect(() => {
        getRequestGroupList()
    }, [props.modalityId])

    useEffect(() => {
        getRequestGroupList()
    }, [])

    return (
        <TreeSelect
            showSearch
            style={{width: '100%'}}
            value={value}
            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
            placeholder={t("Please select")}
            allowClear
            treeDefaultExpandAll
            onChange={onChange}
            filterTreeNode={(val, item) => {
                return item.title.toLowerCase().includes(val)
            }}
            {...props}
        >
            {
                data.map(group => {
                    return (
                        <TreeNode key={`group-${group.id}`} selectable={false} value={`group-${group.id}`} title={group.name}>
                            {
                                group.requestTypes.map(req => {
                                    return (
                                        <TreeNode key={req.id} value={req.id} title={req.name}/>
                                    )
                                })
                            }
                        </TreeNode>
                    )
                })
            }
        </TreeSelect>
    );
}
