import {Button, Form, Input, Space} from "antd";
import {useDataGrid} from "../../../core/components/data-grid/DataGridContext";
import {useTranslation} from "react-i18next";
import {useRef} from "react";

export default function UserFilter()
{
    const {t} = useTranslation();
    const {onFilterPageSort} = useDataGrid();
    const formRef = useRef();

    const filter = (form) => {
        onFilterPageSort({
            filter: form
        })
    }

    const reset = () => {
        formRef.current.resetFields()
        onFilterPageSort({
            filter: {}
        })
    }

    return (
        <>
            <Form
                ref={formRef}
                onFinish={filter}
                layout={'vertical'}
            >
                <Space style={{marginBottom: 16}}>
                    <Button type={'primary'} size={'small'} htmlType="submit" >{t('Search')}</Button>
                    <Button size={'small'} onClick={reset}>{t('Reset')}</Button>
                </Space>
                <Form.Item
                    label={t('Name')}
                    name={'name'}
                >
                    <Input
                        placeholder={t('Name, Username or Email')}
                    />
                </Form.Item>
            </Form>
        </>
    )
}
