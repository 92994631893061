import {useDataGrid} from "../../../core/components/data-grid/DataGridContext";
import DataGrid, {RenderableExpandRow} from "../../../core/components/data-grid/DataGrid";
import {useEffect, useRef} from "react";
import {t} from "i18next";
import {Button, Dropdown, Menu, Modal, notification, Typography} from "antd";
import {PERMISSIONS, useUser} from "../../../core/context/UserContext";
import {v4} from "uuid";
import ApiService from "../../../core/service/api-service";
import {DownOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import RequestRowMenu from "./RequestRowMenu";
import StudyRequestGridTools from "./StudyRequestGridTools";
import ReportPreview from "../../report/ReportPreview";
import {useModal} from "../../../core/context/ModalContext";
import {dateFormat} from "../../../core/utils/date-utils";
import Colors from "../../../core/style/Colors";
import useFocusListener from "../../../core/components/FocusListener";
import useKeypress from "react-use-keypress";
import {Pages} from "../../../AppRouter";

export default function StudyRequestGrid({studyId, onSelected, name, updateKey, selectedRequestId, listOther, hideReqRepPage})
{
    const {hasPermission} = useUser();
    const {setData, setPaginationResult, queryModel, setDataGridProps, setRowColor, refreshDataGrid, setSelectedRows, tableId} = useDataGrid();
    const {openModal, closeModal} = useModal()
    const selectedRequest = useRef({id: selectedRequestId})
    const selectedReq = useRef(null)

    const openReportPreviewModal = (studyId, requestId, reportId) => {
        if(!reportId)
        {
            notification.error({
                message: t('The report for this record is not available.')
            })
            return
        }

        openModal(t('Report Preview'), (
            <ReportPreview
                studyId={studyId}
                requestId={requestId}
                reportId={reportId}
                onConfirmed={async () => {
                    await refreshDataGrid();
                }}
            />
        ))
    }

    useEffect(() => {
        let columns = [
            {
                title: t('Id'),
                dataIndex: 'id',
                key: 'id',
                columnName: 'id',
                className: 'asd',
                render: (text ,record) => {
                    return <RequestRowMenu row={record} studyId={record.studyId} hideReqRepPage={hideReqRepPage}/>
                }
            },
            {
                title: t('Req. Date'),
                dataIndex: 'requestDate',
                key: 'requestDate',
                columnName: 'requestDate',
                render: (text ,record) => {
                    return new Date(text).toLocaleString()
                }
            },
            {
                title: t('Req. Name'),
                dataIndex: 'requestTypeName',
                key: 'requestTypeName',
                columnName: 'requestTypeName',
                render:(text, record) => (
                    <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
                        {text} {record.isHL7 ? <span>•</span> : ''}
                    </div>
                )
            },
            {
                title: t('Study Id'),
                dataIndex: 'studyId',
                key: 'studyId',
                columnName: 'studyId',
            },
            {
                title: t('Accession Number'),
                dataIndex: 'accessionNumber',
                key: 'accessionNumber',
                columnName: 'accessionNumber',
            },
            {
                title: t('Modality'),
                dataIndex: 'modalityName',
                key: 'modalityName',
                columnName: 'modalityName',
            },
            {
                title: t('Read By User'),
                dataIndex: 'readUsername',
                key: 'readUsername',
                columnName: 'readUsername',
                render: (text, record) => {
                    return text ? text : '-';
                }
            },
            {
                title: t('Assigned Doctor'),
                dataIndex: 'assignedDoctorName',
                key: 'assignedDoctorName',
                columnName: 'assignedDoctorName',
                render: (text, record) => {
                    return text ? text : '-'
                }
            },
            {
                title: t('Assigned Reporter'),
                dataIndex: 'assignedReporterName',
                key: 'assignedReporterName',
                columnName: 'assignedReporterName',
                render: (text, record) => {
                    return text ? text : '-'
                }
            },
            {
                title: t('Report Date'),
                dataIndex: 'reportDate',
                key: 'reportDate',
                columnName: 'reportDate',
                render: (text, record) => {
                    if(record.reportDate !== null)
                        return dateFormat(text)
                    else
                        return '-'
                }
            },
            {
                title: t('Confirmed Date'),
                dataIndex: 'reportConfirmDate',
                key: 'reportConfirmDate',
                columnName: 'reportConfirmDate',
                render: (text, record) => {
                    if(record.reportConfirmDate !== null)
                        return dateFormat(text)
                    else
                        return '-'
                }
            },
            {
                title: t('Confirmed By User'),
                dataIndex: 'reportConfirmUser',
                key: 'reportConfirmUser',
                columnName: 'reportConfirmUser',
                render: (text, record) => {
                    if(record.reportConfirmUser !== null)
                        return text
                    else
                        return '-'
                }
            },
        ];
        if(!hasPermission(PERMISSIONS.STUDY_GRID_USER_INFO)) {
            columns = columns.filter(x => x.dataIndex !== 'readUsername')
            columns = columns.filter(x => x.dataIndex !== 'reportConfirmUser')
            columns = columns.filter(x => x.dataIndex !== 'assignedDoctorName')
            columns = columns.filter(x => x.dataIndex !== 'assignedReporterName')
        }

        if(listOther) {

        }

        setDataGridProps({
            rowKey: 'id',
            columns: columns,
            antdTableProps: {
                scroll: {x: 1000},
                pagination: false
            },
            rowClassName: (record, index) => {
                let className = 'normal-row';

                if (record.isRequestDeleted)
                    className = 'deleted-row';
                else if (record.reportIsConfirm)
                    className = 'confirmed-report-row';
                else if (!record.reportIsConfirm)
                    className = 'not-confirmed-report-row'
                else
                    className = 'normal-row'

                return className;
            },
            enableRowSelection: false,
            onRow: (record) => {
                return {
                    onClick: () => {
                        if(selectedRequest.current?.id === record?.id) {
                            setSelectedReq(null, 'A3')
                            setRowColor(record.id, '');
                        }
                        else {
                            setSelectedReq(record, 'A3')
                            setRowColor(record.id, Colors.selectedRow);
                        }
                    },
                    onDoubleClick: () => {
                        setRowColor(record.id, Colors.selectedRow);
                        hasPermission(PERMISSIONS.REPORT_LIST) && openReportPreviewModal(record.studyId, record.id, record.reportId)
                    }
                }
            }
        })
    }, [])

    const getStudyRequests = async () => {
        let response = {}
        if(!listOther) {
            response = await ApiService.Request.GetStudyRequestTableList(studyId);
        } else {
            response = await ApiService.Request.GetOtherRequestTableList(studyId);
        }
        if(response.success)
        {
            setData(response.data);

            if(response.data.length > 0 && !selectedRequest.current?.id) {
                const idx = response.data.findIndex(x => !x.reportIsConfirm)
                if(idx > -1) {
                    selectedRequest.current = response.data[idx]
                    setRowColor(response.data[idx].id, Colors.selectedRow)
                    setSelectedReq(response.data[idx], 'A1')
                }
            }
            else {
                const idx = response.data.findIndex(x => x.id === parseInt(selectedRequest.current.id))
                if(idx > -1) {
                    setRowColor(response.data[idx].id, Colors.selectedRow)
                    setSelectedReq(response.data[idx], 'A2')
                }
            }
        }
    }

    const setSelectedReq = (req, type) => {
        onSelected && onSelected(req, type)
        selectedRequest.current = req
    }

    useEffect(() => {
        getStudyRequests()
    }, [queryModel.updateKey, updateKey])

    useEffect(() => {
        if(updateKey) {
            getStudyRequests()
        }
    }, [updateKey])

    return (
        <div style={{fontSize: '9pt'}}>
            {
                listOther && (
                    <Typography.Text>
                        {t('OTHER_REQUEST_TEXT')}
                    </Typography.Text>
                )
            }
            <DataGrid
                name={name || 'study-request'}
                title={() => !listOther && <StudyRequestGridTools studyId={studyId} />}
            />
        </div>
    )
}