import Flex from "../../../core/components/Flex";
import {Button, Dropdown, notification, Popover, Select, Tooltip, Typography} from "antd";
import {
    AlertOutlined,
    CheckOutlined, CopyOutlined, DeleteOutlined,
    DownOutlined,
    EyeOutlined,
    FieldTimeOutlined,
    LockOutlined
} from "@ant-design/icons";
import {BsFillMicFill} from "react-icons/bs";
import {dateFormat, getAge} from "../../../core/utils/date-utils";
import StudyRowMenu from "./StudyRowMenu";
import {HiOutlineDocumentText} from 'react-icons/hi'
import {BsFileArrowDownFill} from 'react-icons/bs'
import {t} from "i18next";
import {PERMISSIONS} from "../../../core/context/UserContext";
import StudyTypeEnum from "../../../enums/StudyTypeEnum";
import EnumHelper from "../../../enums/EnumHelper";
import ApiService from "../../../core/service/api-service";
import InstitutionSelect from "../../user/InstitutionSelect";
import moment from "moment";

const studyGridColumns = (refresh, hasPermission) => {
    let columns =  [
        {
            title: 'Id',
            key: 'id',
            render: (text ,row) => (
                <>
                    <StudyRowMenu row={row} />
                    <Button
                        size={'small'}
                        type={'text'}
                        icon={<CopyOutlined />}
                        style={{color: '#7f8c8d'}}
                        onClick={async () => {
                            const text = [
                                `ID: ${row.id}`,
                                `${t('Patient ID')}: ${row.dicomPatientId}`,
                                `${t('Patient Name')}: ${row.patientName}`,
                                `${t('Accession Number')}: ${row.accessionNumber}`,
                                `${t('Modality')}: ${row.modality}`,
                                `${t('Description')}: ${row.studyDescription}`,
                                `${t('Study Date')}: ${moment(row.studyDate).format('DD-MM-yyyy HH:mm')}`,
                                `${t('Received Date')}: ${moment(row.receivedDate).format('DD-MM-yyyy HH:mm')}`,
                            ].join('\n')
                            await navigator.clipboard.writeText(text);
                            notification.success({
                                message: t('Copied')
                            })
                        }}
                    />
                </>
            ),
            width: 70,
        },
        {
            title: t('Status'),
            key: 'status',
            width: 60,
            render: (text, record) => {
                return (
                    <Flex alignItems={'center'}>
                        {record.isRead && (
                            <Tooltip title={t('Read')}>
                                <EyeOutlined style={{color: '#2980b9'}}/>
                            </Tooltip>
                        )}
                        {record.isEmergency && (
                            <Tooltip title={t('Emergency')}>
                                <AlertOutlined style={{color: '#c0392b'}}/>
                            </Tooltip>
                        )}
                        {
                            record.isLocked && (
                                <Tooltip title={t('Locked')}>
                                    <LockOutlined style={{color: '#d35400'}}/>
                                </Tooltip>
                            )
                        }
                        {
                            record.hasVoiceRecord && (
                                <Tooltip title={t('Voice Record')}>
                                    <BsFillMicFill />
                                </Tooltip>
                            )
                        }
                        {
                            record.hasAnamnez && (
                                <Tooltip title={t('Anamnez')}>
                                    <HiOutlineDocumentText />
                                </Tooltip>
                            )
                        }
                        {
                            record.hasFile && (
                                <Tooltip title={t('File')}>
                                    <BsFileArrowDownFill color={'#3742fa'} />
                                </Tooltip>
                            )
                        }
                    </Flex>
                )
            }
        },
        {
            title: t('R/Req'),
            dataIndex: 'info',
            key: 'info',
            width: 30,
            render: (text, record) => {
                return `${record.reportCount}/${record.requestCount}`
            }
        },
        {
            title: t('Institution'),
            dataIndex: 'institution',
            key: 'institution',
            columnName: 'institution',
            sorter: true,
            width: 80,
            render: (text, record) => {
                if(hasPermission(PERMISSIONS.EDIT_STUDY_INSTITUTION)) {
                    return (
                        <Popover
                            trigger={'click'}
                            placement={'bottom'}
                            content={(
                                <InstitutionSelect
                                    value={record.institutionId}
                                    size={'small'}
                                    style={{width: 150}}
                                    placeholder={text}
                                    allowClear={true}
                                    showSearch={true}
                                    mode={'single'}
                                    onChange={async (val) => {
                                        const res = await ApiService.Study.EditInstitutions(record.id, val)
                                        if(res.success) {
                                            refresh && refresh()
                                        }
                                    }} />
                            )}
                        >
                            <Typography.Text
                                ellipsis={{tooltip: record.institution || '-'}} style={{width: 80}}
                                onClick={(e) => {

                                }}
                            >
                                <a>{record.institution || '-'}</a>
                            </Typography.Text>
                        </Popover>
                    )
                }
                else {
                    return (
                        <Typography.Text ellipsis={{tooltip: `${record.institution || '-'}`}} style={{width: 80}}>
                            {record.institution || '-'}
                        </Typography.Text>
                    )
                }
            }
        },
        {
            title: t('Hospital Name'),
            dataIndex: 'hospitalName',
            key: 'hospitalName',
            columnName: 'pacsSystem.hospital.name',
            sorter: true,
            width: 120,
            render: (text, record) => {
                return (
                    <Typography.Text ellipsis={{tooltip: text}} style={{width: 120}}>
                        {text}
                    </Typography.Text>
                )
            }
        },
        {
            title: t('Patient Id'),
            dataIndex: 'dicomPatientId',
            key: 'dicomPatientId',
            columnName: 'patient.dicomPatientId',
            sorter: true,
            width: 100,
            render: (text, record) => {
                return (
                    <Typography.Text ellipsis={{tooltip: text}} style={{width: 100}}>
                        {text}
                    </Typography.Text>
                )
            }
        },
        {
            title: t('Patient Name'),
            dataIndex: 'patientName',
            key: 'patientName',
            columnName: 'patient.name',
            sorter: true,
            width: 150,
            render: (text, record) => {
                return (
                    <Typography.Text ellipsis={{tooltip: text}} style={{width: 150}}>
                        {text}
                    </Typography.Text>
                )
            }
        },
        {
            title: t('Age'),
            dataIndex: 'birthdate',
            key: 'birthdate',
            columnName: 'patient.birthdate',
            sorter: true,
            width: 40,
            render: (text, record) => {
                return (
                    <span>{
                        getAge(text)
                    }</span>
                )
            },
        },
        {
            title: t('Accession Number'),
            dataIndex: 'accessionNumber',
            key: 'accessionNumber',
            columnName: 'accessionNumber',
            sorter: true,
            width: 80,
            render: (text, record) => {
                return (
                    <Typography.Text ellipsis={{tooltip: text}} style={{width: 80}}>
                        {text}
                    </Typography.Text>
                )
            }
        },
        {
            title: t('Modality'),
            dataIndex: 'modality',
            key: 'modality',
            width: 65,
            columnName: 'modality.name',
            sorter: true,
        },
        {
            title: t('Description'),
            dataIndex: 'studyDescription',
            key: 'studyDescription',
            sorter: true,
            width: 200,
            render: (text, record) => {
                return (
                    <Typography.Text ellipsis={{tooltip: text}} style={{width: 200}}>
                        {text}
                    </Typography.Text>
                )
            }
        },
        {
            title: t('Read User'),
            dataIndex: 'readUserNames',
            key: 'readUserNames',
            width: 60,
            render: (text, record) => {
                return (
                    <Typography.Text ellipsis={{tooltip: record.readUserNames}} style={{width: 60}}>
                        {record.readUserNames || '-'}
                    </Typography.Text>
                )
            }
        },
        {
            title: t('Assigned Doctor'),
            dataIndex: 'assignedDoctorName',
            key: 'assignedDoctorName',
            columnName: 'assignedDoctorName',
            width: 60,
            render: (text, record) => {
                return (
                    <Typography.Text ellipsis={{tooltip: text}} style={{width: 60}}>
                        {text || '-'}
                    </Typography.Text>
                )
            }
        },
        {
            title: t('Assigned Reporter'),
            dataIndex: 'assignedReporterName',
            key: 'assignedReporterName',
            columnName: 'assignedReporterName',
            width: 60,
            render: (text, record) => {
                return (
                    <Typography.Text ellipsis={{tooltip: text}} style={{width: 60}}>
                        {text || '-'}
                    </Typography.Text>
                )
            }
        },
        {
            title: t('Study Date'),
            dataIndex: 'studyDate',
            key: 'studyDate',
            columnName: 'studyDateTime',
            sorter: true,
            width: 120,
            render: (text, record) => {
                return dateFormat(text)
            },
        },
        {
            title: t('Received Date'),
            dataIndex: 'receivedDate',
            key: 'receivedDate',
            columnName: 'createdAt',
            width: 120,
            render: (text, record) => {
                return dateFormat(text)
            },
            sorter: true,
        },
        {
            title: t('I-C'),
            dataIndex: 'instanceCount',
            key: 'instanceCount',
            columnName: 'numberOfStudyRelatedInstance',
            width: 40,
            sorter: true
        },
        {
            title: t('Study Type'),
            dataIndex: 'studyType',
            key: 'studyType',
            columnName: 'StudyDetail.studyType',
            sorter: true,
            width: 50,
            render: (text, record) => {
                return t(EnumHelper.getKeyByValue(StudyTypeEnum, record.studyType))
            }
        },
        {
            title: t('Time'),
            dataIndex: 'studyTimeoutDate',
            key: 'studyTimeoutDate',
            columnName: 'studyDetail.timeoutDate',
            sorter: true,
            width: 50,
            render: (text, record) => {
                let minStr = 0;

                let now = new Date().getTime();
                if(record.studyStatus)
                    now = new Date(record.studyDetailUpdateDate)

                let timeoutDate = new Date(record.studyTimeoutDate).getTime();
                let remain = timeoutDate - now;

                let sec = remain/1000;
                let min = parseInt(sec / 60);
                minStr = parseInt(min.toString()) + t('m')

                if(min > 240) {
                    minStr = '-'
                }

                let status = ''
                switch (record.studyStatus) {
                    case 2: {
                        status = (
                            <CheckOutlined />
                        )
                        break
                    }
                    case 1: {
                        status = (
                            <Tooltip title={minStr} placement={'bottom'}>
                                <FieldTimeOutlined />
                            </Tooltip>
                        )
                        break
                    }
                    default: {
                        status = minStr
                        break
                    }
                }

                return (
                    <Typography.Text ellipsis={{tooltip: text}} style={{width: 50}}>
                        {status}
                    </Typography.Text>
                )
            }
        },
    ];

    if(!hasPermission(PERMISSIONS.STUDY_GRID_USER_INFO)) {
        columns = columns.filter(x => x.dataIndex !== 'readUserNames')
        columns = columns.filter(x => x.dataIndex !== 'assignedDoctorName')
        columns = columns.filter(x => x.dataIndex !== 'assignedReporterName')
    }

    if(!hasPermission(PERMISSIONS.VIEW_STUDY_INSTITUTION)) {
        columns = columns.filter(x => x.dataIndex !== 'institution')
    }

    return columns
}

export default studyGridColumns;
