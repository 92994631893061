import {useEffect, useState} from "react";
import ApiService from "../../core/service/api-service";
import {Button, Select, Typography} from "antd";
import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    DeleteOutlined,
    EditOutlined,
    SaveOutlined,
    UploadOutlined
} from "@ant-design/icons";
import {useModal} from "../../core/context/ModalContext";
import {t} from "i18next";
import HospitalGroupCreateEdit from "./HospitalGroupCreateEdit";
import SearchableTreeView from "../../core/components/SearchableTreeView";
import _ from "lodash";

export default function HospitalGroupEdit() {
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [groups, setGroups] = useState([])
    const [hospitals, setHospitals] = useState([])
    const [keys, setKeys] = useState([])
    const [removedKeys, setRemovedKeys] = useState([])

    const {openModal, closeModal} = useModal()

    const getGroups = async () => {
        const res = await ApiService.HospitalGroup.GetList();
        if (res.success) {
            setGroups(res.data)
        }
    }

    const getHospitals = async () => {
        const res = await ApiService.Hospital.GetList()
        if (res.success) {
            setHospitals(res.data)
        }
    }

    const init = async () => {
        await getGroups()
        await getHospitals()
    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        if (groups && groups.length > 0 && !selectedGroup) {
            setSelectedGroup(groups[0].id)
        }
    }, [groups])

    const getGroup = (id) => {
        return groups.find(x => x.id === id)
    }

    const createGroup = () => {
        openModal(t('Create Hospital Group'), (
            <HospitalGroupCreateEdit
                onSave={async (data) => {
                    const res = await ApiService.HospitalGroup.Create(data)
                    if (res.success) {
                        await getGroups()
                        closeModal()
                    }
                }}
            />
        ))
    }

    const editGroup = () => {
        const group = getGroup(selectedGroup)
        openModal(t('Edit') + ' - ' + group.name, (
            <HospitalGroupCreateEdit
                name={group.name}
                onSave={async (data) => {
                    const res = await ApiService.HospitalGroup.Edit(group.id, data)
                    if (res.success) {
                        await getGroups()
                        closeModal()
                    }
                }}
            />
        ))
    }

    const deleteGroup = async () => {
        const group = getGroup(selectedGroup)
        const res = await ApiService.HospitalGroup.Delete(group.id)
        if (res.success) {
            setSelectedGroup(null)
            await getGroups()
        }
    }

    const updateGroupHospitals = async (_groupId, idList) => {
        const res = await ApiService.HospitalGroup.SetHospitalsToGroup({
            groupId: _groupId,
            hospitalIdList: idList
        })

        if (res.success) {
            await init()
            setKeys([])
            setRemovedKeys([])
        }
    }

    const sortHospital = (a,b) => {
        if(!a.group?.name)
        {
            a.group = {}
            a.group.name = '-1'
        }

        if(!b.group?.name)
        {
            b.group = {}
            b.group.name = '-1'
        }

        if (a?.group?.name < b?.group?.name) {
            return -1;
        }
        if (a?.group?.name > b?.group?.name) {
            return 1;
        }
        return 0;
    }

    const mapHospital = (x) => {
        const name = x.name
        const group = getGroup(x.groupId)
        const text = `${name} - ${group?.name || t('No Group')}`

        return {
            key: x.id,
            text,
            title: (
                <div>
                    <span style={{fontWeight: 'bold'}}>{x.name}</span> - <span
                    style={{fontStyle: 'italic'}}>{group?.name || t('No Group')}</span>
                </div>
            )
        }
    }

    return (
        <>
            <Typography.Title level={4}>
                {t('Groups')}
            </Typography.Title>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8
                }}
            >
                <Select
                    value={selectedGroup}
                    onChange={(val) => setSelectedGroup(val)}
                    placeholder={'Hospital Groups'}
                    style={{width: 425}}
                >
                    {
                        groups.map(x => {
                            return (
                                <Select.Option key={x.id} value={x.id}>
                                    {x.name}
                                </Select.Option>
                            )
                        })
                    }
                </Select>
                <Button
                    onClick={editGroup}
                    disabled={!selectedGroup}
                >
                    <EditOutlined/>
                </Button>
                <Button
                    onClick={deleteGroup}
                    type={'primary'}
                    disabled={!selectedGroup}
                    danger={true}
                >
                    <DeleteOutlined/>
                </Button>
                <Button
                    onClick={createGroup}
                    type={'primary'}
                >
                    +
                </Button>
            </div>
            <div style={{marginTop: 32, display: 'flex', alignItems: 'start', gap: 16}}>
                <div>
                    <Typography.Title level={4}>
                        {t('Hospitals')}
                    </Typography.Title>
                    <div style={{minWidth: 400}}>
                        <SearchableTreeView
                            checkedKeys={keys}
                            onCheckedChange={(_keys) => {
                                setKeys(_keys)
                            }}
                            treeViewProps={{
                                height: 500,
                                style: {minHeight: 400}
                            }}
                            treeItemData={
                                hospitals
                                    .sort(sortHospital)
                                    .map(mapHospital)
                            }
                        />
                    </div>
                </div>
                <div style={{marginTop: 200}}>
                    <Button
                        disabled={keys.length === 0}
                        style={{width: 80, marginBottom: 4}}
                        type={'primary'}
                        onClick={() => {updateGroupHospitals(selectedGroup, keys)}}
                    >
                        {t('Move')} <ArrowRightOutlined />
                    </Button>
                    <br/>
                    <Button
                        disabled={removedKeys.length === 0}
                        style={{width: 80}}
                        type={'primary'}
                        danger={true}
                        onClick={() => {updateGroupHospitals(null, removedKeys)}}
                    >
                        {t('Remove')} <ArrowLeftOutlined />
                    </Button>
                </div>
                <div>
                    <Typography.Title level={4}>
                        {getGroup(selectedGroup)?.name || t('Group')}
                    </Typography.Title>
                    <div style={{minWidth: 400}}>
                        <SearchableTreeView
                            checkedKeys={removedKeys}
                            onCheckedChange={(_keys) => {
                                setRemovedKeys(_keys)
                            }}
                            treeViewProps={{
                                height: 500,
                                style: {minHeight: 400}
                            }}
                            treeItemData={
                                hospitals
                                    .filter(x => x.groupId === selectedGroup && x.groupId)
                                    .sort(sortHospital)
                                    .map(mapHospital)
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    )
}