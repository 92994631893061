import {useTranslation} from "react-i18next";
import LayoutWithSider from "../../core/components/layout/layout-with-sider/LayoutWithSider";
import {Card} from "antd";
import RequestTypeManage from "../../app/request-type/RequestTypeManage";
import UserDictionary from "../../app/user/UserDictionary";

export default function UserDictionaryPage() {
    const {t} = useTranslation();

    return (
        <LayoutWithSider fullWidth={true}>
            <Card title={t('Dictionary')}>
                <UserDictionary />
            </Card>
        </LayoutWithSider>
    )
}