import AudioPlayer from "../AudioPlayer";
import {useEffect, useRef, useState} from "react";
import ApiService from "../../core/service/api-service";
import {Button, Row, Space, Table, Tooltip, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {dateFormat} from "../../core/utils/date-utils";
import {PlayCircleOutlined} from "@ant-design/icons";
import AudioPlayer2 from "react-h5-audio-player";
import Flex from "../../core/components/Flex";
import {t} from "i18next";
import {PERMISSIONS} from "../../core/context/UserContext";
import DataGrid from "../../core/components/data-grid/DataGrid";
import {useDataGrid} from "../../core/components/data-grid/DataGridContext";
import StudyLogEnum from "../study/StudyLogEnum";
import ReportPreview from "./ReportPreview";
import ReportVersionPreview from "./ReportVersionPreview";
import Colors from "../../core/style/Colors";

export default function ReportVersionsGrid({studyId, requestId, reportId})
{
    const {setDataGridProps, queryModel, setData, setPaginationResult, refreshDataGrid, setRowColor} = useDataGrid()
    const {t} = useTranslation();
    const [report, setReport] = useState({})
    const [versionId, setVersionId] = useState(null)
    const [versionInfo, setVersionInfo] = useState(null)


    const initDataGrid = async () => {
        const response = await ApiService.Report.GetReportVersions(studyId, reportId, queryModel)
        if(response.success) {
            setData(response.data)
            setPaginationResult(response.pagination)
        }
    }

    const getReport = async () => {
        const res = await ApiService.Report.Get(studyId, requestId, reportId)
        if(res.success) {
            debugger
            setReport(res.data)
        }
    }

    useEffect(() => {
        initDataGrid()
        getReport()
    }, [queryModel.updateKey])

    useEffect(() => {
        if(versionId) {
            setRowColor(versionId, Colors.selectedRow)
        }
    }, [versionId]);

    useEffect(() => {
        setDataGridProps({
            rowKey: 'id',
            enableRowSelection: false,
            antdTableProps: {
                scroll: {}
            },
            onRow: (record) => {
                return {
                    onClick: (e) => {
                        setRowColor(record.id, Colors.selectedRow)
                    },
                    onDoubleClick: () => {

                    }
                }
            },
            columns: [
                {
                    title: t('Id'),
                    dataIndex: 'id',
                    key: 'id',
                    width: 50,
                },
                {
                    title: t('Version Date'),
                    dataIndex: 'createdDate',
                    key: 'createdDate',
                    width: 150,
                    render: (text, record) => new Date(text).toLocaleString()
                },
                {
                    title: t('Version User'),
                    dataIndex: 'creatorUserId',
                    key: 'creatorUserId',
                    width: 150,
                    render: (text, record) => record?.creatorUser?.visibleName
                },
                {
                    title: t('Version Study Read User'),
                    dataIndex: 'studyReadUserId',
                    key: 'studyReadUserId',
                    width: 150,
                    render: (text, record) => record?.studyReadUser?.visibleName
                },
                // {
                //     title: t('Previous Version'),
                //     dataIndex: 'previousReportVersionId',
                //     key: 'previousReportVersionId',
                //     width: 50,
                //     render: (text, record) => text || '-'
                // },
                {
                    title: t('#'),
                    dataIndex: '#',
                    key: '#',
                    width: 150,
                    render: (text, record) => (
                        <div style={{display: 'flex', alignItems: 'center', gap: 8, marginLeft: 16}}>
                            <Button
                                onClick={() => {
                                    setVersionId(record.id)
                                    setVersionInfo(record)
                                }}
                                size={'small'}>
                                <a>{t('Report Preview')}</a>
                            </Button>
                        </div>
                    )
                },
            ]
        })
    }, [])

    return (
        <>
            <DataGrid
                name={'report-versions'}
            />
            {
                versionId && (
                    <div style={{display: 'flex', alignItems: 'start', gap: 32}}>
                        <div style={{flex: 1}}>
                            <Typography.Title level={3}>
                                {t('Current Version')} - {new Date(report?.reportFileModifiedDate).toLocaleString()}
                            </Typography.Title>
                            <table>
                                <tr>
                                    <td><span style={{fontWeight: 'bold'}}>{t('Report Owner:')}</span></td>
                                    <td>{report?.createdUser?.visibleName}</td>
                                </tr>
                                <tr>
                                    <td><span style={{fontWeight: 'bold', marginRight: 8}}>{t('Last Modified User:')}</span></td>
                                    <td>
                                        <td>{report?.modifiedUser?.visibleName}</td>
                                    </td>
                                </tr>
                            </table>
                            <ReportPreview reportId={reportId} studyId={studyId} requestId={requestId} disableActions={true}/>
                        </div>
                        <div style={{flex: 1}}>
                            <Typography.Title level={3}>
                                {t('Selected Version')} - {new Date(versionInfo?.createdDate).toLocaleString()}
                            </Typography.Title>
                            <table>
                                <tr>
                                    <td><span style={{fontWeight: 'bold'}}>{t('Report Owner:')}</span></td>
                                    <td>{report?.createdUser?.visibleName}</td>
                                </tr>
                                <tr>
                                    <td><span style={{fontWeight: 'bold', marginRight: 8}}>{t('Modified User:')}</span></td>
                                    <td>{versionInfo?.creatorUser?.visibleName}</td>
                                </tr>
                            </table>
                            <ReportVersionPreview report={report} versionId={versionId} studyId={studyId}/>
                        </div>
                    </div>
                )
            }
        </>
    )
}
