import RowMenu from "../../../core/components/data-grid/RowMenu";
import {Menu, Typography} from "antd";
import {v4} from "uuid";
import ApiService from "../../../core/service/api-service";
import {t} from "i18next";
import {useDataGrid} from "../../../core/components/data-grid/DataGridContext";

export default function TeletipRowMenu({text, row}) {
    const {refreshDataGrid} = useDataGrid()

    return (
        <RowMenu text={text}>
            <Menu>
                <Menu.Item
                    key={v4()}
                    onClick={async () => {
                        const res = await ApiService.Teletip.MakeKos([{studyId: row.studyId}]);
                        if(res.success) {
                            refreshDataGrid()
                        }
                    }}
                >
                    {t('Make Kos')}
                </Menu.Item>
                <Menu.Item
                    key={v4()}
                    onClick={async () => {
                        const res = await ApiService.Teletip.SendKos([row.id]);
                        if(res.success) {
                            refreshDataGrid()
                        }
                    }}
                >
                    {t('Send Kos')}
                </Menu.Item>
                <Menu.Item
                    key={v4()}
                    onClick={async () => {

                    }}
                >
                    {t('Remove Kos')}
                </Menu.Item>
            </Menu>
        </RowMenu>
    )
}