import {useEffect, useState} from "react";
import ApiService from "../../core/service/api-service";
import {Form, Select} from "antd";
import {useTranslation} from "react-i18next";
import {useApp} from "../../core/context/AppContext";

export default function ModalitySelectBox (props)
{
    const { modalities } = useApp()

    return (
        <>
            <Select
                mode="multiple"
                allowClear
                options={modalities}
                fieldNames={{
                    label: 'name',
                    value: 'id'
                }}
                {...props}
            />
        </>
    )
}
