import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import ApiService from "../../core/service/api-service";
import {Button, Col, Divider, Form, List, message, Row, Select, Typography} from "antd";
import ModalitySelectBox from "../modality/ModalitySelectBox";
import Flex from "../../core/components/Flex";
import {EditOutlined} from "@ant-design/icons";
import {useModal} from "../../core/context/ModalContext";
import RequestTypeGroupCreateEdit from "./RequestTypeGroupCreateEdit";
import {toast} from "react-toastify";
import RequestTypeCreateEdit from "./RequestTypeCreateEdit";

export default function RequestTypeManage()
{
    const {t} = useTranslation();
    const {openModal, closeModal} = useModal()

    const [selectedModalityId, setSelectedModalityId] = useState(null);

    const [groups, setGroups] = useState([])
    const [selectedGroupId, setSelectedGroupId] = useState(null);

    const [requestTypes, setRequestTypes] = useState([])
    const [selectedRequestTypeId, setSelectedRequestTypeId] = useState(null);

    const getRequestTypeGroups = async () => {
        if(selectedModalityId && selectedModalityId > 0)
        {
            const response = await ApiService.RequestTypeGroup.GetGroupList(selectedModalityId)
            if(response.success)
            {
                setGroups(response.data)
            }
        }
    }

    const getRequestTypes = async () => {
        if(selectedGroupId && selectedGroupId > 0)
        {
            const response = await ApiService.RequestType.GetListByGroup(selectedGroupId)
            if(response.success)
            {
                setRequestTypes(response.data)
            }
        }
    }

    useEffect(() => {
        getRequestTypeGroups()
    }, [selectedModalityId])

    useEffect(() => {
        getRequestTypes()
    }, [selectedGroupId])

    const editRequestTypeGroup = async (group) => {
        if(!selectedModalityId)
        {
            toast.error(t('Please Select Modality'))
            return
        }

        openModal(t(!group.id ? 'New Request Group' : 'Edit Request Group - ' + group.name), (
            <RequestTypeGroupCreateEdit
                modalityId={selectedModalityId}
                groupId={group.id}
                groupName={group.name}
                onSaved={() => {
                    closeModal()
                    getRequestTypeGroups()
                }}
            />
        ))
    }

    const editRequestType = async (requestType) => {
        if(!selectedModalityId || !selectedGroupId)
        {
            toast.error(t('Please Select Modality and Request Group'))
            return
        }

        openModal(t(!requestType.id ? 'New Request Type' : 'Edit Request Type - ' + requestType.name), (
            <RequestTypeCreateEdit
                modalityId={selectedModalityId}
                groupId={selectedGroupId}
                requestType={requestType}
                onSaved={() => {
                    closeModal()
                    getRequestTypes()
                }}
            />
        ))
    }

    return (
        <>
            <Form
                layout={'vertical'}
            >
                <Form.Item
                    label={t('Modality')}
                >
                    <ModalitySelectBox
                        onChange={(e) => {
                            setSelectedModalityId(e);
                            setSelectedGroupId(null)
                            setSelectedRequestTypeId(null)
                            setRequestTypes([])
                        }}
                        mode={'single'}
                    />
                </Form.Item>

                <Form.Item
                >
                    <Row gutter={24}>
                        <Col xs={12}>
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: 400,
                                    overflow: 'auto',
                                    padding: '0 16px',
                                    border: '1px solid rgba(140, 140, 140, 0.35)',
                                }}
                            >
                                <List
                                    header={(
                                        <Typography.Title level={5}>
                                            {t('Request Groups')}
                                            <Button
                                                onClick={() => {editRequestTypeGroup({})}}
                                                style={{marginLeft: 8}}
                                            >
                                                {t('Add')}
                                            </Button>
                                        </Typography.Title>
                                    )}
                                    dataSource={groups}
                                    renderItem={(item) => {
                                        return (
                                            <List.Item>
                                                <Typography.Link
                                                    onClick={() => {
                                                        setSelectedGroupId(item.id)
                                                    }}
                                                >
                                                    {item.name}
                                                    {selectedGroupId === item.id && (<b style={{marginLeft: 16}}>{t('Selected')}</b>)}
                                                </Typography.Link>
                                                <Button
                                                    style={{marginLeft: 8}}
                                                    type={'text'}
                                                    onClick={() => {editRequestTypeGroup(item)}}
                                                >
                                                    <EditOutlined />
                                                </Button>
                                            </List.Item>
                                        )
                                    }}
                                />
                            </div>

                        </Col>
                        <Col xs={12}>
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: 400,
                                    overflow: 'auto',
                                    padding: '0 16px',
                                    border: '1px solid rgba(140, 140, 140, 0.35)',
                                }}
                            >
                                <List
                                    header={(
                                        <Typography.Title level={5}>
                                            {t('Requests')}
                                            <Button
                                                onClick={() => {editRequestType({})}}
                                                style={{marginLeft: 8}}
                                            >
                                                {t('Add')}
                                            </Button>
                                        </Typography.Title>
                                    )}
                                    dataSource={requestTypes}
                                    renderItem={(item) => {
                                        return (
                                            <List.Item>
                                                <Typography.Text>
                                                    {item.name}
                                                </Typography.Text>
                                                <Button
                                                    onClick={() => {editRequestType(item)}}
                                                    style={{marginLeft: 8}}
                                                    type={'text'}
                                                >
                                                    <EditOutlined />
                                                </Button>
                                            </List.Item>
                                        )
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </>
    )
}
