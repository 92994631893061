import {useEffect, useState} from "react";

export default function useFocusListener({elementId}) {
    const [focus, setFocus] = useState(false)

    const init = async () => {
        while (true) {
            const element = document.getElementById(elementId)
            if(element) {
                element.addEventListener('mouseenter', () => {
                    setFocus(true)
                })
                element.addEventListener('mouseleave', () => {
                    setFocus(false)
                })
                break
            }
        }
    }

    useEffect(() => {
        init()
    }, []);

    return focus
}