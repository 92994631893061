import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Card} from "antd";
import ReportTemplateCreateEditForm from "../../app/report-template/ReportTemplateCreateEditForm";
import LayoutWithSider from "../../core/components/layout/layout-with-sider/LayoutWithSider";
import HospitalCreateEdit from "../../app/hospital/HospitalCreateEdit";

export default function HospitalCreateEditPage()
{
    const {t} = useTranslation();
    const {hospitalId} = useParams();


    return (
        <>
            <LayoutWithSider>
                <Card title={hospitalId ? t('Edit Hospital') : t('Create Hospital')}>
                    <HospitalCreateEdit hospitalId={hospitalId} />
                </Card>
            </LayoutWithSider>
        </>
    )
}
