import React, {useEffect, useState} from "react";
import {LocalStorageService} from "../service/local-storage-service";
import ApiService from "../service/api-service";

export const PERMISSIONS = {
    STUDY_LIST: 1,
    STUDY_LIST_DELETED: 2,
    STUDY_DELETE_OR_RESTORE: 3,
    STUDY_SET_READ: 8,
    STUDY_SET_EMERGENCY: 6,
    STUDY_OPEN_VIEWER: 14,
    STUDY_REQUEST_LIST: 4,
    STUDY_REMOVE_READ: 9,
    STUDY_REMOVE_EMERGENCY: 7,
    STUDY_REQUEST_SET: 5,
    STUDY_ANAMNEZ: 26,

    STUDY_FILE_LIST: 28,
    STUDY_FILE_UPLOAD: 29,
    STUDY_FILE_DELETE: 30,
    STUDY_FILE_DOWNLOAD: 31,
    STUDY_FILE_RESTORE_DELETED: 32,
    STUDY_FILE_LIST_DELETED: 33,
    STUDY_CREATE_EMPTY: 42,
    STUDY_CHANGE_INSTITUTION: 44,

    USER_MANAGER: 24,
    REQUEST_TYPE_MANAGER: 23,
    HOSPITAL_MANAGER: 21,
    REPORT_TEMPLATE_MANAGER: 22,

    REPORT_DOWNLOAD_WORD: 18,
    REPORT_REMOVE_CONFIRM: 20,
    REPORT_CONFIRM: 19,
    REPORT_DOWNLOAD_PDF: 17,
    REPORT_LIST: 16,
    REPORT_ADD_OR_EDIT: 25,
    REPORT_HBYS_TRANSFER: 27,
    REPORT_DOWNLOAD_SIGN: 43,

    VOICE_RECORD_LIST: 10,
    VOICE_RECORD_ADD: 11,
    VOICE_RECORD_OTHER_LIST: 13,
    VOICE_RECORD_DOWNLOAD: 15,
    VOICE_RECORD_DELETE: 12,
    VOICE_RECORD_LIST_DELETED: 37,
    VOICE_RECORD_RESTORE_DELETED: 38,

    STUDY_REQUEST_DELETE: 34,
    STUDY_REQUEST_LIST_DELETED: 35,
    STUDY_REQUEST_RESTORE_DELETED: 36,

    DASHBOARD: 39,

    INSTITUTION_MANAGER: 45,
    STUDY_SET_READ_USER: 47,
    STUDY_GRID_USER_INFO: 48,
    STUDY_ASSIGN_DOCTOR_USER: 49,
    HOSPITAL_GROUP_MANAGER: 50,
    HOSPITAL_GROUP_FILTER: 51,
    HL7_MANAGER: 52,
    STUDY_LOG: 53,
    REPORT_VERSIONS: 54,
    VIEW_STUDY_INSTITUTION: 55,
    EDIT_STUDY_INSTITUTION: 56,
    SHARE_STUDY_BY_EMAIL: 57,
    TELETIP_MANAGER: 58,
    STUDY_ASSIGN_REPORTER_USER: 59,
    REPORT_PREVIEW: 60,
    EXPORT_STUDY: 61,
};

const UserActions = {
    SET_USER: 'SET_USER',
    SET_TOKEN: 'SET_TOKEN',
    SIGN_OUT: 'SIGN_OUT',
    UPDATE_DICTIONARY: 'UPDATE_DICTIONARY'
}

const defaultValue = {
    token: LocalStorageService.getToken(),
    data: LocalStorageService.getUser({
        name: 'test',
        email: 'test',
    }),
    dictionary: []
}

const UserContext = React.createContext(defaultValue);


function userReducer(state, action)
{
    switch (action.type)
    {
        case UserActions.SET_USER: {
            LocalStorageService.setUser(action.data.user)

            return {
                ...state,
                data: action.data?.user,
                dictionary: action.data?.user?.userInformation?.dictionary,
                config: action.data?.config
            }
        }
        case UserActions.SIGN_OUT: {
            return {
                ...state,
                token: '',
                data: {}
            }
        }
        case UserActions.UPDATE_DICTIONARY: {
            return {
                ...state,
                data: {
                    ...state.data,
                    dictionary: action.data || []
                }
            }
        }
        case UserActions.SET_TOKEN: {
            LocalStorageService.setToken(action.data)

            return {
                ...state,
                token: action.data
            }
        }
        default: {
            return state
        }
    }
}

function UserProvider({children})
{
    const [user, dispatch] = React.useReducer(userReducer, defaultValue)
    const [fetched, setFetched] = useState(false)
    const value = {user, dispatch}

    const getUser = async () => {
        const response = await ApiService.User.Information();
        const resConfig = await ApiService.User.GetConfig();

        if(response.success)
        {
            dispatch({
                type: UserActions.SET_USER,
                data: {
                    user: response.data,
                    config: resConfig.data
                }
            })
        }
    }

    useEffect(() => {
        ApiService.setLogout(() => {
            dispatch({
                type: UserActions.SIGN_OUT,
                data: ''
            })
        })
    }, [])

    useEffect(() => {
        (async () => {
            if(user.token && !fetched) {
                await getUser()
                setFetched(true)
            }
            if(!user.token) {
                setFetched(false)
            }
        })()
    }, [user])

    return (
        <UserContext.Provider value={value}>
            {(fetched || !user.token) && children}
        </UserContext.Provider>
    )
}

function useUser()
{
    const context = React.useContext(UserContext);

    if(!context)
        throw new Error('User Provider Error')

    const {user, dispatch} = context;

    const setToken = (token) => {
        dispatch({
            type: UserActions.SET_TOKEN,
            data: token
        })
    }

    const updateDictionary = (dictionary) => {
        dispatch({
            type: UserActions.UPDATE_DICTIONARY,
            data: dictionary || []
        })
    }

    const isAuth = () => {
        return !!user.token;
    }

    const signOut = () => {
        dispatch({
            type: UserActions.SIGN_OUT,
            data: ''
        })
    }

    const hasPermission = (permission) => {
        const config = user?.config

        if([PERMISSIONS.INSTITUTION_MANAGER, PERMISSIONS.VIEW_STUDY_INSTITUTION, PERMISSIONS.EDIT_STUDY_INSTITUTION].includes(permission) && !config?.institutionEnable) {
            return false
        }

        if([PERMISSIONS.SHARE_STUDY_BY_EMAIL].includes(permission) && !config?.emailShareEnable) {
            return false
        }

        if([PERMISSIONS.HL7_MANAGER].includes(permission) && !config?.hl7Enable) {
            return false
        }

        if([PERMISSIONS.TELETIP_MANAGER].includes(permission) && !config?.teletipEnable) {
            return false
        }

        if(!user.data || !user.data.permissionIdList || !user.data.role || !user.data.role.permissionIdList)
        {
            return false;
        }


        return (user.data.permissionIdList.includes(permission) || user.data.role.permissionIdList.includes(permission))
    }

    const showDashboard = (type) => {
        switch (type) {
            case 'admin': {
                return (hasPermission(PERMISSIONS.DASHBOARD))
            }
            case 'doctor': {
                return (hasPermission(PERMISSIONS.DASHBOARD) || hasPermission(PERMISSIONS.VOICE_RECORD_ADD))
            }
            case 'reporter': {
                return (hasPermission(PERMISSIONS.DASHBOARD) || hasPermission(PERMISSIONS.REPORT_ADD_OR_EDIT))
            }
            default: {
                return (hasPermission(PERMISSIONS.DASHBOARD) || hasPermission(PERMISSIONS.VOICE_RECORD_ADD) || hasPermission(PERMISSIONS.REPORT_ADD_OR_EDIT))
            }
        }
    }

    return {
        user,
        dictionary: user?.dictionary,
        modalities: user?.modalities || [],
        config: user?.config,
        setToken,
        isAuth,
        signOut,
        hasPermission: (permission) => {
            return hasPermission(permission)
        },
        showDashboard,
        updateDictionary,
    }
}

export {UserProvider, useUser}

