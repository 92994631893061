import LayoutWithSider from "../../core/components/layout/layout-with-sider/LayoutWithSider";
import {Card} from "antd";
import RequestTypeManage from "../../app/request-type/RequestTypeManage";
import {useTranslation} from "react-i18next";

export default function SystemRequestTypePage()
{
    const {t} = useTranslation();

    return (
        <LayoutWithSider>
            <Card title={t('Request Types')}>
                <RequestTypeManage />
            </Card>
        </LayoutWithSider>
    )
}
