import {Button, Dropdown, Menu, Tooltip} from "antd";
import {DownOutlined} from "@ant-design/icons";
import Flex from "../../../core/components/Flex";
import {useDataGrid} from "../../../core/components/data-grid/DataGridContext";
import {useTranslation} from "react-i18next";
import ApiService from "../../../core/service/api-service";
import {PERMISSIONS, useUser} from "../../../core/context/UserContext";
import FileHelper from "../../../core/utils/file-helper";
import Colors from "../../../core/style/Colors";

const colors = [
    {color: '#ced6e0', text: 'DELETED-ROW'},
    {color: '#55efc4', text: 'REPORT-CONFIRMED'},
    {color: '#dff9fb', text: 'REPORT-NOT-CONFIRMED-ROW'},
    {color: Colors.selectedRow, text: 'SELECTED-ROW'},
]

export default function ReportGridTools()
{
    const {t} = useTranslation();
    const {selectedRows, queryModel} = useDataGrid();
    const {hasPermission} = useUser();

    const downloadExcel = async () => {
        const res = await ApiService.Report.GetReportsExcel(queryModel)
        if(res.success)
        {

        }
    }

    return (
        <>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                <div>
                    <Flex flexDirection={'row'} alignItems={'center'}>
                        <div>
                            <Flex flexDirection={'row'}>
                                {
                                    colors.map(color => {
                                        return (
                                            <Tooltip title={t(color.text)}>
                                                <div style={{width: 16, height: 16, backgroundColor: color.color, marginRight: 4}} />
                                            </Tooltip>
                                        )
                                    })
                                }
                            </Flex>
                        </div>
                        <Dropdown
                            disabled={selectedRows.length === 0}
                            trigger={'click'}
                            overlay={(
                                <Menu>
                                    {
                                        hasPermission(PERMISSIONS.REPORT_DOWNLOAD_PDF) && (
                                            <Menu.Item
                                                key={'6'}
                                                onClick={async () => {
                                                    const response = await ApiService.ReportGenerator.CreatePdfZipByReportIdList(selectedRows.map(r => r.id));
                                                    if(response.success)
                                                    {
                                                        ApiService.downloadFile(response.data, response.fileName);
                                                    }
                                                }}
                                            >
                                                {t('Download PDF')}
                                            </Menu.Item>
                                        )
                                    }
                                    {
                                        hasPermission(PERMISSIONS.REPORT_DOWNLOAD_WORD) && (
                                            <Menu.Item
                                                key={'Download Word'}
                                                onClick={async () => {
                                                    const response = await ApiService.ReportGenerator.CreateWordZipByReportIdList(selectedRows.map(r => r.id));
                                                    if(response.success)
                                                    {
                                                        ApiService.downloadFile(response.data, response.fileName);
                                                    }
                                                }}
                                            >
                                                {t('Download Word')}
                                            </Menu.Item>
                                        )
                                    }
                                    {
                                        hasPermission(PERMISSIONS.REPORT_DOWNLOAD_SIGN) && (
                                            <Menu.Item
                                                key={'report-sign'}
                                                onClick={async () => {
                                                    const response = await ApiService.Report.CreateSignZip(selectedRows.map(r => r.id));
                                                    if(response.success)
                                                    {
                                                        ApiService.downloadFile(response.data, response.fileName);
                                                    }
                                                }}
                                            >
                                                {t('Download Sign')}
                                            </Menu.Item>
                                        )
                                    }
                                </Menu>
                            )}
                        >
                            <Button
                                size={'small'}
                                type={'primary'}
                            >
                                {t('Operations')}<DownOutlined />
                            </Button>
                        </Dropdown>
                    </Flex>
                </div>
                <Button size={'small'} onClick={downloadExcel} type={'success'}>
                    Excel
                </Button>
            </div>
        </>
    )
}
