import locale_us from 'antd/es/date-picker/locale/en_US';
import locale_tr from 'antd/es/date-picker/locale/tr_TR';

function setToken(token)
{
    localStorage.setItem('token', token)
}

function getToken()
{
    return localStorage.getItem('token') || ''
}

function setUser(user)
{
    localStorage.setItem('user', JSON.stringify(user))
}

function getUser(defaultValue)
{
    return JSON.parse(localStorage.getItem('user')) || defaultValue || {}
}

function setLanguage(lang)
{
    localStorage.setItem('language', lang)
}

function getLanguage(defaultValue)
{
    return localStorage.getItem('language') || defaultValue || 'en'
}

function getAntLocale() {
    const lang = getLanguage('en');
    let langAnt = locale_us;
    if(lang === 'en') {
        langAnt = locale_us
    }
    else if(lang === 'tr') {
        langAnt = locale_tr
    }

    return langAnt
}

export const LocalStorageService = {
    setUser,
    getUser,
    setToken,
    getToken,
    setLanguage,
    getLanguage,
    getAntLocale
}
