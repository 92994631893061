import {PERMISSIONS, useUser} from "../../core/context/UserContext";
import {useEffect, useState} from "react";
import ApiService from "../../core/service/api-service";
import _ from "lodash";
import {v4} from "uuid";
import SearchableTreeView from "../../core/components/SearchableTreeView";
import {Select} from "antd";

export default function HospitalSelectBox({onData, ...props}) {
    const {hasPermission} = useUser()
    const [hospitals, setHospitals] = useState([])

    const getHospitals = async () =>
    {
        let response = await ApiService.UserManager.GetHospitalsOfUser();
        if(response.success)
        {
            setHospitals(response.data.map(x => {
                if(x.enableHL7)
                    x.name += ' - HL7'

                return x;
            }))
            onData && onData(response.data)
        }

    }

    useEffect(() =>
    {
        getHospitals()
    }, [])

    return (
        <>
            <Select
                mode={'multiple'}
                allowClear
                options={hospitals}
                filterOption={(input, option) => {
                    return (option?.name ?? '').toUpperCase().includes(input.toUpperCase())
                }}
                fieldNames={{
                    label: 'name',
                    value: 'id'
                }}
                {...props}
            />
        </>
    )
}