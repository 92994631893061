import {Col, Row, Typography, Input} from "antd";
import Flex from "../../core/components/Flex";
import {dateFormat, getAge} from "../../core/utils/date-utils";
import {useEffect, useState} from "react";
import ApiService from "../../core/service/api-service";
import {t} from 'i18next'

export default function StudyInfo({studyId, onChangeAnamnez}) {
    const [study, setStudy] = useState({
        patient: {},
        modality: {}
    })
    const [anamnez, setAnamnez] = useState('')


    const getStudy = async () => {
        const response = await ApiService.Study.Get(studyId);
        if (response?.success) {
            setStudy(response.data)
        }
    }

    const getAnamnez = async () => {
        const response = await ApiService.StudyAnamnez.GetStudyAnamnez(studyId);
        if (response.success) {
            setAnamnez(response.data.clinicalInformation)
        }
    }

    useEffect(() => {
        getStudy()
        getAnamnez();
    }, [])

    return (
        <div>
            <div
                style={{display: 'flex', alignItems: 'start', flexWrap: 'wrap', gap: 24}}
            >
                <Flex
                    flexDirection={'column'}
                >
                    <Typography.Text style={{fontWeight: 'bold'}}>{t('Hospital')}</Typography.Text>
                    <Typography.Text>{study?.hospital?.name}</Typography.Text>
                </Flex>
                <Flex
                    flexDirection={'column'}
                >
                    <Typography.Text style={{fontWeight: 'bold'}}>{t('Study Date')}</Typography.Text>
                    <Typography.Text>{dateFormat(study.studyDateTime)}</Typography.Text>
                </Flex>
                <Flex
                    flexDirection={'column'}
                >
                    <Typography.Text style={{fontWeight: 'bold'}}>{t('Patient Id')}</Typography.Text>
                    <Typography.Text>{study.patient.dicomPatientId}</Typography.Text>
                </Flex>
                <Flex
                    flexDirection={'column'}
                >
                    <Typography.Text style={{fontWeight: 'bold'}}>{t('Patient Name')}</Typography.Text>
                    <Typography.Text>{study.patient.name}</Typography.Text>
                </Flex>
                <Flex
                    flexDirection={'column'}
                >
                    <Typography.Text style={{fontWeight: 'bold'}}>{t('Patient Age')}</Typography.Text>
                    <Typography.Text>{getAge(study.patient.birthdate)}</Typography.Text>
                </Flex>
                <Flex
                    flexDirection={'column'}
                >
                    <Typography.Text style={{fontWeight: 'bold'}}>{t('Patient Gender')}</Typography.Text>
                    <Typography.Text>{t(study.patient.gender)}</Typography.Text>
                </Flex>
            </div>
            <br/>

            <Typography.Text style={{fontWeight: 'bold'}}>{t('Clinical Information')}</Typography.Text>
            <div>
                <Input.TextArea
                    rows={2}
                    value={anamnez}
                    onChange={(e) => {
                        setAnamnez(e.target.value)
                        onChangeAnamnez && onChangeAnamnez(e.target.value)
                    }}
                />
            </div>
        </div>
    )
}