import {PERMISSIONS, useUser} from "../../../core/context/UserContext";
import {useDataGrid} from "../../../core/components/data-grid/DataGridContext";
import {t} from "i18next";
import {dateFormat} from "../../../core/utils/date-utils";
import VoiceRecordRowMenu from "./VoiceRecordRowMenu";
import {Button, Radio, Select, Space, Typography} from "antd";
import ApiService from "../../../core/service/api-service";
import {PlayCircleOutlined, UploadOutlined} from "@ant-design/icons";
import {useEffect, useRef, useState} from "react";
import DataGrid from "../../../core/components/data-grid/DataGrid";
import Colors from "../../../core/style/Colors";
import FileUtils from "../../../core/utils/file-utils";
import {useApp} from "../../../core/context/AppContext";
import AudioPlayerNavigator from "../AudioPlayer";

export default function VoiceRecordGrid({studyId, listOther})
{
    const playerId = 'voicer-record-player'
    const {hasPermission} = useUser();
    const {addKeyEvent, removeKeyEvent, pressedKey} = useApp()
    const {setData, setPaginationResult, queryModel, setDataGridProps, setRowColor, refreshDataGrid, resultModel} = useDataGrid();
    const [pbRate, setPbRate] = useState(1)
    const [seek, setSeek] = useState(1)
    const [action, setAction] = useState(null)
    const url = useRef(null)

    const getVoiceRecords = async () => {
        let response = {}
        if(!listOther) {
            response = await ApiService.VoiceRecord.GetList(studyId);
        }
        else {
            response = await ApiService.VoiceRecord.GetOtherVoiceRecords(studyId);
        }
        if(response.success)
        {
            setData(response.data)
            const recordId = response.data[0]?.id
            const studyId = response.data[0]?.studyId
            if(recordId && studyId) {
                url.current = await getUrl(studyId, recordId);
                setRowColor(recordId, Colors.selectedRow)
            }
        }
    }

    const uploadVoiceRec = async () => {
        const file = await FileUtils.getFileAsBase64({accept: 'mp3'});
        if(file)
        {
            const response = await ApiService.VoiceRecord.UploadBase64(studyId, {
                voiceRecordBase64: file.data
            })

            if(response.success) {
                refreshDataGrid()
            }
        }
    }

    const playF8 = async () => {
        if(url.current) {
            const player = document.getElementById(playerId)
            if(!player)
                return

            if(!player.paused)
            {
                player.pause()
                return
            }

            if(url.current && player.src !== url.current) {
                player.src = url.current
            }

            if (player.currentTime >= 2) {
                player.currentTime -= 2;
            }

            player.play()
        }
    }

    const play = async () => {
        const player = document.getElementById(playerId)
        if(!player)
            return

        if(url.current && player.src !== url.current) {
            player.src = url.current
        }
        else if (player.currentTime >= 2 && player.paused) {
            player.currentTime -= 2;
        }


        if(player.paused) {
            player.play()
            player.playbackRate = pbRate
        }
    }

    const stop = () => {
        const player = document.getElementById(playerId)
        if(player && !player.paused)
        {
            player.pause()
        }
    }

    const getUrl = async (studyId, recordId) => {
        const response = await ApiService.VoiceRecord.GetFile(studyId, recordId);
        if(response.success)
        {
            return response.data
        }
    }

    useEffect(() => {
        if(action) {
            if(action.includes('PLAY')) {
                playEvent()
            }
            else if(action.includes('PAUSE')) {
                stop()
            }
            else if(action.includes('FORWARD')) {
                forwardSeek()
            }
            else if(action.includes('BACK')) {
                backwardSeek()
            }
        }
    }, [action]);

    useEffect(() => {
        let columns = [
            {
                title: t('Play'),
                dataIndex: '#',
                key: '#',
                render: (text, record) => {
                    const {id, studyId} = record;

                    return (
                        <div>
                            <Button
                                size={'small'}
                                type={'text'}
                                onClick={async () => {
                                    url.current = await getUrl(studyId, id)
                                    await play()
                                }}
                            >
                                <PlayCircleOutlined />
                            </Button>
                        </div>
                    )
                }
            },
            {
                title: t('Id'),
                dataIndex: 'id',
                key: 'id',
                columnName: 'id',
                render: (text, record) => (
                    <VoiceRecordRowMenu row={record} studyId={studyId} />
                )
            },
            {
                title: t('User'),
                dataIndex: 'user',
                key: 'user',
                render: (text, record) => record.user?.visibleName
            },
            {
                title: t('Record Date'),
                dataIndex: 'createdDate',
                key: 'createdDate',
                render: (text, record) => {
                    return dateFormat(text)
                }
            },
        ]

        if(listOther) {
            columns = [
                ...columns,
                {
                    title: t('Id'),
                    dataIndex: 'studyId',
                    key: 'studyId',
                    render: (text, record) => record?.study?.id
                },
                {
                    title: t('Modality'),
                    dataIndex: 'modality',
                    key: 'modality',
                    render: (text, record) => record?.study?.modality?.name
                },
                {
                    title: t('Accession Number'),
                    dataIndex: 'accessionNumber',
                    key: 'accessionNumber',
                    render: (text, record) => record?.study?.accessionNumber
                },
                {
                    title: t('Study Date'),
                    dataIndex: 'studyDate',
                    key: 'studyDate',
                    render: (text, record) => dateFormat(record?.study?.studyDateTime)
                },
                {
                    title: t('Received Date'),
                    dataIndex: 'receivedDate',
                    key: 'receivedDate',
                    render: (text, record) => dateFormat(record?.study?.createdAt)
                },
            ]
        }

        setDataGridProps({
            rowKey: 'id',
            antdTableProps: {
                scroll: {x: !listOther ? 500 : 800},
                pagination: false
            },
            columns,
            rowClassName: (record, index) => {
                let className = 'normal-row';

                if (record.isDeleted)
                    className = 'deleted-row';
                else
                    className = 'normal-row'

                return className;
            },
            onRow: (record) => {
                return {
                    onClick: () => {
                        setRowColor(record.id, Colors.selectedRow);
                    }
                }
            }
        })
    }, [])

    useEffect(() => {
        getVoiceRecords()
    }, [queryModel.updateKey])

    useEffect(() => {
        getVoiceRecords()
    }, [listOther]);

    useEffect(() => {
        const player = document.getElementById(playerId);
        if(player) {
            player.playbackRate = pbRate
        }
    }, [pbRate]);

    const backwardSeek = () => {

        const player = document.getElementById(playerId);
        if(player) {
            player.currentTime -= seek
        }
    }

    const playEvent = async () => {

        if(!url.current) {
            const recordId = resultModel?.data[0]?.id
            const studyId = resultModel?.data[0]?.studyId
            if(recordId) {
                url.current = await getUrl(studyId, recordId);
                setRowColor(recordId, Colors.selectedRow)
            }
        }

        if(url.current)
            await play()
    }

    const forwardSeek = () => {
        const player = document.getElementById(playerId);
        if(player) {
            player.currentTime += seek
        }
    }

    useEffect(() => {
        if(!pressedKey.key)
            return

        if(pressedKey.key.code === 'F8' && !pressedKey?.modifier?.code) {
            playF8()
        }
        else if(pressedKey.key.code === 'F7' && !pressedKey?.modifier?.code) {
            backwardSeek()
        }
        else if(pressedKey.key.code === 'F9' && !pressedKey?.modifier?.code) {
            forwardSeek()
        }
    }, [pressedKey]);

    useEffect(() => {
        // eslint-disable-next-line no-undef
        navigator.mediaSession.metadata = new MediaMetadata({
            title: 'ECloudPacs',
            artist: '',
            album: 'Voice Records'
        })

        navigator.mediaSession.setActionHandler('previoustrack', (e) => {
            backwardSeek()
        })
        navigator.mediaSession.setActionHandler('seekbackward', (e) => {
            backwardSeek()
        })

        navigator.mediaSession.setActionHandler('nexttrack', (e) => {
            forwardSeek()
        })
        navigator.mediaSession.setActionHandler('seekforward', (e) => {
            forwardSeek()
        })

        navigator.mediaSession.setActionHandler('play', async (e) => {
            await playF8()
        })
        navigator.mediaSession.setActionHandler('pause', async (e) => {
            await playF8()
        })

        addKeyEvent([
            {keyCode: 'F7', modifier: null, prevent: true},
            {keyCode: 'F8', modifier: null, prevent: true},
            {keyCode: 'F9', modifier: null, prevent: true},
        ])

        const _seek = localStorage.getItem('seek')
        const _pbRate = localStorage.getItem('pbRate')

        if(_seek)
            setSeek(parseFloat(_seek))

        if(_pbRate)
            setPbRate(parseFloat(_pbRate))

        // return () => {
        //     removeKeyEvent('F7', null)
        //     removeKeyEvent('F8', null)
        //     removeKeyEvent('F9', null)
        // }
    }, []);

    useEffect(() => {
        return () => {
            // debugger
            // url.current = null
            // const parent = document.getElementById('audio-player-parent')
            // const player = document.getElementById(playerId)
            // if(parent && player) {
            //     parent.removeChild(player)
            // }
        }
    }, []);

    return (
        <>
            <>
                {
                    listOther && (
                        <Typography.Text>
                            {t('OTHER_VOICE_RECORD_TEXT')}
                        </Typography.Text>
                    )
                }
                <DataGrid
                    title={() => (
                        <Space>
                            {
                                hasPermission(PERMISSIONS.VOICE_RECORD_ADD) && !listOther && (
                                    <Button
                                        onClick={uploadVoiceRec}
                                        style={{width: 75}}
                                        size={'small'}
                                    >
                                        <UploadOutlined />
                                    </Button>
                                )
                            }
                            <div
                                style={{display: 'flex', alignItems: 'center', gap: 8}}
                            >
                                <Select
                                    size={'small'}
                                    style={{width: 100}}
                                    options={[
                                        {label: '0.5x', value: 0.5},
                                        {label: '1x', value: 1},
                                        {label: '1.25x', value: 1.25},
                                        {label: '1.5x', value: 1.5},
                                        {label: '1.75x', value: 1.75},
                                        {label: '2x', value: 2},
                                    ]}
                                    onChange={(val) => {
                                        setPbRate(val)
                                        localStorage.setItem('pbRate', val.toString())
                                    }}
                                    value={pbRate}
                                />

                                <Select
                                    size={'small'}
                                    style={{width: 100}}
                                    options={[
                                        {label: '100ms', value: 0.1},
                                        {label: '250ms', value: 0.25},
                                        {label: '500ms', value: 0.5},
                                        {label: '1s', value: 1},
                                        {label: '2s', value: 2},
                                        {label: '5s', value: 5},
                                    ]}
                                    onChange={(val) => {
                                        setSeek(val)
                                        localStorage.setItem('seek', val.toString())
                                    }}
                                    value={seek}
                                />
                            </div>
                        </Space>
                    )}
                    name={'voice-record'}
                />
            </>
            {
                resultModel?.data?.length > 0 && (
                    <div
                        id={'audio-player-parent'}
                    >
                        <audio
                            onLoadedMetadata={() => {
                                const player = document.getElementById(playerId);
                                if(player) {
                                    player.playbackRate = pbRate
                                }
                            }}
                            id={playerId}
                            controls={true}
                            autoPlay={false}
                            style={{marginTop: 4, width:' 100%'}}
                        />
                    </div>
                )
            }
            <AudioPlayerNavigator
                onBack={() => {setAction('BACK'+new Date().getTime())}}
                onContinue={async () => {setAction('PLAY'+new Date().getTime())}}
                onSeek={() => {setAction('FORWARD'+new Date().getTime())}}
                onStop={async () => {setAction('PAUSE'+new Date().getTime())}}
            />
        </>
    )
}