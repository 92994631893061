import {Button, Card, Space, Menu, Dropdown, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import StudyFilter from "../../app/study/study-grid/StudyFilter";
import StudyGrid from "../../app/study/study-grid/StudyGrid";
import {DataGridProvider} from "../../core/components/data-grid/DataGridContext";
import LayoutWithSider from "../../core/components/layout/layout-with-sider/LayoutWithSider";
import {DATE_OPTIONS, getDateFromNow} from "../../core/utils/date-utils";
import {useEffect} from "react";

export default function StudyGridPage()
{
    const {t} = useTranslation();

    useEffect(() => {
        document.title = `ECloud Pacs - ${t('Studies')}`
    }, []);

    return (
        <>
            <DataGridProvider>
                <LayoutWithSider
                    fullWidth={true}
                    sidebarTitle={t('Study Filter')}
                    sidebarComponent={(
                        <StudyFilter/>
                    )}
                >
                    <Card title={t('Studies')}>
                        <StudyGrid />
                    </Card>
                </LayoutWithSider>
            </DataGridProvider>
        </>
    )
}
