import {Form, Input, Button, Checkbox, Typography} from 'antd';
import {useTranslation} from "react-i18next";
import Keycloak from "../../core/service/keycloak";
import {useUser} from "../../core/context/UserContext";
import {useEffect, useState} from "react";
import {use} from "i18next";
import {useHistory} from "react-router-dom";
import {Pages} from "../../AppRouter";
import ApiService from "../../core/service/api-service";

export default function LoginForm()
{
    const {t} = useTranslation();
    const {user, setToken, isAuth} = useUser();
    const history = useHistory();
    const [error, setError] = useState('')

    const signIn = async (form) => {
        const response = await ApiService.Auth.Login(form)
        if(!response.success)
        {
            setError('Username or password is incorrect!')
            return
        }
        setToken(response.data?.access_token)
    }

    const redirectToHome = () => {
        if(isAuth())
        {
            history.push(Pages.Study.Study)
        }
        else {
            localStorage.removeItem('token')
        }
    }

    useEffect(() => {
        redirectToHome()
    }, [])

    useEffect(() => {
        redirectToHome()
    }, [user])

    return (
        <>
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                onFinish={signIn}
            >
                <Form.Item
                    label={t('Username')}
                    name="username"
                    rules={[{ required: true, message: t('Please input your username!') }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t('Password')}
                    name="password"
                    rules={[{ required: true, message: t('Please input your password!') }]}
                >
                    <Input.Password />
                </Form.Item>
                <Typography.Text style={{color: '#ff0000', fontWeight: 'bold'}}>
                    {t(error)}
                </Typography.Text>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        {t('Login')}
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
