import {useTranslation} from "react-i18next";
import ApiService from "../../../core/service/api-service";
import {
    DATE_OPTIONS,
    getBeginLocalStr,
    getBeginStr,
    getDateFromNow,
    getEndLocalStr,
    getEndStr
} from "../../../core/utils/date-utils";
import {Button, Collapse, DatePicker, Divider, Form, Input, Radio, Select, Space, Switch, Typography} from "antd";
import {DatePickerFooter} from "../../../core/components/DatePickerFooter";
import SearchableTreeView from "../../../core/components/SearchableTreeView";
import {useEffect, useRef, useState} from "react";
import ModalitySelectBox from "../../modality/ModalitySelectBox";
import HospitalTreeView from "../../hospital/HospitalTreeView";
import {useDataGrid} from "../../../core/components/data-grid/DataGridContext";
import QuickDatePicker from "../../../core/components/data-grid/QuickDatePicker";
import {PERMISSIONS, useUser} from "../../../core/context/UserContext";
import HospitalGroupSelectBox from "../../hospital-group/HospitalGroupSelectBox";
import {t} from "i18next";
import UserSelectBox from "../../user/UserSelectBox";
import StudyTypeEnum from "../../../enums/StudyTypeEnum";
import HospitalSelectBox from "../../hospital/HospitalSelectBox";
import {LocalStorageService} from "../../../core/service/local-storage-service";
import InstitutionSelect from "../../user/InstitutionSelect";
const {RangePicker} = DatePicker;

const quickFilters = (userId) => [
    {
        label: 'Emergency, Readings',
        filters: {
            studyTypeList: [StudyTypeEnum.EMERGENCY],
            isRead: false
        }
    },
    {
        label: 'Emergency, to Write',
        filters: {
            studyTypeList: [StudyTypeEnum.EMERGENCY],
            isRead: true,
            studyStatus: [0, 3]
        }
    },
    {
        label: 'What I will read',
        filters: {
            assignDoctor: userId,
            isRead: false,
            studyTypeList: [],
        }
    },
    {
        label: 'What I will write',
        filters: {
            assignReporter: userId,
            isRead: true,
            studyTypeList: [],
            studyStatus: [0, 3]
        }
    },
    {
        label: 'Routine readings',
        filters: {
            studyTypeList: [StudyTypeEnum.INPATIENT, StudyTypeEnum.ROUTINE],
            isRead: false
        }
    },
    {
        label: 'Routine writings',
        filters: {
            studyTypeList: [StudyTypeEnum.INPATIENT, StudyTypeEnum.ROUTINE],
            isRead: true,
            studyStatus: [0, 3]
        }
    },
    {
        label: 'To be read',
        filters: {
            isRead: false,
            studyTypeList: [],
        }
    },
    {
        label: 'To be write',
        filters: {
            isRead: true,
            studyTypeList: [],
            studyStatus: [0, 3]
        }
    },
]

const getDefaultReceivedDate = () => {
    const date = getDateFromNow(DATE_OPTIONS.LAST_5_DAYS);
    return [date.start, date.end]
}

const getDefaultFilter = () => {
    const date = getDefaultReceivedDate();

    return window.studyFilter || {
        studyReceivedDateTimeBegin: date[0],
        studyReceivedDateTimeEnd: date[1],
        isDeleted: false
    }
}

export default function StudyFilter()
{
    const [dateType, setDateType] = useState('received')
    const defaultFilter = useRef(getDefaultFilter());
    const [writableUsers, setWritableUsers] = useState([])
    const [readableUsers, setReadableUsers] = useState([])

    const [showHospitalFilter, setShowHospitalFilter] = useState(true)
    const {hasPermission, user} = useUser();
    const [showDetail, setShowDetail] = useState(false)

    const {onFilterPageSort, queryModel} = useDataGrid();
    const [dates, setDates] = useState({
        dateTimeBegin: defaultFilter.current.studyReceivedDateTimeBegin,
        dateTimeEnd: defaultFilter.current.studyReceivedDateTimeEnd,
    })

    const {t} = useTranslation();
    const formRef = useRef();

    const filter = async (form, dates) =>
    {
        let filter = {
            ...form,
            idList: form.idList?.length > 0 ? form?.idList?.split(',').map(x => x.trim()) : [],
            patientIdList: (form.patientId && form.patientId.split(',')) || [],
        };

        if(dateType === 'received') {
            filter = {
                ...filter,
                studyReceivedDateTimeBegin: dates.dateTimeBegin,
                studyReceivedDateTimeEnd: dates.dateTimeEnd,
            }
        }
        else if(dateType === 'study') {
            filter = {
                ...filter,
                studyDateTimeBegin: dates.dateTimeBegin,
                studyDateTimeEnd: dates.dateTimeEnd,
            }
        }

        window.studyFilter = filter;

        onFilterPageSort({
            filter
        })

    }

    const reset = async () => {
        window.location.reload()
    }

    const getUsers = async () => {
        if (hasPermission(PERMISSIONS.STUDY_ASSIGN_DOCTOR_USER)) {
            const readable = await ApiService.UserManager.GetReadableUsers();
            if (readable.success) {
                setReadableUsers(readable.data);
            }
        }

        if (hasPermission(PERMISSIONS.STUDY_ASSIGN_REPORTER_USER)) {
            const writable = await ApiService.UserManager.GetWritableUsers();
            if (writable.success) {
                setWritableUsers(writable.data);
            }
        }
    }

    useEffect(() => {
        onFilterPageSort({
            filter: {...defaultFilter.current}
        })
    }, [])

    useEffect(() => {
        getUsers()
    }, []);

    return (
        <>
            <Form
                ref={formRef}
                layout={'vertical'}
                onFinish={(form) => filter(form, dates)}
                initialValues={defaultFilter.current}
            >
                <Space style={{ marginBottom: 16 }}>
                    <Button type={'primary'} size={'small'} htmlType='submit'>
                        {t('Search')}
                    </Button>
                    <Button size={'small'} onClick={reset}>
                        {t('Reset')}
                    </Button>
                </Space>

                <Form.Item
                    label={(
                        <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
                            <Radio.Group
                                options={[{label: t('Received Date'), value: 'received'},{label: t('Study Date'), value: 'study'},]}
                                onChange={(e) => setDateType(e.target.value)}
                                value={dateType}
                            />
                        </div>
                    )}
                    style={{ margin: 0, padding: 0 }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <DatePicker
                            locale={LocalStorageService.getAntLocale()}
                            value={dates.dateTimeBegin}
                            showToday={false}
                            onChange={(date) => {
                                setDates({
                                    ...dates,
                                    dateTimeBegin: date,
                                });
                            }}
                        />
                        <DatePicker
                            locale={LocalStorageService.getAntLocale()}
                            value={dates.dateTimeEnd}
                            showToday={false}
                            onChange={(date) => {
                                setDates({
                                    ...dates,
                                    dateTimeEnd: date,
                                });
                            }}
                        />
                    </div>
                </Form.Item>

                <Form.Item>
                    <QuickDatePicker
                        onChange={(start, end) => {
                            setDates({
                                dateTimeBegin: start,
                                dateTimeEnd: end
                            });
                        }}
                    />
                </Form.Item>

                {/*<Form.Item*/}
                {/*    label={t('Received Date')}*/}
                {/*    style={{ margin: 0, padding: 0 }}*/}
                {/*>*/}
                {/*    <div style={{ display: 'flex', alignItems: 'center' }}>*/}
                {/*        <DatePicker*/}
                {/*            locale={LocalStorageService.getAntLocale()}*/}
                {/*            value={dates.studyReceivedDateTimeBegin}*/}
                {/*            showToday={false}*/}
                {/*            onChange={(date) => {*/}
                {/*                setDates({*/}
                {/*                    ...dates,*/}
                {/*                    studyReceivedDateTimeBegin: date,*/}
                {/*                    studyDateTimeBegin: null,*/}
                {/*                    studyDateTimeEnd: null,*/}
                {/*                });*/}
                {/*            }}*/}
                {/*        />*/}
                {/*        <DatePicker*/}
                {/*            locale={LocalStorageService.getAntLocale()}*/}
                {/*            value={dates.studyReceivedDateTimeEnd}*/}
                {/*            showToday={false}*/}
                {/*            onChange={(date) => {*/}
                {/*                setDates({*/}
                {/*                    ...dates,*/}
                {/*                    studyReceivedDateTimeEnd: date,*/}
                {/*                    studyDateTimeBegin: null,*/}
                {/*                    studyDateTimeEnd: null,*/}
                {/*                });*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</Form.Item>*/}

                {/*<Form.Item>*/}
                {/*    <QuickDatePicker*/}
                {/*        onChange={(start, end) => {*/}
                {/*            setDates({*/}
                {/*                studyDateTimeBegin: null,*/}
                {/*                studyDateTimeEnd: null,*/}
                {/*                studyReceivedDateTimeBegin: start,*/}
                {/*                studyReceivedDateTimeEnd: end,*/}
                {/*            });*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</Form.Item>*/}

                {hasPermission(PERMISSIONS.HOSPITAL_GROUP_FILTER) && (
                    <Form.Item
                        label={t('Hospital Groups')}
                        name='hospitalGroupIdList'
                    >
                        <HospitalGroupSelectBox />
                    </Form.Item>
                )}

                <Form.Item label={t('Quick Filters')} name='quickFilters'>
                    <Select
                        placeholder={t('Quick Filters')}
                        onClear={() => {
                            formRef.current.setFieldsValue({
                                isRead: null,
                                studyTypeList: [],
                                studyStatus: [],
                                assignDoctor: null,
                                assignReporter: null,
                            });
                        }}
                        allowClear={true}
                        onChange={(value) => {
                            const quick = quickFilters(user?.data?.id).find(
                                (x) => x.label === value
                            );
                            if (quick) {
                                formRef.current.setFieldsValue({
                                    isRead: null,
                                    studyType: [],
                                    studyStatus: [],
                                    assignDoctor: null,
                                    assignReporter: null,
                                    ...quick.filters,
                                });
                            }
                        }}
                    >
                        {quickFilters(user?.data?.id).map((x) => (
                            <Select.Option key={x.label} value={x.label}>
                                {t(x.label)}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label={t('Patient Id')} name='patientId'>
                    <Input />
                </Form.Item>

                <Form.Item label={t('Patient Name')} name='patientName'>
                    <Input />
                </Form.Item>

                <Form.Item label={t('Accession No')} name='accessionNumber'>
                    <Input />
                </Form.Item>

                {showHospitalFilter && (
                    <Form.Item label={t('Hospitals')} name='hospitalIdList'>
                        <HospitalSelectBox
                            onData={(data) => {
                                if (data?.length > 0)
                                    setShowHospitalFilter(true);
                                else setShowHospitalFilter(false);
                            }}
                        />
                    </Form.Item>
                )}

                <Form.Item label={t('Modality')} name='modalities'>
                    <ModalitySelectBox />
                </Form.Item>

                <Divider>
                    <div>
                        <Typography.Text
                            style={{ fontWeight: 'bold', marginRight: 8 }}
                        >
                            {t('Detail Filters')}{' '}
                        </Typography.Text>
                        <Switch onChange={(e) => setShowDetail(e)} />
                    </div>
                </Divider>

                <div
                    style={{
                        marginTop: 16,
                        display: showDetail ? '' : 'none',
                    }}
                >
                    {hasPermission(PERMISSIONS.VIEW_STUDY_INSTITUTION) && (
                        <InstitutionSelect
                            hide={true}
                            formItem={{
                                label: t('Institution'),
                                name: 'institutions',
                            }}
                        />
                    )}

                    <Form.Item name={'idList'} label={t('Study Id List')}>
                        <Input />
                    </Form.Item>

                    <Form.Item label={t('Study Type')} name={'studyTypeList'}>
                        <Select mode={'multiple'}>
                            <Select.Option value={null}>
                                {t('All')}
                            </Select.Option>
                            <Select.Option value={1}>
                                {t('Routine')}
                            </Select.Option>
                            <Select.Option value={2}>
                                {t('Inpatient')}
                            </Select.Option>
                            <Select.Option value={3}>
                                {t('Emergency')}
                            </Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label={t('Study Status')} name={'studyStatus'}>
                        <Select mode={'multiple'}>
                            <Select.Option value={-1}>
                                {t('All')}
                            </Select.Option>
                            <Select.Option value={0}>
                                {t('Not Completed')}
                            </Select.Option>
                            <Select.Option value={1}>
                                {t('Timeout, Reports not confirmed')}
                            </Select.Option>
                            <Select.Option value={3}>
                                {t('Reports not confirmed')}
                            </Select.Option>
                            <Select.Option value={2}>
                                {t('Completed')}
                            </Select.Option>
                        </Select>
                    </Form.Item>

                    {hasPermission(PERMISSIONS.STUDY_SET_READ) && (
                        <Form.Item
                            label={t('Assigned (Doctor)')}
                            name={'assignDoctor'}
                        >
                            <Select
                                showSearch={true}
                                filterOption={(input, option) => {
                                    return option?.children
                                        ?.toString()
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase());
                                }}
                            >
                                <Select.Option value={null}>
                                    {t('All')}
                                </Select.Option>
                                {!hasPermission(
                                    PERMISSIONS.STUDY_ASSIGN_DOCTOR_USER
                                ) && (
                                    <Select.Option value={user?.data?.id}>
                                        {t('Assigned Me')}
                                    </Select.Option>
                                )}
                                {hasPermission(
                                    PERMISSIONS.STUDY_ASSIGN_DOCTOR_USER
                                ) && (
                                    <>
                                        <Select.Option value={'-1'}>
                                            {t('Not Assigned')}
                                        </Select.Option>
                                        {readableUsers.map((x) => (
                                            <Select.Option
                                                value={x.id}
                                                key={x.id}
                                            >
                                                {x.visibleName}
                                            </Select.Option>
                                        ))}
                                    </>
                                )}
                            </Select>
                        </Form.Item>
                    )}

                    {hasPermission(PERMISSIONS.REPORT_ADD_OR_EDIT) && (
                        <Form.Item
                            label={t('Assigned (Reporter)')}
                            name={'assignReporter'}
                        >
                            <Select
                                showSearch={true}
                                filterOption={(input, option) => {
                                    return option?.children
                                        ?.toString()
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase());
                                }}
                            >
                                <Select.Option value={null}>
                                    {t('All')}
                                </Select.Option>
                                {!hasPermission(
                                    PERMISSIONS.STUDY_ASSIGN_REPORTER_USER
                                ) && (
                                    <Select.Option value={user?.data?.id}>
                                        {t('Assigned Me')}
                                    </Select.Option>
                                )}
                                {hasPermission(
                                    PERMISSIONS.STUDY_ASSIGN_REPORTER_USER
                                ) && (
                                    <>
                                        <Select.Option value={'-1'}>
                                            {t('Not Assigned')}
                                        </Select.Option>
                                        {writableUsers.map((x) => (
                                            <Select.Option
                                                value={x.id}
                                                key={x.id}
                                            >
                                                {x.visibleName}
                                            </Select.Option>
                                        ))}
                                    </>
                                )}
                            </Select>
                        </Form.Item>
                    )}

                    <Form.Item label={t('Read User')} name='readUserId'>
                        <UserSelectBox type={'read'} />
                    </Form.Item>

                    <Form.Item label={t('Wrote User')} name='writeUserId'>
                        <UserSelectBox type={'write'} />
                    </Form.Item>

                    <Form.Item
                        label={t('Report Written')}
                        name={'isReportWritten'}
                    >
                        <Select>
                            <Select.Option value={null}>
                                {t('All')}
                            </Select.Option>
                            <Select.Option value={true}>
                                {t('Written')}
                            </Select.Option>
                            <Select.Option value={false}>
                                {t('Not Written')}
                            </Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={t('Voice Record')}
                        name={'hasVoiceRecord'}
                    >
                        <Select>
                            <Select.Option value={null}>
                                {t('All')}
                            </Select.Option>
                            <Select.Option value={true}>
                                {t('Has Voice Record')}
                            </Select.Option>
                            <Select.Option value={false}>
                                {t('No Voice Record')}
                            </Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label={t('Is Read')} name={'isRead'}>
                        <Select>
                            <Select.Option value={null}>
                                {t('All')}
                            </Select.Option>
                            <Select.Option value={true}>
                                {t('Read')}
                            </Select.Option>
                            <Select.Option value={false}>
                                {t('Not Read')}
                            </Select.Option>
                        </Select>
                    </Form.Item>

                    {hasPermission(PERMISSIONS.STUDY_LIST_DELETED) && (
                        <Form.Item label={t('Is Deleted')} name={'isDeleted'}>
                            <Select>
                                <Select.Option value={null}>
                                    {t('All')}
                                </Select.Option>
                                <Select.Option value={true}>
                                    {t('Deleted')}
                                </Select.Option>
                                <Select.Option value={false}>
                                    {t('Not Deleted')}
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    )}
                </div>
            </Form>
        </>
    );
}
