import {useTranslation} from "react-i18next";
import ApiService from "../../core/service/api-service";
import {Select} from "antd";
import {useEffect, useState} from "react";

export default function RoleSelectBox({onRoleChange, ...props})
{
    const {t} = useTranslation();

    const [roles, setRoles] = useState([])

    const getRoles = async () =>
    {
        const response = await ApiService.Role.GetList();
        if (response.success)
        {
            setRoles(response.data)
        }
    }

    useEffect(() => {
        getRoles()
    }, [])

    return (
        <>
            <Select
                mode="single"
                allowClear
                options={roles}
                fieldNames={{
                    label: 'name',
                    value: 'id'
                }}
                {...props}
                onChange={(val, option) => {
                    props && props.onChange && props.onChange(val, option)
                    onRoleChange && onRoleChange(roles.find(x => x.id === val), roles)
                }}
            />
        </>
    )
}
