import {useParams} from "react-router-dom";
import ApiService from "../../core/service/api-service";
import SearchableTreeView from "../../core/components/SearchableTreeView";
import {Button, Radio, Typography} from "antd";
import {t} from "i18next";
import {useEffect, useRef, useState} from "react";
import _ from 'lodash'
import {SortAscendingOutlined} from "@ant-design/icons";

export default function HospitalUserPermission({hospitalId})
{
    const usersOfHospital = useRef(false)
    const [hospital, setHospital] = useState({})
    const [selectedData, setSelectedData] = useState([]);
    const [treeData, setTreeData] = useState([])
    const copyData = useRef([])
    const [sort, setSort] = useState({
        column: "username",
    })

    const getUsersOfHospital = async () => {
        if(usersOfHospital.current)
            return

        const res = await ApiService.UserManager.GetUsersOfHospital(hospitalId);
        if(res.success)
        {
            const list = [];
            for (const iu of res.data) {
                for (const m of iu.modalityIdList) {
                    list.push(`m.${iu.userId}.${m}`)
                }
            }
            setSelectedData(list)
            usersOfHospital.current = true
        }
    }

    const getHospital = async () => {
        const res = await ApiService.Hospital.Get(hospitalId)
        if(res.success)
        {
            setHospital(res.data)
        }
    }

    const getUsers = async () => {
        const res = await ApiService.UserManager.GetUserList();
        if(res.success)
        {
            return res.data
        }
    }

    const getModalities = async () => {
        const res = await ApiService.Modality.GetList();
        if(res.success)
        {
            return res.data
        }
    }

    const createTreeData = (users, modalities) => {
        let data = users.map(u => {
            return  {
                title: (
                    <table>
                        <tr>
                            <td style={{width: 200}}>{u.username}</td>
                            <td style={{width: 200}}>{u.name}</td>
                            <td style={{width: 200}}>{u.visibleName}</td>
                            <td style={{width: 200}}>{u?.role?.name}</td>
                        </tr>
                    </table>
                ),
                text: `${u.username} ${u.name} ${u?.visibleName} ${u?.role?.name}`,
                key: `u.${u.id}`,
                item: u,
                children: modalities.map(m => {
                    return  {
                        title: m.name,
                        key: `m.${u.id}.${m.id}`
                    }
                })
            }
        });
        setTreeData(data)
        copyData.current = [...data]
    }

    const init = async () => {
        await getHospital()
        const users = await getUsers()
        const modalities = await getModalities()
        createTreeData(users, modalities)
    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        if(treeData && treeData.length > 0)
        {
            getUsersOfHospital()
        }
    }, [treeData])

    const save = async () => {
        const groupedData = _.groupBy(selectedData.filter(x => !['u', 'i'].includes(x[0])), s => {
            const arr = s.split('.');
            return arr.slice(0, arr.length-1).join('.')
        });

        const data = Object.keys(groupedData).map(key => {
            const arr = key.split('.');
            const userId = arr[1];

            return {
                hospitalId,
                userId,
                modalityIdList: groupedData[key].map(m => {
                    const s = m.split('.');
                    return parseInt(s[s.length-1])
                })
            }
        })

        const res = await ApiService.UserManager.SetUsersToHospital({
            hospitalId,
            hospitalUsers: data
        })

        if(res.success)
        {
            await getUsersOfHospital()
        }
    }

    useEffect(() => {
        console.log(sort.column)
        if(sort.column === 'username') {
            setTreeData([...copyData.current].sort((a, b) => (a.item.username.toLocaleUpperCase() > b.item.username.toLocaleUpperCase()) ? 1 : ((b.item.username.toLocaleUpperCase() > a.item.username.toLocaleUpperCase()) ? -1 : 0)))
        }
        else if(sort.column === 'name') {
            setTreeData([...copyData.current].sort((a, b) => (a.item.name.toLocaleUpperCase() > b.item.name.toLocaleUpperCase()) ? 1 : ((b.item.name.toLocaleUpperCase() > a.item.name.toLocaleUpperCase()) ? -1 : 0)))
        }
        else if(sort.column === 'visible') {
            setTreeData([...copyData.current].sort((a, b) => (a.item.visibleName.toLocaleUpperCase() > b.item.visibleName.toLocaleUpperCase()) ? 1 : ((b.item.visibleName.toLocaleUpperCase() > a.item.visibleName.toLocaleUpperCase()) ? -1 : 0)))
        }
        else if(sort.column === 'role') {
            setTreeData([...copyData.current].sort((a, b) => (a.item.role.name.toLocaleUpperCase() > b.item.role.name.toLocaleUpperCase()) ? 1 : ((b.item.role.name.toLocaleUpperCase() > a.item.role.name.toLocaleUpperCase()) ? -1 : 0)))
        }
    }, [sort.column]);

    return (
        <>
            <div style={{display: 'flex', alignItems: 'center', gap: 8, marginBottom: 8}}>
                <Typography.Text style={{fontWeight: 'bold', fontSize: '13pt'}}>
                    {hospital.name}
                </Typography.Text>
                <Button.Group
                    size={'small'}
                >
                    <Button disabled={true}>
                        <SortAscendingOutlined />
                    </Button>
                    <Radio.Group
                        size={'small'}
                        value={sort.column}
                        onChange={(e) => setSort({column: e.target.value})}
                        buttonStyle={'solid'}
                    >
                        <Radio.Button type={'primary'} value={'username'}>Username</Radio.Button>
                        <Radio.Button type={'primary'} value={'name'}>Name</Radio.Button>
                        <Radio.Button type={'primary'} value={'visible'}>Visible Name</Radio.Button>
                        <Radio.Button type={'primary'} value={'role'}>Role</Radio.Button>
                    </Radio.Group>
                </Button.Group>
            </div>

            <div key={sort.column}>
                <SearchableTreeView
                    checkedKeys={selectedData}
                    treeItemData={treeData}
                    onCheckedChange={(keys) => {
                        setSelectedData(keys)
                    }}
                    treeViewProps={{
                        minHeight: 230,
                        style: {minHeight: 250}
                    }}
                />
            </div>

            <Button
                onClick={save}
                type={'primary'}
            >
                {t('Save')}
            </Button>
        </>
    )
}
