import React, {useContext} from "react";
import { PERMISSIONS, useUser } from "./UserContext";

const defaultValue = {
    title: '',
    component: (<div></div>),
    open: false,
    modal2: {
        open: false,
        component: null,
        title: ''
    }
}

export const ModalActions = {
    OPEN_MODAL: 'OPEN_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',
}

const ModalContext = React.createContext(defaultValue)

function modalReducer(state, action)
{
    switch (action.type)
    {
        case ModalActions.OPEN_MODAL: {
            if(state.open)
            {
                return {
                    ...state,
                    modal2: {
                        open: true,
                        component: action.component,
                        title: action.title,
                        onCloseFn: action.onCloseFn,
                    }
                }
            }

            return {
                ...state,
                open: true,
                component: action.component,
                title: action.title,
                onCloseFn: action.onCloseFn,
                width: action.width,
                top: action.top,
            }
        }
        case ModalActions.CLOSE_MODAL: {
            if(state.modal2.open)
            {
                state?.modal2?.onCloseFn && state?.modal2?.onCloseFn()
                return {
                    ...state,
                    modal2: defaultValue.modal2
                }
            }

            state?.modal?.onCloseFn && state?.modal?.onCloseFn()
            return defaultValue
        }
        default: {
            return defaultValue
        }
    }
}

function ModalProvider({children})
{
    const [modal, dispatch] = React.useReducer(modalReducer, defaultValue);

    const value = {modal, dispatch};

    return (
        <ModalContext.Provider value={value}>
            {children}
        </ModalContext.Provider>
    )
}

function useModal()
{
    const context = React.useContext(ModalContext);

    if(!context)
        throw new Error('Modal Provider Error')

    const {modal, dispatch} = context;

    const openModal = (title, component, onCloseFn, width, top) => {
        dispatch({
            type: ModalActions.OPEN_MODAL,
            component: component,
            title: title,
            width,
            top,
            onCloseFn
        })
    }

    const closeModal = () => {
        dispatch({
            type: ModalActions.CLOSE_MODAL,
        })
    }

    return {
        modal: modal,
        openModal,
        closeModal
    }
}

export {ModalProvider, useModal}
