import {useUser} from "../../core/context/UserContext";
import ApiService from "../../core/service/api-service";
import {useEffect, useState} from "react";
import {Button, Divider, Form, Input, List, Select, Table, Typography} from "antd";
import {t} from "i18next";
import {DeleteOutlined} from "@ant-design/icons";
import TinymceEditor from "../../core/components/TinymceEditor";

export default function UserDictionary({}) {
    const {user,dictionary, updateDictionary} = useUser()
    const [shortcut, setShortcut] = useState(localStorage.getItem('shortcut') || 'Tab')
    const [filterText, setFilterText] = useState('')
    const [dict, setDict] = useState([
        {keyword: 'mrb', text: 'merhaba'},
        {keyword: 'tst', text: 'test'},
    ])
    const [selected, setSelected] = useState({})

    const save = async () => {
        const res = await ApiService.User.UpdateUserDictionary(dict.filter(x => x.text && x.keyword));
        if(res.success) {
            updateDictionary(res.data)
            window.location.reload()
        }
    }

    useEffect(() => {
        if(dictionary) {
            setDict(dictionary)
            if(dictionary.length > 0)
                setSelected([...dictionary].reverse()[0])
        }
    }, [dictionary])

    const delDict = (keyword, text) => {
        setDict(dict.filter(x => x.keyword !== keyword && x.text !== text))
    }

    return (
        <>
            <div style={{display: 'flex', alignItems: 'start', gap: 8}}>
                <Input
                    placeholder={t('Search')}
                    style={{marginBottom: 16, width: 220}}
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                />
                <Button
                    onClick={() => {
                        const item = {id: 'new-field', keyword: '', text: ''}
                        setDict([
                            ...dict,
                            item
                        ])
                        setSelected(item)
                    }}
                >
                    +
                </Button>
                <Button
                    type={'primary'}
                    onClick={save}
                >
                    {t('Save')}
                </Button>
                <Select
                    style={{width: 200}}
                    value={shortcut}
                    onChange={(e) => {
                        setShortcut(e)
                        localStorage.setItem('shortcut', e)
                    }}
                >
                    <Select.Option value={'Tab'}>{t('Trigger')}: Tab</Select.Option>
                    <Select.Option value={'Enter'}>{t('Trigger')}: Enter</Select.Option>
                    <Select.Option value={'ControlLeft'}>{t('Trigger')}: ControlLeft</Select.Option>
                    <Select.Option value={'AltLeft'}>{t('Trigger')}: AltLeft</Select.Option>
                    <Select.Option value={'MetaLeft'}>{t('Trigger')}: Win/Option Key</Select.Option>
                    <Select.Option value={'F1'}>{t('Trigger')}: F1</Select.Option>
                    {/*<Select.Option value={'Space'}>{t('Trigger')}: Space</Select.Option>*/}
                </Select>
            </div>
            <div style={{display: 'flex', alignItems: 'start', gap: 8}}>
                <div style={{width: 200, overflowY: 'auto', height: 600}}>
                    {
                        [
                            ...dict
                            .filter(x => !filterText || (x.keyword.toLowerCase().includes(filterText.toLowerCase())))
                        ]
                            .reverse()
                            .map((item, idx) => (
                                <Input
                                    style={{marginBottom: 3, backgroundColor: selected.keyword === item.keyword ? '#3498db' : '', padding: 2, border: 'none', outline: 'none'}}
                                    key={idx}
                                    addonAfter={(
                                        <Button
                                            size={'small'}
                                            onClick={() => delDict(item.keyword, item.text)}
                                        >
                                            <DeleteOutlined />
                                        </Button>
                                    )}
                                    onClick={(e) => setSelected(item)}
                                    value={item.keyword}
                                    onChange={(e) => {
                                        const _dict = [...dict]
                                        const index = _dict.findIndex(x => x.keyword === item.keyword)
                                        if(index > -1) {
                                            _dict[index].keyword = e.target.value
                                            setDict(_dict)
                                        }
                                    }}
                                />
                            ))
                    }
                </div>
                <Divider type={'vertical'} style={{height: '100%'}} />
                <div style={{flex: 1}}>
                    <Typography.Title level={4}>{selected.keyword}</Typography.Title>
                    <TinymceEditor
                        height={'40vh'}
                        value={selected.text}
                        handleChange={(data) => {
                            if(!selected.keyword) {
                                return
                            }
                            // selected.text = data

                            //can not update state
                            const index = dict.findIndex(x => x.keyword === selected.keyword)
                            if(index > -1) {
                                dict[index].text = data;
                            }
                        }}
                    />
                </div>
            </div>
        </>
    )
}