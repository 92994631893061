import {Card} from "antd";
import LayoutWithSider from "../../core/components/layout/layout-with-sider/LayoutWithSider";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import ReportPreview from "../../app/report/ReportPreview";

export default function ReportPreviewPage()
{
    const {t} = useTranslation()
    const {studyId, requestId, reportId} = useParams();

    return (
        <>
            <LayoutWithSider>
                <Card title={t('Report Preview')}>
                    <ReportPreview
                        studyId={studyId}
                        requestId={requestId}
                        reportId={reportId}
                    />
                </Card>
            </LayoutWithSider>
        </>
    )
}
