import {useTranslation} from "react-i18next";
import {Button, Card, Dropdown, Form, Input, Menu} from "antd";
import LayoutWithSider from "../../core/components/layout/layout-with-sider/LayoutWithSider";
import {DataGridProvider} from "../../core/components/data-grid/DataGridContext";
import HospitalGrid from "../../app/hospital/hospital-data-grid/HospitalGrid";
import HospitalFilter from "../../app/hospital/hospital-data-grid/HospitalFilter";
import Flex from "../../core/components/Flex";
import CardTitle from "../../core/components/CardTitle";
import {Pages} from "../../AppRouter";
import {useHistory} from "react-router-dom";
import {useEffect} from "react";

export default function HospitalGridPage()
{
    const {t} = useTranslation();
    const history = useHistory();

    useEffect(() => {
        document.title = `ECloud Pacs - ${t('Hospitals')}`
    }, []);

    const Title = () => {

        return (
            <Flex justifyContent={'space-between'}>
                <CardTitle title={t('Hospitals')} />
                <Button
                    onClick={() => {
                        history.push(Pages.Hospital.Create)
                    }}
                >
                    {t('New Hospital')}
                </Button>
            </Flex>
        )
    }

    return (
        <>
            <DataGridProvider>
                <LayoutWithSider
                    fullWidth={true}
                    sidebarTitle={t('Hospital Filter')}
                    sidebarComponent={(
                        <HospitalFilter/>
                    )}
                >
                    <Card title={<Title />}>
                        <HospitalGrid />
                    </Card>
                </LayoutWithSider>
            </DataGridProvider>
        </>
    )
}
