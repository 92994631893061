import {useEffect, useRef, useState} from "react";
import ApiService from "../core/service/api-service";
import {
    BellOutlined,
    CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, DeleteOutlined, InboxOutlined,
    LeftOutlined,
    RightOutlined, SettingOutlined,
    TranslationOutlined,
    UserOutlined,
    ZhihuOutlined
} from "@ant-design/icons";
import {Button, Divider, Dropdown, List, Menu, Popover, Spin, Tooltip, Typography} from "antd";
import {t} from "i18next";
import FileHelper from "../core/utils/file-helper";
import {LocalStorageService} from "../core/service/local-storage-service";

const SubMenu = Menu.SubMenu;

export default function NotificationMenu({}) {
    const [notifications, setNotifications] = useState([])
    const [page, setPage] = useState(0)
    const updateRef = useRef(new Date())

    const getNotifications = async () => {
        if(window.notificationVisible) {
            const res = await ApiService.Notification.GetNotificationList(page)
            if(res.success) {
                setNotifications(res.data)
                updateRef.current = new Date()
            }
        }
    }

    useEffect(() => {
        const id = setInterval(async () => {
            await getNotifications()
        }, 3000)

        return () => {
            clearInterval(id)
        }
    }, []);

    useEffect(() => {
        if(page === -1)
        {
            setPage(0)
            return
        }
        getNotifications()
    }, [page]);

    const getIcon = (status) => {
        if(status === 1)
            return <Spin spinning={true} size={'small'} />
        if(status === 2)
            return <CheckCircleOutlined twoToneColor="#52c41a" />
        if(status === 3)
            return <CloseCircleOutlined />
    }

    const downloadReportExcel = async (notification) => {
        const downloadExcel = async () => {
            const res = await ApiService.Report.DownloadReportsExcel(notification.id)
            if(res.success)
            {
                FileHelper.saveFile({
                    blobLink: res.data,
                    fileName: res.fileName
                })
                await setRead(notification.id)
            }
        }
        await downloadExcel()
    }

    const downloadStudyZip = async (notification) => {
        const fileUrl = `${ApiService.RisApiEndpoint}/api/Sharing/DownloadStudyZip/${notification.id}?access_token=${LocalStorageService.getToken()}`

        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = `${notification.name}.zip`;
        link.click()
    }

    const getActionButton = (notification) => {
        if(notification.type === 1 && notification.status === 2) {
            return <a onClick={async () => downloadReportExcel(notification)}>{t('Download')}</a>
        }
        if(notification.type === 2 && notification.status === 2) {
            return <a onClick={async () => downloadStudyZip(notification)}>{t('Download')}</a>
        }
        if(notification.type === 3 && notification.status === 2) {
            return <a onClick={async () => downloadStudyZip(notification)}>{t('Download')}</a>
        }
    }

    const setRead = async (id) => {
        const res = await ApiService.Notification.SetRead(id)
        if(res.success) {
            await getNotifications()
        }
    }

    const removeItem = async (id) => {
        const res = await ApiService.Notification.RemoveItem(id)
        if(res.success) {
            await getNotifications()
        }
    }

    const clear = async () => {
        const res = await ApiService.Notification.Clear()
        if(res.success) {
            await getNotifications()
        }
    }

    const menu = (
        <div>
            <Typography.Title level={5} style={{paddingLeft: 8, width: 400}}>
                Background Tasks
            </Typography.Title>
            {
                notifications.length === 0 && (
                    <Typography.Text style={{paddingLeft: 8}}>
                        No Task
                    </Typography.Text>
                )
            }
            {
                notifications.map(n => (
                    <>
                        <div style={{padding: 4, paddingLeft: 8, paddingRight: 8, width: 400}}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
                                    <Typography.Text>
                                        {
                                            n.status === 1 ? `${Math.round((new Date().getTime() - new Date(n.createdDate))/1000)} s` : new Date(n.statusDate).toLocaleString()
                                        }
                                    </Typography.Text>
                                    {
                                        !n.readDate && (<div style={{width: 8, height: 8, backgroundColor: 'red', borderRadius: 4}}></div>)
                                    }
                                </div>
                                <div>
                                    {/*{*/}
                                    {/*    n.status === 1 && (<Button size={'small'}>Stop</Button>)*/}
                                    {/*}*/}
                                    {
                                        n.status === 2 && !n.readDate && (<Button onClick={() => setRead(n.id)} size={'small'}><CheckOutlined /></Button>)
                                    }
                                    {
                                        n.status === 2 && (<Button onClick={() => removeItem(n.id)} size={'small'}><DeleteOutlined /></Button>)
                                    }
                                </div>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 4}}>
                                <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', width: '100%'}}>
                                    <Typography.Text style={{color: 'black', fontWeight: 'bold', marginRight: 8}} ellipsis={true}>
                                        {n.name}
                                    </Typography.Text>
                                    {getActionButton(n)}
                                </div>
                                {getIcon(n.status)}
                            </div>
                            {
                                n.description && (
                                    <div style={{fontSize: '9pt', color: '#6a6d70'}}>
                                        {n.description}
                                    </div>
                                )
                            }
                        </div>
                        <Divider style={{margin: 0}}/>
                    </>
                ))
            }

            <div style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between',
                marginTop: 8,
                paddingLeft: 8,
                paddingRight: 8
            }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Button size={'small'} disabled={page === 0} icon={<LeftOutlined/>} onClick={(e) => {e.stopPropagation(); setPage(page-1)}}/>
                    <Button size={'small'} disabled={notifications.length === 0 || notifications.length < 5} icon={<RightOutlined/>} onClick={(e) => {e.stopPropagation(); setPage(page-1)}}/>
                </div>
                <Button onClick={clear} disabled={notifications.length === 0} size={'small'}>Clear</Button>
            </div>
        </div>
    )

    return (
        <>
            <Popover
                content={menu}
                trigger={'click'}
                placement={'bottomLeft'}
                onVisibleChange={visible => {window.notificationVisible = visible}}
            >
                <Button>
                    {/*<BellOutlined />*/}
                    <SettingOutlined />
                </Button>
            </Popover>
        </>
    )
}