import HospitalRowMenu from "./HospitalRowMenu";

export default function hospitalColumns(t)
{
    return [
        {
            title: t('Id'),
            dataIndex: 'id',
            key: 'id',
            columnName: 'id',
            sorter: true,
            render: (text, record) => (
                <HospitalRowMenu row={record} />
            )
        },
        {
            title: t('Hospital Name'),
            dataIndex: 'name',
            key: 'name',
            columnName: 'name',
            sorter: true,
        },
        {
            title: t('Official Name'),
            dataIndex: 'officialName',
            key: 'officialName',
            columnName: 'officialName',
            sorter: true,
        },
        {
            title: t('Firm'),
            dataIndex: 'firmName',
            key: 'firmName',
            columnName: 'firm.name',
            sorter: true,
            render: (text, record) => {
                return record.firm.name;
            }
        },
    ]
}
