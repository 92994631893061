import HospitalGroupEdit from "../../app/hospital-group/HospitalGroupEdit";
import Flex from "../../core/components/Flex";
import CardTitle from "../../core/components/CardTitle";
import {Button, Card} from "antd";
import LayoutWithSider from "../../core/components/layout/layout-with-sider/LayoutWithSider";
import {t} from "i18next";
import {useEffect} from "react";

export default function HospitalGroupEditPage() {

    useEffect(() => {
        document.title = `ECloud Pacs - ${t('Hospital Groups')}`
    }, []);
    const Title = () => {

        return (
            <Flex justifyContent={'space-between'}>
                <CardTitle title={t('Hospital Groups')} />
            </Flex>
        )
    }

    return (
        <>
            <LayoutWithSider
                fullWidth={true}
            >
                <Card title={<Title />}>
                    <HospitalGroupEdit />
                </Card>
            </LayoutWithSider>
        </>
    )
}