import {useTranslation} from "react-i18next";
import {useDataGrid} from "../../core/components/data-grid/DataGridContext";
import ApiService from "../../core/service/api-service";
import {useEffect, useRef, useState} from "react";
import DataGrid from "../../core/components/data-grid/DataGrid";
import {PERMISSIONS, useUser} from "../../core/context/UserContext";
import Colors from "../../core/style/Colors";
import {DATE_OPTIONS, getDateFromNow} from "../../core/utils/date-utils";
import {Button, DatePicker, Form, Table} from "antd";
import {t} from "i18next";
import HospitalSelectBox from "../hospital/HospitalSelectBox";
import ModalitySelectBox from "../modality/ModalitySelectBox";
import QuickDatePicker from "../../core/components/data-grid/QuickDatePicker";
import {useApp} from "../../core/context/AppContext";

export default function HospitalDashboard({modalities, data, tableRef}) {
    const {t} = useTranslation();
    const {hasPermission} = useUser();

    const [columns, setColumns] = useState([])

    useEffect(() => {
        if(!modalities)
            return

        setColumns([
            {
                title: t('Hospital Name'),
                dataIndex: 'hospitalName',
                key: 'hospitalName',
                columnName: 'hospitalName',
                width: 200
            },
            {
                title: () => (
                    <div style={{backgroundColor: '#c7ecee'}}>
                        {t('Routine')}
                    </div>
                ),
                children: [
                    ...modalities.map(m => {
                        return {
                            title: m.name,
                            dataIndex: m.name,
                            key: m.name,
                            render: (text, record) => {
                                let count = record?.reportCounts?.find(x => x.modalityId === m.id)?.count
                                if(count) {
                                    return (
                                        <div style={{backgroundColor: '#c7ecee', paddingLeft: 4, fontWeight: 'bold'}}>
                                            {count}
                                        </div>
                                    )
                                }
                                return 0
                            }
                        }
                    }),
                    {
                        title: t('Sum'),
                        dataIndex: 'sum',
                        key: 'sum',
                        render: (text, record) => {
                            let sum = 0;
                            record?.reportCounts?.forEach(x => sum += x.count)
                            return (
                                <div style={{backgroundColor: sum > 0 ? '#7ed6df' : '', paddingLeft: 4, fontWeight: 'bold'}}>
                                    {sum}
                                </div>
                            )
                        }
                    }
                ]
            },
            {
                title: () => (
                    <div style={{backgroundColor: '#fab1a0'}}>
                        {t('Emergency')}
                    </div>
                ),
                children: [
                    ...modalities.map(m => {
                        return {
                            title: m.name,
                            dataIndex: m.name,
                            key: m.name,
                            render: (text, record) => {
                                let count = record?.emergencyReportCounts?.find(x => x.modalityId === m.id)?.count
                                if(count) {
                                    return (
                                        <div style={{backgroundColor: '#fab1a0', paddingLeft: 4, fontWeight: 'bold'}}>
                                            {count}
                                        </div>
                                    )
                                }
                                return 0
                            }
                        }
                    }),
                    {
                        title: t('Sum'),
                        dataIndex: 'sum',
                        key: 'sum',
                        render: (text, record) => {
                            let sum = 0;
                            record?.emergencyReportCounts?.forEach(x => sum += x.count)
                            return (
                                <div style={{backgroundColor: sum > 0 ? '#ff7979' : '', paddingLeft: 4, fontWeight: 'bold'}}>
                                    {sum}
                                </div>
                            )
                        }
                    }
                ]
            }
        ])
    }, [modalities])

    return (
        <>
            <Table
                ref={tableRef}
                size={'small'}
                pagination={false}
                dataSource={data}
                columns={columns}
            />
        </>
    )
}
