import {Upload, message, Button, Input, Row, Col, Typography} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import {useEffect, useRef, useState} from "react";
import ApiService from "../service/api-service";
import {t} from "i18next";
import Flex from "./Flex";
import {toast} from "react-toastify";

export default function FileUpload({action, onFileUploaded, onError})
{
    const files = useRef([])
    const [update, setUpdate] = useState(0)
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [description, setDescription] = useState('')

    const onChange = (info) => {
        if (info.file.status === 'done') {
            onFileUploaded && onFileUploaded();
        }
        else if (info.file.status === 'error') {
            onError && onError();
        }
    }

    const handleUpload = async () => {
        setUploading(true)
        const _files = [...files.current]
        for (const x of _files) {
            try {
                const formData = new FormData();
                formData.append('file', x.file);
                formData.append('description', x.description);

                const res = await fetch(action, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        Authorization: ApiService.getAuthorization()
                    }
                });
                await res.json()
                files.current = files.current.filter(y => y.file !== x.file)
            } catch (exp) {
                onError && onError()
            }
        }
        setUploading(false)
        setUpdate(update+1)

        if(files.current.length === 0) {
            onFileUploaded && onFileUploaded()
        }
    };

    // useEffect(() => {
    //     setUpdate(update+1)
    // }, [files.current]);

    return (
        <>
            <Flex justifyContent={'center'} style={{border: '1px dashed #000', height: '100%', padding: 8}}>
                <Upload
                    style={{}}
                    multiple={true}
                    beforeUpload={file => {
                        files.current.push({
                            file,
                            description: ''
                        })
                        setUpdate(update+1)
                        return false;
                    }}
                    onRemove={file => {
                        const index = files.current.indexOf(file);
                        const newFileList = files.current.slice();
                        newFileList.splice(index, 1);

                        files.current = newFileList;
                        setUpdate(update+1)
                    }}
                    fileList={fileList}
                >
                    <Button icon={<UploadOutlined />}>{t('Select File')}</Button>
                </Upload>
            </Flex>
            <br/>
            {
                update > 0 && (
                    <>
                        <table style={{width: '100%'}}>
                            <tr>
                                <td style={{textAlign: 'start'}}>
                                    <Typography.Title level={5}>{t('File Name')}</Typography.Title>
                                </td>
                                <td style={{textAlign: 'center'}}>
                                    <Typography.Title level={5}>{t('Description')}</Typography.Title>
                                </td>
                            </tr>
                            {
                                files.current.map(f => {
                                    return (
                                        <tr>
                                            <td style={{textAlign: 'start', verticalAlign: 'middle'}}>
                                                {f.file.name}
                                            </td>
                                            <td>
                                                <Input
                                                    size={'small'}
                                                    style={{width: '100%'}}
                                                    value={f.description}
                                                    onChange={(e) => {
                                                        files.current.forEach(x => {
                                                            if(x.file === f.file) {
                                                                x.description = e.target.value
                                                                setUpdate(update+1)
                                                            }
                                                        })
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <Button
                                                    size={'small'}
                                                    onClick={() => {
                                                        files.current = files.current.filter(x => x.file !== f.file)
                                                        setUpdate(update+1)
                                                    }}
                                                >
                                                    X
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                    </>
                )
            }
            <br/>
            <Button onClick={handleUpload} loading={uploading} disabled={files.current.length === 0} type={'primary'}>
                {uploading ? t('Uploading') : t('Start Upload')}
            </Button>
        </>
    )
}