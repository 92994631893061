import {Button, DatePicker, Form, Input, Select, Space} from "antd";
import {useTranslation} from "react-i18next";
import {useDataGrid} from "../../../core/components/data-grid/DataGridContext";
import {useRef, useState} from "react";
import {DATE_OPTIONS, getBeginStr, getDateFromNow, getEndStr} from "../../../core/utils/date-utils";
import HospitalTreeView from "../../hospital/HospitalTreeView";
import HospitalSelectBox from "../../hospital/HospitalSelectBox";

export default function HL7Filter()
{
    const {t} = useTranslation();
    const {onFilterPageSort} = useDataGrid();
    const formRef = useRef();

    const getDefaultDate = () => {
        const date = getDateFromNow(DATE_OPTIONS.TODAY);
        return [date.start, date.end]
    }

    const date = getDefaultDate();

    const reset = () => {
        window.location.reload()
    }

    const filter = (form) => {
        onFilterPageSort({
            filter: {
                ...form,
                beginDate: getBeginStr(form.beginDate),
                endDate: getEndStr(form.endDate),
            }
        })
    }

    return (
        <>
            <Form
                ref={formRef}
                onFinish={filter}
                layout={'vertical'}
                initialValues={{
                    beginDate: date[0],
                    endDate: date[1],
                    messageType: 0,
                    hl7Status: 0
                }}
            >
                <Space style={{marginBottom: 16}}>
                    <Button type={'primary'} size={'small'} htmlType="submit" >{t('Search')}</Button>
                    <Button size={'small'} onClick={reset}>{t('Reset')}</Button>
                </Space>
                <div
                    style={{display: 'flex', alignItems: 'center'}}
                >
                    <Form.Item
                        label={t('Date')}
                        name="beginDate"
                    >
                        <DatePicker showToday={false} />
                    </Form.Item>
                    <Form.Item
                        label={' '}
                        name="endDate"
                    >
                        <DatePicker showToday={false} />
                    </Form.Item>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Form.Item
                        label={'Status'}
                        name={'messageType'}
                    >
                        <Select
                            style={{width: 100}}
                        >
                            <Select.Option value={0}>
                                {t('Request')}
                            </Select.Option>
                            <Select.Option value={1}>
                                {t('Report')}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={' '}
                        name={'hl7Status'}
                    >
                        <Select
                            style={{width: 100}}
                        >
                            <Select.Option value={null}>
                                {t('All')}
                            </Select.Option>
                            <Select.Option value={0}>
                                {t('Waiting')}
                            </Select.Option>
                            <Select.Option value={1}>
                                {t('Completed')}
                            </Select.Option>
                            <Select.Option value={2}>
                                {t('Error')}
                            </Select.Option>
                            <Select.Option value={3}>
                                {t('Continue')}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </div>
                <Form.Item
                    label={t('Message Id')}
                    name={'id'}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t('Patient Id')}
                    name={'patientId'}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t('Accession Number')}
                    name={'accessionNumber'}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t('ACK')}
                    name={'ack'}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t('Hospitals')}
                    name="hospitalIdList"
                >
                    <HospitalSelectBox />
                </Form.Item>
            </Form>
        </>
    )
}
