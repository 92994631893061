import {Layout, Menu, Breadcrumb, Button, Divider, Select} from 'antd';

import './layout.css'
import {LocalStorageService} from "../../../service/local-storage-service";
import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    GroupOutlined,
    KeyOutlined,
    MenuOutlined,
    UserOutlined
} from "@ant-design/icons";
import Flex from "../../Flex";
import Logo from "./Logo";
import {BiFirstAid} from "react-icons/bi";
import {Pages} from "../../../../AppRouter";
import {AiFillSetting} from "react-icons/ai";
import ProfileDropdown from "./ProfileDropdown";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

import {ImInsertTemplate} from 'react-icons/im'
import {RiDashboardLine} from 'react-icons/ri'
import {HiOutlineDocumentReport} from 'react-icons/hi'
import {FaRegHospital} from 'react-icons/fa'
import {PERMISSIONS, useUser} from "../../../context/UserContext";
import {BrowserView, isMobile, MobileView} from "react-device-detect";
import NotificationMenu from "../../../../app/notification-menu";

const { Header } = Layout;

const {SubMenu} = Menu;
export default function MainLayout({children})
{
    const {t} = useTranslation();
    const history = useHistory();
    const key = window.location.pathname;
    const {hasPermission, showDashboard} = useUser()

    const hasSystemPermission = hasPermission(PERMISSIONS.HOSPITAL_MANAGER)
        || hasPermission(PERMISSIONS.REPORT_TEMPLATE_MANAGER)
        || hasPermission(PERMISSIONS.REQUEST_TYPE_MANAGER)
        || hasPermission(PERMISSIONS.USER_MANAGER)

    const onClick = (item) => {
        // window.open(item.key, '_blank')
        history.push(item.key)
    }

    return (
        <Layout>
            <MobileView>
                <Header style={{ position: 'fixed', zIndex: 100, width: '100%', height: 64 }}>
                    <Flex alignItems={'center'} flexWrap={'wrap'} justifyContent={'space-between'} style={{width: '100%'}}>
                        <Menu
                            style={{marginLeft: 16, minWidth: 48}}
                            onClick={onClick}
                            theme="dark"
                            mode="horizontal"
                            defaultSelectedKeys={[key]}
                        >
                            <SubMenu icon={<MenuOutlined />} key={'mobile'}>
                                {showDashboard() && (<Menu.Item key={Pages.Dashboard.Home} icon={<RiDashboardLine/>} title={t('Dashboard')} />)}
                                {hasPermission(PERMISSIONS.STUDY_LIST) && (<Menu.Item icon={<BiFirstAid/>} key={Pages.Study.Study}>{t('Study')}</Menu.Item>)}
                                {hasPermission(PERMISSIONS.REPORT_LIST) && (<Menu.Item icon={<HiOutlineDocumentReport/>} key={Pages.Report.DataGrid}>{t('Reports')}</Menu.Item>)}
                                {hasSystemPermission && (
                                    <SubMenu key="sub4" icon={<AiFillSetting/>} title={t('System')}>
                                        {hasPermission(PERMISSIONS.HOSPITAL_MANAGER) && (<Menu.Item icon={<FaRegHospital/>} key={Pages.Hospital.Hospital}>{t('Hospital')}</Menu.Item>)}
                                        {hasPermission(PERMISSIONS.HOSPITAL_GROUP_MANAGER) && (<Menu.Item icon={<FaRegHospital/>} key={Pages.HospitalGroup.HospitalGroupEdit}>{t('Hospital Groups')}</Menu.Item>)}
                                        {hasPermission(PERMISSIONS.HL7_MANAGER) && (<Menu.Item icon={<FaRegHospital/>} key={Pages.HL7.DataGrid}>{t('HL7')}</Menu.Item>)}
                                        {hasPermission(PERMISSIONS.TELETIP_MANAGER) && (<Menu.Item icon={<FaRegHospital/>} key={Pages.Teletip.DataGrid}>{t('Teletip')}</Menu.Item>)}
                                        {hasPermission(PERMISSIONS.REPORT_TEMPLATE_MANAGER) && (<Menu.Item icon={<ImInsertTemplate/>} key={Pages.ReportTemplate.DataGrid}>{t('Report Templates')}</Menu.Item>)}
                                        {hasPermission(PERMISSIONS.REQUEST_TYPE_MANAGER) && (<Menu.Item icon={<GroupOutlined />} key={Pages.RequestType.System}>{t('Request Types')}</Menu.Item>)}
                                        {hasPermission(PERMISSIONS.USER_MANAGER) && (<Menu.Item icon={<KeyOutlined />} key={Pages.Role.Manager}>{t('Roles')}</Menu.Item>)}
                                        {hasPermission(PERMISSIONS.USER_MANAGER) && (<Menu.Item icon={<UserOutlined/>} key={Pages.User.DataGrid}>{t('Users')}</Menu.Item>)}
                                    </SubMenu>
                                )}
                            </SubMenu>
                        </Menu>
                        <Logo/>
                        <ProfileDropdown />
                    </Flex>
                </Header>
            </MobileView>
            <BrowserView>
                <Header style={{ position: 'fixed', zIndex: 100, width: '100%', height: 64 }}>
                    <Flex alignItems={'center'}>
                        <Logo/>
                        <Menu
                            style={{marginLeft: 16, flex: 1}}
                            onClick={onClick}
                            theme="dark"
                            mode="horizontal"
                            defaultSelectedKeys={[key]}
                        >

                            {showDashboard() && (<Menu.Item key={Pages.Dashboard.Home} icon={<RiDashboardLine/>} title={t('Dashboard')} />)}
                            {hasPermission(PERMISSIONS.STUDY_LIST) && (<Menu.Item icon={<BiFirstAid/>} key={Pages.Study.Study}>{t('Study')}</Menu.Item>)}
                            {hasPermission(PERMISSIONS.REPORT_LIST) && (<Menu.Item icon={<HiOutlineDocumentReport/>} key={Pages.Report.DataGrid}>{t('Reports')}</Menu.Item>)}
                            {hasSystemPermission && (
                                <SubMenu key="sub4" icon={<AiFillSetting/>} title={t('System')}>
                                    {hasPermission(PERMISSIONS.HOSPITAL_MANAGER) && (<Menu.Item icon={<FaRegHospital/>} key={Pages.Hospital.Hospital}>{t('Hospital')}</Menu.Item>)}
                                    {hasPermission(PERMISSIONS.HOSPITAL_GROUP_MANAGER) && (<Menu.Item icon={<FaRegHospital/>} key={Pages.HospitalGroup.HospitalGroupEdit}>{t('Hospital Groups')}</Menu.Item>)}
                                    {hasPermission(PERMISSIONS.HL7_MANAGER) && (<Menu.Item icon={<FaRegHospital/>} key={Pages.HL7.DataGrid}>{t('HL7')}</Menu.Item>)}
                                    {hasPermission(PERMISSIONS.TELETIP_MANAGER) && (<Menu.Item icon={<FaRegHospital/>} key={Pages.Teletip.DataGrid}>{t('Teletip')}</Menu.Item>)}
                                    {hasPermission(PERMISSIONS.REPORT_TEMPLATE_MANAGER) && (<Menu.Item icon={<ImInsertTemplate/>} key={Pages.ReportTemplate.DataGrid}>{t('Report Templates')}</Menu.Item>)}
                                    {hasPermission(PERMISSIONS.REQUEST_TYPE_MANAGER) && (<Menu.Item icon={<GroupOutlined />} key={Pages.RequestType.System}>{t('Request Types')}</Menu.Item>)}
                                    {hasPermission(PERMISSIONS.USER_MANAGER) && (<Menu.Item icon={<KeyOutlined />} key={Pages.Role.Manager}>{t('Roles')}</Menu.Item>)}
                                    {hasPermission(PERMISSIONS.USER_MANAGER) && (<Menu.Item icon={<UserOutlined/>} key={Pages.User.DataGrid}>{t('Users')}</Menu.Item>)}
                                </SubMenu>
                            )}
                        </Menu>
                        <NotificationMenu />
                        <ProfileDropdown />
                    </Flex>
                </Header>
            </BrowserView>
            <Layout className="site-layout">
                {children}
            </Layout>
        </Layout>
    )
}
