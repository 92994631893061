import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Editor} from '@tinymce/tinymce-react';
import {v4} from "uuid";
import {util} from "tinymce";
import useKeypress from "react-use-keypress";
import {useKeyListener} from "./KeyListener";
import {useApp} from "../context/AppContext";

const TinymceEditor = ({value, handleChange, height, onInit, dictionary}) =>
{
    const marker = `<span id="text-replacement-marker-element"></span>`
    const editorRef = useRef(null);
    const [html, setHtml] = useState('')
    const isFocus = useRef(false)
    const {appKeyDown, appKeyUp, addKeyEvent, removeKeyEvent, pressedKey} = useApp()
    const ctrlRef = useRef(null)

    const insertMarker = () => {
        editorRef.current.insertContent(marker)
    }

    const removeMarker = () => {
        const span = editorRef.current.getBody().querySelector('#text-replacement-marker-element')
        span?.removeAttribute('id')
    }

    const extractText = (html) => {
        const element = document.createElement('div')
        element.innerHTML = html
        return element.innerText;
    }

    const findCurrentWord = () => {
        let content = editorRef.current.getContent()
        let indexOf = content.indexOf(marker)
        let htmlIndexOf = indexOf-1;

        let word = ''
        if(indexOf > -1) {
            const id = v4()
            content = extractText(
                content
                    .replaceAll(marker, id)
                    .replaceAll('<br />', '\n')
            )
                .replaceAll(/\n/g,' ')
            indexOf = content.indexOf(id)-1

            content = content.replaceAll(id, ' ')
            word = getWordFromPosition(content, indexOf)
        }

        return {
            word: word.trim(),
            indexOf: htmlIndexOf
        }
    }

    const removeMarkers = (content) => {
        if(content) {
            const div = document.createElement('div')
            div.innerHTML = content

            let span = div.querySelector('#text-replacement-marker-element')
            let counter = 0;
            while (span && counter < 50) {
                span.remove()
                span = div.querySelector('#text-replacement-marker-element')
                counter++
            }
            return div.innerHTML
        }
    }

    const getStartEndIndexOfWord = (text, word, position) => {
        let index = -1
        let startIndex = 0
        let indices = []
        while ((index = text.indexOf(word, startIndex)) > -1) {
            indices.push(index);
            startIndex = index + word.length;
        }

        let indexWord = -1;
        let close = 99999999;
        indices.forEach(x => {
            if(Math.abs(x-position) < close)
            {
                indexWord = x;
                close = Math.abs(x-position);
            }
        })

        if(indexWord > -1) {
            return {
                startIndex: indexWord,
                endIndex: indexWord + word.length-1,
                length: word.length
            }
        }

        return null
    }

    const getWordFromPosition = (text, position) => {
        let word = []
        let isFindChar = false
        for(let i=position; i >= 0; i--) {
            if(!text[i]?.trim() && !isFindChar)
                continue

            if(text[i] === ' ')
                break

            isFindChar = true
            word.push(text[i]);
        }

        return word.reverse().join('')
    }

    const getShortcut = () => {
        return localStorage.getItem('shortcut') || 'Tab'
    }

    const keydown = (evt) => {
        if(evt.key === getShortcut() || evt.code === getShortcut() || evt.keyCode === getShortcut()) {
            insertMarker()
            let keyword = findCurrentWord()
            let text = dictionary?.find(y => y.keyword.trim() === keyword.word.trim())?.text

            removeMarker()
            if(text) {
                evt.preventDefault()
                const endId = editorRef.current.dom.uniqueId();
                editorRef.current.insertContent(`<span id="${endId}"></span>`)

                text = text.split('\n').join('')
                const index = getStartEndIndexOfWord(editorRef.current.getContent(), keyword.word, keyword.indexOf)
                if(index) {
                    let content = editorRef.current.getContent();
                    if(getShortcut() === 'Space') {
                        text = text + '&nbsp;'
                    }

                    let modified = content.substring(0, index.startIndex) + text + content.substring(index.endIndex+1);
                    editorRef.current.setContent(modified)

                    //select that span
                    const newNode = editorRef.current.dom.select('span#' + endId);
                    editorRef.current.selection.select(newNode[0]);
                }
            }
        }
        else if(isFocus.current) {
            appKeyDown && appKeyDown(evt)
        }
    }

    const keyup = (evt) => {
        appKeyUp && appKeyUp(evt)
    }

    const getValue = (_value) => {
        const data = removeMarkers(_value)
        if(data) {
            return data
        }
        else {
            return ''
        }
    }

    useEffect(() => {
        if(value) {
            setHtml(getValue(value))
        }
        else {
            setHtml('')
        }
    }, [value]);

    return (
        <Editor
            onFocusIn={e => isFocus.current = true}
            onFocusOut={e => isFocus.current = false}
            onKeyUp={keyup}
            onKeyDown={keydown}
            onInit={(evt, editor) => {
                editorRef.current = editor;
                onInit && onInit(editor);
            }}
            initialValue={html}
            onEditorChange={(text) => {
                handleChange && handleChange(text)
            }}
            apiKey="df4dfc1015117c1e1c5977834165d2178ed1e15db678d07512cf758892cf2cf9"
            init={{
                height: height || '75vh',
                menubar: false,
                content_style: `body { color: #000; font-size: 11pt; line-height:1 }`,
                mobile: false,
                fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt',
                statusbar: false,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table code paste help wordcount table code codesample pagebreak'
                ],

                contextmenu: false,
                paste_preprocess : function(pl, o) {
                    const div = document.createElement('div');
                    div.innerHTML = o.content;
                    for(let element of div.getElementsByTagName('*'))
                    {
                        element.style = {};
                    }
                    o.content = div.innerHTML;
                },
                formats: {
                    bold:{inline: 'b'},
                    underline:{inline: 'u'},
                    italic:{inline: 'i'},
                },
                extended_valid_elements:'u,b,i',
                valid_elements: "@[id|class|style|title|dir<ltr?rtl|lang|xml::lang|onclick|ondblclick|"
                    + "onmousedown|onmouseup|onmouseover|onmousemove|onmouseout|onkeypress|"
                    + "onkeydown|onkeyup],a[rel|rev|charset|hreflang|tabindex|accesskey|type|"
                    + "name|href|target|title|class|onfocus|onblur],b/strong,i/em,strike,u,"
                    + "#p,-ol[type|compact],-ul[type|compact],-li,br,img[longdesc|usemap|"
                    + "src|border|alt=|title|hspace|vspace|width|height|align],-sub,-sup,"
                    + "-blockquote,-table[border=0|cellspacing|cellpadding|width|frame|rules|"
                    + "height|align|summary|bgcolor|background|bordercolor],-tr[rowspan|width|"
                    + "height|align|valign|bgcolor|background|bordercolor],tbody,thead,tfoot,"
                    + "#td[colspan|rowspan|width|height|align|valign|bgcolor|background|bordercolor"
                    + "|scope],#th[colspan|rowspan|width|height|align|valign|scope],caption,-div,"
                    + "-span,-code,-pre,address,-h1,-h2,-h3,-h4,-h5,-h6,hr[size|noshade],-font[face"
                    + "|size|color],dd,dl,dt,cite,abbr,acronym,del[datetime|cite],ins[datetime|cite],"
                    + "object[classid|width|height|codebase|*],param[name|value|_value],embed[type|width"
                    + "|height|src|*],script[src|type],map[name],area[shape|coords|href|alt|target],bdo,"
                    + "button,col[align|char|charoff|span|valign|width],colgroup[align|char|charoff|span|"
                    + "valign|width],dfn,fieldset,form[action|accept|accept-charset|enctype|method],"
                    + "input[accept|alt|checked|disabled|maxlength|name|readonly|size|src|type|value],"
                    + "kbd,label[for],legend,noscript,optgroup[label|disabled],option[disabled|label|selected|value],"
                    + "q[cite],samp,select[disabled|multiple|name|size],small,"
                    + "textarea[cols|rows|disabled|name|readonly],tt,var,big",
                invalid_elements:'em strong',
                toolbar:
                    'styleselect | formats blockformats fontformatselect fontsizeselect align lineheight | bold italic bullist numlist image blockquote table media undo redo forecolor backcolor code codesample layout_12',
                theme_advanced_toolbar_align: 'center',
                table_default_attributes: {
                    border: '0',
                    align: 'center'
                },
                table_default_styles: {
                    width: '50%'
                },
                setup: (editor) => {
                    const getMatchedChars = function (pattern) {
                        const keywords = dictionary.filter(function (char) {
                            return char.keyword.indexOf(pattern) !== -1;
                        });

                        return keywords
                    };

                    editor.ui.registry.addAutocompleter(v4(), {
                        ch: ':',
                        minChars: 0,
                        columns: 1,
                        onAction: function (autocompleteApi, rng, value) {
                            editor.selection.setRng(rng);
                            editor.insertContent(dictionary.find(x => x.keyword === value)?.text);
                            autocompleteApi.hide();
                        },
                        fetch: function (pattern) {
                            return new util.Promise(function (resolve) {
                                const results = getMatchedChars(pattern).map(function (char) {
                                    return {
                                        type: 'autocompleteitem',
                                        value: char.keyword,
                                        text: char.text,
                                        icon: char.keyword
                                    }
                                });
                                resolve(results);
                            });
                        }
                    });

                    editor.ui.registry.addButton('layout_12', {
                        text: '1x2',
                        onAction: function (_) {
                            const html = `
                                        <table style="width: 100%; border-style: none;" border="0" align="center">
                                        <tbody>
                                        <tr>
                                            <td style="width: 15%">Baslik</td>
                                            <td style="width: 34%">Icerik</td>
                                            <td style="width: 2%;"></td>
                                            <td style="width: 15%; text-align: right;">Baslik</td>
                                            <td style="width: 34%">Icerik</td>
                                        </tr>
                                        </tbody>
                                        </table>
                                        `
                            editor.insertContent(html);
                        }
                    });
                }
            }}
        />
    );
};

TinymceEditor.propTypes = {
    value: PropTypes.string,
    handleChange: PropTypes.func
};

export default TinymceEditor;
