import {DataGridProvider} from "../../core/components/data-grid/DataGridContext";
import HL7MessageGrid from "../../app/hl7/hl7-data-grid/HL7MessageGrid";
import LayoutWithSider from "../../core/components/layout/layout-with-sider/LayoutWithSider";
import {Card} from "antd";
import HL7Filter from "../../app/hl7/hl7-data-grid/HL7Filter";
import {DATE_OPTIONS, getBeginStr, getDateFromNow, getEndStr} from "../../core/utils/date-utils";
import {useEffect} from "react";
import {t} from "i18next";
import TeletipGrid from "../../app/teletip/teletip-data-grid/TeletipGrid";
import TeletipFilter from "../../app/teletip/teletip-data-grid/TeletipFilter";

export default function TeletipGridPage() {
    const getDefaultDate = () => {
        const date = getDateFromNow(DATE_OPTIONS.TODAY);
        return [date.start, date.end]
    }

    const date = getDefaultDate();

    useEffect(() => {
        document.title = `ECloud Pacs - ${t('Teletip')}`
    }, []);

    return (
        <>
            <DataGridProvider
                defaultFilter={{
                    studyReceivedDateTimeBegin: getBeginStr(date[0]),
                    studyReceivedDateTimeEnd: getEndStr(date[1]),
                }}
            >
                <LayoutWithSider
                    fullWidth={true}
                    sidebarComponent={(
                        <TeletipFilter />
                    )}
                >
                    <Card title={'Teletip'}>
                        <TeletipGrid />
                    </Card>
                </LayoutWithSider>
            </DataGridProvider>
        </>
    )
}
