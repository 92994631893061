import {DataGridProvider} from "../../core/components/data-grid/DataGridContext";
import HL7MessageGrid from "../../app/hl7/hl7-data-grid/HL7MessageGrid";
import LayoutWithSider from "../../core/components/layout/layout-with-sider/LayoutWithSider";
import {Card} from "antd";
import HL7Filter from "../../app/hl7/hl7-data-grid/HL7Filter";
import {DATE_OPTIONS, getBeginStr, getDateFromNow, getEndStr} from "../../core/utils/date-utils";
import {useEffect} from "react";
import {t} from "i18next";

export default function HL7GridPage() {
    const getDefaultDate = () => {
        const date = getDateFromNow(DATE_OPTIONS.TODAY);
        return [date.start, date.end]
    }

    const date = getDefaultDate();

    useEffect(() => {
        document.title = `ECloud Pacs - ${t('HL7 Messages')}`
    }, []);

    return (
        <>
            <DataGridProvider
                defaultFilter={{
                    beginDate: getBeginStr(date[0]),
                    endDate: getEndStr(date[1]),
                    messageType: 0,
                    hl7Status: 0
                }}
            >
                <LayoutWithSider
                    fullWidth={true}
                    sidebarComponent={(
                        <HL7Filter />
                    )}
                >
                    <Card title={'HL7 Messages'}>
                        <HL7MessageGrid />
                    </Card>
                </LayoutWithSider>
            </DataGridProvider>
        </>
    )
}
