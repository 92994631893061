import {startOfMonth, startOfWeek, subDays, subMonths} from "date-fns";
import moment from "moment";

export const DATE_OPTIONS = {
    TODAY: 'Today',
    YESTERDAY: 'Yesterday',
    LAST_2_DAYS: 'Last 2 Days',
    THIS_WEEK: 'This Week',
    THIS_MONTH: 'This Month',
    LAST_5_DAYS: 'Last 5 Days',
    LAST_7_DAYS: 'Last 7 Days',
    LAST_15_DAYS: 'Last 15 Days',
    LAST_30_DAYS: 'Last 30 Days',
    LAST_3_MONTH: 'Last 3 Month',
    LAST_6_MONTH: 'Last 6 Month',
}

export const dateFormat = (dateStr, format) => {
    return moment(dateStr).format(format || 'DD-MM-yyyy HH:mm')
}

export const getBeginStr = (date) => {
    if(date && date.clone) {
        return date.clone().set({hour:0, minute:0, second:0, millisecond: 0}).utc().format()
    }

    return date
}

export const getEndStr = (date) => {
    if(date && date.clone) {
        return date.clone().set({hour:23, minute:59, second:59, millisecond: 999}).utc().format()
    }

    return date
}

export const getBeginLocalStr = (date) => {
    if(date && date.clone) {
        return date.clone().set({hour:0, minute:0, second:0, millisecond: 0}).utcOffset(0, true).format()
    }

    return date
}

export const getEndLocalStr = (date) => {
    if(date && date.clone) {
        return date.clone().set({hour:23, minute:59, second:59, millisecond: 999}).utcOffset(0, true).format()
    }

    return date
}

export const getDateFromNow = (option) => {
    let now = moment();
    let start = now.set({hour:0, minute:0, second:0, millisecond: 0});
    let end  = moment();
    end.set({hour:23, minute:59, second:59, millisecond: 999})

    if(option === DATE_OPTIONS.TODAY)
    {
        start = now.set({hour:12, minute:0, second:0, millisecond: 0});
    }
    else if(option === DATE_OPTIONS.YESTERDAY)
    {
        start = now.subtract(1, 'days');
        end = start
    }
    else if(option === DATE_OPTIONS.LAST_2_DAYS)
    {
        start = now.subtract(2, 'days');
    }
    else if(option === DATE_OPTIONS.LAST_5_DAYS)
    {
        start = now.subtract(5, 'days');
    }
    else if(option === DATE_OPTIONS.THIS_WEEK)
    {
        start = now.startOf('isoWeek');
    }
    else if(option === DATE_OPTIONS.THIS_MONTH)
    {
        start = now.startOf('month')
    }
    else if(option === DATE_OPTIONS.LAST_7_DAYS)
    {
        start = now.subtract(7, 'days')
    }
    else if(option === DATE_OPTIONS.LAST_15_DAYS)
    {
        start = now.subtract(15, 'days')
    }
    else if(option === DATE_OPTIONS.LAST_30_DAYS)
    {
        start = now.subtract(30, 'days')
    }
    else if(option === DATE_OPTIONS.LAST_3_MONTH)
    {
        start = now.subtract(3, 'months')
    }
    else if(option === DATE_OPTIONS.LAST_6_MONTH)
    {
        start = now.subtract(6, 'months')
    }

    return {
        start: start,
        end: end
    }
}

export const getAge = (dateStr) => {
    if(!dateStr)
        return '*';
    else if(dateStr.includes('0001-01-01'))
        return '*'

    return moment().diff(dateStr, 'years');
}
