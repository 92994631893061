import {Button, Divider, Form, Input, InputNumber, Switch, Tabs} from "antd";
import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import ApiService, {RisApiEndpoint} from "../../core/service/api-service";
import {Pages} from "../../AppRouter";
import {useHistory} from "react-router-dom";
import Keycloak from "../../core/service/keycloak";

export default function PacsSystemCreateEdit({hospitalId, pacsSystemId, onSuccess})
{
    const {t} = useTranslation();
    const formRef = useRef();
    const [authEnabled, setAuthEnabled] = useState(true)
    const [pacs, setPacs] = useState({})

    const getPacsSystem = async () =>
    {
        if (pacsSystemId)
        {
            const response = await ApiService.PacsSystem.Get(hospitalId, pacsSystemId)
            if (response.success)
            {
                formRef.current.setFieldsValue({
                    ...response.data
                })
                setPacs(response.data)
                setAuthEnabled(response.data.authEnabled)
            }
        }
    }

    const save = async (form) =>
    {
        if (pacsSystemId)
        {
            const response = await ApiService.PacsSystem.Edit(hospitalId, pacsSystemId, form)
            if (response.success)
                onSuccess(hospitalId, response.data)
        } else
        {
            const response = await ApiService.PacsSystem.Create(hospitalId, form)
            if (response.success)
                onSuccess(hospitalId, response.data)
        }
    }

    useEffect(() =>
    {
        getPacsSystem()
    }, [])

    const autoFill = ({address, aetitle}) =>
    {
        formRef.current.setFieldsValue({
            qidoRsAddress: `https://${address}:8443/dcm4chee-arc/aets/${formRef.current.getFieldsValue().aeTitle}/rs`,
            wadoRsAddress: `https://${address}:8443/dcm4chee-arc/aets/${formRef.current.getFieldsValue().aeTitle}/rs`,
            wadoAddress: `https://${address}:8443/dcm4chee-arc/aets/${formRef.current.getFieldsValue().aeTitle}/wado`,
            keycloakTokenUrl: `https://${address}:8843/auth/realms/dcm4che/protocol/openid-connect/token`,
            keycloakClientId: `dcm4chee-arc-api`,
        })
    }

    return (
        <>
            <Form
                onFinish={save}
                ref={formRef}
                layout={'vertical'}
                initialValues={{
                    authEnabled: authEnabled,
                    enableStudyFetch: true,
                    dimseCPort: 22112
                }}
            >
                <Form.Item
                    label={t('Name')}
                    name={'name'}
                    rules={[
                        {required: true}
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label={t('AE Title')}
                    name={'aeTitle'}
                    rules={[
                        {required: true}
                    ]}
                >
                    <Input
                        onChange={(e) =>
                        {
                            const address = e.target.value;
                            autoFill({
                                address: formRef.current.getFieldsValue().serverAddress,
                                aetitle: e.target.value
                            })
                        }}
                    />
                </Form.Item>

                <Form.Item
                    label={t('Server Address')}
                    name={'serverAddress'}
                    rules={[
                        {required: true}
                    ]}
                >
                    <Input
                        onChange={(e) =>
                        {
                            const address = e.target.value;
                            autoFill({address, aetitle: formRef.current.getFieldsValue().aetitle})
                        }}
                    />
                </Form.Item>

                <Form.Item
                    label={t('Dimse C Port')}
                    name={'dimseCPort'}
                    rules={[
                        {required: true}
                    ]}
                >
                    <InputNumber/>
                </Form.Item>

                <Form.Item
                    label={t('QIDO-RS Address')}
                    name={'qidoRsAddress'}
                    rules={[
                        {required: true}
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label={t('WADO-RS Address')}
                    name={'wadoRsAddress'}
                    rules={[
                        {required: true}
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label={t('WADO Address')}
                    name={'wadoAddress'}
                    rules={[
                        {required: true}
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label={t('Enable Study Fetch')}
                    name={'enableStudyFetch'}
                    valuePropName={'checked'}
                    rules={[
                        {required: true}
                    ]}
                >
                    <Switch/>
                </Form.Item>

                <Form.Item
                    label={t('Auto Delete Studies')}
                    name={'deleteBeforeDays'}
                    rules={[
                        {required: false}
                    ]}
                >
                    <InputNumber />
                </Form.Item>

                <Divider />

                <Form.Item
                    label={t('Auth Enabled')}
                    name={'authEnabled'}
                    valuePropName={'checked'}
                    rules={[
                        {required: true}
                    ]}
                >
                    <Switch onChange={(checked) => setAuthEnabled(checked)}/>
                </Form.Item>

                {
                    authEnabled && (
                        <>
                            <Form.Item
                                label={t('Keycloak Token URL')}
                                name={'keycloakTokenUrl'}
                                rules={[
                                    {required: authEnabled}
                                ]}
                            >
                                <Input/>
                            </Form.Item>

                            <Form.Item
                                label={t('Keycloak Client ID')}
                                name={'keycloakClientId'}
                                rules={[
                                    {required: authEnabled}
                                ]}
                            >
                                <Input/>
                            </Form.Item>

                            <Form.Item
                                label={t('Keycloak Client Secret')}
                                name={'keycloakClientSecret'}
                                rules={[
                                    {required: authEnabled}
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </>
                    )
                }

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {t('Save')}
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
