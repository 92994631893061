import { message, Button } from 'antd';
import Keycloak from "./keycloak";
import {LocalStorageService} from "./local-storage-service";
import {toast} from "react-toastify";
import axios from "axios";
import {t} from 'i18next'

export const RisApiEndpoint = (window.AppSettings.server && window.AppSettings.server.risApi);
const name = (window.AppSettings.server && window.AppSettings.server.name);

function showLoader()
{
    document.getElementById('request-spinner').style.setProperty('display', 'block');
}

function hideLoaderItem()
{
    document.getElementById('request-spinner').style.setProperty('display', 'none');
}

function setLogout(func)
{
    document.logout = func;
}

function getAuthorization()
{
    const token = LocalStorageService.getToken();
    return `Bearer ${token}`;
}

async function request({endpoint, method, body, onValidationError, contentType, isResponseFile, showSuccess, hideLoader, browserDownload, hideError})
{
    if(!hideLoader && !browserDownload)
        showLoader()

    try
    {
        const token = LocalStorageService.getToken();

        let headers = {};

        if(browserDownload) {
            const url = `${RisApiEndpoint}${endpoint}?access_token=${token}`
            const href = document.createElement('a');
            href.setAttribute('href', url)
            href.setAttribute('download', '')
            href.style.display = 'none'
            href.click();
            // window.open(url, '_blank')
            return
        }

        if(contentType)
        {
            headers['Content-Type'] = contentType;
        }

        if (token)
        {
            headers.Authorization = `Bearer ${token}`;
        }

        const response = await fetch(`${RisApiEndpoint}${endpoint}`, {
            method: method,
            headers: headers,
            body: JSON.stringify(body)
        });

        if (response.status === 401)
        {
            if(document.logout)
            {
                hideLoaderItem()
                document.logout()
            }
            return {
                success: false,
            }
        }

        let json = {};
        if(isResponseFile && response.status === 200)
        {
            const content = response.headers.get('Content-Disposition');
            if(content)
            {
                const fileName = content.split(';')[1].split('=')[1].replaceAll('"','');
                json = {
                    fileName,
                    success: true,
                    data: URL.createObjectURL(await response.blob())
                }
            }
            else
            {
                json = {
                    success: true,
                    data: URL.createObjectURL(await response.blob())
                }
            }
        }
        else
        {
            json = await response.json();
        }

        if(!hideLoader && !browserDownload)
            hideLoaderItem()


        if (response.status === 200)
        {
            if((method === 'POST' || method === 'PUT') && showSuccess)
            {
                toast.success('İşlem Başarılı');
            }

            return {
                success: true,
                ...json
            }
        } else if (response.status === 204)
        {
            json.message && toast.success(json.message);

            return {
                success: true,
                message: json.message
            }
        }
        else if (response.status >= 500)
        {
            if(!hideError)
                json.Message && toast.error(t(json.Message));

            return {
                success: false,
                message: json.Message,
                errorData: json.ErrorData
            }
        }
    }
    catch (exp)
    {
        hideLoaderItem()

        if(!hideError)
            toast.error('İşlem Başarısız!', 2000)

        return {
            success: false,
            data: null,
            message: null
        }
    }
}

function jsonRequest({endpoint, body, method, onValidationError, isResponseFile, showSuccess, hideLoader, browserDownload, hideError})
{
    return request({
        endpoint,
        body,
        method,
        onValidationError,
        isResponseFile,
        contentType: 'application/json',
        showSuccess,
        hideLoader,
        browserDownload,
        hideError
    })
}

function formRequest({endpoint, form, onValidationError})
{
    return new Promise((res, rej) => {
        const request = new XMLHttpRequest();
        request.open("POST", `${RisApiEndpoint}${endpoint}`, true);

        request.setRequestHeader('Authorization', 'Bearer '+LocalStorageService.getAuth().token)
        request.onreadystatechange = () => {
            if (request.readyState === 4 && request.status === 200) {
                res(JSON.parse(request.responseText))
            }
        };
        request.send(form);
    })
}

async function login({username, password})
{
    const resp = await Keycloak.auth(username, password);

    return {
        data: {
            token: resp.access_token,
            user: {
                username: 'Test'
            }
        }
    }
}

const Firm = {
    GetList: async function getFirmList()
    {
        return jsonRequest({
            endpoint: `/api/Firm/GetList`,
            method: 'GET'
        })
    },
}

const PacsSystem = {
    Create: async function getPacsSystemList(hospitalId, data)
    {
        return jsonRequest({
            endpoint: `/api/PacsSystem/Create/${hospitalId}`,
            method: 'POST',
            body:data,
            showSuccess: true
        })
    },
    Edit: async function getPacsSystemList(hospitalId, pacsSystemId, data)
    {
        return jsonRequest({
            endpoint: `/api/PacsSystem/Edit/${hospitalId}/${pacsSystemId}`,
            method: 'POST',
            body:data,
            showSuccess: true
        })
    },
    Get: async function getPacsSystem(hospitalId, pacsSystemId)
    {
        return jsonRequest({
            endpoint: `/api/PacsSystem/Get/${hospitalId}/${pacsSystemId}`,
            method: 'GET'
        })
    },
    GetList: async function getPacsSystemList(hospitalId)
    {
        return jsonRequest({
            endpoint: `/api/PacsSystem/GetList/${hospitalId}`,
            method: 'GET'
        })
    },
}

const Hospital = {
    Create: async function getHospitalList(data)
    {
        return jsonRequest({
            endpoint: `/api/Hospital/Create`,
            method: 'POST',
            body:data,
            showSuccess: true
        })
    },
    Edit: async function getHospitalList(hospitalId,data)
    {
        return jsonRequest({
            endpoint: `/api/Hospital/Edit/${hospitalId}`,
            method: 'POST',
            body:data,
            showSuccess: true
        })
    },
    GetList: async function()
    {
        return jsonRequest({
            endpoint: `/api/Hospital/GetList`,
            method: 'GET'
        })
    },
    GetDataGrid: async function getDataGrid(gridInput)
    {
        return jsonRequest({
            endpoint: `/api/Hospital/GetDataGrid`,
            method: 'POST',
            body: gridInput
        })
    },
    Get: async function getHospital(id)
    {
        return jsonRequest({
            endpoint: `/api/Hospital/Get/${id}`,
            method: 'GET'
        })
    },
}

const Modality = {
    GetList: async function()
    {
        return jsonRequest({
            endpoint: `/api/Modality/GetList`,
            method: 'GET'
        })
    }
}

const PermissionGroup = {
    GetList: async function()
    {
        return jsonRequest({
            endpoint: `/api/PermissionGroup/GetList`,
            method: 'GET'
        })
    },
}

const Role = {
    GetList: async function()
    {
        return jsonRequest({
            endpoint: `/api/Role/GetList`,
            method: 'GET'
        })
    },
    Get: async function(roleId)
    {
        return jsonRequest({
            endpoint: `/api/Role/Get/${roleId}`,
            method: 'GET'
        })
    },
    Create: async function(name)
    {
        return jsonRequest({
            endpoint: `/api/Role/Create`,
            method: 'POST',
            body: name,
            showSuccess: true
        })
    },
    Edit: async function(roleId, name)
    {
        return jsonRequest({
            endpoint: `/api/Role/Edit/${roleId}`,
            method: 'POST',
            body: name,
            showSuccess: true
        })
    },
    SetRolePermissions: async function(roleId, permissions)
    {
        return jsonRequest({
            endpoint: `/api/Role/SetRolePermissions/${roleId}`,
            method: 'POST',
            body: permissions,
            showSuccess: true
        })
    }
}

const ReportGenerator = {
    GetReportModel: async (studyId, requestId, reportId) => {
        return jsonRequest({
            endpoint: `/api/ReportGenerator/GetReportModel/${studyId}/${requestId}/${reportId}`,
            method: 'GET',
        });
    },
    GetReportVersionPreview: async (studyId, versionId) => {
        return jsonRequest({
            endpoint: `/api/ReportGenerator/GetReportVersionPreview/${studyId}/${versionId}`,
            method: 'GET',
        });
    },
    GetReportPdf: function (studyId, requestId, reportId) {
        return jsonRequest({
            endpoint: `/api/ReportGenerator/GetReportPdf/${studyId}/${requestId}/${reportId}`,
            method: 'GET',
            isResponseFile: true,
            showSuccess: true,
        });
    },
    GetReportWord: function (studyId, requestId, reportId) {
        return jsonRequest({
            endpoint: `/api/ReportGenerator/GetReportWord/${studyId}/${requestId}/${reportId}`,
            method: 'GET',
            isResponseFile: true,
            showSuccess: true,
        });
    },
    CreatePdfZip: async function (studyIdList) {
        return jsonRequest({
            endpoint: `/api/ReportGenerator/CreatePdfZip`,
            method: 'POST',
            isResponseFile: true,
            body: studyIdList,
            showSuccess: true,
        });
    },
    CreateWordZip: async function (studyIdList) {
        return jsonRequest({
            endpoint: `/api/ReportGenerator/CreateWordZip`,
            method: 'POST',
            isResponseFile: true,
            body: studyIdList,
            showSuccess: true,
        });
    },
    CreatePdfZipByReportIdList: async function (reportIdList) {
        return jsonRequest({
            endpoint: `/api/ReportGenerator/CreatePdfZipByReportIdList`,
            method: 'POST',
            isResponseFile: true,
            body: reportIdList,
            showSuccess: true,
        });
    },
    CreateWordZipByReportIdList: async function (reportIdList) {
        return jsonRequest({
            endpoint: `/api/ReportGenerator/CreateWordZipByReportIdList`,
            method: 'POST',
            isResponseFile: true,
            body: reportIdList,
            showSuccess: true,
        });
    },
};

const Report = {
    CreateSignZip: async function(reportIdList)
    {
        return jsonRequest({
            endpoint: `/api/Report/CreateSignZip`,
            method: 'POST',
            isResponseFile: true,
            body: reportIdList,
            showSuccess: true
        })
    },
    Get: async function(studyId, requestId, reportId)
    {
        return jsonRequest({
            endpoint: `/api/Report/Get/${studyId}/${requestId}/${reportId}`,
            method: 'GET'
        })
    },
    GetPreview: async function(studyId, requestId, reportId)
    {
        return jsonRequest({
            endpoint: `/api/Report/GetPreview/${studyId}/${requestId}/${reportId}`,
            method: 'GET'
        })
    },
    GetDataGrid: async function(input)
    {
        return jsonRequest({
            endpoint: `/api/Report/GetDataGrid`,
            method: 'POST',
            body: input
        })
    },
    Create: async function(studyId, requestId, input)
    {
        return jsonRequest({
            endpoint: `/api/Report/Create/${studyId}/${requestId}`,
            method: 'POST',
            body: input,
            showSuccess: true
        })
    },
    Edit: async function(studyId, requestId, reportId, input)
    {
        return jsonRequest({
            endpoint: `/api/Report/Edit/${studyId}/${requestId}/${reportId}`,
            method: 'POST',
            body: input,
            showSuccess: true
        })
    },
    ConfirmReport: async function(studyId, requestId, reportId)
    {
        return jsonRequest({
            endpoint: `/api/Report/ConfirmReport/${studyId}/${requestId}/${reportId}`,
            method: 'POST',
            showSuccess: true
        })
    },
    RemoveReportConfirmation: async function(studyId, requestId, reportId)
    {
        return jsonRequest({
            endpoint: `/api/Report/RemoveReportConfirmation/${studyId}/${requestId}/${reportId}`,
            method: 'POST',
            showSuccess: true
        })
    },
    SetHbysTransfer: async function(studyId, requestId, reportId, isTransfer)
    {
        return jsonRequest({
            endpoint: `/api/Report/SetHbysTransfer/${studyId}/${requestId}/${reportId}`,
            method: 'POST',
            body: isTransfer
        })
    },
    GetReportFileData: function (studyId, requestId, reportId) {
        return jsonRequest({
            endpoint: `/api/Report/GetReportFileData/${studyId}/${requestId}/${reportId}`,
            method: 'GET',
        })
    },
    GetReportsExcel: (gridInput) => {
        return jsonRequest({
            endpoint: `/api/Report/GetReportsExcel`,
            method: 'POST',
            body: gridInput,
        })
    },
    DownloadReportsExcel: (notificationId) => {
        return jsonRequest({
            endpoint: `/api/Report/DownloadReportsExcel/${notificationId}`,
            method: 'POST',
            isResponseFile: true
        })
    },
    GetReportVersions: (studyId, reportId, gridInput) => {
        return jsonRequest({
            endpoint: `/api/Report/GetReportVersions/${studyId}/${reportId}`,
            method: 'POST',
            body: gridInput,
            isResponseFile: false
        })
    },
}

const ReportTemplate = {
    GetList: async function(studyId, requestId)
    {
        return jsonRequest({
            endpoint: `/api/ReportTemplate/GetList/${studyId}/${requestId}`,
            method: 'GET'
        })
    },
    GetDataGrid: async function(gridInput)
    {
        return jsonRequest({
            endpoint: `/api/ReportTemplate/GetDataGrid`,
            method: 'POST',
            body: gridInput
        })
    },
    Get: async function(templateId)
    {
        return jsonRequest({
            endpoint: `/api/ReportTemplate/Get/${templateId}`,
            method: 'GET'
        })
    },
    Create: async function(data)
    {
        return jsonRequest({
            endpoint: `/api/ReportTemplate/Create`,
            method: 'POST',
            body: data,
            showSuccess: true
        })
    },
    Edit: async function(templateId, data)
    {
        return jsonRequest({
            endpoint: `/api/ReportTemplate/Edit/${templateId}`,
            method: 'POST',
            body: data,
            showSuccess: true
        })
    },
    GetReportTemplateFileData: function (templateId) {
        return jsonRequest({
            endpoint: `/api/ReportTemplate/GetReportTemplateFileData/${templateId}`,
            method: 'GET',
        })
    }
}

const Request = {
    SetRequests: async function(studyId, input)
    {
        return jsonRequest({
            endpoint: `/api/Request/SetRequests/${studyId}`,
            method: 'POST',
            body: input,
            showSuccess: true
        })
    },
    AddRequests: async function(studyId, input)
    {
        return jsonRequest({
            endpoint: `/api/Request/AddRequests/${studyId}`,
            method: 'POST',
            body: input,
            showSuccess: true
        })
    },
    Get: async function(studyId, requestId)
    {
        return jsonRequest({
            endpoint: `/api/Request/Get/${studyId}/${requestId}`,
            method: 'GET'
        })
    },
    DeleteRequest: async function(studyId, requestId)
    {
        return jsonRequest({
            endpoint: `/api/Request/DeleteRequest/${studyId}/${requestId}`,
            method: 'POST',
            showSuccess: true
        })
    },
    RestoreRequest: async function(studyId, requestId)
    {
        return jsonRequest({
            endpoint: `/api/Request/RestoreRequest/${studyId}/${requestId}`,
            method: 'POST',
            showSuccess: true
        })
    },
    GetStudyRequestTableList: async function(studyId)
    {
        return jsonRequest({
            endpoint: `/api/Request/GetStudyRequestTableList/${studyId}`,
            method: 'GET',
        })
    },
    GetOtherRequestTableList: async function(studyId)
    {
        return jsonRequest({
            endpoint: `/api/Request/GetOtherRequestTableList/${studyId}`,
            method: 'GET',
        })
    },
}

const RequestTypeGroup = {
    Create: async function(modalityId, name)
    {
        return jsonRequest({
            endpoint: `/api/RequestTypeGroup/Create/${modalityId}`,
            method: 'POST',
            body: name
        })
    },
    Edit: async function(modalityId, groupId, name)
    {
        return jsonRequest({
            endpoint: `/api/RequestTypeGroup/Edit/${modalityId}/${groupId}`,
            method: 'POST',
            body: name
        })
    },
    GetGroupList: async function(modalityId)
    {
        return jsonRequest({
            endpoint: `/api/RequestTypeGroup/GetGroupList/${modalityId}`,
            method: 'GET'
        })
    },
    GetAvailableRequestTypes: async function(studyId)
    {
        return jsonRequest({
            endpoint: `/api/RequestTypeGroup/GetAvailableRequestTypes/${studyId}`,
            method: 'GET'
        })
    },
}

const RequestType = {
    Create: async function(modalityId, groupId, data)
    {
        return jsonRequest({
            endpoint: `/api/RequestType/Create/${modalityId}/${groupId}`,
            method: 'POST',
            body: data
        })
    },
    Edit: async function(modalityId, groupId, typeId, data)
    {
        return jsonRequest({
            endpoint: `/api/RequestType/Edit/${modalityId}/${groupId}/${typeId}`,
            method: 'POST',
            body: data
        })
    },
    GetListByGroup: async function(groupId)
    {
        return jsonRequest({
            endpoint: `/api/RequestType/GetListByGroup/${groupId}`,
            method: 'GET'
        })
    },
}

const StudyFile = {
    DataGridByStudyId: function (studyId, gridInput) {
        return jsonRequest({
            endpoint: `/api/StudyFile/GetDataGridByStudyId/${studyId}`,
            method: 'POST',
            body: gridInput
        })
    },
    UploadFileUrl: function (studyId) {
        return `${RisApiEndpoint}/api/StudyFile/UploadFile/${studyId}`;
    },
    DownloadFile: function (studyId, fileId) {
        return jsonRequest({
            endpoint: `/api/StudyFile/DownloadFile/${studyId}/${fileId}`,
            method: 'GET',
            isResponseFile: true
        })
    },
    DeleteFile: function (studyId, fileId) {
        return jsonRequest({
            endpoint: `/api/StudyFile/DeleteFile/${studyId}/${fileId}`,
            method: 'POST',
        })
    },
    RestoreFile: function (studyId, fileId) {
        return jsonRequest({
            endpoint: `/api/StudyFile/RestoreFile/${studyId}/${fileId}`,
            method: 'POST',
        })
    }
}

const Study = {
    DataGrid: function(searchInput)
    {
        return jsonRequest({
            endpoint: `/api/Study/GetDataGrid`,
            method: 'POST',
            body: searchInput
        })
    },
    Get: async function(studyId)
    {
        return jsonRequest({
            endpoint: `/api/Study/Get/${studyId}`,
            method: 'GET',
        })
    },
    GetStudyLogs: async function(gridInput)
    {
        return jsonRequest({
            endpoint: `/api/Study/GetStudyLogs`,
            method: 'POST',
            body: gridInput
        })
    },
    DeleteStudies: async function(studyIdList)
    {
        return jsonRequest({
            endpoint: `/api/Study/DeleteStudy`,
            method: 'POST',
            body: studyIdList
        })
    },
    RestoreStudies: async function(studyIdList)
    {
        return jsonRequest({
            endpoint: `/api/Study/RestoreStudy`,
            method: 'POST',
            body: studyIdList
        })
    },
    EditInstitutions: async function(studyId, data)
    {
        return jsonRequest({
            endpoint: `/api/Study/EditInstitutions/${studyId}`,
            method: 'POST',
            body: data
        })
    },
    GetInstitutions: async function()
    {
        return jsonRequest({
            endpoint: `/api/Study/GetInstitutions`,
            method: 'GET',
        })
    },
}

const Weasis = {
    CreateWeasisUri: (studies) => {
        return jsonRequest({
            endpoint: `/api/Weasis/CreateWeasisUri`,
            method: 'POST',
            body: studies
        })
    },
    CreateCDUri: (studies) => {
        return jsonRequest({
            endpoint: `/api/Weasis/CreateCDUri`,
            method: 'POST',
            body: studies
        })
    },
}

const User = {
    Information: async function()
    {
        return jsonRequest({
            endpoint: `/api/User/Information`,
            method: 'GET'
        })
    },
    GetConfig: async function()
    {
        return jsonRequest({
            endpoint: `/api/User/GetConfig`,
            method: 'GET'
        })
    },
    UpdateUserDictionary: async function(dictionary)
    {
        return jsonRequest({
            endpoint: `/api/User/UpdateUserDictionary`,
            method: 'POST',
            body: dictionary,
            showSuccess: true
        })
    }
}

const VoiceRecord = {
    GetList: async function(studyId)
    {
        return jsonRequest({
            endpoint: `/api/VoiceRecord/GetList/${studyId}`,
            method: 'GET',
        })
    },
    GetOtherVoiceRecords: async function(studyId)
    {
        return jsonRequest({
            endpoint: `/api/VoiceRecord/GetOtherVoiceRecords/${studyId}`,
            method: 'GET',
        })
    },
    DeleteVoiceRecord: async function(studyId, recordId)
    {
        return jsonRequest({
            endpoint: `/api/VoiceRecord/DeleteVoiceRecord/${studyId}/${recordId}`,
            method: 'POST',
        })
    },
    RestoreVoiceRecord: async function(studyId, recordId)
    {
        return jsonRequest({
            endpoint: `/api/VoiceRecord/RestoreVoiceRecord/${studyId}/${recordId}`,
            method: 'POST',
        })
    },
    CreateZip: async function(studyIdList)
    {
        return jsonRequest({
            endpoint: `/api/VoiceRecord/CreateZip`,
            method: 'POST',
            isResponseFile: true,
            body: studyIdList,
            showSuccess: true
        })
    },
    GetFile: async function(studyId, recordId)
    {
        return jsonRequest({
            endpoint: `/api/VoiceRecord/GetFile/${studyId}/${recordId}`,
            method: 'GET',
            isResponseFile: true
        })
    },
    UploadBase64: async function(studyId, data)
    {
        return jsonRequest({
            endpoint: `/api/VoiceRecord/UploadBase64/${studyId}`,
            method: 'POST',
            body: data
        })
    }
}

const StudyAnamnez = {
    GetStudyAnamnez: function (studyId) {
        return jsonRequest({
            endpoint: `/api/StudyAnamnez/GetStudyAnamnez/${studyId}`,
            method: `GET`
        })
    },
    EditStudyAnamnez: function (studyId, data) {
        return jsonRequest({
            endpoint: `/api/StudyAnamnez/EditStudyAnamnez/${studyId}`,
            method: `POST`,
            body: data,
            showSuccess: true
        })
    },
    EditClinicalInformation: function (studyId, data) {
        return jsonRequest({
            endpoint: `/api/StudyAnamnez/EditClinicalInformation/${studyId}`,
            method: `POST`,
            body: data,
            showSuccess: true
        })
    },
}

const StudyDetails = {
    AddRead: function (data) {
        return jsonRequest({
            endpoint: `/api/StudyDetail/AddRead`,
            method: 'POST',
            body: data,
            showSuccess: true
        })
    },
    DeleteReadUser: function (studyIdList, userId) {
        if(!userId)
            return jsonRequest({
                endpoint: `/api/StudyDetail/RemoveRead`,
                method: 'DELETE',
                body: studyIdList,
                showSuccess: true
            })

        return jsonRequest({
            endpoint: `/api/StudyDetail/DeleteReadUser/${userId}`,
            method: 'DELETE',
            body: studyIdList,
            showSuccess: true
        })
    },
    RemoveRead: function (studyIdList) {
        return jsonRequest({
            endpoint: `/api/StudyDetail/RemoveRead`,
            method: 'DELETE',
            body: studyIdList,
            showSuccess: true
        })
    },
    SetStudyType: function (data) {
        return jsonRequest({
            endpoint: `/api/StudyDetail/SetStudyType`,
            method: 'POST',
            body: data,
            showSuccess: true
        })
    },
    GetReadUsersOfStudy: function (studyIdList) {
        return jsonRequest({
            endpoint: `/api/StudyDetail/GetReadUsersOfStudy`,
            method: 'POST',
            body: studyIdList
        })
    }
}

const ReportOutputTemplate = {
    UploadReportOutputTemplate: function(hospitalId, data) {
        return jsonRequest({
            endpoint: `/api/ReportOutputTemplate/UploadReportOutputTemplate/${hospitalId}`,
            method: 'POST',
            body: data,
            showSuccess: true
        })
    },
    DownloadReportOutputTemplate: function(id, hospitalId) {
        return jsonRequest({
            endpoint: `/api/ReportOutputTemplate/DownloadReportOutputTemplate/${hospitalId}/${id}`,
            method: 'GET',
            isResponseFile: true
        })
    },
}

const Dashboard = {
    HospitalStatistics: function (data) {
        return jsonRequest({
            endpoint: `/api/Dashboard/HospitalStatistics`,
            method: 'POST',
            body: data
        })
    },
    ReadUserStatistics: function (data) {
        return jsonRequest({
            endpoint: `/api/Dashboard/ReadUserStatistics`,
            method: 'POST',
            body: data
        })
    },
    WrittenUserStatistics: function (data) {
        return jsonRequest({
            endpoint: `/api/Dashboard/WrittenUserStatistics`,
            method: 'POST',
            body: data
        })
    }
}

function downloadFile(url, fileName)
{
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    fileName && link.setAttribute('download', fileName);
    link.click();
}

const StudyDashboard = {
    ByHospital: function (data) {
        return jsonRequest({
            endpoint: `/api/StudyDashboard/GetDashboardByHospital`,
            method: 'POST',
            body: data
        })
    },
    ByHospitalModality: function (data) {
        return jsonRequest({
            endpoint: `/api/StudyDashboard/GetDashboardByHospitalModality`,
            method: 'POST',
            body: data
        })
    },
    ByHospitalModalityUser: function (data) {
        return jsonRequest({
            endpoint: `/api/StudyDashboard/GetDashboardByHospitalModalityUser`,
            method: 'POST',
            body: data
        })
    },
    ByHospitalModalityCurrentUser: function (data) {
        return jsonRequest({
            endpoint: `/api/StudyDashboard/GetDashboardByHospitalModalityCurrentUser`,
            method: 'POST',
            body: data
        })
    },
}

const Dicom = {
    UploadDicom: (data) => {
        return jsonRequest({
            endpoint: `/api/Dicom/UploadDicom`,
            method: 'POST',
            body: data,
            showSuccess: true
        })
    },
    GetStudyZip: (studyId, compress, dicomDir) => {
        return jsonRequest({
            endpoint: `/api/Dicom/GetStudyZip/${studyId}/${compress}/${dicomDir}`,
            method: 'GET',
            showSuccess: false,
            isResponseFile: true,
            hideLoader: true,
            hideError: true
        })
    },
    GetStudyDicomMetadata: (studyId) => {
        return jsonRequest({
            endpoint: `/api/Dicom/GetStudyDicomMetadata/${studyId}`,
            method: 'GET',
            showSuccess: false,
        })
    },
    GetSeriesMetadata: (studyId) => {
        return jsonRequest({
            endpoint: `/api/Dicom/GetSeriesMetadata/${studyId}`,
            method: 'GET',
            showSuccess: false,
        })
    },
    GetDicomFile: (studyId, studyUid, seriesUid, instanceUid) => {
        return jsonRequest({
            endpoint: `/api/Dicom/GetDicomFile/${studyId}/${studyUid}/${seriesUid}/${instanceUid}`,
            method: 'GET',
            showSuccess: false,
            isResponseFile: true,
            hideLoader: true,
            hideError: true
        })
    },
    GetExportAETitleList: (data) => {
        return jsonRequest({
            endpoint: `/api/Dicom/GetExportAETitleList`,
            method: 'POST',
            showSuccess: false,
            body: data
        })
    },
    ExportStudies: (exportName, data) => {
        return jsonRequest({
            endpoint: `/api/Dicom/ExportStudies/${exportName}`,
            method: 'POST',
            showSuccess: true,
            body: data
        })
    },
}

const StudyUser = {
    AssignDoctorUser: (data) => {
        return jsonRequest({
            endpoint: `/api/StudyUser/AssignDoctorUser`,
            method: 'POST',
            body: data,
            showSuccess: true,
        });
    },
    RemoveDoctorUser: (data) => {
        return jsonRequest({
            endpoint: `/api/StudyUser/RemoveDoctorUser`,
            method: 'POST',
            body: data,
            showSuccess: true,
        });
    },
    AssignReporterUser: (data) => {
        return jsonRequest({
            endpoint: `/api/StudyUser/AssignReporterUser`,
            method: 'POST',
            body: data,
            showSuccess: true,
        });
    },
    RemoveReporterUser: (data) => {
        return jsonRequest({
            endpoint: `/api/StudyUser/RemoveReporterUser`,
            method: 'POST',
            body: data,
            showSuccess: true,
        });
    },
};

const Auth = {
    Login: (data) => {
        return jsonRequest({
            endpoint: `/api/Auth/Login`,
            method: 'POST',
            body: {
                ...data,
                grant_type: 'password'
            },
            showSuccess: true
        })
    },
}

const UserManager = {
    CreateUser: async function(data)
    {
        return jsonRequest({
            endpoint: `/api/UserManager/CreateUser`,
            method: 'POST',
            body: data,
            showSuccess: true
        })
    },
    EditUser: async function(userId, data)
    {
        return jsonRequest({
            endpoint: `/api/UserManager/EditUser/${userId}`,
            method: 'POST',
            body: data,
            showSuccess: true
        })
    },
    SetHospitals: async function(userId, data)
    {
        return jsonRequest({
            endpoint: `/api/UserManager/SetHospitals/${userId}`,
            method: 'POST',
            body: data,
            showSuccess: true
        })
    },
    GetUserDataGrid: async function(gridInput)
    {
        return jsonRequest({
            endpoint: `/api/UserManager/GetUserDataGrid`,
            method: 'POST',
            body: gridInput
        })
    },
    GetUserList: async function()
    {
        return jsonRequest({
            endpoint: `/api/UserManager/GetUserList`,
            method: 'GET',
        })
    },
    GetUser: async function(userId)
    {
        return jsonRequest({
            endpoint: `/api/UserManager/GetUser/${userId}`,
            method: 'GET'
        })
    },
    GetDoctorSignImage: async function(userId)
    {
        return jsonRequest({
            endpoint: `/api/UserManager/GetDoctorSignImage/${userId}`,
            method: 'GET',
            isResponseFile: true
        })
    },
    GetHospitalsOfUser: async function()
    {
        return jsonRequest({
            endpoint: `/api/UserManager/GetHospitalsOfUser`,
            method: 'GET'
        })
    },
    GetInstitutionsOfUser: async function()
    {
        return jsonRequest({
            endpoint: `/api/UserManager/GetInstitutionsOfUser`,
            method: 'GET'
        })
    },
    GetReadableUsers: async function()
    {
        return jsonRequest({
            endpoint: `/api/UserManager/GetReadableUsers`,
            method: 'GET'
        })
    },
    GetWritableUsers: async function()
    {
        return jsonRequest({
            endpoint: `/api/UserManager/GetWritableUsers`,
            method: 'GET'
        })
    },
    SetUsersToHospital: async function(data)
    {
        return jsonRequest({
            endpoint: `/api/UserManager/SetUsersToHospital`,
            method: 'POST',
            body: data
        })
    },
    GetUsersOfHospital: async function(hospitalId)
    {
        return jsonRequest({
            endpoint: `/api/UserManager/GetUsersOfHospital/${hospitalId}`,
            method: 'GET'
        })
    },
    GetDoctorsOfHospital: async function(hospitalId)
    {
        return jsonRequest({
            endpoint: `/api/UserManager/GetDoctorsOfHospital/${hospitalId}`,
            method: 'GET',
        })
    },
    SetDoctorsToHospital: async function(hospitalId, data)
    {
        return jsonRequest({
            endpoint: `/api/UserManager/SetDoctorsToHospital/${hospitalId}`,
            method: 'POST',
            body: data
        })
    },
}

const HospitalGroup = {
    Create: async function(data)
    {
        return jsonRequest({
            endpoint: `/api/HospitalGroup/Create`,
            method: 'POST',
            body: data
        })
    },
    Edit: async function(groupId, data)
    {
        return jsonRequest({
            endpoint: `/api/HospitalGroup/Edit/${groupId}`,
            method: 'POST',
            body: data
        })
    },
    Delete: async function(groupId)
    {
        return jsonRequest({
            endpoint: `/api/HospitalGroup/Delete/${groupId}`,
            method: 'DELETE',
        })
    },
    GetById: async function(groupId)
    {
        return jsonRequest({
            endpoint: `/api/HospitalGroup/GetById/${groupId}`,
            method: 'GET',
        })
    },
    GetList: async function()
    {
        return jsonRequest({
            endpoint: `/api/HospitalGroup/GetList`,
            method: 'GET',
        })
    },
    SetHospitalsToGroup: async function(data)
    {
        return jsonRequest({
            endpoint: `/api/HospitalGroup/SetHospitalsToGroup`,
            method: 'POST',
            body: data
        })
    },
}

const HL7Client = {
    CreateClient: async function(data)
    {
        return jsonRequest({
            endpoint: `/api/HL7Client/CreateClient`,
            method: 'POST',
            body: data
        })
    },
    EditClient: async function(id, data)
    {
        return jsonRequest({
            endpoint: `/api/HL7Client/EditClient/${id}`,
            method: 'PUT',
            body: data
        })
    },
    RefreshToken: async function(id)
    {
        return jsonRequest({
            endpoint: `/api/HL7Client/RefreshToken/${id}`,
            method: 'PUT',
        })
    },
    DeleteClient: async function(id)
    {
        return jsonRequest({
            endpoint: `/api/HL7Client/DeleteClient/${id}`,
            method: 'DELETE',
        })
    },
    GetHL7ClientDataGrid: async function(hospitalId)
    {
        return jsonRequest({
            endpoint: `/api/HL7Client/GetHL7ClientList/${hospitalId}`,
            method: 'GET',
        })
    },
}

const HL7Service = {
    GetDataGrid: async (gridInput) =>
    {
        return jsonRequest({
            endpoint: `/api/HL7/GetDataGrid`,
            method: 'POST',
            body: gridInput
        })
    },
    GetHL7ClientDataGrid: async function(messageId)
    {
        return jsonRequest({
            endpoint: `/api/HL7/GetHL7FileMessage/${messageId}`,
            method: 'GET',
        })
    },
    CreateReportMessage: async function(reportIdList)
    {
        return jsonRequest({
            endpoint: `/api/HL7/CreateReportMessage`,
            method: 'POST',
            body: reportIdList
        })
    },
    SendReport: async function(messageIdList)
    {
        return jsonRequest({
            endpoint: `/api/HL7/SendReport`,
            method: 'POST',
            body: messageIdList
        })
    },
    ManualMatchHL7: async function(messageId, studyId)
    {
        return jsonRequest({
            endpoint: `/api/HL7/ManualMatchHL7/${messageId}/${studyId}`,
            method: 'POST',
            showSuccess: true
        })
    },
    ManualProcess: async function(messageId)
    {
        return jsonRequest({
            endpoint: `/api/HL7/ManualProcess/${messageId}`,
            method: 'POST',
            showSuccess: true
        })
    },
    AddManualHL7: async function(hospitalId, data)
    {
        return jsonRequest({
            endpoint: `/api/HL7/AddManualHL7Message/${hospitalId}`,
            method: 'POST',
            showSuccess: true,
            body: data
        })
    },
}

const Notification = {
    GetNotificationList: async function(page)
    {
        return jsonRequest({
            endpoint: `/api/Notification/GetNotificationList/${page}`,
            method: 'GET',
            hideLoader: true,
            hideError: true
        })
    },
    SetRead: async function(id)
    {
        return jsonRequest({
            endpoint: `/api/Notification/SetRead/${id}`,
            method: 'PUT',
            hideLoader: true,
            hideError: true
        })
    },
    RemoveItem: async function(id)
    {
        return jsonRequest({
            endpoint: `/api/Notification/RemoveItem/${id}`,
            method: 'DELETE',
            hideLoader: true,
            hideError: true
        })
    },
    Clear: async function()
    {
        return jsonRequest({
            endpoint: `/api/Notification/Clear`,
            method: 'DELETE',
            hideLoader: true,
            hideError: true
        })
    },
}

const Sharing = {
    ShareStudyDicom: async function(studyId)
    {
        return jsonRequest({
            endpoint: `/api/Sharing/ShareStudyDicom`,
            method: 'POST',
            showSuccess: true,
            body: studyId
        })
    },
    DownloadStudyZip: (notificationId, token) => {
        return jsonRequest({
            endpoint: `/api/Sharing/DownloadStudyZip/${notificationId}`,
            method: 'GET',
            isResponseFile: true
        })
    },
}

const Teletip = {
    MakeKos: async (data) =>
    {
        return jsonRequest({
            endpoint: `/api/Teletip/MakeKos`,
            method: 'POST',
            showSuccess: true,
            body: data
        })
    },
    SendKos: async (teletipIdList) =>
    {
        return jsonRequest({
            endpoint: `/api/Teletip/SendKos`,
            method: 'POST',
            body: teletipIdList,
            showSuccess: true,
        })
    },
    GetTeletipGrid: async (gridInput) =>
    {
        return jsonRequest({
            endpoint: `/api/Teletip/GetTeletipGrid`,
            method: 'POST',
            body: gridInput
        })
    },
}

const ApiService = {
    login,
    RisApiEndpoint,
    setLogout,
    downloadFile,
    getAuthorization,
    Auth,
    Hospital,
    PacsSystem,
    Firm,
    Modality,
    Report,
    ReportGenerator,
    ReportTemplate,
    Request,
    RequestType,
    RequestTypeGroup,
    Study,
    Dicom,
    StudyFile,
    Weasis,
    User,
    VoiceRecord,
    StudyAnamnez,
    StudyDetails,
    ReportOutputTemplate,
    Dashboard,
    StudyDashboard,
    Role,
    PermissionGroup,
    UserManager,
    StudyUser,
    HospitalGroup,
    HL7Client,
    HL7Service,
    Notification,
    Sharing,
    Teletip
}

export default ApiService;
