import {Button, Dropdown, Menu, Space, Tooltip} from "antd";
import ApiService from "../../../core/service/api-service";
import {DownOutlined} from "@ant-design/icons";
import Flex from "../../../core/components/Flex";
import {useDataGrid} from "../../../core/components/data-grid/DataGridContext";
import {useTranslation} from "react-i18next";
import {PERMISSIONS, useUser} from "../../../core/context/UserContext";
import {useModal} from "../../../core/context/ModalContext";
import StudyCreateModal from "../StudyCreateModal";
import {useEffect, useState} from "react";
import useKeypress from "react-use-keypress";
import {HubConnection, HubConnectionBuilder} from "@microsoft/signalr";
import StudyUserAssignModal from "../StudyUserAssignModal";
import Colors from "../../../core/style/Colors";
import useFocusListener from "../../../core/components/FocusListener";
import {v4} from "uuid";
import StudyTypeEnum from "../../../enums/StudyTypeEnum";
import StudyReadUsersModal from "../StudyReadUsersModal";
import ShareEmailModal from "../ShareEmailModal";
import moment from "moment";
import { FaCompactDisc } from "react-icons/fa";
import StudyExportModal from "../StudyExportModal";

const colors = [
    {color: '#ced6e0', text: 'DELETED-ROW'},
    {color: '#fab1a0', text: 'EMERGENCY-ROW'},
    {color: '#f6e58d', text: 'NO-REPORT-ROW'},
    {color: '#55efc4', text: 'HAS-REPORT-ROW'},
    {color: '#dff9fb', text: 'NOT-CONFIRMED-ROW'},
    {color: Colors.selectedRow, text: 'SELECTED-ROW'},
]

export default function StudyGridTools()
{
    const {t} = useTranslation();
    const {selectedRows, refreshDataGrid, tableId} = useDataGrid();
    const {hasPermission, user} = useUser();
    const {openModal, closeModal, modal} = useModal();
    const isFocus = useFocusListener({elementId: tableId})

    useKeypress(['t', 'd', 'v', 'w'], async (e) => {
        if(!isFocus)
            return

        if(e)
        {
            switch (e.key)
            {
                case 't': {
                    await setRead()
                    break;
                }
                case 'd': {
                    await openViewer();
                    break;
                }
                case 'v': {
                    await downloadVoiceRecords();
                    break;
                }
                case 'w': {
                    openWebViewer()
                    break;
                }
                default: {
                    return;
                }
            }
        }
    })

    const openWebViewer = () => {
        if(selectedRows.length > 0) {
            const studies = selectedRows.map(x => x.id).join('_')
            const url = `/app/study/viewer?studies=${studies}`
            window.open(url, '_')
        }
    }

    const sendEmail = async () => {
        if(selectedRows.length > 0) {
            openModal(t('Send Email'), (
                <ShareEmailModal
                    onFinish={async (form) => {
                        const res = await ApiService.Sharing.ShareStudyDicom({
                            studyIdList: selectedRows.map(x => x.id),
                            ...form
                        })

                        if(res.success) {
                            closeModal()
                        }
                    }}
                />
            ))
        }
    }

    const makeKos = async () => {
        if(selectedRows.length > 0) {
            const res = await ApiService.Teletip.MakeKos(selectedRows.map(x => ({studyId: x.id})))
        }
    }

    const downloadStudies = async () => {
        if(selectedRows.length > 0) {
            const res = await ApiService.Sharing.ShareStudyDicom({
                studyIdList: selectedRows.map(x => x.id)
            })
        }
    }

    const deleteReadUser = async () => {
        openModal(t('Study Read User'), (
            <StudyReadUsersModal
                studyIdList={selectedRows.map(x => x.id)}
                onSelected={async (userId) => {
                    const response = await ApiService.StudyDetails.DeleteReadUser(selectedRows.map(x => x.id), userId)
                    if (response.success)
                    {
                        refreshDataGrid()
                        closeModal()
                    }
                }}
            />
        ))
    }

    const removeRead = async () => {
        const response = await ApiService.StudyDetails.RemoveRead(selectedRows.map(x => x.id))
        if (response.success)
        {
            refreshDataGrid()
            closeModal()
        }
    }

    const setRead = async () => {
        if(!selectedRows || selectedRows.length === 0)
            return

        const read = async (userId) => {
            const response = await ApiService.StudyDetails.AddRead({
                studyIdList: selectedRows.map(x => x.id),
                isRead: true,
                readUserId: userId
            })

            if (response.success)
            {
                refreshDataGrid()
                return true
            }

            return false
        }

        if(hasPermission(PERMISSIONS.STUDY_SET_READ_USER)) {
            openModal(t('Doctors'), (
                <StudyUserAssignModal
                    selectDoctor={true}
                    onSelected={async ({doctor, reporter}) => {
                        if(await read(doctor))
                            closeModal()
                    }}
                />
            ))
        }
        else {
            if(await read(user?.data?.id))
                closeModal()
        }
    }

    const downloadVoiceRecords = async () => {
        if(!selectedRows || selectedRows.length === 0)
            return

        const response = await ApiService.VoiceRecord.CreateZip(selectedRows.map(r => r.id));
        if(response.success)
        {
            ApiService.downloadFile(response.data, response.fileName);
        }
    }

    const openViewer = async () => {
        if(!selectedRows || selectedRows.length === 0)
            return

        const idList = selectedRows.map(r => {
            return r.id;
        });

        const response = await ApiService.Weasis.CreateWeasisUri(idList)
        if(response.success)
        {
            window.open(response.data, '_blank');
        }
    }

    const burnCD = async () => {
        if(!selectedRows || selectedRows.length === 0)
            return

        const idList = selectedRows.map(r => {
            return r.id;
        });

        const response = await ApiService.Weasis.CreateCDUri(idList)
        if(response.success)
        {
            window.open(response.data, '_blank');
        }
    }

    const exportStudy = async () => {
        openModal(t('StudyExport'), (
            <StudyExportModal
                studyIdList={selectedRows.map(x => x.id)}
                onExport={async (exportName, studyIdList) => {
                    const res = await ApiService.Dicom.ExportStudies(exportName, studyIdList)
                    if(res.success) {
                        closeModal()
                    }
                }}
            />
        ))
    }

    return (
        <Flex
            flexDirection={'row'}
            alignItems={'center'}
            style={{ gap: 8 }}
            flexWrap={'wrap'}
        >
            <div>
                <Flex flexDirection={'row'}>
                    {colors.map((color) => {
                        return (
                            <div key={color.text}>
                                <Tooltip title={t(color.text)}>
                                    <div
                                        style={{
                                            width: 16,
                                            height: 16,
                                            backgroundColor: color.color,
                                            marginRight: 4,
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        );
                    })}
                </Flex>
            </div>
            <Dropdown
                disabled={selectedRows.length === 0}
                trigger={'click'}
                overlay={
                    <Menu>
                        {/*{(hasPermission(PERMISSIONS.STUDY_SET_EMERGENCY) ||*/}
                        {/*    hasPermission(*/}
                        {/*        PERMISSIONS.STUDY_REMOVE_EMERGENCY*/}
                        {/*    )) && (*/}
                        {/*    <>*/}
                        {/*        <Menu.Item key={'set-emergency-menu'}>*/}
                        {/*            <div*/}
                        {/*                style={{*/}
                        {/*                    display: 'flex',*/}
                        {/*                    alignItems: 'center',*/}
                        {/*                    gap: 4,*/}
                        {/*                }}*/}
                        {/*            >*/}
                        {/*                <div style={{ width: 100 }}>*/}
                        {/*                    {t('Emergency')}*/}
                        {/*                </div>*/}
                        {/*                <>*/}
                        {/*                    <Button*/}
                        {/*                        size={'small'}*/}
                        {/*                        style={{ width: 80 }}*/}
                        {/*                        onClick={async (e) => {*/}
                        {/*                            const response =*/}
                        {/*                                await ApiService.StudyDetails.SetStudyType(*/}
                        {/*                                    {*/}
                        {/*                                        studyIdList:*/}
                        {/*                                            selectedRows.map(*/}
                        {/*                                                (r) => {*/}
                        {/*                                                    return r.id;*/}
                        {/*                                                }*/}
                        {/*                                            ),*/}
                        {/*                                        studyType:*/}
                        {/*                                            StudyTypeEnum.EMERGENCY,*/}
                        {/*                                    }*/}
                        {/*                                );*/}

                        {/*                            if (response.success) {*/}
                        {/*                                refreshDataGrid();*/}
                        {/*                            }*/}
                        {/*                        }}*/}
                        {/*                    >*/}
                        {/*                        {t('Emergency')}*/}
                        {/*                    </Button>*/}
                        {/*                    <Button*/}
                        {/*                        size={'small'}*/}
                        {/*                        style={{ width: 80 }}*/}
                        {/*                        onClick={async (e) => {*/}
                        {/*                            const response =*/}
                        {/*                                await ApiService.StudyDetails.SetStudyType(*/}
                        {/*                                    {*/}
                        {/*                                        studyIdList:*/}
                        {/*                                            selectedRows.map(*/}
                        {/*                                                (r) => {*/}
                        {/*                                                    return r.id;*/}
                        {/*                                                }*/}
                        {/*                                            ),*/}
                        {/*                                        studyType:*/}
                        {/*                                            StudyTypeEnum.INPATIENT,*/}
                        {/*                                    }*/}
                        {/*                                );*/}

                        {/*                            if (response.success) {*/}
                        {/*                                refreshDataGrid();*/}
                        {/*                            }*/}
                        {/*                        }}*/}
                        {/*                    >*/}
                        {/*                        {t('Inpatient')}*/}
                        {/*                    </Button>*/}
                        {/*                    <Button*/}
                        {/*                        size={'small'}*/}
                        {/*                        style={{ width: 80 }}*/}
                        {/*                        onClick={async (e) => {*/}
                        {/*                            const response =*/}
                        {/*                                await ApiService.StudyDetails.SetStudyType(*/}
                        {/*                                    {*/}
                        {/*                                        studyIdList:*/}
                        {/*                                            selectedRows.map(*/}
                        {/*                                                (r) => {*/}
                        {/*                                                    return r.id;*/}
                        {/*                                                }*/}
                        {/*                                            ),*/}
                        {/*                                        studyType:*/}
                        {/*                                            StudyTypeEnum.ROUTINE,*/}
                        {/*                                    }*/}
                        {/*                                );*/}

                        {/*                            if (response.success) {*/}
                        {/*                                refreshDataGrid();*/}
                        {/*                            }*/}
                        {/*                        }}*/}
                        {/*                    >*/}
                        {/*                        {t('Routine')}*/}
                        {/*                    </Button>*/}
                        {/*                </>*/}
                        {/*            </div>*/}
                        {/*        </Menu.Item>*/}
                        {/*    </>*/}
                        {/*)}*/}

                        {(hasPermission(PERMISSIONS.STUDY_SET_READ) ||
                            hasPermission(PERMISSIONS.STUDY_REMOVE_READ)) && (
                            <Menu.Item key={'set-read-menu'}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 4,
                                    }}
                                >
                                    <div style={{ width: 100 }}>
                                        {t('Read')}
                                    </div>
                                    {hasPermission(
                                        PERMISSIONS.STUDY_SET_READ
                                    ) && (
                                        <Button
                                            size={'small'}
                                            style={{ width: 80 }}
                                            onClick={async (e) => {
                                                await setRead();
                                            }}
                                        >
                                            {t('Read')} (T)
                                        </Button>
                                    )}
                                    {/*{hasPermission(PERMISSIONS.STUDY_REMOVE_READ) && (*/}
                                    {/*    <Button*/}
                                    {/*        size={'small'}*/}
                                    {/*        style={{width: 80}}*/}
                                    {/*        onClick={async (e) => {*/}
                                    {/*            await removeRead()*/}
                                    {/*        }}*/}
                                    {/*    >*/}
                                    {/*        {t('Not Read')}*/}
                                    {/*    </Button>*/}
                                    {/*)}*/}
                                    {hasPermission(
                                        PERMISSIONS.STUDY_REMOVE_READ
                                    ) && (
                                        <Button
                                            size={'small'}
                                            style={{ width: 80 }}
                                            onClick={async (e) => {
                                                await deleteReadUser();
                                            }}
                                        >
                                            {t('Not Read')}
                                        </Button>
                                    )}
                                </div>
                            </Menu.Item>
                        )}

                        {(hasPermission(PERMISSIONS.STUDY_ASSIGN_DOCTOR_USER) ||
                            hasPermission(
                                PERMISSIONS.STUDY_ASSIGN_REPORTER_USER
                            )) && (
                            <Menu.Item
                                key={'assign-user'}
                                onClick={() => {
                                    openModal(
                                        t('Assign User'),
                                        <StudyUserAssignModal
                                            selectDoctor={hasPermission(
                                                PERMISSIONS.STUDY_ASSIGN_DOCTOR_USER
                                            )}
                                            selectReporter={hasPermission(
                                                PERMISSIONS.STUDY_ASSIGN_REPORTER_USER
                                            )}
                                            onSelected={async ({
                                                doctor,
                                                reporter,
                                            }) => {
                                                let resDoctor = null
                                                let resReporter = null

                                                if (
                                                    hasPermission(
                                                        PERMISSIONS.STUDY_ASSIGN_DOCTOR_USER
                                                    )
                                                ) {
                                                    resDoctor =
                                                        await ApiService.StudyUser.AssignDoctorUser(
                                                            {
                                                                studyIdList:
                                                                    selectedRows.map(
                                                                        (r) =>
                                                                            r.id
                                                                    ),
                                                                doctorUserId:
                                                                    doctor,
                                                            }
                                                        );
                                                }
                                                    
                                                if (
                                                    hasPermission(
                                                        PERMISSIONS.STUDY_ASSIGN_REPORTER_USER
                                                    )
                                                ) {
                                                    resReporter =
                                                        await ApiService.StudyUser.AssignReporterUser(
                                                            {
                                                                studyIdList:
                                                                    selectedRows.map(
                                                                        (r) =>
                                                                            r.id
                                                                    ),
                                                                reporterUserId:
                                                                    reporter,
                                                            }
                                                        );
                                                }
                                                    

                                                if (
                                                    resDoctor.success ||
                                                    resReporter.success
                                                ) {
                                                    refreshDataGrid();
                                                    closeModal();
                                                }
                                            }}
                                        />
                                    );
                                }}
                            >
                                <div>{t('Assign User')}</div>
                            </Menu.Item>
                        )}
                        {hasPermission(
                            PERMISSIONS.STUDY_ASSIGN_DOCTOR_USER
                        ) && (
                            <Menu.Item
                                key={'remove-doctor'}
                                onClick={async () => {
                                    const res =
                                        await ApiService.StudyUser.RemoveDoctorUser(
                                            {
                                                studyIdList: selectedRows.map(
                                                    (r) => r.id
                                                ),
                                                doctorUserId: 'remove',
                                                reporterUserId: null,
                                            }
                                        );

                                    if (res.success) {
                                        refreshDataGrid();
                                        closeModal();
                                    }
                                }}
                            >
                                <div>{t('Remove Doctor')}</div>
                            </Menu.Item>
                        )}
                        {hasPermission(
                            PERMISSIONS.STUDY_ASSIGN_REPORTER_USER
                        ) && (
                            <Menu.Item
                                key={'remove-reporter'}
                                onClick={async () => {
                                    const res =
                                        await ApiService.StudyUser.RemoveReporterUser(
                                            {
                                                studyIdList: selectedRows.map(
                                                    (r) => r.id
                                                ),
                                                doctorUserId: null,
                                                reporterUserId: 'remove',
                                            }
                                        );

                                    if (res.success) {
                                        refreshDataGrid();
                                        closeModal();
                                    }
                                }}
                            >
                                <div>{t('Remove Reporter')}</div>
                            </Menu.Item>
                        )}
                        {hasPermission(PERMISSIONS.VOICE_RECORD_DOWNLOAD) && (
                            <Menu.Item key={'5'} onClick={downloadVoiceRecords}>
                                {t('Download Voice Records') + ' (V)'}
                            </Menu.Item>
                        )}
                        {hasPermission(PERMISSIONS.REPORT_DOWNLOAD_PDF) && (
                            <Menu.Item
                                key={'6'}
                                onClick={async () => {
                                    const response =
                                        await ApiService.ReportGenerator.CreatePdfZip(
                                            selectedRows.map((r) => r.id)
                                        );
                                    if (response.success) {
                                        ApiService.downloadFile(
                                            response.data,
                                            response.fileName
                                        );
                                    }
                                }}
                            >
                                {t('Download PDF')}
                            </Menu.Item>
                        )}
                        {hasPermission(PERMISSIONS.REPORT_DOWNLOAD_WORD) && (
                            <Menu.Item
                                key={'Download Word'}
                                onClick={async () => {
                                    const response =
                                        await ApiService.ReportGenerator.CreateWordZip(
                                            selectedRows.map((r) => r.id)
                                        );
                                    if (response.success) {
                                        ApiService.downloadFile(
                                            response.data,
                                            response.fileName
                                        );
                                    }
                                }}
                            >
                                {t('Download Word')}
                            </Menu.Item>
                        )}
                        {hasPermission(PERMISSIONS.STUDY_DELETE_OR_RESTORE) && (
                            <Menu.Item
                                key={'Delete Studies'}
                                onClick={async () => {
                                    const response =
                                        await ApiService.Study.DeleteStudies(
                                            selectedRows.map((r) => r.id)
                                        );
                                    if (response.success) {
                                        await refreshDataGrid();
                                    }
                                }}
                            >
                                {t('Delete Studies')}
                            </Menu.Item>
                        )}
                        {hasPermission(PERMISSIONS.STUDY_DELETE_OR_RESTORE) && (
                            <Menu.Item
                                key={'Restore Studies'}
                                onClick={async () => {
                                    const response =
                                        await ApiService.Study.RestoreStudies(
                                            selectedRows.map((r) => r.id)
                                        );
                                    if (response.success) {
                                        await refreshDataGrid();
                                    }
                                }}
                            >
                                {t('Restore Studies')}
                            </Menu.Item>
                        )}
                        {hasPermission(PERMISSIONS.STUDY_OPEN_VIEWER) && (
                            <Menu.Item
                                key={'Open Web Viewer'}
                                onClick={async () => {
                                    await openWebViewer();
                                }}
                            >
                                {t('Open Web Viewer') + ' (W)'}
                            </Menu.Item>
                        )}
                        {hasPermission(PERMISSIONS.STUDY_OPEN_VIEWER) && (
                            <Menu.Item
                                key={t('downloadStudies')}
                                onClick={downloadStudies}
                            >
                                {t('Download Dicom Zip')}
                            </Menu.Item>
                        )}
                        {hasPermission(PERMISSIONS.SHARE_STUDY_BY_EMAIL) && (
                            <Menu.Item key={'Email'} onClick={sendEmail}>
                                {t('Email')}
                            </Menu.Item>
                        )}
                        {hasPermission(PERMISSIONS.TELETIP_MANAGER) && (
                            <Menu.Item key={'MakeKOS'} onClick={makeKos}>
                                {t('Make KOS')}
                            </Menu.Item>
                        )}
                        <Menu.Item
                            key={'copy-info'}
                            onClick={async () => {
                                const texts = selectedRows?.map((row) =>
                                    [
                                        `ID: ${row.id}`,
                                        `${t('Patient ID')}: ${
                                            row.dicomPatientId
                                        }`,
                                        `${t('Patient Name')}: ${
                                            row.patientName
                                        }`,
                                        `${t('Accession Number')}: ${
                                            row.accessionNumber
                                        }`,
                                        `${t('Modality')}: ${row.modality}`,
                                        `${t('Description')}: ${
                                            row.studyDescription
                                        }`,
                                        `${t('Study Date')}: ${moment(
                                            row.studyDate
                                        ).format('DD-MM-yyyy HH:mm')}`,
                                        `${t('Received Date')}: ${moment(
                                            row.receivedDate
                                        ).format('DD-MM-yyyy HH:mm')}`,
                                    ].join('\n')
                                );

                                const text = texts.join(
                                    '\n--------------------------\n'
                                );

                                await navigator.clipboard.writeText(text);
                            }}
                        >
                            <div>{t('Copy Info')}</div>
                        </Menu.Item>
                    </Menu>
                }
            >
                <Button size={'small'} type={'primary'}>
                    {t('Operations')}
                    <DownOutlined />
                </Button>
            </Dropdown>

            {hasPermission(PERMISSIONS.STUDY_OPEN_VIEWER) && (
                <Button
                    size={'small'}
                    disabled={selectedRows.length === 0}
                    onClick={openViewer}
                >
                    {t('Viewer') + ' (D)'}
                </Button>
            )}

            {hasPermission(PERMISSIONS.STUDY_OPEN_VIEWER) && user?.config?.enableCDBurn && (
                <Button
                    size={'small'}
                    disabled={selectedRows.length === 0}
                    onClick={burnCD}
                >
                    {t('Burn CD')}
                </Button>
            )}

            {hasPermission(PERMISSIONS.EXPORT_STUDY) && (
                <Button
                    size={'small'}
                    disabled={selectedRows.length === 0}
                    onClick={exportStudy}
                >
                    {t('Export Study')}
                </Button>
            )}

            {hasPermission(PERMISSIONS.STUDY_CREATE_EMPTY) && (
                <Button
                    type={'warning'}
                    size={'small'}
                    onClick={() => {
                        openModal(
                            t(''),
                            <StudyCreateModal
                                onSuccess={() => {
                                    closeModal();
                                }}
                            />
                        );
                    }}
                >
                    {t('Create Study')}
                </Button>
            )}

            {/*<Button*/}
            {/*    type={'primary'}*/}
            {/*    onClick={() => {*/}
            {/*        hubConnection.invoke("SendMessage", 'user-test', 'Hello SignalR').then((res)=>{})*/}
            {/*    }}*/}
            {/*>*/}
            {/*    SignalR*/}
            {/*</Button>*/}
        </Flex>
    );
}
