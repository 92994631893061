import {useEffect, useState} from "react";
import {Form, Select} from "antd";
import ApiService from "../../core/service/api-service";

export default function InstitutionSelect({formItem, hide, ...props}) {
    const [institutions, setInstitutions] = useState([])

    const getInstitutions = async () => {
        const res = await ApiService.UserManager.GetInstitutionsOfUser();
        if(res.success) {
            setInstitutions(res.data)
        }
    }

    useEffect(() => {
        getInstitutions()
    }, [])

    const sel = (<Select
        mode={'multiple'}
        options={institutions}
        allowClear={true}
        showSearch={true}
        filterOption={(input, option) => {
            return (option?.name ?? '').toUpperCase().includes(input.toUpperCase())
        }}
        fieldNames={{
            label: 'name',
            value: 'id'
        }}
        {...props}
    />)

    return (
        <>
            {
                formItem && ((hide && institutions.length > 0) || !hide) && (
                    <Form.Item
                        {...formItem}
                    >
                        {sel}
                    </Form.Item>
                )
            }

            {
                !formItem && ((hide && institutions.length > 0) || !hide) && (sel)
            }
        </>
    )
}