import {useTranslation} from "react-i18next";
import ApiService from "../../core/service/api-service";
import {Select} from "antd";
import {useEffect, useState} from "react";

export default function UserSelectBox({onUsers, type, ...props}) {
    const {t} = useTranslation();

    const [users, setUsers] = useState([])

    const getUsers = async () =>
    {
        let response = {}
        if(type === 'read') {
            response = await ApiService.UserManager.GetReadableUsers();
        }
        else if(type === 'write') {
            response = await ApiService.UserManager.GetWritableUsers();
        }

        if (response.success)
        {
            setUsers(response.data)
            onUsers && onUsers(response.data)
        }
    }

    useEffect(() => {
        getUsers()
    }, [])

    return (
        <>
            <Select
                allowClear
                showSearch={true}
                filterOption={(input, option) => {
                    return option.visibleName?.toLocaleLowerCase().includes(input.toLocaleLowerCase())
                }}
                options={users}
                fieldNames={{
                    label: 'visibleName',
                    value: 'id'
                }}
                {...(props || {})}
            />
        </>
    )
}