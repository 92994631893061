import {Button, Dropdown} from "antd";
import {DownOutlined} from "@ant-design/icons";

export default function RowMenu({children, text})
{
    const renderButton = () => {
        if(text)
            return (
                <a
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                    style={{textDecoration: 'underline', fontWeight: 'bold'}}
                >
                    {text}
                </a>
            )

        return (
            <Button onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
            }}>
                <DownOutlined />
            </Button>
        )
    }

    return (
        <Dropdown trigger={'click'} overlay={children}>
            {renderButton()}
        </Dropdown>
    )
}
