import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import reportTemplateColumns from "./ReportTemplateColumns";
import DataGrid from "../../../core/components/data-grid/DataGrid";
import ApiService from "../../../core/service/api-service";
import {useDataGrid} from "../../../core/components/data-grid/DataGridContext";

export default function ReportTemplateGrid({})
{
    const {t} = useTranslation();
    const {setDataGridProps, queryModel, setData, setPaginationResult} = useDataGrid();


    const initGridData = async () => {
        const response = await ApiService.ReportTemplate.GetDataGrid(queryModel)
        setPaginationResult(response.pagination);
        setData(response.data)
    }

    useEffect(() => {
        initGridData()
    }, [queryModel.updateKey])

    useEffect(() => {
        setDataGridProps({
            rowKey: 'id',
            columns: reportTemplateColumns(t)
        })
    }, [])

    return (
        <>
            <DataGrid
                name={'report-template'}
            />
        </>
    )
}
