import React, {useContext, useEffect, useState} from "react";
import {HubConnectionBuilder} from "@microsoft/signalr";
import {v4} from "uuid";
import {RisApiEndpoint} from "../service/api-service";

const defaultValue = {
    hubContext: null,
    onUpdateStudyGrid: {}
}

export const SignalRActions = {
    ON_UPDATE_STUDY_GRID: 'ON_UPDATE_STUDY_GRID',
    SET_HUB_CONTEXT: 'SET_HUB_CONTENT',
}

const SignalRContext = React.createContext(defaultValue)

function signalRReducer(state, action)
{
    switch (action.type)
    {
        case SignalRActions.ON_UPDATE_STUDY_GRID: {
            return {
                ...state,
                onUpdateStudyGrid: {
                    key: v4(),
                    data: action.data
                }
            }
        }
        case SignalRActions.SET_HUB_CONTEXT: {
            return {
                ...state,
                hubContext: action.hubContext
            }
        }
        default: {
            return defaultValue
        }
    }
}

function SignalRProvider({children})
{
    const [hubConnection, setHubConnection] = useState(null);
    const [signalR, dispatch] = React.useReducer(signalRReducer, defaultValue);
    const value = {signalR, dispatch};

    const createHub = async () => {
        const hub = new HubConnectionBuilder().withUrl(`${RisApiEndpoint}/api/notification-hub`).build();
        try {
            await hub.start();
            setHubConnection(hub)
        } catch (exp) {
            console.log(exp)
        }
    }

    useEffect(() => {
        createHub()

        return () => {
            hubConnection?.stop()
        }
    }, [])

    useEffect(() => {
        if(hubConnection) {
            hubConnection.on('UpdateStudyGrid', (...args) => {
                dispatch({
                    type: SignalRActions.ON_UPDATE_STUDY_GRID,
                    data: args
                })
            })

            dispatch({
                type: SignalRActions.SET_HUB_CONTEXT,
                hubContext: hubConnection
            })
        }
    }, [hubConnection])

    return (
        <SignalRContext.Provider value={value}>
            {children}
        </SignalRContext.Provider>
    )
}

function useSignalR()
{
    const context = React.useContext(SignalRContext);

    if(!context)
        throw new Error('SignalR Provider Error')

    const {signalR, dispatch} = context;

    return {
        onUpdateStudyGrid: signalR.onUpdateStudyGrid,
        hubContext: signalR.hubContext
    }
}

export {SignalRProvider, useSignalR}
