import {useTranslation} from "react-i18next";
import ApiService from "../../core/service/api-service";
import {Button, Form, Input} from "antd";
import {useRef} from "react";

export default function RoleCreateEdit({roleId, roleName, onSaved})
{
    const formRef = useRef();
    const {t} = useTranslation();

    const save = async (form) => {
        if(roleId)
        {
            const response = await ApiService.Role.Edit(roleId, form.name);
            if(response.success)
                onSaved && onSaved(roleId)
        }
        else
        {
            const response = await ApiService.Role.Create(form.name);
            if(response.success)
                onSaved && onSaved(response.data)
        }
    }

    return (
        <>
            <Form
                layout={'vertical'}
                onFinish={save}
                ref={formRef}
                initialValues={{name: roleName}}
            >
                <Form.Item
                    label={t('Name')}
                    name={'name'}
                    rules={[
                        {required: true}
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {t('Save')}
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
