import UserSelectBox from "../user/UserSelectBox";
import {useEffect, useRef, useState} from "react";
import ApiService from "../../core/service/api-service";
import {Button, List, Switch, Table} from "antd";
import {t} from 'i18next'
import {DeleteOutlined} from "@ant-design/icons";
import {v4} from "uuid";

export default function HospitalDoctors({hospitalId, onSave}) {
    const [data, setData] = useState([])
    const users = useRef([])

    const getDoctors = async () => {
        const res = await ApiService.UserManager.GetDoctorsOfHospital(hospitalId)
        if(res.success) {
            setData(res.data)
        }
    }

    useEffect(() => {
        getDoctors()
    }, []);

    return (
        <>
            <UserSelectBox
                type={'read'}
                style={{width:300, marginBottom: 8}}
                onUsers={(_users) => users.current = _users}
                onChange={(val) => {
                    const user = users.current.find(x => x.id === val);
                    if(user) {
                        const hasDefaultItem = data.find(x => x.isDefault)

                        setData([
                            ...data,
                            {
                                id: v4(),
                                user,
                                isDefault: !hasDefaultItem
                            }
                        ])
                    }
                }}
                placeholder={'Select Doctor'}
            />
            <Table
                size={'small'}
                pagination={false}
                dataSource={data}
                rowKey={'id'}
                columns={[
                    {
                        title: t('Name'),
                        key: 'name',
                        dataIndex: 'name',
                        render: (text, record) => record?.user?.visibleName
                    },
                    {
                        title: t('Default'),
                        key: 'isDefault',
                        dataIndex: 'isDefault',
                        render: (text, record) => (
                            <Switch
                                checked={record.isDefault}
                                onChange={(e) => {
                                    if(!e)
                                        return

                                    data.forEach(x => {
                                        x.isDefault = false
                                    })
                                    const item = data.find(x => x.id === record.id)
                                    if(item) {
                                        item.isDefault = e
                                        setData([...data])
                                    }
                                }}
                            />
                        )
                    },
                    {
                        title: t('#'),
                        key: '#',
                        dataIndex: '#',
                        render: (text, record) => (
                            <>
                                <Button
                                    icon={<DeleteOutlined />}
                                    onClick={() => {
                                        const _data = data.filter(x => x.id !== record.id)
                                        setData(_data)
                                    }}
                                />
                            </>
                        )
                    },
                ]}
            />

            <Button
                type={'primary'}
                onClick={() => {
                    onSave && onSave(data)
                }}
            >
                {t('Save')}
            </Button>
        </>
    )
}