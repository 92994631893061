import {useTranslation} from "react-i18next";
import {useDataGrid} from "../../../core/components/data-grid/DataGridContext";
import ApiService from "../../../core/service/api-service";
import {useEffect} from "react";
import DataGrid from "../../../core/components/data-grid/DataGrid";
import userColumns from "./UserColumns";

export default function UserGrid({onSelect})
{
    const {t} = useTranslation();
    const {setDataGridProps, queryModel, setData, setPaginationResult} = useDataGrid();


    const initGridData = async () => {
        const response = await ApiService.UserManager.GetUserDataGrid(queryModel)
        setPaginationResult(response.pagination);
        setData(response.data)
    }

    useEffect(() => {
        initGridData()
    }, [queryModel.updateKey])

    useEffect(() => {
        setDataGridProps({
            rowKey: 'id',
            columns: userColumns(t, onSelect)
        })
    }, [])

    return (
        <>
            <DataGrid
                name={'user'}
            />
        </>
    )
}
