import {Button, DatePicker, Form, Input, Select, Space} from "antd";
import {useTranslation} from "react-i18next";
import {useDataGrid} from "../../../core/components/data-grid/DataGridContext";
import {useRef, useState} from "react";
import {DATE_OPTIONS, getBeginStr, getDateFromNow, getEndStr} from "../../../core/utils/date-utils";
import HospitalSelectBox from "../../hospital/HospitalSelectBox";

export default function TeletipFilter()
{
    const {t} = useTranslation();
    const {onFilterPageSort} = useDataGrid();
    const formRef = useRef();

    const getDefaultDate = () => {
        const date = getDateFromNow(DATE_OPTIONS.TODAY);
        return [date.start, date.end]
    }

    const date = getDefaultDate();

    const reset = () => {
        window.location.reload()
    }

    const filter = (form) => {
        onFilterPageSort({
            filter: {
                ...form,
                studyReceivedDateTimeBegin: getBeginStr(form.studyReceivedDateTimeBegin),
                studyReceivedDateTimeEnd: getEndStr(form.studyReceivedDateTimeEnd),
                studyIdList: form.studyIdList?.split('\n').filter(x => x.trim()),
                patientIdList: form.patientIdList?.split('\n').filter(x => x.trim()),
                accessionNumberList: form.accessionNumberList?.split('\n').filter(x => x.trim()),
            }
        })
    }

    return (
        <>
            <Form
                ref={formRef}
                onFinish={filter}
                layout={'vertical'}
                initialValues={{
                    studyReceivedDateTimeBegin: date[0],
                    studyReceivedDateTimeEnd: date[1],
                }}
            >
                <Space style={{marginBottom: 16}}>
                    <Button type={'primary'} size={'small'} htmlType="submit" >{t('Search')}</Button>
                    <Button size={'small'} onClick={reset}>{t('Reset')}</Button>
                </Space>
                <div
                    style={{display: 'flex', alignItems: 'center'}}
                >
                    <Form.Item
                        label={t('Date')}
                        name="studyReceivedDateTimeBegin"
                    >
                        <DatePicker showToday={false} />
                    </Form.Item>
                    <Form.Item
                        label={' '}
                        name="studyReceivedDateTimeEnd"
                    >
                        <DatePicker showToday={false} />
                    </Form.Item>
                </div>
                <Form.Item
                    label={t('Study Ids')}
                    name={'studyIdList'}
                >
                    <Input.TextArea rows={2} />
                </Form.Item>
                <Form.Item
                    label={t('Patient Ids')}
                    name={'patientIdList'}
                >
                    <Input.TextArea rows={2} />
                </Form.Item>
                <Form.Item
                    label={t('Accession Numbers')}
                    name={'accessionNumberList'}
                >
                    <Input.TextArea rows={2} />
                </Form.Item>
                <Form.Item
                    label={t('Hospitals')}
                    name="hospitalIdList"
                >
                    <HospitalSelectBox />
                </Form.Item>
            </Form>
        </>
    )
}
