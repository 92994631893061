
function getFileAsBase64({accept})
{
    return new Promise((resolve, reject) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.id = 'file';
        input.accept = accept

        input.onchange = async (e) => {
            const file = input.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve({
                data: reader.result.split(',')[1],
                url: reader.result,
                fileName: file.name
            });
            reader.onerror = error => reject(error);
        }

        input.click()
    })
}

const FileUtils = {
    getFileAsBase64
}

export default FileUtils;
