import 'antd/dist/antd.compact.css'
import 'antd-button-color/dist/css/style.css';

import {UserProvider, useUser} from "./core/context/UserContext";
import AppRouter from "./AppRouter";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Flex from "./core/components/Flex";
import MoonLoader from "react-spinners/MoonLoader";
import {ModalProvider} from "./core/context/ModalContext";
import Modal from "./core/components/Modal";
import {AppProvider} from "./core/context/AppContext";
import {ConfigProvider} from "antd";
import {LocalStorageService} from "./core/service/local-storage-service";

export default function App()
{
    return (
        <>
            <div
                id={'request-spinner'}
                style={{
                    display: 'none',
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#00000020',
                    zIndex: 9999
                }}
            >
                <Flex style={{height: '100%'}} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} justifyItems={'center'}>
                    <MoonLoader color={'#0000ff'} size={80}/>
                </Flex>
            </div>
            <div className="App">
                <UserProvider>
                    <AppProvider>
                        <ModalProvider>
                            <AppRouter />
                            <Modal />
                        </ModalProvider>
                    </AppProvider>
                </UserProvider>

                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </>
    )
}
