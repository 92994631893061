import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {Button, Form, Input, Select} from "antd";
import RequestTypeSelectBox from "../request-type/RequestTypeSelectBox";
import ReportEditor from "../../core/components/ReportEditor";
import ModalitySelectBox from "../modality/ModalitySelectBox";
import ApiService from "../../core/service/api-service";
import {Pages} from "../../AppRouter";

const defaultReportContent = {
    Teknik: '',
    Karsilastirma: '',
    SonucVeOneriler: '',
    Bulgular: ''
}

export default function ReportTemplateCreateEditForm({reportTemplateId})
{
    const {t} = useTranslation()
    const history = useHistory();
    const formRef = useRef();

    const [defaultTemplateContent, setDefaultTemplateContent] = useState(defaultReportContent);
    const [selectedModalityId, setSelectedModalityId] = useState(-1);
    const template = useRef({})

    const getReportTemplate = async () => {
        if(reportTemplateId && reportTemplateId > 0)
        {
            const templateResponse = await ApiService.ReportTemplate.Get(reportTemplateId);
            if(templateResponse.success)
            {
                formRef.current.setFieldsValue({
                    name: templateResponse.data.name,
                    modalityId: templateResponse.data.requestType.modality.name,
                    requestTypeId: templateResponse.data.requestType.name,
                })

                await getReportTemplateFileData()
            }
        }
    }

    const getReportTemplateFileData = async () => {
        const tempFileResponse = await ApiService.ReportTemplate.GetReportTemplateFileData(reportTemplateId);
        setDefaultTemplateContent(JSON.parse(tempFileResponse.data))
        template.current = JSON.parse(tempFileResponse.data)
    }

    const save = async (form) => {
        const data = {
            ...form,
            content: template.current,
        };

        if(reportTemplateId)
        {
            const response = await ApiService.ReportTemplate.Edit(reportTemplateId, data);
        }
        else
        {
            const response = await ApiService.ReportTemplate.Create(data);
            if(response.success)
            {
                history.push(Pages.ReportTemplate.Edit(response.data))
            }
        }
    }

    useEffect(() => {
        getReportTemplate()
    }, [])

    return (
        <>
            <Form
                ref={formRef}
                name="basic"
                layout={'vertical'}
                onFinish={save}
                autoComplete="off"
            >
                <Form.Item
                    label={t('Name')}
                    name="name"
                    rules={[{ required: true, message: t('Please input report template name!') }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item name="modalityId" label={t('Modality')} rules={[{ required: true }]}>
                    <ModalitySelectBox
                        disabled={reportTemplateId}
                        mode={'single'}
                        onChange={(modalityId) => {
                            setSelectedModalityId(modalityId)
                            formRef.current.setFieldsValue({
                                requestTypeId: null
                            })
                        }}
                        filterOption={(input, option) => {
                            return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }}
                        showSearch={true}
                    />
                </Form.Item>

                <Form.Item name="requestTypeId" label={t('Request Type')} rules={[{ required: true }]}>
                    <RequestTypeSelectBox
                        disabled={reportTemplateId}
                        modalityId={selectedModalityId}
                        onChange={(requestId) => {

                        }}
                    />
                </Form.Item>

                <Form.Item>
                    <ReportEditor
                        editable={reportTemplateId}
                        defaultReportContent={defaultTemplateContent}
                        onBulgular={(data) => {
                            template.current = {
                                ...template.current,
                                Bulgular: data
                            }
                        }}
                        onSonuc={(data) => {
                            template.current = {
                                ...template.current,
                                SonucVeOneriler: data
                            }
                        }}
                        onKarsilastirma={(data) => {
                            template.current = {
                                ...template.current,
                                Karsilastirma: data
                            }
                        }}
                        onTeknik={(data) => {
                            template.current = {
                                ...template.current,
                                Teknik: data
                            }
                        }}
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {t('Save')}
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
