import {useEffect, useState} from "react";
import ApiService from "../../core/service/api-service";
import {Select} from "antd";
import {t} from "i18next";

export default function HospitalGroupSelectBox({onGroupList, ...props}) {
    const [groups, setGroups] = useState([])

    const getGroups = async () => {
        const res = await ApiService.HospitalGroup.GetList();
        if (res.success) {
            setGroups(res.data)
            onGroupList && onGroupList(res.data)
        }
    }

    useEffect(() => {
        getGroups()
    }, [])

    return (
        <Select
            mode={'multiple'}
            allowClear={true}
            placeholder={t('Hospital Groups')}
            {...props}
        >
            {
                groups.map(x => {
                    return (
                        <Select.Option key={x.id} value={x.id}>
                            {x.name}
                        </Select.Option>
                    )
                })
            }
        </Select>
    )
}