import {Button, Select, Typography} from "antd";
import {useEffect, useState} from "react";
import ApiService from "../../core/service/api-service";
import {t} from "i18next";

export default function ({studyIdList, onExport}) {
    const [exports, setExports] = useState([])
    const [exportName, setExportName] = useState(null)

    const get = async () => {
        const res = await ApiService.Dicom.GetExportAETitleList(studyIdList)
        if(res.success) {
            setExports(res.data)
            setExportName(res.data[0])
        }
    }

    useEffect(() => {
        get()
    }, [])

    return (
        <>
            <div style={{display: 'flex', alignItems: 'center', gap: 8, marginBottom: 8}}>
                <Typography.Text>
                    {t('Export Name')} ({studyIdList?.length} {t('Study')})
                </Typography.Text>
                <Select
                    style={{width: 200}}
                    onChange={(e) => setExportName(e)}
                    value={exportName}
                >
                    {
                        exports.map(x => (
                            <Select.Option value={x} key={x}>
                                {x}
                            </Select.Option>
                        ))
                    }
                </Select>
            </div>
            <Button
                size={'small'}
                type={'primary'}
                style={{marginTop: 8}}
                onClick={() => {
                    onExport && onExport(exportName, studyIdList)
                }}
            >
                {t('Export Study')}
            </Button>
        </>
    )
}