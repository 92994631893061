import React, {useEffect, useReducer, useRef, useState} from "react";
import {LocalStorageService} from "../service/local-storage-service";
import ApiService from "../service/api-service";
import {useKeyListener} from "../components/KeyListener";
import {v4} from "uuid";
import { useUser } from "./UserContext";


const AppActions = {
    SET_DATA: 'SET_DATA',
    SET_INSTITUTIONS: 'SET_INSTITUTIONS',
    ADD_KEY_EVENT: 'ADD_KEY_EVENT',
    REMOVE_KEY_EVENT: 'REMOVE_KEY_EVENT',
    ON_KEY_DOWN_UP: 'ON_KEY_DOWN_UP',
    SET_PRESSED_KEY: 'SET_PRESSED_KEY'
}

const defaultValue = {
    modalities: [],
    keyEvents: [],
    pressedKey: {},
    keyDown: () => {},
    keyUp: () => {}
}

const AppContext = React.createContext(defaultValue);


function appReducer(state, action)
{
    switch (action.type)
    {
        case AppActions.SET_DATA: {
            return {
                ...state,
                modalities: action.data.modalities || []
            }
        }
        case AppActions.SET_PRESSED_KEY: {
            return {
                ...state,
                pressedKey: action.data
            }
        }
        case AppActions.ADD_KEY_EVENT: {
            return {
                ...state,
                keyEvents: Array.from(new Set([...state.keyEvents, ...action.data]))
            }
        }
        case AppActions.REMOVE_KEY_EVENT: {
            const events = state.keyEvents.filter(x => {
                return !action.data.find(y => y.keyCode === x.keyCode && y.modifier === x.modifier)
            })

            return {
                ...state,
                keyEvents: events
            }
        }
        case AppActions.ON_KEY_DOWN_UP: {
            return {
                ...state,
                keyDown: action.data.keyDown,
                keyUp: action.data.keyUp
            }
        }
        default: {
            return state
        }
    }
}

function AppProvider({children})
{
    const [app, dispatch] = useReducer(appReducer, defaultValue)
    const value = {app, dispatch}
    const modifierKey = useRef(null)
    const keyEvents = useRef([])
    const { user } = useUser()

    const initData = async () => {
        let modalities = []

        let res = await ApiService.Modality.GetList();
        if(res.success) {
            modalities = res.data
        }

        dispatch({
            type: AppActions.SET_DATA,
            data: {
                modalities,
            }
        })
    }
    const keyDown = (e) => {
        const event = {
            code: e.code || e.key,
            key: e.key,
            keyCode: e.keyCode
        }

        if(['F3', 'F4', 'F7', 'F8', 'F9'].includes(event.code))
            e.preventDefault()

        // ''Block: Ctrl+S, Ctrl+D
        if(modifierKey.current && app.keyEvents.find(x => x.modifier === modifierKey?.current?.code && x.keyCode === event.code))
            e.preventDefault()

        if(app.keyEvents.find(x => x.modifier === event.code)) {
            e.preventDefault()
            modifierKey.current = event
        }
    }

    const keyUp = (e) => {
        const event = {
            code: e.code || e.key,
            key: e.key,
            keyCode: e.keyCode
        }

        if(app.keyEvents.find(x => x.modifier === event.code)) {
            e.preventDefault()
            modifierKey.current = null
            return;
        }

        const events = Array.from(new Set(app.keyEvents.filter(x => x.keyCode === event.code)));

        if(events?.length > 0) {
            e.preventDefault()
            dispatch({
                type: AppActions.SET_PRESSED_KEY,
                data: {
                    key: event,
                    modifier: modifierKey.current,
                    time: new Date(),
                    id: v4(),
                    pressed: true
                }
            })
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', keyDown);
        window.addEventListener('keyup', keyUp);

        return () => {
            window.removeEventListener('keydown', keyDown);
            window.removeEventListener('keyup', keyUp);
        };
    }, [app]);

    useEffect(() => {
        if(keyEvents.current !== app.keyEvents)
        {
            keyEvents.current = app.keyEvents
            dispatch({
                type: AppActions.ON_KEY_DOWN_UP,
                data: {
                    keyDown,
                    keyUp
                }
            })
        }
    }, [app]);

    useEffect(() => {
        if (user && user.token) {
            initData()
        }
    }, [user])

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    )
}

function useApp()
{
    const context = React.useContext(AppContext);

    if(!context)
        throw new Error('App Provider Error')

    const {app, dispatch} = context;

    const addKeyEvent = (data) => {
        dispatch({
            type: AppActions.ADD_KEY_EVENT,
            data: data
        })
    }

    const addKeyEvents = (data) => {
        dispatch({
            type: AppActions.ADD_KEY_EVENT,
            data: data
        })
    }

    const removeKeyEvent = (data) => {
        dispatch({
            type: AppActions.REMOVE_KEY_EVENT,
            data: data
        })
    }

    return {
        modalities: app?.modalities || [],
        institutions: app?.institutions || [],
        addKeyEvent,
        removeKeyEvent,
        appKeyDown: app?.keyDown,
        appKeyUp: app?.keyUp,
        pressedKey: app?.pressedKey
    }
}

export {AppProvider, useApp}

