import {useTranslation} from "react-i18next";
import {Button, Card, Dropdown, Menu} from "antd";
import {DataGridProvider} from "../../core/components/data-grid/DataGridContext";
import LayoutWithSider from "../../core/components/layout/layout-with-sider/LayoutWithSider";
import ReportFilter from "../../app/report/report-grid/ReportFilter";
import ReportGrid from "../../app/report/report-grid/ReportGrid";
import {DATE_OPTIONS, getDateFromNow} from "../../core/utils/date-utils";
import {useEffect} from "react";

export default function ReportDataGridPage()
{
    const {t} = useTranslation();

    useEffect(() => {
        document.title = `ECloud Pacs - ${t('Reports')}`
    }, []);

    return (
        <>
            <DataGridProvider>
                <LayoutWithSider
                    fullWidth={true}
                    sidebarTitle={t('Report Filter')}
                    sidebarComponent={(
                        <ReportFilter/>
                    )}
                >
                    <Card title={t('Reports')}>
                        <ReportGrid />
                    </Card>
                </LayoutWithSider>
            </DataGridProvider>
        </>
    )
}
