import {useEffect, useState} from "react";
import {Checkbox, Collapse, Divider, Tag, Tooltip, Typography} from 'antd';
import ApiService from "../../core/service/api-service";
import {t} from "i18next";
import Flex from "../../core/components/Flex";

const { Panel } = Collapse;
export default function PermissionGroup({selectedPermissions = [], onChangeSelectedPermissions, disabledPermission})
{
    const [groups, setGroup] = useState([])
    const [permissions, setPermissions] = useState([])

    const getGroups = async () => {
        const response = await ApiService.PermissionGroup.GetList()
        if(response.success)
        {
            setGroup(response.data)

            let permissions = [];
            response.data.forEach(group => {
                permissions = [...permissions, ...group.permissions.map(p => {
                    return {...p, groupName: group.name};
                })]
            })
            setPermissions(permissions)
        }
    }

    const getDependencies = (permission) => {
        return permissions.filter(p => permission.dependencies.includes(p.id))
    }

    const onCheckedPermission = (checked, permission) => {
        if(checked)
        {
            onSelect([...selectedPermissions, permission.id])
        }
        else
        {
            onSelect(selectedPermissions.filter(p => p !== permission.id))
        }
    }

    const onSelect = (data) => {
        data = data.filter(x => (disabledPermission || []).findIndex(y => y === x) < 0)
        onChangeSelectedPermissions && onChangeSelectedPermissions([...new Set(data)])
    }

    useEffect(() => {
        getGroups()
    }, [])

    return (
        <>
            <Collapse>
                {
                    groups.map(group => {
                        return (
                            <>
                                <Panel
                                    key={group.id+'g'}
                                    header={(
                                        <div>
                                            {group.name}
                                        </div>
                                    )}
                                >
                                    {
                                        group.permissions.map(p => {
                                            return (
                                                <div key={p.id+'pg'} style={{marginBottom: 8, borderBottom: '1px solid #e0e0e0', paddingBottom: 8}}>
                                                    <Checkbox
                                                        id={p.id+'pg'}
                                                        onChange={(e) => {onCheckedPermission(e.target.checked, p)}}
                                                        disabled={disabledPermission?.findIndex(x => x === p.id) > -1}
                                                        checked={selectedPermissions.findIndex(x => x === p.id) > -1 || disabledPermission?.findIndex(x => x === p.id) > -1}
                                                    >
                                                        <Tooltip title={t(`${p.name}_DESC`)}>
                                                            <b>{t(p.name)}</b>
                                                        </Tooltip>
                                                    </Checkbox>
                                                </div>
                                            )
                                        })
                                    }
                                </Panel>
                            </>
                        )
                    })
                }
            </Collapse>
        </>
    )
}
