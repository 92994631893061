import {useEffect, useState} from "react";
import ApiService from "../../core/service/api-service";
import Flex from "../../core/components/Flex";
import {PERMISSIONS, useUser} from "../../core/context/UserContext";
import {Button, Divider, notification, Space, Tooltip, Typography} from "antd";
import {t} from "i18next";
import printJS from 'print-js'
import './report-preview.css'
import {toast} from "react-toastify";

const Keys = {
    firmName: '{{firmName}}',
    hospitalName: '{{hospitalName}}',
    hospitalOfficialName: '{{hospitalOfficialName}}',
    patientId: '{{patientId}}',
    patientName: '{{patientName}}',
    gender: '{{gender}}',
    patientBirthdate: '{{patientBirthdate}}',
    age: '{{age}}',
    createdDate: '{{createdDate}}',
    accessionNumber: '{{accessionNumber}}',
    studyDate: '{{studyDate}}',
    modality: '{{modality}}',
    requestName: '{{requestName}}',
    complaintPeriod: '{{complaintPeriod}}',
    diagnosis: '{{diagnosis}}',
    medicalHistory: '{{medicalHistory}}',
    patientComplaint: '{{patientComplaint}}',
    physicalExamination: '{{physicalExamination}}',
    clinicalInformation: '{{clinicalInformation}}',
    previousExaminations: '{{previousExaminations}}',
    doctorName: '{{doctorName}}',
    doctorReportName: '{{doctorReportName}}',
    doctorSign: '{{doctorSign}}',
    teknik: 'ECAHUAQD',
    bulgular: 'AZCMSRCT',
    karsilastirma: 'QWDGGXPD',
    sonucVeOneri: 'SUFHFZNM'
}

export default function ReportVersionPreview({studyId, versionId, report})
{
    const [reportModel, setReportModel] = useState({
        reportFields: []
    });
    const {hasPermission} = useUser()

    const getReportModel = async () => {
        const response =
            await ApiService.ReportGenerator.GetReportVersionPreview(
                studyId,
                versionId
            );
        if(response.success)
        {
            setReportModel(response.data)
        }
    }

    const get = async () => {
        await getReportModel()
    }

    useEffect(() => {
        get()
    }, [versionId])

    const getData = (key, defaultData = '') => {
        const field = reportModel.reportFields.find(x => x.key === key);
        if(!field || !field.data)
            return defaultData || '';

        return field.data;
    }

    const labelStyle = {
        color: '#ff0000',
        marginRight: 4,
        fontWeight: 'bold'
    }

    return (
        <>
            <Flex flexDirection={'column'} style={{fontSize: '10pt'}}>
                {
                    reportModel.reportFields.length > 0 && (
                        <>
                            <Typography.Title level={3} style={{flex: 1, textAlign: 'center'}}>
                                {getData(Keys.hospitalOfficialName, '')}
                            </Typography.Title>
                            <Flex justifyContent={'space-between'}>
                                <table>
                                    <tr>
                                        <td>
                                            <Typography.Text style={labelStyle}>
                                                {t('Patient Name')+':'}
                                            </Typography.Text>
                                        </td>
                                        <td>
                                            <Typography.Text>
                                                {getData(Keys.patientName)}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography.Text style={labelStyle}>
                                                {t('Accession Number')+':'}
                                            </Typography.Text>
                                        </td>
                                        <td>
                                            <Typography.Text>
                                                {getData(Keys.accessionNumber, '--')}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography.Text style={labelStyle}>
                                                {t('Patient Id')+':'}
                                            </Typography.Text>
                                        </td>
                                        <td>
                                            <Typography.Text>
                                                {getData(Keys.patientId)}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography.Text style={labelStyle}>
                                                {t('Birthdate')+':'}
                                            </Typography.Text>
                                        </td>
                                        <td>
                                            <Typography.Text>
                                                {getData(Keys.patientBirthdate)}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr>
                                        <td>
                                            <Typography.Text style={labelStyle}>
                                                {t('Study Date')+':'}
                                            </Typography.Text>
                                        </td>
                                        <td>
                                            <Typography.Text>
                                                {getData(Keys.studyDate)}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography.Text style={labelStyle}>
                                                {t('Report Date')+':'}
                                            </Typography.Text>
                                        </td>
                                        <td>
                                            <Typography.Text>
                                                {getData(Keys.createdDate, '--')}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography.Text style={labelStyle}>
                                                {t('Modality')+':'}
                                            </Typography.Text>
                                        </td>
                                        <td>
                                            <Typography.Text>
                                                {getData(Keys.modality)}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography.Text style={labelStyle}>
                                                {t('Gender')+':'}
                                            </Typography.Text>
                                        </td>
                                        <td>
                                            <Typography.Text>
                                                {getData(Keys.gender)}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                </table>
                            </Flex>
                            <Divider />
                            <div id={'report-content'} style={{padding: 8}}>
                                <Typography.Title level={5}>
                                    Sayın meslektaşım hastanızın {report?.request?.requestType?.displayName} incelemesinde :
                                </Typography.Title>
                                <Typography.Text style={labelStyle}>
                                    {t('Teknik')+':'}
                                </Typography.Text>
                                <div dangerouslySetInnerHTML={{__html: getData(Keys.teknik, '--')}} />
                                <Typography.Text style={labelStyle}>
                                    {t('Clinical Information')+':'}
                                </Typography.Text>
                                <br/>
                                <Typography.Text>
                                    {getData(Keys.clinicalInformation, '--')}
                                </Typography.Text>
                                <br/><br/>
                                <Typography.Text style={labelStyle}>
                                    {t('Bulgular')+':'}
                                </Typography.Text>
                                <div dangerouslySetInnerHTML={{__html: getData(Keys.bulgular, '--')}} />
                                <Typography.Text style={labelStyle}>
                                    {t('Karşılaştırma')+':'}
                                </Typography.Text>
                                <div dangerouslySetInnerHTML={{__html: getData(Keys.karsilastirma, '--')}} />
                                <Typography.Text style={labelStyle}>
                                    {t('Sonuç ve Öneri')+':'}
                                </Typography.Text>
                                <div dangerouslySetInnerHTML={{__html: getData(Keys.sonucVeOneri, '--')}} />
                                {/*<Typography.Text style={{fontWeight: 'bold'}}>*/}
                                {/*    {getData(Keys.doctorReportName, '--')}*/}
                                {/*</Typography.Text>*/}
                            </div>
                        </>
                    )
                }
            </Flex>
        </>
    )
}
