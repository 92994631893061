import {useEffect, useRef} from "react";
import {Button, Form, Input, Switch} from "antd";
import HospitalSelectBox from "../../../HospitalSelectBox";
import {t} from 'i18next'
import {v4} from "uuid";

export default function HL7ClientCreateEditModal({hospitalId, data, onSave}) {
    const formRef = useRef()

    useEffect(() => {
        if(data) {
            formRef.current.setFieldsValue({
                ...data,
                name: data?.appClient?.name
            })
        } else {
            const date = new Date();
            formRef.current.setFieldsValue({
                name: `hl7-client-${date.getTime()}`
            })
        }
    }, [data]);

    useEffect(() => {
        if(hospitalId) {
            formRef.current.setFieldsValue({
                hospitalId: hospitalId
            })
        }
    }, [hospitalId])

    const finish = async () => {
        onSave && onSave(formRef.current.getFieldsValue())
    }

    return (
        <>
            <Form
                layout={'vertical'}
                ref={formRef}
                onFinish={finish}
            >
                <Form.Item
                    label={t('Hospital')}
                    name={'hospitalId'}
                >
                    <HospitalSelectBox mode={'single'} disabled={true} />
                </Form.Item>

                <Form.Item
                    label={t('Name')}
                    name={'name'}
                >
                    <Input disabled={data} />
                </Form.Item>

                <Form.Item
                    label={t('Description')}
                    name={'description'}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t('Enabled')}
                    name={'enabled'}
                    valuePropName={'checked'}
                >
                    <Switch />
                </Form.Item>

                <Form.Item>
                    <Button htmlType={'submit'} type={'primary'}>
                        {t('Save')}
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}