import {useEffect, useRef, useState} from "react";
import {Button, Form, Input, Select} from "antd";
import HospitalSelectBox from "../../hospital/HospitalSelectBox";
import ApiService from "../../../core/service/api-service";

export default function HL7Input({onFinish}) {
    const formRef = useRef()

    return (
        <>
            <Form
                ref={formRef}
                layout={'vertical'}
                size={'small'}
                onFinish={() => {
                    onFinish && onFinish(formRef.current.getFieldsValue())
                }}
            >
                <Form.Item
                    label={'Hospital'}
                    name={'hospitalId'}
                >
                    <HospitalSelectBox
                        mode={'single'}
                        showSearch={true}
                    />
                </Form.Item>

                <Form.Item
                    label={'Message'}
                    name={'message'}
                >
                    <Input.TextArea
                        rows={5}
                    />
                </Form.Item>

                <Form.Item>
                    <Button htmlType={'submit'} type={'primary'}>
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}