import {Pages} from "../../AppRouter";
import {Modal} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {t} from "i18next";

const openReqRepPage = (selectedReq, selectedStudy) => {
    if(selectedReq && selectedStudy?.isRead) {
        return true
    }
    else if(selectedStudy?.isRead) {
        return  true
    }
    else {
        Modal.warning({
            title: `${selectedStudy.dicomPatientId} - ${selectedStudy.patientName}`,
            icon: <ExclamationCircleOutlined />,
            content: t('Read this study, before!'),
            okText: 'Okay',
            cancelButtonProps: null
        })
        return false
    }
}

const StudyHelper = {
    openReqRepPage
}

export default StudyHelper;