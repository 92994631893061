import Flex from "../../../core/components/Flex";
import {Button, Select, Space, Tooltip} from "antd";
import {t} from "i18next";
import {useModal} from "../../../core/context/ModalContext";
import StudyAddRequest from "../../study/study-add-request/StudyAddRequest";
import {useDataGrid} from "../../../core/components/data-grid/DataGridContext";
import Colors from "../../../core/style/Colors";
import {useEffect, useState} from "react";
import ApiService from "../../../core/service/api-service";
import {PERMISSIONS, useUser} from "../../../core/context/UserContext";
import {RedoOutlined, ReloadOutlined} from "@ant-design/icons";

const colors = [
    {color: '#ced6e0', text: 'DELETED-ROW'},
    {color: '#55efc4', text: 'REPORT-CONFIRMED'},
    {color: '#dff9fb', text: 'REPORT-NOT-CONFIRMED-ROW'},
    {color: Colors.selectedRow, text: 'SELECTED-ROW'},
]

export default function StudyRequestGridTools({studyId})
{
    const {refreshDataGrid} = useDataGrid();
    const {hasPermission} = useUser()
    const [reqGroups, setReqGroups] = useState([])
    const [selReqs, setSelReqs] = useState([])

    const getGroups = async () => {
        const res = await ApiService.RequestTypeGroup.GetAvailableRequestTypes(studyId);
        if(res.success) {
            setReqGroups(res.data.filter(x => x.requestTypes?.length > 0).map(grp => ({
                ...grp,
                label: grp.name,
                options: grp.requestTypes?.map(req => ({
                    ...req,
                    label: req.name,
                    value: req.id
                }))
            })))
        }
    }

    useEffect(() => {
        hasPermission(PERMISSIONS.STUDY_REQUEST_SET) && getGroups()
    }, [])

    const addRequest = async () => {
        const res = await ApiService.Request.AddRequests(studyId, {
            requestTypeIdList: selReqs
        });

        if(res.success) {
            refreshDataGrid()
            setSelReqs([])
        }
    }

    return (
        <>
            <div style={{display: 'flex', alignItems: 'start', flexWrap: 'wrap', gap: 8}}>
                <div>
                    <Flex flexDirection={'row'}>
                        {
                            colors.map(color => {
                                return (
                                    <div key={color.text}>
                                        <Tooltip title={t(color.text)}>
                                            <div style={{width: 16, height: 16, backgroundColor: color.color, marginRight: 4}} />
                                        </Tooltip>
                                    </div>
                                )
                            })
                        }
                    </Flex>
                </div>
                {
                    hasPermission(PERMISSIONS.STUDY_REQUEST_SET) && (
                        <>
                            <Select
                                size={'small'}
                                value={selReqs}
                                onChange={(values) => setSelReqs(values)}
                                mode={'multiple'}
                                options={reqGroups}
                                showSearch={true}
                                allowClear={true}
                                placeholder={t('Add Request & Anamnez')}
                                style={{width: 300}}
                                filterOption={(input, option) => {
                                    return (
                                        option.name.toLocaleLowerCase().includes(input.toLocaleLowerCase())
                                        || option.options?.find(x => x.label.toLocaleLowerCase().includes(input.toLocaleLowerCase()))
                                    )
                                }}
                            />
                            <Button
                                size={'small'}
                                type={'primary'}
                                onClick={addRequest}
                            >
                                {t('Add')}
                            </Button>
                        </>
                    )
                }
            </div>
        </>
    )
}