import StudyAnamnezEdit from "../StudyAnamnezEdit";
import {useRef, useState} from "react";
import ApiService from "../../../core/service/api-service";
import {Button, Divider, Typography} from "antd";
import {t} from "i18next";
import StudyRequestGrid from "../../request/request-data-grid/StudyRequestGrid";
import {DataGridProvider} from "../../../core/components/data-grid/DataGridContext";
import {getAge} from "../../../core/utils/date-utils";

export default function StudyAddRequest({studyId, onSaved, studyRow})
{
    const [requestTypes, setRequestTypes] = useState([])
    const formRef = useRef()

    const save = async () => {
        const resp1 = await ApiService.StudyAnamnez.EditStudyAnamnez(studyId, formRef.current.getFieldsValue(true));
        if(resp1.success)
        {
            onSaved && onSaved()
        }
    }

    return (
        <>
            <div style={{backgroundColor:'#f5f6fa', fontSize: '10pt', paddingLeft: 4}}>
                <table>
                    <thead>
                    <tr style={{fontWeight: 'bold'}}>
                        <td style={{width: 150, borderBottom: '1px solid black'}}>{t('Patient Id')}</td>
                        <td style={{width: 200, borderBottom: '1px solid black'}}>{t('Patient Name')}</td>
                        <td style={{width: 200, borderBottom: '1px solid black'}}>{t('Age')}</td>
                        <td style={{width: 200, borderBottom: '1px solid black'}}>{t('Hospital')}</td>
                        <td style={{width: 200, borderBottom: '1px solid black'}}>{t('Description')}</td>
                        <td style={{width: 80, borderBottom: '1px solid black'}}>{t('Modality')}</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{studyRow.dicomPatientId}</td>
                        <td>{studyRow.patientName}</td>
                        <td>{getAge(studyRow.birthdate)}</td>
                        <td>{studyRow.hospitalName}</td>
                        <td>{studyRow.studyDescription}</td>
                        <td>{studyRow.modality}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div style={{border: '1px solid #e0e0e0', padding: 4, marginBottom: 4}}>
                <Typography.Title level={5}>{t('Requests')}</Typography.Title>
                <DataGridProvider>
                    <StudyRequestGrid
                        studyId={studyId}
                    />
                </DataGridProvider>
            </div>

            <div style={{padding: 4, marginBottom: 8, marginTop: 16, border: '1px solid #cfcfcf'}}>
                <Typography.Title level={5}>{t('Anamnez')}</Typography.Title>
                <StudyAnamnezEdit studyId={studyId} formRef={formRef} />
                <Button type={'primary'} onClick={save}>{t('Save')}</Button>
            </div>
        </>
    )
}
