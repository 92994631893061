import RowMenu from "../../../core/components/data-grid/RowMenu";
import {Button, Menu, Upload} from "antd";
import {Pages} from "../../../AppRouter";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useModal} from "../../../core/context/ModalContext";
import {UploadOutlined} from "@ant-design/icons";
import FileUtils from "../../../core/utils/file-utils";
import ApiService from "../../../core/service/api-service";
import HospitalDoctors from "../HospitalDoctors";
import HospitalUserPermission from "../HospitalUserPermission";
import HospitalCreateEdit from "../HospitalCreateEdit";

export default function HospitalRowMenu({row})
{
    const {t} = useTranslation();
    const history = useHistory();
    const {openModal, closeModal} = useModal();

    return (
        <>
            <RowMenu text={row.id}>
                <Menu>
                    <Menu.Item key={'hospital-edit'} onClick={() => {
                        // history.push(Pages.Hospital.Edit(row.id))
                        openModal(t('Hospital Users Permission'), (
                            <>
                                <HospitalCreateEdit
                                    hospitalId={row.id}
                                />
                            </>
                        ))
                    }}>
                        <div>
                            {t('Edit')}
                        </div>
                    </Menu.Item>
                    <Menu.Item key={'hospital-user-edit'} onClick={() => {
                        openModal(t('Hospital Users Permission'), (
                            <>
                                <HospitalUserPermission
                                    hospitalId={row.id}
                                />
                            </>
                        ))
                    }}>
                        <div>
                            {t('Users')}
                        </div>
                    </Menu.Item>
                    <Menu.Item key={'hospital-doctor-edit'} onClick={() => {
                        openModal('Hospital Doctors', (
                            <>
                                <HospitalDoctors
                                    hospitalId={row.id}
                                    onSave={async (data) => {
                                        const res = await ApiService.UserManager.SetDoctorsToHospital(row.id, data.map(x => ({
                                            userId: x?.user?.id,
                                            isDefault: x?.isDefault
                                        })))

                                        if(res.success) {
                                            closeModal()
                                        }
                                    }}
                                />
                            </>
                        ))
                    }}>
                        <div>
                            {t('Doctors')}
                        </div>
                    </Menu.Item>
                    <Menu.Item
                        key={'template-upload'}
                        onClick={async () => {
                            const file = await FileUtils.getFileAsBase64({accept: 'docx'});
                            if(file)
                            {
                                const response = await ApiService.ReportOutputTemplate.UploadReportOutputTemplate(row.id, {
                                    docxFileBase64: file.data
                                })
                            }
                        }}
                    >
                        {t('Upload Report Template')}
                    </Menu.Item>
                    <Menu.Item
                        key={'template-download'}
                        onClick={async () => {
                            const response = await ApiService.ReportOutputTemplate.DownloadReportOutputTemplate(row.reportOutputTemplate.id, row.id)
                            if(response.success)
                                ApiService.downloadFile(response.data, 'template.docx')
                        }}
                    >
                        {t('Download Report Template')}
                    </Menu.Item>
                </Menu>
            </RowMenu>
        </>
    )
}
