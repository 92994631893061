function saveFile({blobLink, fileName})
{
    const link = document.createElement('a');
    link.href = blobLink;
    link.setAttribute('download', fileName || 'file'); //or any other extension
    document.body.appendChild(link);
    link.click();
}

function getFileAsBase64({accept})
{
    return new Promise((resolve, reject) =>
    {
        const input = document.createElement('input');
        input.type = 'file';
        input.id = 'file';
        input.accept = accept

        input.onchange = async (e) =>
        {
            const file = input.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve({
                data: reader.result.replace(new RegExp('data:.*/.*;base64,'), ''),
                url: reader.result,
                fileName: file.name
            });
            reader.onerror = error => reject(error);
        }

        input.click()
    })
}

function getFile({accept})
{
    return new Promise((resolve, reject) =>
    {
        const input = document.createElement('input');
        input.type = 'file';
        input.id = 'file';
        input.accept = accept

        input.onchange = async (e) =>
        {
            const file = input.files[0];
            resolve({
                data: file,
                fileName: file.name
            });
        }

        input.click()
    })
}

const FileHelper = {
    saveFile,
    getFile,
    getFileAsBase64
}

export default FileHelper