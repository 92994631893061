import {useEffect, useRef, useState} from "react";
import {v4} from "uuid";
import RoleSelectBox from "./RoleSelectBox";
import {Button, Form, Select, Space} from "antd";
import {t} from "i18next";
import PermissionGroup from "./PermissionGroup";
import {useModal} from "../../core/context/ModalContext";
import RoleCreateEdit from "./RoleCreateEdit";
import ApiService from "../../core/service/api-service";
import {toast} from "react-toastify";

export default function RoleManager()
{
    // key degistiginde component yeniden render edilir;
    const [key, setKey] = useState(v4());
    const {openModal, closeModal} = useModal()
    const [selectedRole, setSelectedRole] = useState({
        permissionIdList: []
    });
    const [selectedPermissions, setSelectedPermissions] = useState([])
    const formRef = useRef();

    const getRole = async (roleId) => {
        const response = await ApiService.Role.Get(roleId);
        if(response.success)
        {
            setSelectedRole(response.data)
            setSelectedPermissions(response.data.permissionIdList)
        }
    }

    const setPermissions = async () => {
        if(!selectedRole.id)
        {
            toast.error(t('Please Select Role!'))
            return
        }
        const response = await ApiService.Role.SetRolePermissions(selectedRole.id, selectedPermissions);
        if(response.success)
        {
            refresh()
        }
    }

    const refresh = () => {
        setKey(v4())
        setSelectedRole({permissionIdList: []})
        setSelectedPermissions([])
    }

    return (
        <div key={key}>
            <Form
                ref={formRef}
                layout={'vertical'}
            >

                <Form.Item>
                    <Button
                        onClick={() => {
                            openModal(t('New Role'), (
                                <>
                                    <RoleCreateEdit
                                        onSaved={async () => {
                                            refresh()
                                            closeModal()
                                        }}
                                    />
                                </>
                            ))
                        }}
                    >
                        {t('New Role')}
                    </Button>
                    <Button
                        onClick={() => {
                            if(!selectedRole.id)
                            {
                                toast.error(t('Please Select Role!'))
                                return
                            }
                            openModal(t('Edit Role Name'), (
                                <>
                                    <RoleCreateEdit
                                        roleId={selectedRole.id}
                                        roleName={selectedRole.name}
                                        onSaved={async () => {
                                            refresh()
                                            closeModal()
                                        }}
                                    />
                                </>
                            ))
                        }}
                    >
                        {t('Edit Role Name')}
                    </Button>
                    <Button
                        onClick={setPermissions}
                    >
                        {t('Set Permissions')}
                    </Button>
                    {/*<Button>{t('Duplicate')}</Button>*/}
                    {/*<Button>{t('Delete')}</Button>*/}
                </Form.Item>

                <Form.Item
                    label={t('Roles')}
                    name={'role'}
                >
                    <RoleSelectBox
                        onChange={getRole}
                    />
                </Form.Item>

                <Form.Item>
                    <PermissionGroup
                        selectedPermissions={selectedPermissions}
                        onChangeSelectedPermissions={(permissions) => {
                            setSelectedPermissions(permissions)
                        }}
                    />
                </Form.Item>
            </Form>
        </div>
    )
}
