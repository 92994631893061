import {Menu} from "antd";
import {v4} from "uuid";
import {Pages} from "../../../AppRouter";
import ApiService from "../../../core/service/api-service";
import {useTranslation} from "react-i18next";
import {useDataGrid} from "../../../core/components/data-grid/DataGridContext";
import RowMenu from "../../../core/components/data-grid/RowMenu";
import {PERMISSIONS, useUser} from "../../../core/context/UserContext";
import ReportPreview from "../ReportPreview";
import {useModal} from "../../../core/context/ModalContext";
import ReportModal from "../../../pages/report/ReportModal";

export default function ReportRowMenu({row})
{
    const {t} = useTranslation();
    const {refreshDataGrid} = useDataGrid();
    const {hasPermission} = useUser();
    const {openModal, closeModal} = useModal()

    const openReportPreviewModal = (studyId, requestId, reportId) => {
        openModal(t('Report Preview'), (
            <ReportPreview
                studyId={studyId}
                requestId={requestId}
                reportId={reportId}
            />
        ))
    }

    return (
        <>
            <RowMenu text={row.id}>
                <Menu>
                    {
                        hasPermission(PERMISSIONS.REPORT_ADD_OR_EDIT) && (
                            <Menu.Item
                                key={'add-request-report'}
                                onClick={() => {
                                    openModal(t('Report'), (
                                        <ReportModal
                                            studyId={row.studyId}
                                            requestId={row.requestId}
                                        />
                                    ), null, window.innerWidth*0.98, 20)
                                }}>
                                <div>
                                    {t('Request & Reports') + ' (R)'}
                                </div>
                            </Menu.Item>
                        )
                    }
                    {
                        hasPermission(PERMISSIONS.REPORT_LIST) && (
                            <Menu.Item
                                key={v4()}
                                onClick={() => {openReportPreviewModal(row.studyId, row.requestId, row.id)}}
                            >
                                {t('Report Preview')}
                            </Menu.Item>
                        )
                    }
                    {
                        hasPermission(PERMISSIONS.REPORT_CONFIRM) && !row.isConfirm && (
                            <Menu.Item
                                key={v4()}>
                                <div
                                    onClick={async () => {
                                        await ApiService.Report.ConfirmReport(row.studyId, row.requestId, row.id);
                                        refreshDataGrid()
                                    }}
                                >
                                    {t('Confirm Report')}
                                </div>
                            </Menu.Item>
                        )
                    }
                    {
                        hasPermission(PERMISSIONS.REPORT_REMOVE_CONFIRM) && row.isConfirm && (
                            <Menu.Item
                                key={v4()}>
                                <div
                                    onClick={async () => {
                                        await ApiService.Report.RemoveReportConfirmation(row.studyId, row.requestId, row.id);
                                        refreshDataGrid()
                                    }}
                                >
                                    {t('Remove Confirmation')}
                                </div>
                            </Menu.Item>
                        )
                    }
                    {
                        hasPermission(PERMISSIONS.REPORT_DOWNLOAD_PDF) && row.isConfirm && (
                            <Menu.Item
                                key={v4()}
                            >
                                <div
                                    onClick={async () => {
                                        const response = await ApiService.ReportGenerator.GetReportPdf(row.studyId, row.requestId, row.id);
                                        if(response.success)
                                        {
                                            ApiService.downloadFile(response.data, response.fileName)
                                        }
                                    }}
                                >
                                    {t('Download PDF')}
                                </div>
                            </Menu.Item>
                        )
                    }
                    {
                        hasPermission(PERMISSIONS.REPORT_DOWNLOAD_WORD) && row.isConfirm && (
                            <Menu.Item
                                key={v4()}
                            >
                                <div
                                    onClick={async () => {
                                        const response = await ApiService.ReportGenerator.GetReportWord(row.studyId, row.requestId, row.id)
                                        if(response.success)
                                        {
                                            ApiService.downloadFile(response.data, response.fileName)
                                        }
                                    }}
                                >
                                    {t('Download Word')}
                                </div>
                            </Menu.Item>
                        )
                    }
                    {
                        hasPermission(PERMISSIONS.STUDY_REQUEST_DELETE) && !row.isConfirm && !row.isRequestDeleted && (
                            <Menu.Item
                                key={v4()}
                            >
                                <div
                                    onClick={async () => {
                                        const response = await ApiService.Request.DeleteRequest(row.studyId, row.requestId)
                                        if(response.success)
                                        {
                                            refreshDataGrid()
                                        }
                                    }}
                                >
                                    {t('Delete')}
                                </div>
                            </Menu.Item>
                        )
                    }
                    {
                        hasPermission(PERMISSIONS.STUDY_REQUEST_RESTORE_DELETED) && row.isRequestDeleted && (
                            <Menu.Item
                                key={v4()}
                            >
                                <div
                                    onClick={async () => {
                                        const response = await ApiService.Request.RestoreRequest(row.studyId, row.requestId)
                                        if(response.success)
                                        {
                                            refreshDataGrid()
                                        }
                                    }}
                                >
                                    {t('Restore')}
                                </div>
                            </Menu.Item>
                        )
                    }
                </Menu>
            </RowMenu>
        </>
    )
}
