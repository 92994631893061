import {useEffect, useRef, useState} from "react";
import {v4} from "uuid";

const preventDefault = (e) => {
    !window.stopPrevent && e.preventDefault()
    window.stopPrevent = false
}

const useKeyListener = ({keyCode, modifier}) => {
    const modifierKey = useRef(null)
    const [keyPressed, setKeyPressed] = useState({
        keyDown: (e) => {
            if(e.code === keyCode)
                preventDefault(e)

            if(e.code === modifier) {
                preventDefault(e)
                modifierKey.current = e
            }
        },
        keyUp: (e) => {
            if(modifier && modifierKey?.current?.code === e.code) {
                modifierKey.current = null
                preventDefault(e)
                return;
            }

            if(modifier && modifierKey?.current?.code !== modifier) {
                return
            }

            if(e.code === keyCode) {
                preventDefault(e)
                setKeyPressed({
                    ...keyPressed,
                    key: e,
                    time: new Date(),
                    id: v4(),
                    pressed: true
                })
            }
        },
        pressed: null
    })

    useEffect(() => {
        window.addEventListener('keydown', keyPressed.keyDown);
        window.addEventListener('keyup', keyPressed.keyUp);

        return () => {
            window.removeEventListener('keydown', keyPressed.keyDown);
            window.removeEventListener('keyup', keyPressed.keyUp);
        };
    }, []);

    return keyPressed
}

export {useKeyListener}
