import {useRef} from "react";
import {Button, Form, Input} from "antd";
import {useTranslation} from "react-i18next";

export default function ShareEmailModal({onFinish}) {
    const formRef = useRef();
    const {t} = useTranslation();

    const finish = () => {
        onFinish && onFinish(formRef.current.getFieldsValue())
    }

    return (
        <>
            <Form
                ref={formRef}
                onFinish={finish}
                size={'small'}
                layout={'vertical'}
            >
                <Form.Item
                    label={t('Email')}
                    name={'email'}
                >
                    <Input />
                </Form.Item>
                {/*<Form.Item*/}
                {/*    label={t('Title')}*/}
                {/*    name={'title'}*/}
                {/*>*/}
                {/*    <Input />*/}
                {/*</Form.Item>*/}

                <Form.Item>
                    <Button
                        type={'primary'}
                        htmlType={'submit'}
                    >
                        {t('Send')}
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}