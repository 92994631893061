import {useDataGrid} from "../../../../core/components/data-grid/DataGridContext";
import ApiService from "../../../../core/service/api-service";
import {useEffect} from "react";
import './study-file-grid.css'
import DataGrid from "../../../../core/components/data-grid/DataGrid";
import {t} from "i18next";
import {dateFormat} from "../../../../core/utils/date-utils";
import {Button, Space, Tooltip, Typography} from "antd";
import {useModal} from "../../../../core/context/ModalContext";
import FileUpload from "../../../../core/components/FileUpload";
import {PERMISSIONS, useUser} from "../../../../core/context/UserContext";
import Flex from "../../../../core/components/Flex";

const colors = [
    {color: '#ced6e0', text: 'DELETED-ROW'}
]

export default function StudyFileGrid({studyId})
{
    const {setDataGridProps, queryModel, setData, setPaginationResult, refreshDataGrid} = useDataGrid()
    const {openModal, closeModal} = useModal();
    const {hasPermission} = useUser()

    const initDataGrid = async () => {
        const response = await ApiService.StudyFile.DataGridByStudyId(studyId, queryModel);
        setData(response.data)
        setPaginationResult(response.pagination)
    }


    useEffect(() => {
        initDataGrid()
    }, [queryModel.updateKey])

    const downloadFile = async (fileId) => {
        const response = await ApiService.StudyFile.DownloadFile(studyId, fileId);
        if(response.success)
        {
            ApiService.downloadFile(response.data, response.fileName);
        }
    }

    const deleteFile = async (fileId) => {
        const response = await ApiService.StudyFile.DeleteFile(studyId, fileId);
        if(response.success)
        {
            refreshDataGrid()
        }
    }

    const restoreFile = async (fileId) => {
        const response = await ApiService.StudyFile.RestoreFile(studyId, fileId);
        if(response.success)
        {
            refreshDataGrid()
        }
    }

    useEffect(() => {
        setDataGridProps({
            rowKey: 'id',
            enableRowSelection: false,
            columns: [
                {
                    title: t('Upload Date'),
                    dataIndex: 'createdDate',
                    key: 'createdDate',
                    columnName: 'createdDate',
                    width: 200,
                    render: (text, record) => {
                        return (
                            <>
                                {dateFormat(text)}
                                {
                                    hasPermission(PERMISSIONS.STUDY_FILE_DELETE) && !record.isDeleted && (
                                        <Button
                                            size={'small'}
                                            type={'text'}
                                            danger={true}
                                            onClick={() => deleteFile(record.id)}
                                        >
                                            {t('Delete')}
                                        </Button>
                                    )
                                }
                                {
                                    hasPermission(PERMISSIONS.STUDY_FILE_RESTORE_DELETED) && record.isDeleted && (
                                        <Button
                                            type={'text'}
                                            size={'small'}
                                            danger={true}
                                            style={{}}
                                            onClick={() => restoreFile(record.id)}
                                        >
                                            {t('Restore')}
                                        </Button>
                                    )
                                }
                            </>
                        )
                    }
                },
                {
                    title: t('File Name'),
                    dataIndex: 'originalFileName',
                    key: 'originalFileName',
                    columnName: 'originalFileName',
                    width: 300,
                    render: (text, record) => {
                        if(!hasPermission(PERMISSIONS.STUDY_FILE_DOWNLOAD))
                        {
                            return text;

                        }
                        return (
                            <>
                                <Typography.Link onClick={() => {downloadFile(record.id)}}>
                                    {text}
                                </Typography.Link>
                            </>
                        );
                    }
                },
                {
                    title: t('Description'),
                    dataIndex: 'description',
                    key: 'description',
                    columnName: 'description',
                }
            ],
            rowClassName: (record, index) => {
                let className = 'normal-row';

                if(record.isDeleted)
                    className = 'deleted-row ';

                return className;
            },
        })
    }, [])

    const openModel = () => {
        openModal(t('Upload File'), (
            <>
                <FileUpload
                    action={ApiService.StudyFile.UploadFileUrl(studyId)}
                    onFileUploaded={() => {
                        closeModal()
                        refreshDataGrid()
                    }}
                />
            </>
        ))
    }

    return (
        <>
            <DataGrid
                title={() => (
                    <Space size={'small'}>
                        <div>
                            <Flex flexDirection={'row'}>
                                {
                                    colors.map(color => {
                                        return (
                                            <Tooltip title={t(color.text)}>
                                                <div style={{width: 16, height: 16, backgroundColor: color.color, marginRight: 4}} />
                                            </Tooltip>
                                        )
                                    })
                                }
                            </Flex>
                        </div>
                        {
                            hasPermission(PERMISSIONS.STUDY_FILE_UPLOAD) && (<Button size={'small'} type={'info'} onClick={openModel}>{t('Upload File')}</Button>)
                        }
                    </Space>
                )}
                name={'study-files'}
            />
        </>
    )
}