import {Modal as AntdModal, Button} from "antd";
import {useModal} from "../context/ModalContext";
import {use} from "i18next";
import {useEffect, useState} from "react";

export default function Modal()
{
    const {modal, closeModal} = useModal();
    const [reRender, setReRender] = useState(false)
    const [render, setRender] = useState(false)

    // modal yeniden acildiginda component guncellenmiyor.
    // render ile tekrar renderlanmaya zorlaniyor.
    useEffect(() => {
        if(modal.open)
        {
            setReRender(true)
            setRender(false)
        }
    }, [modal.open])

    useEffect(() => {
        if(reRender)
        {
            setTimeout(() => {
                setRender(true)
                setReRender(false)
            }, 100)
        }
    },[reRender])

    return (
        <div>
            {
                modal.open && (
                    <AntdModal
                        style={{top: modal.top || window.innerHeight*0.2}}
                        width={modal.width || window.innerWidth*0.6}
                        visible={modal.open}
                        title={modal.title}
                        onCancel={closeModal}
                        onOk={false}
                        footer={false}
                    >
                        {
                            render && modal.component
                        }
                    </AntdModal>
                )
            }
            {
                modal?.modal2?.open && (
                    <AntdModal
                        style={{marginTop: 24}}
                        width={window.innerWidth*0.5}
                        visible={modal.modal2.open}
                        title={modal.modal2.title}
                        onCancel={closeModal}
                        onOk={false}
                        footer={false}
                    >
                        {
                            render && modal.modal2.component
                        }
                    </AntdModal>
                )
            }
        </div>
    )
}
