import {Button, Card, Col, DatePicker, Form, Row} from "antd";
import LayoutWithSider from "../../core/components/layout/layout-with-sider/LayoutWithSider";
import {DataGridProvider} from "../../core/components/data-grid/DataGridContext";
import HospitalDashboard from "../../app/dashboard/HospitalDashboard";
import ReadUserDashboard from "../../app/dashboard/ReadUserDashboard";
import WrittenUserDashboard from "../../app/dashboard/WrittenUserDashboard";
import QuickDatePicker from "../../core/components/data-grid/QuickDatePicker";
import {useEffect, useRef, useState} from "react";
import {DATE_OPTIONS, getBeginStr, getDateFromNow, getEndStr} from "../../core/utils/date-utils";
import {t} from 'i18next'
import {useApp} from "../../core/context/AppContext";
import ModalitySelectBox from "../../app/modality/ModalitySelectBox";
import {PERMISSIONS, useUser} from "../../core/context/UserContext";
import ApiService from "../../core/service/api-service";
import {downloadExcel, DownloadTableExcel} from "react-export-table-to-excel";
import FileUtils from "../../core/utils/file-utils";

export default function DashboardPage() {
    const formRef = useRef()
    const {modalities} = useApp()
    const {hasPermission, showDashboard} = useUser()
    const [dashboard, setDashboard] = useState({
        hospitalData: [],
        reporterData: [],
        doctorData: []
    })
    const hospitalTableRef = useRef()
    const reporterTableRef = useRef()
    const doctorTableRef = useRef()

    const date = getDateFromNow(DATE_OPTIONS.THIS_MONTH)

    const onFinish = async () => {
        const form = formRef.current.getFieldsValue();
        const filter = {
            ...form,
            reportCreatedDateBegin: getBeginStr(form.reportCreatedDateBegin),
            reportCreatedDateEnd: getEndStr(form.reportCreatedDateEnd),
        }

        const _dashboard = {}
        const resHospital = await ApiService.Dashboard.HospitalStatistics(filter)
        if (resHospital.success) {
            _dashboard.hospitalData = resHospital.data
        }

        const resDoctor = await ApiService.Dashboard.ReadUserStatistics(filter)
        if (resDoctor.success) {
            _dashboard.doctorData = resDoctor.data
        }

        const resReporter = await ApiService.Dashboard.WrittenUserStatistics(filter)
        if (resReporter.success) {
            _dashboard.reporterData = resReporter.data
        }

        setDashboard(_dashboard)
    }

    const toExcel = (table, name, fileName) => {
        const uri = 'data:application/vnd.ms-excel;base64,';

        const template =
            '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>';

        const base64 = function (s) {
            return window.btoa(unescape(encodeURIComponent(s)));
        };

        const format = function (s, c) {
            return s.replace(/{(\w+)}/g, function (m, p) {
                return c[p];
            });
        };

        if (!table.nodeType) table = document.getElementById(table);
        const ctx = {
            worksheet: name || 'Worksheet',
            table: table.innerHTML,
        };
        window.location.href = uri + base64(format(template, ctx));
        ApiService.downloadFile(
            uri + base64(format(template, ctx)),
            fileName
        );
    };

    return (
        <>
            <LayoutWithSider fullWidth={true}>
                <Row justify={'center'} gutter={[24, 12]}>
                    <Col xs={24}>
                        <Card>
                            <Form
                                size={'small'}
                                ref={formRef}
                                initialValues={{
                                    reportCreatedDateBegin: date.start,
                                    reportCreatedDateEnd: date.end
                                }}
                                onFinish={onFinish}
                            >
                                <div
                                    style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 8}}
                                >
                                    <Form.Item
                                        style={{marginBottom: 0}}
                                    >
                                        <QuickDatePicker
                                            onChange={(start, end) => {
                                                formRef.current.setFieldsValue({
                                                    reportCreatedDateBegin: start,
                                                    reportCreatedDateEnd: end,
                                                })
                                            }}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        style={{marginBottom: 0}}
                                        label={t('')}
                                        name="reportCreatedDateBegin"
                                    >
                                        <DatePicker showToday={false}/>
                                    </Form.Item>

                                    <Form.Item
                                        style={{marginBottom: 0}}
                                        label={''}
                                        name="reportCreatedDateEnd"
                                    >
                                        <DatePicker showToday={false}/>
                                    </Form.Item>

                                    <Form.Item
                                        style={{marginBottom: 0}}
                                    >
                                        <Button type={'primary'} htmlType={'submit'}>
                                            Search
                                        </Button>
                                    </Form.Item>

                                    <Button size={'small'} type={'success'} onClick={() => {
                                        const start = formRef.current.getFieldsValue().reportCreatedDateBegin;
                                        const end = formRef.current.getFieldsValue().reportCreatedDateEnd;

                                        const table = document.createElement('table')
                                        const rowHeader = document.createElement('tr')
                                        rowHeader.innerHTML = `
                                        <td style="font-size: 13pt"><span style="font-weight: bold">Tarih:</span> ${new Date(
                                            start
                                        ).toLocaleString()} - ${new Date(
                                            end
                                        ).toLocaleString()}</td>
                                        `;
                                        const row1 = document.createElement('tr')
                                        const row2 = document.createElement('tr')
                                        const row3 = document.createElement('tr')
                                        const cell1 = document.createElement('td')
                                        const cell2 = document.createElement('td')
                                        const cell3 = document.createElement('td')
                                        cell1.appendChild(hospitalTableRef.current.cloneNode(true))
                                        cell2.appendChild(doctorTableRef.current.cloneNode(true))
                                        cell3.appendChild(reporterTableRef.current.cloneNode(true))
                                        row1.appendChild(cell1)
                                        row2.appendChild(cell2)
                                        row3.appendChild(cell3)

                                        table.appendChild(rowHeader)
                                        table.appendChild(row1)
                                        table.appendChild(row2)
                                        table.appendChild(row3)

                                        const fileName = `Dashboard_${new Date(
                                            start
                                        ).toLocaleDateString()}_${new Date(
                                            end
                                        ).toLocaleDateString()}.xls`;
                                        toExcel(table, 'Dashboard', fileName);
                                    }}
                                    >
                                        Excel
                                    </Button>
                                </div>
                            </Form>
                        </Card>
                    </Col>

                    {
                        showDashboard() && (
                            <Col xs={24}>
                                <Card title={t('Hospital')}>
                                    <HospitalDashboard
                                        tableRef={hospitalTableRef}
                                        modalities={modalities}
                                        data={dashboard.hospitalData}
                                    />
                                </Card>
                            </Col>
                        )
                    }
                    {
                        showDashboard('doctor') && (
                            <Col xs={24}>
                                <Card title={t('Doctor')}>
                                    <ReadUserDashboard
                                        tableRef={doctorTableRef}
                                        modalities={modalities}
                                        data={dashboard.doctorData}/>
                                </Card>
                            </Col>
                        )
                    }
                    {
                        showDashboard('reporter') && (
                            <Col xs={24}>
                                <Card title={t('Reporter')}>
                                    <WrittenUserDashboard
                                        tableRef={reporterTableRef}
                                        modalities={modalities}
                                        data={dashboard.reporterData}/>
                                </Card>
                            </Col>
                        )
                    }
                </Row>
            </LayoutWithSider>
        </>
    )
}
