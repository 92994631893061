import {useTranslation} from "react-i18next";
import CardTitle from "../../core/components/CardTitle";
import {Button, Card} from "antd";
import Flex from "../../core/components/Flex";
import {useHistory} from "react-router-dom";
import {Pages} from "../../AppRouter";
import ResponsiveLayout from "../../core/components/ResponsiveLayout";
import LayoutWithSider from "../../core/components/layout/layout-with-sider/LayoutWithSider";
import StudyFilter from "../../app/study/study-grid/StudyFilter";
import StudyGrid from "../../app/study/study-grid/StudyGrid";
import {DataGridProvider} from "../../core/components/data-grid/DataGridContext";
import ReportTemplateGrid from "../../app/report-template/report-template-grid/ReportTemplateGrid";
import ReportTemplateFilter from "../../app/report-template/report-template-grid/ReportTemplateFilter";
import {useEffect} from "react";

export default function ReportTemplateDataGridPage()
{
    const {t} = useTranslation();
    const history = useHistory();

    useEffect(() => {
        document.title = `ECloud Pacs - ${t('Report Templates')}`
    }, []);

    const Title = () => {

        return (
            <Flex justifyContent={'space-between'}>
                <CardTitle title={t('Report Templates')} />
                <Button
                    onClick={() => {
                        history.push(Pages.ReportTemplate.Create)
                    }}
                >
                    {t('New Report Template')}
                </Button>
            </Flex>
        )
    }

    return (
        <>
            <DataGridProvider>
                <LayoutWithSider
                    fullWidth={true}
                    sidebarTitle={t('Report Template Filter')}
                    sidebarComponent={(
                        <ReportTemplateFilter/>
                    )}
                >
                    <Card title={<Title />}>
                        <ReportTemplateGrid />
                    </Card>
                </LayoutWithSider>
            </DataGridProvider>
        </>
    )
}
