
export default function Flex({
                                 children, justifyContent,
                                 alignItems, flexWrap,
                                 flexDirection, justifyItems, style = {}
                             })
{

    const getStyles = () => {
        let styles = {
            display: 'flex',
        };

        if(justifyContent)
            styles.justifyContent = justifyContent;

        if(alignItems)
            styles.alignItems = alignItems;

        if(flexDirection)
            styles.flexDirection = flexDirection;

        if(justifyItems)
            styles.justifyItems = justifyItems;

        if(flexWrap)
            styles.flexWrap = flexWrap;

        return styles;
    }

    return (
        <div style={{...(getStyles()), ...style}}>
            {children}
        </div>
    )
}
