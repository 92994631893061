import AudioPlayer from "../AudioPlayer";
import {useEffect, useRef, useState} from "react";
import ApiService from "../../core/service/api-service";
import {Button, Space, Table, Tooltip, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {dateFormat} from "../../core/utils/date-utils";
import {PlayCircleOutlined} from "@ant-design/icons";
import AudioPlayer2 from "react-h5-audio-player";
import Flex from "../../core/components/Flex";
import {t} from "i18next";
import {PERMISSIONS} from "../../core/context/UserContext";
import DataGrid from "../../core/components/data-grid/DataGrid";
import {useDataGrid} from "../../core/components/data-grid/DataGridContext";
import StudyLogEnum from "./StudyLogEnum";

export default function StudyLogsGrid({studyId})
{
    const {setDataGridProps, queryModel, setData, setPaginationResult, refreshDataGrid} = useDataGrid()
    const [logs, setLogs] = useState([]);
    const {t} = useTranslation();

    const initDataGrid = async () => {
        const response = await ApiService.Study.GetStudyLogs({
            ...queryModel,
            filter: {
                studyId: studyId
            }
        });
        if(response.success)
        {
            setData(response.data)
            setPaginationResult(response.pagination)
        }
    }


    useEffect(() => {
        initDataGrid()
    }, [queryModel.updateKey])

    useEffect(() => {
        setDataGridProps({
            rowKey: 'id',
            enableRowSelection: false,
            antdTableProps: {
                scroll: {}
            },
            columns: [
                {
                    title: t('Date'),
                    dataIndex: 'createdDate',
                    key: 'createdDate',
                    width: 150,
                    render: (text, record) => new Date(text).toLocaleString()
                },
                {
                    title: t('Type'),
                    dataIndex: 'type',
                    key: 'type',
                    width: 250,
                    render: (text, record) => text
                },
                {
                    title: t('User'),
                    dataIndex: 'user',
                    key: 'user',
                    width: 400,
                    render: (text, record) => {
                        const vals = record?.user?.split('__')
                        if(vals?.length > 1)
                            return vals[1]

                        return '-'
                    }
                },
                {
                    title: t('Description'),
                    dataIndex: 'description',
                    key: 'description',
                    render: (text, record) => record?.description
                },
            ]
        })
    }, [])

    return (
        <DataGrid
            name={'study-logs'}
        />
    )
}
